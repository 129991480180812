import React from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import EditIcon from '../../../images/edit.webp';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import './expansion.scss';
import appStore, { CharacterViewOptions, CreateCharacterViewOptions, ViewTypeOptions } from '../../../store/AppStore';
import CharacterCatalogCPExpansionItem from './CharacterCatalogCPExpansionItem';
import backgroundImage from '../../../images/gray-orange-abstract-texture.webp';
import PlusIcon from '../../../images/plus-math.svg';
import KindredIcon from '../../../images/kindred.svg';
import FantasyIcon from '../../../images/fantasy-icon.png';
import RWLogoIcon from '../../../images/Realm_worlds_logo.png';
import { Character } from '../../../__generated__/graphql';
import {
  CPCreateButton, CPCreateButtonText, FlexibleHeightImg, SectionHeadingText,
} from '../../styledComps/styledComps';

interface Props {
  title: string | null | undefined,
  backgroundUrl: string | null | undefined,
  characters: (Character | null)[]
}

/**
  * A new expansion component specifically for the character catalog - character section
  * It is different from the token maker expansion component in the following ways:
    - It is expected to have a background image
    - It doesn't have a seperate header component
    - It has a custom image border
  * It must have the child component `CharacterCatalogCPExpansionItem`
*/
function CharacterCatalogCPExpansion({
  title,
  backgroundUrl,
  characters,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const viewType:
  ViewTypeOptions = appStore(
    (state) => state.viewType,
  );

  const setSelectedView = (view: CharacterViewOptions) => {
    appStore.setState({
      selectedCharacterView: view,
    });
  };
  const createCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.createCharacterView,
  );
  const setCreateCharacterView = (view: CreateCharacterViewOptions) => {
    if (view !== createCharacterView) {
      appStore.setState({
        createCharacterView: view,
      });
    }
  };
  const defaultCreateCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.defaultCreateCharacterView,
  );

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '300px',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundUrl && backgroundUrl !== undefined && characters.length > 0 ? backgroundUrl : backgroundImage})`,
          backgroundPosition: 'center center',
          backgroundColor: 'rgba(208, 177, 122, 0.50)',
          backgroundRepeat: 'none',
          zIndex: '1',
        }}
      />
      <Box
        sx={{
          width: '100%',
          minHeight: '300px',
          boxSizing: 'border-box',
          padding: '38px 50px',
          border: '8px solid #68583D',
          borderImageSource: `url(${CustomBorder})`,
          borderImageSlice: '17',
          borderImageRepeat: 'round',
          backgroundColor: '#D0B17AAA',
          position: 'relative',
          zIndex: '5',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '26px',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px', /* 66.667% */
                letterSpacing: '0.1px',
                color: '#250503',
              }}
            >
              {title}
            </Typography>
            <img src={EditIcon} alt="edit" />
          </Box>

          {characters.length > 6 && <ArrowDropDown onClick={() => setIsExpanded(!isExpanded)} className={`expansionArrow cp ${isExpanded ? 'rotated' : ''}`} />}

        </Box>
        {/* content */}
        {characters.length > 0
          ? (
            <Box
              sx={{
                display: 'flex',
                gap: viewType === 'grid' ? '50px' : '25px',
                flexWrap: 'wrap',
                paddingTop: '34px',
              }}
            >
              {characters.slice(0, 6).map((character) => (
                <CharacterCatalogCPExpansionItem
                  key={character?._id}
                  character={character}
                />
              ))}
              {/* <CharacterCatalogCPExpansionItem />
              <CharacterCatalogCPExpansionItem />
              <CharacterCatalogCPExpansionItem />
              <CharacterCatalogCPExpansionItem />
              <CharacterCatalogCPExpansionItem />
              {isExpanded && (
                <>
                  <CharacterCatalogCPExpansionItem />
                  <CharacterCatalogCPExpansionItem />
                  <CharacterCatalogCPExpansionItem />
                  <CharacterCatalogCPExpansionItem />
                  <CharacterCatalogCPExpansionItem />
                  <CharacterCatalogCPExpansionItem />
                </>
              )} */}
            </Box>
          ) : (
            <Stack pt="34px" alignItems="center" justifyContent="center" gap="16px">
              <SectionHeadingText
                sx={{
                  fontSize: '36px!important',
                  lineHeight: 'normal!important',
                }}
              >
                Your Character Set is Empty
              </SectionHeadingText>
              <Stack gap="33px" direction="row" alignItems="center" justifyContent="center">
                <CPCreateButton
                  sx={{
                    background: '#41521F',
                    '&:hover': {
                      background: '#41521F',
                    },
                  }}
                  onClick={() => {
                    setSelectedView('creator');
                    setCreateCharacterView(defaultCreateCharacterView);
                  }}
                >
                  <Stack direction="row" gap="5px" alignItems="center" justifyContent="center">
                    <FlexibleHeightImg src={PlusIcon} width="8px" />
                    <FlexibleHeightImg src={KindredIcon} width="25px" />
                    <CPCreateButtonText>Create a Character</CPCreateButtonText>
                  </Stack>
                </CPCreateButton>
                <CPCreateButton
                  sx={{
                    background: '#DA16A2',
                    '&:hover': {
                      background: '#DA16A2',
                    },
                  }}
                >
                  <Stack direction="row" gap="5px" alignItems="center" justifyContent="center">
                    <FlexibleHeightImg src={PlusIcon} width="8px" />
                    <FlexibleHeightImg src={FantasyIcon} width="25px" />
                    <CPCreateButtonText>AI Generate Character</CPCreateButtonText>
                  </Stack>
                </CPCreateButton>
                <CPCreateButton
                  sx={{
                    background: '#41521F',
                    '&:hover': {
                      background: '#41521F',
                    },
                  }}
                  onClick={() => setSelectedView('store')}
                >
                  <Stack direction="row" gap="5px" alignItems="center" justifyContent="center">
                    <FlexibleHeightImg src={RWLogoIcon} width="25px" />
                    <CPCreateButtonText>RW Store</CPCreateButtonText>
                  </Stack>
                </CPCreateButton>
              </Stack>
            </Stack>
          )}
      </Box>
    </Box>
  );
}

export default CharacterCatalogCPExpansion;
