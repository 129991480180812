import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Link as RouterLink,
} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import {
  Grid,
  Link,
  LinkProps,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Image from 'mui-image';
import appStore from '../../../store/AppStore';
import rwlogo from '../../../images/Realm_worlds_logo.png';
import DiscoverIcon from '../../../images/discover-icon.svg';
import DiscoverIconActive from '../../../images/discover-icon-active.svg';
import RealmsIcon from '../../../images/realms-icon.svg';
import RealmsIconActive from '../../../images/realms-icon-active.svg';
import NewsIcon from '../../../images/news-icon.svg';
import NewsIconActive from '../../../images/news-icon-active.svg';
import FavoritesIcon from '../../../images/favorites-icon.svg';
import FavoritesIconActive from '../../../images/favorites-icon-active.svg';
import SettingsIcon from '../../../images/settings-icon.svg';
import SettingsIconActive from '../../../images/settings-icon-active.svg';
import Avatar from '../../../images/avatar.png';
import MembershipCard from '../../../images/MembershipCard.png';
import { INavItem, NavString } from '../../../interfaces/navitems';
import {
  StyledAvatar, StyledImage,
  StyledListItemIcon, StyledListItemText,
  StyledMembershipCard, StyledText, StyledUserName, VerticalSpacingBox,
} from './SideNav.styles';
import tokenCoin from '../../../assets/images/azamel_Coin.png';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
}

interface LinkRouterProps extends LinkProps {
  to: string;
  // replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return (
    <Link
      {...props}
      component={RouterLink as any}
      sx={{
        fontFamily: 'Passero One',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textDecoration: 'none',
      }}
    />
  );
}

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const activeItem: NavString | null = appStore((state) => state.activeNavItem);

  const userCredits:
  number = appStore(
    (state) => state.userCredits,
  );

  const userName:
  string = appStore(
    (state) => state.userName as string,
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const itemList: INavItem [] = [
    { id: 0, text: 'Discover', icon: <StyledImage src={activeItem === 'Discover' ? DiscoverIconActive : DiscoverIcon} /> },
    { id: 1, text: 'Favorites', icon: <StyledImage src={activeItem === 'Favorites' ? FavoritesIconActive : FavoritesIcon} /> },
    { id: 2, text: 'Realms', icon: <StyledImage src={activeItem === 'Realms' ? RealmsIconActive : RealmsIcon} /> },
    { id: 3, text: 'News', icon: <StyledImage src={activeItem === 'News' ? NewsIconActive : NewsIcon} /> },
    { id: 4, text: 'Settings', icon: <StyledImage src={activeItem === 'Settings' ? SettingsIconActive : SettingsIcon} /> },
  ];

  const drawer = (
    <VerticalSpacingBox>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box pb={2} display="flex" flexDirection="column" width="167px">
          <Grid container>
            <Grid item xs={5}>
              <Image
                style={{
                  maxWidth: '70px',
                  maxHeight: '80px',
                }}
                src={rwlogo}
                alt="RW Logo"
              />

            </Grid>
&nbsp; &nbsp;
            <Grid item xs={3}><Typography variant="h6" sx={{ fontFamily: 'Passero One' }}>Realm Worlds</Typography></Grid>
            {' '}

            <Grid item xs={6} />
          </Grid>
        </Box>
        <Box
          sx={{
            height: '338px',
            flexShrink: 0,
            borderRadius: '4px',
            background: '#D0B17A',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <List sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '135px',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
          >
            {itemList.map(({ text, icon }: INavItem) => (
              <ListItem
                button
                key={text}
                disablePadding
                onClick={() => appStore.setState({
                  activeNavItem: text,
                })}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  backgroundColor: activeItem === text ? '#250503' : 'transparent', // Set background color for active item
                }}
              >
                <ListItemButton>
                  <StyledListItemIcon>{icon}</StyledListItemIcon>
                  <StyledListItemText
                    primary={text}
                    sx={{
                      color: activeItem === text ? '#FFF' : '#250503', // Set text color for active item
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pb: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItem: 'center',
                flexShrink: 0,
                borderRadius: '4px',
                background: '#D0B17A',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                padding: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItem: 'center',
                  maxWidth: '182px',
                  // maxHeight: '55px',
                  columnGap: '5px',
                  // m: 1,
                }}
              >
                <StyledAvatar src={Avatar} />
                <Grid
                  container
                  sx={{
                    m: 0.3,
                  }}
                >
                  <Grid item xs={6}>
                    <StyledUserName>{userName}</StyledUserName>
                  </Grid>
                  {' '}
&nbsp;
                  <Grid item xs={8}>
                    <div
                      style={{
                        display: 'flex', gap: '3px', flexWrap: 'nowrap', alignItems: 'center',
                      }}
                    >
                      <img src={tokenCoin} alt="token" />
                      <Typography sx={{ fontFamily: 'DM Sans', fontSize: '14px' }}>{userCredits}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <StyledMembershipCard src={MembershipCard} />
                  </Grid>

                </Grid>

              </Box>

            </Box>
          </Grid>
          <Grid item xs={3}>
            <StyledText>
              <LinkRouter style={{ whiteSpace: 'nowrap' }} color="#FFF" to="/signup" variant="body2">Privacy Policy</LinkRouter>
            </StyledText>
          </Grid>
          <Grid item xs={3}>
            <StyledText>
              <LinkRouter color="#FFF" to="/signup" variant="body2">About</LinkRouter>
            </StyledText>
          </Grid>
          <Grid item xs={3}>
            <StyledText>
              <LinkRouter color="#FFF" to="/signup" variant="body2">Help</LinkRouter>
            </StyledText>
          </Grid>
          <Grid item xs={3}>
            <StyledText>
              <LinkRouter color="#FFF" to="/signup" variant="body2">Press</LinkRouter>
            </StyledText>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pb: 1 }} />
          <Grid item xs={12}>
            <StyledText sx={{ color: '#FFF' }}>
              Realm worlds LLC ®
              {' '}
              {currentYear}
            </StyledText>
          </Grid>
        </Grid>
      </Box>
    </VerticalSpacingBox>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* Mobile View */}
      <Drawer
        container={container}
        variant="permanent"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>

      {/* Desktop View */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#874000' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
