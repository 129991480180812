/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  cloneElement,
  createContext,
  useContext,
  useState,
  ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
// import { HiXMark } from 'react-icons/hi2'; // Corrected import
import { useOutsideClick } from '../../hooks/useOutsideClick';
import s from './Modal.module.scss';
// import { useDarkMode } from '../../context/DarkModeContext';

interface ModalContextType {
  openName: string;
  close: () => void;
  open: (name: string) => void;
}

/**
 * Context type for the Modal component.
 * @typedef {Object} ModalContextType
 * @property {string} openName - The name of the open modal window.
 * @property {Function} close - Function to close the currently open modal window.
 * @property {Function} open - Function to open a modal window by name.
 */

/**
 * Modal context to manage the state of modal windows.
 * @type {React.Context<ModalContextType>}
 */

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined,
);

/**
 * Props for the Modal component.
 * @typedef {Object} ModalProps
 * @property {React.ReactNode} children - The content of the modal.
 */

/**
 * Modal component to manage modal windows.
 * @component
 * @example
 * // Example usage of Modal component
 * <Modal>
 *   {/* ... modal content components ... *\/}
 * </Modal>
 *
 * @param {ModalProps} props - The properties of the Modal component.
 * @returns {JSX.Element} React element representing the Modal component.
 */

interface ModalProps {
  children: ReactNode;
}

function Modal({ children }: ModalProps) {
  /**
   * State to manage the name of the open modal window.
   * @type {string}
   */

  const [openName, setOpenName] = useState('');

  /**
   * Function to close the currently open modal window.
   * @type {Function}
   */

  const close = () => setOpenName('');

  /**
   * Function to open a modal window by name.
   * @type {Function}
   */
  const open = (name: string) => setOpenName(name);

  return (
    <ModalContext.Provider value={{ openName, close, open }}>
      {children}
    </ModalContext.Provider>
  );
}

interface OpenProps {
  children: ReactNode;
  opensWindowName: string;
}

/**
 * Props for the Open component.
 * @typedef {Object} OpenProps
 * @property {React.ReactNode} children - The content that triggers the opening of the modal.
 * @property {string} opensWindowName - The name of the modal window to open.
 */

/**
 * Open component to trigger the opening of a modal window.
 * @component
 * @example
 * // Example usage of Open component
 * <Modal.Open opensWindowName="exampleModal">
 *   {/* ... content that triggers the opening of the modal ... *\/}
 * </Modal.Open>
 *
 * @param {OpenProps} props - The properties of the Open component.
 * @returns {JSX.Element} React element representing the Open component.
 */

function Open({ children, opensWindowName }: OpenProps) {
  /**
   * Modal context to access modal functions.
   * @type {ModalContextType | undefined}
   */

  const context = useContext(ModalContext);

  if (!context) return null;

  const { open } = context;

  return cloneElement(children as React.ReactElement, {
    onClick: () => open(opensWindowName),
  });
}

interface WindowProps {
  children: ReactNode;
  name: string;
}

/**
 * Window component representing a modal window.
 * @component
 * @example
 * // Example usage of Window component
 * <Modal.Window name="exampleModal" type="large">
 *   {/* ... modal window content components ... *\/}
 * </Modal.Window>
 *
 * @param {WindowProps} props - The properties of the Window component.
 * @returns {JSX.Element | null} React element representing the Window component or null if not active.
 */

function Window({ children, name }: WindowProps) {
  const context = useContext(ModalContext);

  if (!context) return null;

  /**
   * Modal context to access modal state and functions.
   * @type {ModalContextType | undefined}
   */

  const { openName, close } = context;

  /**
   * Ref to handle clicks outside the modal window to close it.
   * @type {React.RefObject<HTMLDivElement>}
   */

  const ref = useOutsideClick(close);

  if (name !== openName) return null;

  return createPortal(
    <div className={s.overlay}>
      <div
        className={s.overlay__modal}
        ref={ref as React.LegacyRef<HTMLDivElement>}
      >
        <div>
          {cloneElement(children as React.ReactElement, {
            onCloseModal: close,
          })}
        </div>
      </div>
    </div>,
    document.body,
  );
}

/**
 * Attach Open and Window components to the Modal component.
 */

Modal.Open = Open;
Modal.Window = Window;

export default Modal;
