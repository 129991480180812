/* eslint-disable max-len */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  CardMedia, Divider, Grid, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AzamelCoinImg from '../../../../images/azamel_Coin.png';
import StripeLogo from '../../../../images/stripe-logo.png';
import PaypalLogo from '../../../../images/paypal_logo.png';
import RecommendedCard from '../../../RecommendedCharacter/RecommendedCharacter';
import SimplePaypal from '../Paypal/SimplePaypal';
// import StripePay from '../Stripe/Stripe';
// import Checkout from '../Stripe/Checkout';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
};
type PaymentOpion = 'coins' | 'paypal' | 'stripe';

// TODO: Implement Add and remove feature for cart items and use the rwStoreItems global variable to keep track of the array

export default function CartItemsModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = (e: Event, r: 'backdropClick' | 'escapeKeyDown') => { onClose?.(e, r); setOpen(false); };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paymentOption, setPaymentOption] = React.useState<PaymentOpion>('coins');

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: '1080px',
              height: '906px',
              background: '#fff',
              borderRadius: 4,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: '1080px',
                height: '50px',
                background: '#41521F',
                mb: '15px',
              }}
            />
            <Grid container sx={{ height: '100%' }}>
              <Grid item container xs={6}>

                {/* Cart Items */}
                <Grid item xs={11} sx={{ height: '70%' }}>
                  <Box>
                    <Typography
                      sx={{
                        ml: 2,
                        mt: 2,
                        mb: '20px',
                        mr: 2,
                        fontFamily: 'Passero One',
                        fontSize: '36px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                      }}
                    >
                      Shopping Cart ( x Items )

                    </Typography>
                  </Box>
                  {/* Cart Item */}
                  <Box
                    sx={{
                      width: '450px',
                      height: '125px',
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: '6px',
                      ml: 2,
                      mb: 2,
                      cursor: 'pointer',
                      background: 'var(--white, #FFF)',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    {/* Image */}
                    <Box
                      sx={{
                        width: '140px',
                        height: '124.5px',
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                      }}
                    >
                      <CardMedia
                        component="img"
                        image="https://picsum.photos/140/124"
                        alt="char from store"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '310px',
                        height: '124.5px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex', justifyContent: 'flex-end', mr: 1, mb: 1,
                        }}
                        // TODO: Implement remove functionality
                        onClick={() => console.log('remove from list!')}
                      >
                        <CancelOutlinedIcon sx={{ width: '15px', height: '15px' }} />
                      </Box>
                      <Box sx={{ ml: 2, mb: 1.5 }}>
                        <Typography sx={{
                          color: 'var(--dark-brown, #250503)',
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          Sacred Mothers
                        </Typography>

                      </Box>
                      <Box sx={{ ml: 2, mb: 1 }}>
                        <Typography sx={{
                          color: '#000',
                          fontFamily: 'DM Sans',
                          fontSize: '10px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
                        </Typography>

                      </Box>
                      <Box sx={{ ml: 2 }}>
                        {' '}
                        <Typography sx={{
                          color: 'var(--dark-brown, #250503)',
                          fontFamily: 'Passero One',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          <span style={{ color: '#A3794A' }}> 10 Credits </span>
                          {' '}
                          | $2.00
                        </Typography>

                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: '450px',
                      height: '125px',
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: '6px',
                      ml: 2,
                      mb: 2,
                      cursor: 'pointer',
                      background: 'var(--white, #FFF)',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    {/* Image */}
                    <Box
                      sx={{
                        width: '140px',
                        height: '124.5px',
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                      }}
                    >
                      <CardMedia
                        component="img"
                        image="https://picsum.photos/140/124"
                        alt="char from store"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '310px',
                        height: '124.5px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex', justifyContent: 'flex-end', mr: 1, mb: 1,
                        }}
                        // TODO: Implement remove functionality
                        onClick={() => console.log('remove from list!')}
                      >
                        <CancelOutlinedIcon sx={{ width: '15px', height: '15px' }} />
                      </Box>
                      <Box sx={{ ml: 2, mb: 1.5 }}>
                        <Typography sx={{
                          color: 'var(--dark-brown, #250503)',
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          Sacred Mothers
                        </Typography>

                      </Box>
                      <Box sx={{ ml: 2, mb: 1 }}>
                        <Typography sx={{
                          color: '#000',
                          fontFamily: 'DM Sans',
                          fontSize: '10px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
                        </Typography>

                      </Box>
                      <Box sx={{ ml: 2 }}>
                        {' '}
                        <Typography sx={{
                          color: 'var(--dark-brown, #250503)',
                          fontFamily: 'Passero One',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          <span style={{ color: '#A3794A' }}> 10 Credits </span>
                          {' '}
                          | $2.00
                        </Typography>

                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: '450px',
                      height: '125px',
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: '6px',
                      ml: 2,
                      mb: 2,
                      cursor: 'pointer',
                      background: 'var(--white, #FFF)',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    {/* Image */}
                    <Box
                      sx={{
                        width: '140px',
                        height: '124.5px',
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                      }}
                    >
                      <CardMedia
                        component="img"
                        image="https://picsum.photos/140/124"
                        alt="char from store"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '310px',
                        height: '124.5px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex', justifyContent: 'flex-end', mr: 1, mb: 1,
                        }}
                        // TODO: Implement remove functionality
                        onClick={() => console.log('remove from list!')}
                      >
                        <CancelOutlinedIcon sx={{ width: '15px', height: '15px' }} />
                      </Box>
                      <Box sx={{ ml: 2, mb: 1.5 }}>
                        <Typography sx={{
                          color: 'var(--dark-brown, #250503)',
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          Sacred Mothers
                        </Typography>

                      </Box>
                      <Box sx={{ ml: 2, mb: 1 }}>
                        <Typography sx={{
                          color: '#000',
                          fontFamily: 'DM Sans',
                          fontSize: '10px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
                        </Typography>

                      </Box>
                      <Box sx={{ ml: 2 }}>
                        {' '}
                        <Typography sx={{
                          color: 'var(--dark-brown, #250503)',
                          fontFamily: 'Passero One',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                        }}
                        >
                          <span style={{ color: '#A3794A' }}> 10 Credits </span>
                          {' '}
                          | $2.00
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
&nbsp;&nbsp;
                  <Divider />
                  &nbsp;&nbsp;
                  <Typography sx={{
                    color: 'var(--dark-brown, #250503)',
                    fontFamily: 'Passero One',
                    fontSize: '25px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    ml: 10,
                  }}
                  >
                    Subtotal ( 3 Items ):
                    {' '}
                    <span style={{ color: '#7A3900' }}>30 Credits</span>
                    {' '}
                    | $6.00
                    {' '}

                  </Typography>
                </Grid>

                {/* Recommended Character Packs */}
                {/* TODO: Use different component and fix it to fit grid pattern */}
                <Grid item sx={{ height: '30%' }}>
                  <Box
                    display="flex"
                    sx={{
                      mt: 5,
                      ml: 2.5,
                      mr: 1,
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '25px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                      }}
                    >
                      Recommended for you

                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    sx={{
                      ml: 0.5,
                      mr: 1,
                      mb: 2,
                    }}
                  >
                    {RecommendedCard}
                  </Box>
                </Grid>
              </Grid>

              {/* Payment Options */}
              <Grid item container xs={6} sx={{ height: '30%' }}>

                <Box sx={{
                  width: '435px',
                  height: '353px',
                  pt: '26px',
                  pb: '37px',
                  pl: '18px',
                  pr: '37px',
                  borderRadius: '20px',
                  border: '1px solid var(--grey-border, #E0E0E0)',
                  background: 'var(--white, #FFF)',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                }}
                >
                  <Grid container justifyContent="center">
                    <Grid item container xs={12} justifyContent="center">
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            width: '120px',
                            height: '60px',
                            padding: '10px 12px',
                            borderRadius: '6px',
                            ml: 0,
                            cursor: 'pointer',
                            border: paymentOption === 'coins' ? '2px solid var(--dark-green, #41521F)' : '1px solid var(--grey-border, #E0E0E0)',
                            background: 'var(--white, #FFF)',
                            opacity: paymentOption === 'coins' ? 1 : 0.6,
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.07)',
                          }}
                          onClick={() => setPaymentOption('coins')}
                        >
                          <img src={AzamelCoinImg} alt="coin-img" />
                          <Typography sx={{
                            color: 'var(--dark-brown, #250503)',
                            fontFamily: 'DM Sans',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                          >
                            Coins
                          </Typography>
                        </Box>
                      </Grid>
                      {' '}
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            width: '120px',
                            height: '60px',
                            padding: '10px 12px',
                            borderRadius: '6px',
                            ml: 1,
                            mr: 1,
                            cursor: 'pointer',
                            border: paymentOption === 'paypal' ? '2px solid var(--dark-green, #41521F)' : '1px solid var(--grey-border, #E0E0E0)',
                            background: 'var(--white, #FFF)',
                            opacity: paymentOption === 'paypal' ? 1 : 0.6,
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.07)',
                          }}
                          onClick={() => setPaymentOption('paypal')}
                        >
                          <img src={PaypalLogo} alt="coin-img" style={{ width: '80px', height: '20px' }} />
                          <Typography sx={{
                            color: 'var(--dark-brown, #250503)',
                            fontFamily: 'DM Sans',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                          >
                            Paypal
                          </Typography>
                        </Box>
                      </Grid>
                      {' '}
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            width: '120px',
                            height: '60px',
                            padding: '10px 12px',
                            borderRadius: '6px',
                            ml: 2,
                            mr: 3,
                            cursor: 'pointer',
                            border: paymentOption === 'stripe' ? '2px solid var(--dark-green, #41521F)' : '1px solid var(--grey-border, #E0E0E0)',
                            background: 'var(--white, #FFF)',
                            opacity: paymentOption === 'stripe' ? 1 : 0.6,
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.07)',
                          }}
                          onClick={() => setPaymentOption('stripe')}
                        >
                          <img src={StripeLogo} alt="coin-img" style={{ width: '40px', height: '20px' }} />
                          <Typography sx={{
                            color: 'var(--dark-brown, #250503)',
                            fontFamily: 'DM Sans',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                          >
                            Stripe
                          </Typography>
                        </Box>
                      </Grid>
                      {' '}
                    </Grid>
                    {/* Coins UI */}

                    {paymentOption === 'coins' && (
                    <>
                      <Grid item container xs={12} justifyContent="center" mt="30px" mb="20px">
                        {' '}
                        <Box sx={{
                          width: '310px', height: '85px', background: '#A89F68', borderRadius: '6px',
                        }}
                        >
                          <Box sx={{
                            width: '300px',
                            height: '45px',
                            background: '#fff',
                            borderTopLeftRadius: '6px',
                            borderTopRightRadius: '6px',
                            mt: '4px',
                            ml: '5px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          >
                            <Box sx={{ ml: '17px', mr: '64px' }}>
                              <img src={AzamelCoinImg} alt="coin-img" style={{ width: '40px', height: '40px' }} />
                            </Box>
                            {' '}
&nbsp;
                            <Typography sx={{
                              textAlign: 'center',
                              fontFamily: 'DM Sans',
                              fontSize: '20px',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              lineHeight: 'normal',
                            }}
                            >
                              {' '}
                              Coins: 30

                            </Typography>
                          </Box>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: '8px',
                            // border: '1px solid red',
                          }}
                          >
                            <Typography sx={{
                              textAlign: 'center',
                              fontFamily: 'DM Sans',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              lineHeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            >
                              Current Balance:
                           &nbsp;
                              <img src={AzamelCoinImg} alt="coin-img" style={{ width: '15px', height: '15px' }} />
                              {' '}
&nbsp;
                              500
                              &nbsp;
                              | Wallet:
                              &nbsp;
                              <img src={AzamelCoinImg} alt="coin-img" style={{ width: '15px', height: '15px' }} />
                              {' '}
&nbsp;
                              100
                              &nbsp;
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item container xs={12} justifyContent="center" alignItems="center" mb="38px">
                        <AddCircleOutlinedIcon sx={{ color: '#41521F' }} />
                        &nbsp;
                        <Typography sx={{
                          textAlign: 'center',
                          fontFamily: 'DM Sans',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                          letterSpacing: '0.1px',
                          textDecorationLine: 'underline',
                        }}
                        >
                          {' '}
                          add more coins

                        </Typography>
                        {' '}

                      </Grid>
                      <Grid item container xs={12} justifyContent="center">
                        {' '}
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          onClick={() => { console.log('on click!'); }}
          // loading={authLoading} TODO: Implement loading state when button is made functional
                          sx={{
                            mt: 1,
                            mb: 1,
                            height: 40,
                            width: '400px',
                            borderRadius: '5px',
                            background: '#41521F',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            fontFamily: 'Passero One',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Use Coins

                        </LoadingButton>

                      </Grid>
                    </>
                    )}

                    {/* Paypal UI */}
                    {paymentOption === 'paypal' && (
                      <SimplePaypal />
                    )}

                    {/* Stripe UI */}
                    {/* TODO: Implement component */}
                    {paymentOption === 'stripe' && <>Stripe Integration</>}

                  </Grid>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
