import React from 'react';
import { Box, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { Token_Set } from '../../../__generated__/graphql';
import './selecttokenoptions.scss';
import { ThemeContext } from '../../../context/ThemeContext';

interface Props {
  tokenSets: Token_Set[];
  selectedTokenSet: string;
  setSelectedTokenSet: (_id: string) => void;
  openNewTokenSet: () => void;
  selectLabel: string;
  setSelectLabel: (label: string | null | undefined) => void;
}

function SelectTokenOptions({
  tokenSets,
  selectedTokenSet,
  setSelectedTokenSet,
  openNewTokenSet,
  selectLabel,
  setSelectLabel,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  const selectRef = React.useRef<HTMLDivElement | null>(null);
  const { theme } = React.useContext(ThemeContext);

  const handleClickOutside = (e: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleOpenNewTokenSet = () => {
    openNewTokenSet();
    setIsOpen(false);
  };
  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="select_options_wrapper" ref={selectRef}>
      <button className="select_options_box" type="button" onClick={() => setIsOpen(!isOpen)}>
        <Typography>{selectLabel}</Typography>
        <ArrowDropDown className={`selectArrow ${isOpen ? 'rotated' : ''}`} />
      </button>
      {isOpen && (
        <ul className={`select_options ${theme === 'light' ? 'light' : 'dark'}`}>
          <Box className={`select_options_scroll_container ${theme === 'light' ? 'light' : 'dark'}`}>
            {tokenSets?.map((tokenSet: Token_Set) => (
              <li key={tokenSet?._id} className={`select_option ${theme === 'light' ? 'light' : 'dark'} ${selectedTokenSet === tokenSet?._id && 'active'}`}>
                <button
                  type="button"
                  role="tab"
                  tabIndex={0}
                  onClick={() => (
                    // eslint-disable-next-line no-sequences
                    setSelectLabel(tokenSet?.name),
                    setSelectedTokenSet(tokenSet?._id),
                    setIsOpen(false)
                  )}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setSelectLabel(tokenSet?.name);
                      setSelectedTokenSet(tokenSet?._id);
                      setIsOpen(false);
                    }
                  }}
                >
                  {tokenSet?.name}
                </button>
              </li>
            ))}
          </Box>
          <li className={`select_option ${theme === 'light' ? 'light' : 'dark'}`}>
            <button
              type="button"
              onClick={() => handleOpenNewTokenSet()}
            >
              <span className={`select_new_token_set_button ${theme === 'light' ? 'light' : 'dark'}`}>+</span>
            </button>
          </li>
        </ul>
      )}
    </div>
  );
}

export default SelectTokenOptions;
