/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Box,
} from '@mui/material';
import useGraphQL from '../../hooks/useGraphQL';
import { GetManyMediaAssetsDocument } from '../../__generated__/graphql';
import useItem from '../../hooks/useItem';
import useToken from '../../hooks/useToken';
import { handleURLImageDrop } from '../realms/TokenMaker/utils';

interface RenderMediaAssetsProps {
  mediaAssetIds: string[] | undefined,
}

const RenderMediaAssets: React.FC<RenderMediaAssetsProps> = ({ mediaAssetIds }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: mediaAssets, error: mediaAssetsError } = useGraphQL(
    GetManyMediaAssetsDocument,
    {
      query: {
        _id_in: mediaAssetIds ?? [],
      },
    },
  );
  // console.log('mediaAssets', mediaAssets);
  // console.log('mediaAssetsError', mediaAssetsError);
  const {
    createItem, removeItem,
  } = useItem();
  const {
    setToken,
  } = useToken();

  // React.useEffect(() => {
  //   const imageUrl = 'https://storage.googleapis.com/rw-dev-library-content-packs/6538a5ad4d3925ace7e61c08/c95a7553-2513-4c78-8c04-8d066b4763f5.png';

  //   const droppedImageBlob = fetchImageAndConvertToBlob(imageUrl);
  //   console.log(droppedImageBlob);
  // }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '8px',
        rowGap: '8px',
      }}
    >

      {
      mediaAssets !== undefined ? mediaAssets?.media_assets.map((mediaAsset) => (
        <img
          alt={mediaAsset?.name ?? 'alt'}
          key={`${mediaAsset?.name}-${mediaAsset?.thumbnail_url}`}
          src={mediaAsset?.thumbnail_url ?? ''}
          width="95px"
          height="95px"
          onClick={() => {
            if (mediaAsset && mediaAsset.asset_url) {
              handleURLImageDrop(mediaAsset.asset_url, createItem, removeItem, setToken);
              // Use below to test feature. currently having CORS issues with the actual url.
              // handleAIImageDrop('https://picsum.photos/200/300');
            }
          }}
        />

      )) : null
}

    </Box>
  );
};

export default RenderMediaAssets;
