/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Box, Button, Checkbox, FormControlLabel, MenuItem,
  Select, SelectChangeEvent, Stack, Tooltip, Typography, Zoom,
} from '@mui/material';
import {
  AIButton,
  AIButtonImg,
  AIButtonText,
  AttributeTextField,
  AttributeWrapper,
  BookmarkImg, BookmarkTitleImg, CoinImg, HelpImg, OptionsStack, OptionsStackIcon,
  OptionsStackIconContainer, OptionsStackText, OptionsStackTextContainer,
  SmallHeadingText,
} from '../../styledComps/styledComps';
import { useAuth } from '../../../hooks/use-auth';
import useGraphQL from '../../../hooks/useGraphQL';
import CharacterSheetBGImg from '../../../images/character-sheet-background.webp';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import BookmarkImage from '../../../images/bookmark.webp';
import TexturedBackground from '../../../images/textured-background-1@3x.webp';
import CharacterSheetIcon from '../../../images/character-sheet-light.webp';
import UploadIcon from '../../../images/upload-image.webp';
import LibraryIcon from '../../../images/library.webp';
import HeraldIcon from '../../../images/herald.webp';
import FantasyIcon from '../../../images/fantasy.webp';
import HelpIcon from '../../../images/help.webp';
import CoinIcon from '../../../images/azamel_Coin.png';
import CharacterAttributes from './CharacterAttributes';
import CharacterNotesWrapper from '../../notes/CharacterNotesWrapper';
import CharacterAbilitiesGen from './CharacterAbilitiesGen';
import CharacterBackground from './CharacterBackground';
import CharacterPhysicalProfile from './CharacterPhysicalProfile';
import sampleNotes from '../../notes/characternotesample.json';
import {
  Body_Shape,
  CharacterAbility, CharacterInsertInput, CharacterLong_Term_Goal,
  CharacterShort_Term_Goal, Character_Note, Character_Set,
  Class, CreateCharacterDocument, Eye, Gender, GetCharactersSetsDocument,
  NoteInsertInput, Personality, Race, Skin, UpdateCharacterSetDocument,
} from '../../../__generated__/graphql';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import useNotification from '../../../hooks/useNotification';

function CharacterSheet() {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = React.useState<Blob | null>(null);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [characterName, setCharacterName] = React.useState('');
  const [characterAge, setCharacterAge] = React.useState<number>(0);
  const [characterPronouns, setCharacterPronouns] = React.useState('');
  const [characterGender, setCharacterGender] = React.useState<Gender | null>(null);
  const [characterRace, setCharacterRace] = React.useState<Race | null>(null);
  const [characterClass, setCharacterClass] = React.useState<Class | null>(null);
  const [characterAlignment, setCharacterAlignment] = React.useState('');
  const [characterMotivations, setCharacterMotivations] = React.useState<string[]>([]);
  const [characterPersonalityType, setCharacterPersonalityType] = React
    .useState<Personality | null>(null);
  const [characterBodyType, setCharacterBodyType] = React.useState<Body_Shape | null>(null);
  const [characterSkinColor, setCharacterSkinColor] = React.useState<Skin | null>(null);
  const [characterHairColor, setCharacterHairColor] = React.useState('');
  const [characterHairStyle, setCharacterHairStyle] = React.useState('');
  const [characterEyeColor, setCharacterEyeColor] = React.useState<Eye | null>(null);
  const [characterProfession, setCharacterProfession] = React.useState('');
  const [characterRelationship, setCharacterRelationship] = React.useState('');
  const [characterLifeAlteringEvent, setCharacterLifeAlteringEvent] = React.useState('');
  const [characterBackStory, setCharacterBackStory] = React.useState('');
  const [characterShortTermGoals, setCharacterShortTermGoals] = React
    .useState<CharacterShort_Term_Goal[]>([]);
  const [characterLongTermGoals, setCharacterLongTermGoals] = React
    .useState<CharacterLong_Term_Goal[]>([]);
  const [characterNotes, setCharacterNotes] = React
    .useState<NoteInsertInput[]>([]);
  const [charactersCharacterSet, setCharactersCharacterSet] = React.useState({
    name: 'Select a Character Set',
    _id: '',
  });
  const [characterAbilities, setCharacterAbilities] = React.useState<CharacterAbility>({
    strength: '0',
    dexterity: 0,
    consitution: 0,
    intelligence: 0,
    wisdom: 0,
    charisma: 0,
  });
  const [readyToSave, setReadyToSave] = React.useState(false);

  const auth = useAuth();
  const { displayNotification } = useNotification();

  const { data: charactersSets } = useGraphQL(
    GetCharactersSetsDocument,
    {
      query: {
        owner: {
          _id: auth?.user?.uid,
        },
      },
    },
  );

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(URL.createObjectURL(file));
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      console.log(URL.createObjectURL(file));
    }
  };

  React.useEffect(() => {
    if (previewUrl !== null && charactersCharacterSet._id !== ''
    && characterName !== '') {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  }, [
    previewUrl, characterAbilities, characterAge, characterAlignment,
    characterBodyType, characterClass, characterEyeColor, characterGender,
    characterHairColor, characterHairStyle, characterLifeAlteringEvent,
    characterMotivations, characterName, characterPersonalityType,
    characterProfession, characterPronouns, characterPronouns, characterRace,
    characterRelationship, characterSkinColor, charactersCharacterSet,
  ]);

  const {
    data: createdCharacter,
    mutate: createCharacter,
    status: createdCharacterStatus,
    error: createCharacterError,
  } = useGraphQLMutation(
    CreateCharacterDocument,
  );

  if (createCharacterError) console.log(createCharacterError);

  const {
    mutate: updateCharacterSet,
    status: updateCharacterSetStatus,
  } = useGraphQLMutation(UpdateCharacterSetDocument);

  const handleSaveCharacter = () => {
    const character: CharacterInsertInput = {
      name: characterName,
      avatar_url: previewUrl,
      character_image_thumbnail_url: previewUrl,
      owner: {
        link: auth?.realmUser?.id,
      },
      race: characterRace?.name,
      class: characterClass?.name,
      gender: characterGender?.name,
      pronoun: characterPronouns,
      motivations: characterMotivations,
      abilities: characterAbilities,
      alignment: characterAlignment,
      physical_profile: {
        body_type: characterBodyType?.name,
        skin_color: characterSkinColor?.name,
        hair_color: characterHairColor,
        hair_style: characterHairStyle,
        eye_color: characterEyeColor?.name,
      },
      profession: characterProfession,
      relationship_status: characterRelationship,
      life_altering_event: characterLifeAlteringEvent,
      backstory: characterBackStory,
      short_term_goals: characterShortTermGoals,
      long_term_goals: characterLongTermGoals,
      // notes: {
      //   create: characterNotes,
      // },
    };
    console.log(character);
    createCharacter({
      data: character,
    });
  };

  const handleUploadCharacterImage = (id: string) => {
    const formData = new FormData();
    formData.append('AvatarImage', selectedFileBlob!);
    formData.append('CharacterImage', selectedFileBlob!);
    displayNotification({ message: 'Uploading character image ...', type: 'info' });
    fetch(`${process.env.REACT_APP_API_URL}/Characters/${id}/Images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          displayNotification({ message: 'Failed to upload character image', type: 'error' });
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        // handleClose();
        displayNotification({ message: 'Character image uploaded successfully', type: 'success' });
        // refetch();
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        displayNotification({ message: 'Failed to upload character image', type: 'error' });
      });
  };

  React.useEffect(() => {
    if (createdCharacterStatus === 'loading') {
      displayNotification({ message: 'Creating character ...', type: 'info' });
    }
    if (createdCharacterStatus === 'error') {
      displayNotification({ message: 'Failed to create character ...', type: 'error' });
    }
    if (createdCharacterStatus === 'success') {
      displayNotification({ message: 'Character created successfully ...', type: 'success' });
      handleUploadCharacterImage(createdCharacter.insertOneCharacter?._id);
      const oldCharacterIDs = charactersSets?.character_sets.find(
        (characterset) => characterset?._id === charactersCharacterSet._id,
      )?.characters?.map((characters) => characters?._id) ?? [];
      console.log([...oldCharacterIDs, createdCharacter.insertOneCharacter?._id]);
      updateCharacterSet({
        set: {
          characters: {
            link: [...oldCharacterIDs, createdCharacter.insertOneCharacter?._id],
          },
        },
        query: {
          _id: charactersCharacterSet._id,
        },
      });
    }
  }, [createdCharacterStatus]);

  React.useEffect(() => {
    if (updateCharacterSetStatus === 'loading') {
      displayNotification({ message: 'Adding character to character set ...', type: 'info' });
    }
    if (updateCharacterSetStatus === 'error') {
      displayNotification({ message: 'Failed to add character to a character set', type: 'error' });
    }
    if (updateCharacterSetStatus === 'success') {
      displayNotification({ message: 'Successfully added character to character set', type: 'success' });
    }
  }, [updateCharacterSetStatus]);

  return (
    <Stack
      sx={{
        height: 'calc(100vh - 48px)',
        background: `url(${CharacterSheetBGImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxSizing: 'border-box',
      }}
      alignItems="center"
      // justifyContent="center"
    >
      <Box
        sx={{
          width: '1507px',
          minHeight: '540px',
          maxHeight: '85%',
          boxSizing: 'border-box',
          border: '8px solid #68583D',
          borderImageSource: `url(${CustomBorder})`,
          borderImageSlice: '17',
          borderImageRepeat: 'round',
          background: `url(${TexturedBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          marginTop: '130px',
        }}
      >
        {/* Bookmark Title */}
        <Box
          sx={{
            position: 'relative',
            width: '360px',
            height: '64px',
            mt: '-45px',
            ml: '-9px',
            zIndex: '10',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="15px"
            zIndex={2}
            position="absolute"
            left="44px"
            top="50%"
            sx={{
              transform: 'translate(0%, -50%)',
            }}
          >
            <BookmarkTitleImg src={CharacterSheetIcon} />
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: 'Passero One',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
            >
              Character Sheet
            </Typography>
          </Stack>
          <BookmarkImg src={BookmarkImage} />
        </Box>
        {/* Greetings */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            minHeight: 'calc(540px - 43px)',
            maxHeight: 'calc(100% - 43px)',
            boxSizing: 'border-box',
            ml: '-20px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: '#CFCAAD',
              borderRadius: '8px',
            },
            '&.scrollbar': {
              scrollbarWidth: 'thin',
              scrollbarColor: '#CFCAAD',
              backgroundColor: '#CFCAAD',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
              {
                backgroundColor: '#41521F',
                borderRadius: '8px',
              },
          }}
        >
          <Stack pl="92px" pr="72px" py="92px" gap="40px">
            <Stack>
              <Typography
                sx={{
                  color: '#250503',
                  fontFamily: 'Passero One',
                  fontSize: '36px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '50px',
                  letterSpacing: '.1px',
                }}
              >
                👋  Hello, fellow adventurer,
              </Typography>
              <Typography
                sx={{
                  color: '#250503',
                  fontFamily: 'DM Sans',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '30px',
                  letterSpacing: '.1px',
                  mt: '30px',
                }}
              >
                {/* eslint-disable-next-line max-len */}
                In our &quot;AI Generate&quot; feature, just choose basics like race, class, and gender, and our AI will magically craft the rest of your character&apos;s tale. Want full control? Fill in all details and save. Or mix it up - add some traits and let the AI do the rest. Let&apos;s create something epic together! 🐉 ✨
              </Typography>

              <Box
                pt="25px"
              >
                <Stack direction="row" gap="27px" alignItems="flex-start">
                  {/* Character Image Box */}
                  <Stack
                    sx={{
                      borderRadius: '4px',
                      background: '#E3D5BC',
                      width: '396px',
                    }}
                    gap="8px"
                  >
                    <SmallHeadingText pt="9px" pl="21px">Character Image</SmallHeadingText>
                    {previewUrl === null && (
                      <Stack
                        height="410px"
                        alignItems="center"
                        justifyContent="center"
                        gap="38px"
                        px="38px"
                        boxSizing="border-box"
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="fileInput" style={{ width: '100%' }}>
                          <OptionsStack>
                            <OptionsStackIconContainer>
                              <OptionsStackIcon src={UploadIcon} />
                            </OptionsStackIconContainer>
                            <OptionsStackTextContainer>
                              <OptionsStackText>Upload Image</OptionsStackText>
                            </OptionsStackTextContainer>
                          </OptionsStack>
                        </label>
                        <input
                          type="file"
                          accept=".jpg,.png,.webp"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          id="fileInput"
                        />
                        <OptionsStack>
                          <OptionsStackIconContainer>
                            <OptionsStackIcon src={LibraryIcon} />
                          </OptionsStackIconContainer>
                          <OptionsStackTextContainer>
                            <OptionsStackText>My Library</OptionsStackText>
                          </OptionsStackTextContainer>
                        </OptionsStack>
                      </Stack>
                    )}
                    {previewUrl !== null && (
                      <Stack
                        sx={{
                          height: '410px',
                          width: '100%',
                          boxSizing: 'border-box',
                          background: `url(${previewUrl})`,
                          backgroundSize: 'cover',
                          padding: '20px',
                          '.replaceImage': {
                            display: 'none',
                          },
                          ':hover': {
                            '.replaceImage': {
                              display: 'flex',
                            },
                          },
                        }}
                        justifyContent="center"
                        alignItems="center"
                        gap="20px"
                      >
                        <Stack direction="row" gap="20px" className="replaceImage">
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label htmlFor="fileInput" style={{ width: '100%' }}>
                            <OptionsStack>
                              <OptionsStackIconContainer>
                                <OptionsStackIcon src={UploadIcon} />
                              </OptionsStackIconContainer>
                            </OptionsStack>
                          </label>
                          <input
                            type="file"
                            accept=".jpg,.png,.webp"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            id="fileInput"
                          />
                          <OptionsStack>
                            <OptionsStackIconContainer>
                              <OptionsStackIcon src={LibraryIcon} />
                            </OptionsStackIconContainer>
                          </OptionsStack>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                  {/* Character Attributes */}
                  <CharacterAttributes
                    characterName={characterName}
                    setCharacterName={(char: string) => setCharacterName(char)}
                    characterAge={characterAge}
                    setCharacterAge={(char: number) => setCharacterAge(char)}
                    characterPronouns={characterPronouns}
                    setCharacterPronouns={(char: string) => setCharacterPronouns(char)}
                    characterGender={characterGender}
                    setCharacterGender={(char: Gender) => setCharacterGender(char)}
                    characterRace={characterRace}
                    setCharacterRace={(char: Race) => setCharacterRace(char)}
                    characterClass={characterClass}
                    setCharacterClass={(char: Class) => setCharacterClass(char)}
                    characterAlignment={characterAlignment}
                    setCharacterAlignment={(char: string) => setCharacterAlignment(char)}
                    characterPersonalityType={characterPersonalityType}
                    setCharacterPersonalityType={
                      (char: Personality) => setCharacterPersonalityType(char)
                    }
                    characterMotivations={characterMotivations}
                    setCharacterMotivations={(char: string[]) => setCharacterMotivations(char)}
                  />
                </Stack>
              </Box>
            </Stack>
            {/* Character Abilities */}
            <CharacterAbilitiesGen
              abilities={characterAbilities}
              setCharacterAbilities={(ab: CharacterAbility) => setCharacterAbilities(ab)}
            />
            {/* Character Physical Profile */}
            <CharacterPhysicalProfile
              characterBodyType={characterBodyType}
              setCharacterBodyType={(char: Body_Shape) => setCharacterBodyType(char)}
              characterSkinColor={characterSkinColor}
              setCharacterSkinColor={(char: Skin) => setCharacterSkinColor(char)}
              characterHairColor={characterHairColor}
              setCharacterHairColor={(char: string) => setCharacterHairColor(char)}
              characterHairStyle={characterHairStyle}
              setCharacterHairStyle={(char: string) => setCharacterHairStyle(char)}
              characterEyeColor={characterEyeColor}
              setCharacterEyeColor={(char: Eye) => setCharacterEyeColor(char)}
            />
            {/* Character Background */}
            <CharacterBackground
              characterProfession={characterProfession}
              setCharacterProfession={(char: string) => setCharacterProfession(char)}
              characterRelationship={characterRelationship}
              setCharacterRelationship={(char: string) => setCharacterRelationship(char)}
              characterLifeAlteringEvent={characterLifeAlteringEvent}
              setCharacterLifeAlteringEvent={(char: string) => setCharacterLifeAlteringEvent(char)}
              characterBackStory={characterBackStory}
              setCharacterBackStory={(char: string) => setCharacterBackStory(char)}
            />
            {/* Notes */}
            <Stack direction="row" gap="28px" flexWrap="nowrap">
              <Box sx={{ width: '33.3333%' }}>
                <CharacterNotesWrapper
                  title="Notes"
                  notes={characterNotes}
                  setNotes={(notes: any[]) => setCharacterNotes(notes)}
                  realmNote
                />
              </Box>
              <Box sx={{ width: '33.3333%' }}>
                <CharacterNotesWrapper
                  title="short term goals"
                  notes={characterShortTermGoals}
                  setNotes={(goals: any[]) => setCharacterShortTermGoals(goals)}
                />
              </Box>
              <Box sx={{ width: '33.3333%' }}>
                <CharacterNotesWrapper
                  title="long term goals"
                  notes={characterLongTermGoals}
                  setNotes={(goals: any[]) => setCharacterLongTermGoals(goals)}
                />
              </Box>
            </Stack>
            {/* Helpers and Save */}
            <Stack direction="row" alignItems="top" justifyContent="space-between">
              <Stack direction="row" gap="60px">
                <Stack>
                  <Stack direction="row" gap="3px" alignItems="center">
                    <AIButton>
                      <AIButtonImg src={HeraldIcon} />
                      <AIButtonText>AI Town Crier</AIButtonText>
                    </AIButton>
                    <Tooltip
                      title="A help note about AI town crier"
                      arrow
                      placement="bottom"
                      TransitionComponent={Zoom}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: 'Passero One',
                            fontWeight: '400px',
                            fontSize: '14px',
                            padding: '10px',
                            bgcolor: '#3c3c3ce6',
                            '& .MuiTooltip-arrow': {
                              color: '#3c3c3ce6',
                            },
                            maxWidth: '250px',
                          },
                        },
                      }}
                    >
                      <HelpImg src={HelpIcon} style={{ marginTop: '-40px' }} />
                    </Tooltip>
                    <Stack direction="row" alignItems="center">
                      <CoinImg src={CoinIcon} />
                      <Typography
                        sx={{
                          color: '#250503',
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                          letterSpacing: '.1px',
                        }}
                      >
                        1
                      </Typography>
                    </Stack>
                  </Stack>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        inputProps={{ 'aria-label': 'Include Image Generation' }}
                        sx={{
                          color: '#E9DCC3',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: '#41521F',
                          },
                        }}
                      />
                    )}
                    label="Include Image Generation"
                    sx={{
                      '.MuiTypography-root': {
                        color: '#1D1B20',
                        fontFamily: 'DM Sans',
                        fontSize: '16pxpx',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '.5px',
                      },
                    }}
                  />
                </Stack>
                <Stack>
                  <Stack direction="row" gap="3px" alignItems="center">
                    <AIButton>
                      <AIButtonImg src={FantasyIcon} />
                      <AIButtonText>AI Scribe</AIButtonText>
                    </AIButton>
                    <Tooltip
                      title="A help note about AI scribe"
                      arrow
                      placement="bottom"
                      TransitionComponent={Zoom}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: 'Passero One',
                            fontWeight: '400px',
                            fontSize: '14px',
                            padding: '10px',
                            bgcolor: '#3c3c3ce6',
                            '& .MuiTooltip-arrow': {
                              color: '#3c3c3ce6',
                            },
                            maxWidth: '250px',
                          },
                        },
                      }}
                    >
                      <HelpImg src={HelpIcon} style={{ marginTop: '-40px' }} />
                    </Tooltip>
                    <Stack direction="row" alignItems="center">
                      <CoinImg src={CoinIcon} />
                      <Typography
                        sx={{
                          color: '#250503',
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                          letterSpacing: '.1px',
                        }}
                      >
                        2
                      </Typography>
                    </Stack>
                  </Stack>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        inputProps={{ 'aria-label': 'Include Image Generation' }}
                        sx={{
                          color: '#E9DCC3',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: '#41521F',
                          },
                        }}
                      />
                    )}
                    label="Include Image Generation"
                    sx={{
                      '.MuiTypography-root': {
                        color: '#1D1B20',
                        fontFamily: 'DM Sans',
                        fontSize: '16pxpx',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '.5px',
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap="13px" maxHeight="60px">
                {/* <CharacterFilterDropdown
                  title="Character Set"
                  tooltip
                  toolTipMessage="Be sure to choose a character set"
                  options={CharacterSetOptions}
                  width="260px"
                  value={charactersCharacterSet}
                  onChange={setCharactersCharacterSet}
                /> */}
                <AttributeWrapper
                  width="260px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Select
                    value={charactersCharacterSet.name}
                    onChange={(event: SelectChangeEvent<string>) => {
                      const setName:
                      Character_Set | undefined | null = charactersSets?.character_sets.find(
                        (set) => set?._id === event.target.value,
                      );
                      setCharactersCharacterSet({
                        name: setName?.name ?? 'Select Character Set',
                        _id: event.target.value,
                      });
                      console.log(event.target.value);
                    }}
                    input={(
                      <AttributeTextField
                        fullWidth
                        sx={{
                          width: '248px',
                        }}
                      />
                    )}
                    renderValue={(selected) => {
                      if (selected === '') {
                        return 'Select Character Set';
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="">Select Character Set</MenuItem>
                    {charactersSets?.character_sets.map((character_set) => (
                      <MenuItem key={character_set?._id} value={character_set?._id}>
                        {character_set?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </AttributeWrapper>
                <Button
                  sx={{
                    display: 'flex',
                    padding: '10px 24px',
                    borderRadius: '4px',
                    color: 'white',
                    fontFamily: 'Passero One',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '16px', /* 150% */
                    letterSpacing: '.1px',
                    height: '60px',
                    background: '#41521F',
                    '&:hover': {
                      background: '#41521F',
                    },
                  }}
                  disabled={!readyToSave}
                  onClick={handleSaveCharacter}
                >
                  Save Character
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default CharacterSheet;
