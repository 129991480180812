/* eslint-disable react/jsx-indent */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardTabButton from '../DashBoardTabButton/DashboardTabButton';
import s from './HeaderTabs.module.scss';

interface Tab {
  name: string;
  path: string;
}

interface TabsProps {
  tabs: Tab[];
}

/**
 * Header tabs component displaying navigation buttons.
 *
 * @component
 * @example
 * // Example usage of HeaderTabs component
 * <HeaderTabs tabs={[
 *   { name: 'Dashboard view', path: 'dashboard-view' },
 *   { name: 'Detail view', path: 'detail-view' },
 * ]} />
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.tabs - An array of tab objects with name and path properties.
 *
 * @returns {JSX.Element} React element representing the header tabs.
 */

const HeaderTabs = ({ tabs }: TabsProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={s.buttonWrapper}>
      {tabs.map((tab) => (
        <DashboardTabButton
          active={location.pathname.includes(tab.path)}
          name={tab.name}
          key={tab.name}
          onClick={() => navigate(tab.path)}
        />
      ))}
    </div>
  );
};

export default HeaderTabs;
