import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ReactiveList, SearchBox } from '@appbaseio/reactivesearch';
import '../../MyCollectionPanel/mycollectionpanel.scss';
import { Star } from '@mui/icons-material';
import Expansion from '../../Expansion/Expansion';
import ExpansionAIImageItem from '../../Expansion/ExpansionAIImageItem';
import fantasyIcon from '../../../../assets/images/Fantasy1.png';
import { MyLibraryString } from '../../../../interfaces/mylibrary';
import appStore from '../../../../store/AppStore';
import ExpansionTokenSetsItem from '../../Expansion/ExpansionTokenSetsItem';
import ContentLoading from '../../../contentLoadingPlaceholder/ContentLoading';
import RenderMediaAssets from '../../../RenderMediaAssets/RenderMediaAssets';
import GoldImg from '../../../../images/gold-small.png';
import useGraphQL from '../../../../hooks/useGraphQL';
import { DeleteOneMyLibraryFavoritesDocument, GetMyLibraryFavoritesDocument, User_Library_Content_Pack_FavoriteQueryInput } from '../../../../__generated__/graphql';
import useGraphQLMutation from '../../../../hooks/useGraphQLMutation';

interface Props {
  fetchSize: number;
  pagination: boolean;
}

function MyLibrary({ fetchSize, pagination }: Props) {
  const selectedFilterOption:
  MyLibraryString | null = appStore(
    (state) => state.activeMyLibraryFilter,
  );

  const [priorityFilter, setPriorityFilter] = React.useState<MyLibraryString | null>(null);
  const [showLoader, setShowLoader] = React.useState(false);
  // Array to hold expansion states
  const [expandedStates, setExpandedStates] = React.useState<boolean[]>([]);

  const [favoritesList, setfavoritesList] = React.useState<any>({});
  const { data: favoritesSet } = useGraphQL(
    GetMyLibraryFavoritesDocument,
    {},
  );

  // Create a function to toggle the expansion state of a specific item
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleExpansion = (index: number) => {
    const updatedStates = [...expandedStates];
    updatedStates[index] = !expandedStates[index];
    setExpandedStates(updatedStates);
  };

  const sortData = (data: any) => {
    const rawData = data;
    const newData = rawData.sort((a: any, b:any) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (selectedFilterOption === 'A-Z') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      if (selectedFilterOption === 'Z-A') {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
    // console.log(newData);
    return newData;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: removeMyLibFavorites, error: removeMyLibFavoritesError } = useGraphQLMutation(
    DeleteOneMyLibraryFavoritesDocument,
  );

  const handleDelToFavorites = async (packName: any, id: any) => {
    const delData: User_Library_Content_Pack_FavoriteQueryInput = {
      name: packName,
      library_content_pack: {
        _id: id,
      },
    };
    try {
      // Use the createMyLibFavorites mutation function from your GraphQL client
      await removeMyLibFavorites({
        query: delData,
      });
      // Handle success response from the server
      // const idToRemove = id;
      // const updatedArray = favoritesList.filter((item: any) => item._id !== idToRemove);
      // setfavoritesList(updatedArray);
    } catch (error: any) {
      console.error('Error removing fave:', error.message);
      // Handle unexpected errors
    }
  };

  React.useEffect(() => {
    setfavoritesList(favoritesSet?.user_library_content_pack_favorites);
  }, [favoritesList, favoritesSet, handleDelToFavorites]);

  React.useEffect(() => {
    if (selectedFilterOption !== ('A-Z' || 'Z-A')) {
      setPriorityFilter(selectedFilterOption);
      if (selectedFilterOption === 'A.I. Generated') {
        setShowLoader(true);
      }
    }
  }, [selectedFilterOption]);

  const [allAIImagesExpanded, setAllAIImagesExpanded] = React.useState(false);
  return (
    <Grid container sx={{ px: '11px' }}>
      <Grid item xs={12} mb={2}>
        <SearchBox
          componentId="mylibrary-searchbox"
          iconPosition="right"
          dataField={['_id']}
          includeFields={['thumbnail_url', 'tags']}
          autosuggest={false}
          AIConfig={{}}
          AIUIConfig={{}}
          type="search"
          compoundClause="must"
          enableIndexSuggestions={false}
          enterButton={false}
          className="my_custom_searchbox"
        />
      </Grid>
      <ReactiveList
        componentId="mylibrary-results"
        dataField="my_library"
        size={fetchSize}
        sortBy="desc"
        pagination={pagination}
        onPageChange={() => {
          setShowLoader(true);
        }}
        onData={({ data }) => {
          console.log('new data received: ', data);
          if (data && data?.length > 0) {
            setShowLoader(false);
          } else {
            setShowLoader(true);
          }
        }}
        compoundClause="must"
        className="my_custom_reactive_list"
        innerClass={{
          pagination: 'list_pagination',
          resultStats: 'list_resultStats',
          resultsInfo: 'list_resultsInfo',
          noResults: 'list_noResults',
        }}
        react={{
          and: ['mylibrary-searchbox'],
        }}
        onError={(err) => console.log(err)}
        render={({
          data,
        }: any) => (
          <Box>
            {priorityFilter === 'A.I. Generated' && (
              <Grid item xs={12}>
                <Expansion
                  title="Generated Images"
                  isExpanded={allAIImagesExpanded}
                  setIsExpanded={() => setAllAIImagesExpanded(!allAIImagesExpanded)}
                  headerChildren={(
                    <Typography
                      sx={{
                        color: 'var(--white, #FFF)',
                        background: '#5E224D',
                        textAlign: 'center',
                        borderRadius: 4,
                        fontFamily: 'DM Sans',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        padding: '0 10px',
                      }}
                    >
                      <span>A.I.Gen</span>
                      <span style={{ paddingLeft: '10px' }}>
                        <img src={fantasyIcon} alt="aiGen" />
                      </span>
                    </Typography>
                  )}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      columnGap: '8px',
                      rowGap: '8px',
                    }}
                  >
                    { showLoader && <ContentLoading />}
                    {!showLoader && (data && sortData(data)?.length > 0)
                      && sortData(data).slice(0, 9)
                        .map((image: any) => (
                          <ExpansionAIImageItem key={image._id} aiImage={image} />
                        ))}
                    {!showLoader && allAIImagesExpanded && (data && sortData(data)?.length > 0)
                      && sortData(data)
                        .slice(9).map((image: any) => (
                          <ExpansionAIImageItem key={image._id} aiImage={image} />
                        ))}
                  </Box>
                </Expansion>
              </Grid>
            )}
            {(priorityFilter === null || priorityFilter === 'Tokens') && (
            <Grid item xs={12} rowGap={1} mb={2} display="flex" flexWrap="wrap">
              {(data && sortData(data)?.length > 0) ? sortData(data)
                .map((tokenSet: any) => (
                  <ExpansionTokenSetsItem
                    token_set={tokenSet}
                    tokensSlice={3}
                    tightSpacing
                    key={tokenSet._id}
                  />
                )) : (
                  <p>No items found.</p>
              )}
            </Grid>
            )}
            {priorityFilter === 'Sort' && (
              <Grid item xs={12} rowGap={1} mb={2} display="flex" flexWrap="wrap">
                {(data && sortData(data)?.length > 0) ? sortData(data)
                  .map((tokenSet: any) => (
                    <ExpansionTokenSetsItem
                      token_set={tokenSet}
                      tokensSlice={3}
                      tightSpacing
                      key={tokenSet._id}
                    />
                  )) : (
                    <p>No items found.</p>
                )}
              </Grid>
            )}
            {priorityFilter === 'Favorites' && favoritesList && favoritesList?.length > 0
              && (
                favoritesList
                  .map((item: any, index: any) => (
                    <Expansion
                      key={item.library_content_pack.media_assets[index]}
                      title={item.library_content_pack.name}
                      isExpanded={expandedStates[index] || false}
                      setIsExpanded={() => toggleExpansion(index)}
                      headerChildren={(
                        <>
                          <Typography
                            sx={{
                              color: 'var(--white, #FFF)',
                              background: '#41531E',
                              textAlign: 'center',
                              borderRadius: 4,
                              fontFamily: 'DM Sans',
                              fontSize: '10px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '0.1px',
                              padding: '0 10px',
                              whiteSpace: 'nowrap',
                              cursor: 'pointer',
                            }}
                            // Remove for now.
                            // onClick={() => {
                            //   handleDelToFavorites(
                            //     item.library_content_pack.name,
                            //     item.library_content_pack._id,
                            //   );
                            // }}
                          >
                            Favorited

                            <Star
                              sx={{ color: 'gold', fontSize: '10px' }}
                            />
                          </Typography>
                          <img src={GoldImg} alt="shield-img" />
                        </>
                )}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          columnGap: '8px',
                          rowGap: '8px',
                        }}
                      >
                        <RenderMediaAssets
                          mediaAssetIds={item?.library_content_pack.media_assets?.slice(0, 3).map(
                            (media: any) => media._id,
                          ) as string[]}
                        />
                        {expandedStates[index]
                            && (
                            <RenderMediaAssets
                              mediaAssetIds={item?.library_content_pack.media_assets?.slice(3).map(
                                (media: any) => media._id,
                              ) as string[]}
                            />
                            )}
                      </Box>
                      {' '}
&nbsp;
                    </Expansion>
                  ))
              )}
          </Box>
        )}
      />
    </Grid>
  );
}

export default MyLibrary;
