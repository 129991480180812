/* eslint-disable react/function-component-definition */
import {
  Box, Button, Card, CardContent, CardMedia, Grid, Typography, styled,
} from '@mui/material';
import React, { useState } from 'react';
import { Maybe } from '../../__generated__/graphql';

const StyledTitle = styled(Typography)({
  width: '135px',
  color: '#250503',
  fontFamily: 'Passero One',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textDecorationLine: 'underline',
  whiteSpace: 'nowrap', // Prevents text from wrapping
});
/**
 * This component is what is displayed when a user clicks on a Realm and it pops up as a Modal.
 * It is similar to the RealmsCard.tsx component.
 */
export interface RealmsPreviewProps {
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  realm?: object;
  realmId: string;
  realmName: string;
  realmDescription: string;
  thumbnail: string;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  isFavorited?: boolean;
  tags: Maybe<Maybe<string>[]>;
  // eslint-disable-next-line react/require-default-props
  handleClick?: () => void;
}

const RealmsPreview: React.FC<RealmsPreviewProps> = ({
  realmId, realmName, realmDescription, thumbnail, tags, handleClick,
}) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  const handleImageError = () => {
    setIsImageBroken(true);
  };

  return (
    <Grid
      item
      xs={3}
      sx={{
        p: 0.5,
      }}
      key={realmId}
    >

      <Card
        sx={{
          display: 'flex',
          borderRadius: '5px',
          background: '#FFF',
          border: '1px solid white',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box sx={{ flex: 1 }} onClick={handleClick}>
          <CardContent>
            <StyledTitle>
              {realmName}
            </StyledTitle>
            &nbsp;
            <Box display="flex" justifyContent="flex-start" pt={1}>
              <Button sx={{
                borderRadius: '5px',
                background: '#A89F68',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
              >
                <Typography sx={{
                  display: 'flex',
                  height: '18px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                  color: '#41521F',
                  textAlign: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                >
                  {tags && tags.length >= 1 ? tags[0] : 'Tag'}
                </Typography>
              </Button>
              {' '}
            &nbsp;
              <Button sx={{
                borderRadius: '5px',
                background: '#A89F68',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
              >
                <Typography sx={{
                  display: 'flex',
                  height: '18px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                  color: '#41521F',
                  textAlign: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                >
                  {tags && tags.length >= 2 ? tags[1] : ' Long Tag'}
                </Typography>
              </Button>
            </Box>
            &nbsp;
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                // maxHeight: '4em',
              }}
            >
              {realmDescription}
            </Typography>

          </CardContent>
        </Box>
        <Box sx={{
          flex: 1, width: 360,
        }}
        >
          {(isImageBroken || !thumbnail) ? (
            <CardMedia
              component="img"
              sx={{ minWidth: 194, minHeight: 180 }}
              image="https://picsum.photos/194"
              alt="This is a placeholder"
              onError={handleImageError}
            />
          ) : (
            <CardMedia
              component="img"
              sx={{ minWidth: 194, minHeight: 180 }}
              image={thumbnail}
              alt="Live from space album cover"
              onError={handleImageError}
            />
          )}

        </Box>
      </Card>
    </Grid>
  );
};
export default RealmsPreview;
