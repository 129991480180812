/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';
import {
  ReactiveBase,
  ReactiveList,
  SearchBox,
} from '@appbaseio/reactivesearch';
import { StyledTab, StyledTabs, setTabId } from '../StyledPanel/StyledPanel';
import TabPanel from '../../tab/TabPanel';
import Expansion from '../Expansion/Expansion';
import ExpansionTokenSetsItem from '../Expansion/ExpansionTokenSetsItem';
import './mycollectionpanel.scss';
import sampleTokenSetUnordered from '../../../config/sampletokens.json';
import ExpansionTokenItem from '../Expansion/ExpansionTokenItem';
import FixedFilter from '../../fixedFilter/FixedFilter';
import ExpansionRealmItem from '../Expansion/ExpansionRealmItem';
import appStore from '../../../store/AppStore';
import { MyCollectionString } from '../../../interfaces/mycollection';
import { ThemeContext } from '../../../context/ThemeContext';

const optionsMyCollection: MyCollectionString[] = [
  'Recent',
  'Favorites',
  'Token Set',
  'Realms',
  'A-Z',
  'Z-A',
];

function MyCollectionPanel() {
  const [tabValue, setTabValue] = React.useState(0);
  const [allTokensExpanded, setAllTokensExpanded] = React.useState(false);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(0);
  };
  const [sampleTokenSet, setSampleTokenSet] = React.useState(
    sampleTokenSetUnordered,
  );
  const { theme } = useContext(ThemeContext);

  const selectedFilterOption: MyCollectionString | null = appStore(
    (state) => state.activeMyCollectionFilter,
  );
  const setSelectedFilterOption = (option: MyCollectionString | null) => {
    appStore.setState({
      activeMyCollectionFilter: option,
    });
  };
  const [priorityFilter, setPriorityFilter] =
    React.useState(selectedFilterOption);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [panelWidth, setPanelWidth] = useState('500px');
  const [tokensSlice, setTokensSlice] = useState(30);

  const navigate = useNavigate();

  const [appText, setAppText] = useState('');
  const [collectionText, setCollectionText] = useState('');

  const handleLoadMoreClick = () => {
    setAllTokensExpanded(!allTokensExpanded);
  };

  const handleShowLessClick = () => {
    setAllTokensExpanded(false);
  };

  let rawData;

  const sortData = (data: any) => {
    rawData = data;
    if (selectedFilterOption === 'Favorites') {
      rawData = data.filter((x: any) => x.is_favorited === true);
    }
    const newData = rawData.sort((a: any, b: any) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (selectedFilterOption === 'A-Z') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      if (selectedFilterOption === 'Z-A') {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
    console.log(newData);
    return newData;
  };

  useEffect(() => {
    setAppText('tokens_index');
    setCollectionText('tokens');
    setSelectedFilterOption('Recent');
    setPriorityFilter('Recent');
    function handleResize() {
      setViewportWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (viewportWidth <= 1600) {
      setPanelWidth('500px');
      setTokensSlice(30);
    } else {
      setPanelWidth('620px');
      setTokensSlice(30);
    }
  }, [viewportWidth]);

  useEffect(() => {
    if (selectedFilterOption === 'Recent') {
      setAppText('');
      setCollectionText('');
      setTimeout(() => {
        setAppText('tokens_index');
        setCollectionText('tokens');
        setPriorityFilter(selectedFilterOption);
      }, 300);
    }
    if (selectedFilterOption === 'Token Set') {
      setAppText('token_sets_index');
      setCollectionText('token_sets');
      setPriorityFilter(selectedFilterOption);
    }
    if (selectedFilterOption === 'Realms') {
      setAppText('realms_index');
      setCollectionText('realms');
      setPriorityFilter(selectedFilterOption);
    }
    if (selectedFilterOption === 'Favorites') {
      setAppText('');
      setCollectionText('');
      setTimeout(() => {
        setAppText('tokens_index');
        setCollectionText('tokens');
        setPriorityFilter('Recent');
      }, 300);
    }
  }, [selectedFilterOption]);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="My Collection" {...setTabId(0)} />
      </StyledTabs>
      <FixedFilter
        options={optionsMyCollection}
        setSelectedFilterOption={(option) =>
          setSelectedFilterOption(option as MyCollectionString)
        }
        disableFirstOption={false}
      />
      <ReactiveBase
        app={appText}
        url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
        transformRequest={(request: any) => ({
          ...request,
          headers: {
            ...request.headers,
            Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
          },
        })}
        // @ts-ignore
        mongodb={{
          collection: collectionText,
          db: 'realm_worlds',
        }}
        analytics={false}
        credentials="none"
      >
        <Box
          sx={{
            p: '11px',
            pt: '5px',
            backgroundColor: `${theme === 'light' ? '#313131' : '#141218'}`,
          }}
        >
          {selectedFilterOption === 'Favorites' &&
          priorityFilter !== 'Token Set' ? (
            <SearchBox
              componentId="mycollection-searchbox"
              iconPosition="right"
              dataField={['name']}
              includeFields={['*']}
              autosuggest={false}
              AIConfig={{}}
              AIUIConfig={{}}
              type="search"
              compoundClause="must"
              enableIndexSuggestions={false}
              enterButton={false}
              className="my_custom_searchbox"
              customQuery={(value, props) => ({
                $search: {
                  equals: {
                    path: 'is_favorited',
                    value: true,
                  },
                },
              })}
            />
          ) : (
            <SearchBox
              componentId="mycollection-searchbox"
              iconPosition="right"
              dataField={['name']}
              includeFields={['*']}
              autosuggest={false}
              AIConfig={{}}
              AIUIConfig={{}}
              type="search"
              compoundClause="must"
              enableIndexSuggestions={false}
              enterButton={false}
              className="my_custom_searchbox"
            />
          )}
        </Box>
        <TabPanel
          topBorder={false}
          value={tabValue}
          index={0}
          width={panelWidth}
        >
          <Grid container sx={{ px: '11px' }}>
            <ReactiveList
              componentId="mycollection-results"
              dataField="_score1"
              size={1000}
              compoundClause="must"
              className="my_custom_reactive_list"
              innerClass={{
                pagination: 'list_pagination',
                resultStats: 'list_resultStats',
                resultsInfo: 'list_resultsInfo',
              }}
              react={{
                and: ['mycollection-searchbox'],
              }}
              onError={(err) => console.log(err)}
              render={({ data }: any) => (
                <Box>
                  {priorityFilter === 'Recent' && (
                    <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
                      <Expansion
                        title="All Tokens"
                        isExpanded={allTokensExpanded}
                        setIsExpanded={() =>
                          setAllTokensExpanded(!allTokensExpanded)
                        }
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: '16px',
                            rowGap: '16px',
                            // justifyContent: 'center',
                          }}
                        >
                          {sortData(data)
                            .slice(0, tokensSlice)
                            .map((token: any) => (
                              <ExpansionTokenItem
                                token={token}
                                key={token._id}
                              />
                            ))}
                          {data.length > tokensSlice && !allTokensExpanded && (
                            <div onClick={handleLoadMoreClick}>
                              <p className="loadMore">
                                Show More ({data.length - tokensSlice} more)
                              </p>
                            </div>
                          )}

                          {allTokensExpanded &&
                            sortData(data)
                              .slice(tokensSlice)
                              .map((token: any) => (
                                <ExpansionTokenItem
                                  token={token}
                                  key={token._id}
                                />
                              ))}
                        </Box>
                        {allTokensExpanded && (
                          <div onClick={handleShowLessClick}>
                            <p className="loadMore">Show Less</p>
                          </div>
                        )}
                      </Expansion>
                    </Grid>
                  )}
                  {priorityFilter === 'Token Set' && (
                    <Grid
                      item
                      xs={12}
                      rowGap={1}
                      mb={2}
                      display="flex"
                      flexWrap="wrap"
                    >
                      {data && sortData(data)?.length > 0 ? (
                        sortData(data).map((tokenSet: any) => (
                          <ExpansionTokenSetsItem
                            token_set={tokenSet}
                            tokensSlice={tokensSlice}
                            key={tokenSet._id}
                          />
                        ))
                      ) : (
                        <p>No items found.</p>
                      )}
                    </Grid>
                  )}
                  {priorityFilter === 'Realms' && (
                    <Grid
                      item
                      xs={12}
                      rowGap={1}
                      mb={2}
                      display="flex"
                      flexWrap="wrap"
                    >
                      {data && sortData(data)?.length > 0 ? (
                        sortData(data).map((realm: any) => (
                          <ExpansionRealmItem
                            realm={realm}
                            tokensSlice={tokensSlice}
                            key={realm._id}
                          />
                        ))
                      ) : (
                        <p>No items found.</p>
                      )}
                    </Grid>
                  )}
                </Box>
              )}
            />
          </Grid>
        </TabPanel>
      </ReactiveBase>
    </>
  );
}

export default MyCollectionPanel;
