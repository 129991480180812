/* eslint-disable no-confusing-arrow */
import React, { useContext } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { ReactiveBase, ReactiveList, SearchBox } from '@appbaseio/reactivesearch';
import styled from '@emotion/styled';
import appStore, { CharacterViewOptions, ViewTypeOptions } from '../../../store/AppStore';
import { ThemeContext } from '../../../context/ThemeContext';
import { FilterImg, ShieldImg } from '../../../components/styledComps/styledComps';
import CharacterPack from './CharacterPack/CharacterPack';
import GridViewIcon from '../../../images/grid-view.svg';
import DetailedViewIcon from '../../../images/detailed-view.svg';
import BackgroundImage from '../../../images/character-catalog.webp';
import CharacterPackImage from '../../../images/character-pack.webp';
import RWLogoIcon from '../../../images/Realm_worlds_logo.png';
import FilterIcon from '../../../images/filter-slider.webp';
import AICharacterGenWelcomeModal from '../../../components/modals/AICharacterGenWelcomeModal';
import CharacterSummaryModal from '../../../components/modals/CharacterSummaryModal';
import CreateCharacterSetModal from '../../../components/modals/CreateCharacterSetModal';
import CharacterCatalogCPExpansion from '../../../components/panel/Expansion/CharacterCatalogCPExpansion';
import CharacterSetHeader from '../../../components/realms/Character/CharacterSetHeader';
import './charactergenerator.scss';
import useGraphQL from '../../../hooks/useGraphQL';
import { useAuth } from '../../../hooks/use-auth';
import { GetCharactersSetsDocument } from '../../../__generated__/graphql';

const RWLogoImg = styled('img')({
  height: '25px',
  width: '25px',
  objectFit: 'contain',
});

/**
 * Represents the Character Catalog component.
 *
 * @component
 * @example
 * // Example usage of CharacterCatalog component
 * <CharacterCatalog />
 *
 * @returns {JSX.Element} React element representing the character catalog.
 */
function CharacterCatalog() {
  const { theme } = useContext(ThemeContext);
  const viewType:
  ViewTypeOptions = appStore(
    (state) => state.viewType,
  );
  const [openCreateCharacterSetModal, setOpenCreateCharacterSetModal] = React.useState(false);
  const [openAICharacterGenModal, setOpenAICharacterGenModal] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const openCharacterSummaryModal:
  boolean = appStore(
    (state) => state.openCharacterSummaryModal,
  );
  const setOpenCharacterSummaryModal = (condition: boolean) => appStore.setState({
    openCharacterSummaryModal: condition,
  });
  const auth = useAuth();

  const { data: charactersSets, refetch: refetchCharacterSets } = useGraphQL(
    GetCharactersSetsDocument,
    {
      query: {
        owner: {
          _id: auth?.user?.uid,
        },
      },
    },
  );

  const setSelectedView = (view: CharacterViewOptions) => {
    appStore.setState({
      selectedCharacterView: view,
    });
  };

  // State to track the value of the search box
  const [searchBoxValue, setSearchBoxValue] = React.useState('');

  // Function to handle changes in the search box value
  const handleSearchBoxChange = (value: any) => {
    setSearchBoxValue(value);
  };

  React.useEffect(() => {
    console.log(charactersSets?.character_sets);
  }, [charactersSets]);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: 15,
          width: '100vw',
          height: '370px',
          position: 'relative',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            height: '100%',
            width: '100%',
            background: `linear-gradient(180deg, #ffffff00 30%, ${theme === 'light' ? '#E9DCC3' : '#313131'} 100%)`,
            zIndex: 2,
          }}
        />
        <Typography sx={{
          color: '#FFF',
          textAlign: 'center',
          fontFamily: 'Passero One',
          fontSize: '100px',
          fontStyle: 'normal',
          textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          fontWeight: 400,
          lineHeight: 'normal',
          paddingBottom: 5,
          zIndex: 3,
        }}
        >
          Character Catalog
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ml: '120px',
          mr: '120px',
          mt: '10px',
          mb: '36px',

        }}
      >
        <ShieldImg src={CharacterPackImage} />
        <Typography
          sx={{
            fontFamily: 'Passero One',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
            marginRight: '26px',
            marginLeft: '13px',
          }}
        >
          Character Packs
        </Typography>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => setSelectedView('store')}
          // loading={authLoading} TODO: Implement loading state when button is made functional
          sx={{
            mt: 1,
            mb: 1,
            height: 40,
            width: '120px',
            borderRadius: '5px',
            background: '#41521F',
            color: '#FFFFFFCC',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              background: '#41521F',
            },
          }}
        >
          <Stack direction="row" gap="10px" alignItems="center">
            <RWLogoImg src={RWLogoIcon} />
            <Typography
              sx={{
                fontFamily: 'Passero One',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                whiteSpace: 'nowrap',
              }}
            >
              RW Store
            </Typography>
          </Stack>
        </LoadingButton>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
        }}
        >
          <ArrowDropDown
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsExpanded(!isExpanded)}
            className={`expansionArrow cp ${isExpanded ? 'rotated' : ''}`}
          />
        </Box>
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        columnGap="2%"
        rowGap="40px"
        flexWrap="wrap"
        minHeight="164px"
        px="120px"
      >
        <CharacterPack width="32%" />
        <CharacterPack width="32%" />
        <CharacterPack width="32%" />
        {isExpanded && (
        <>
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
        </>
        )}
      </Stack>

      {/* Character Sets */}
      <Box
        sx={{
          px: '50px',
          mt: '60px',
        }}
      >
        {/* Characters in Realm name and Buttons */}
        <CharacterSetHeader
          openCreateCharacterSetModal={() => setOpenCreateCharacterSetModal(true)}
          openAICharacterGenModal={() => setOpenAICharacterGenModal(true)}
        />
        {/* Search and View Toggle */}
        <ReactiveBase
          app="characters_index"
          url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
          transformRequest={(request: any) => ({
            ...request,
            headers: {
              ...request.headers,
              Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
            },
          })}
          // @ts-ignore
          mongodb={{
            collection: 'characters',
            db: 'realm_worlds',
          }}
          analytics={false}
          credentials="none"
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: '64px',
              mt: '30px',
            }}
          >
            {/* Search Area */}
            <Stack direction="row" gap="30px" alignItems="center">
              <SearchBox
                componentId="mycharacterpacks-searchbox"
                iconPosition="right"
                dataField={['name']}
                includeFields={['name', 'character_set']}
                autosuggest={false}
                AIConfig={{}}
                AIUIConfig={{}}
                type="search"
                compoundClause="must"
                enableIndexSuggestions={false}
                enterButton={false}
                className="my_custom_searchbox cp"
                // Set the defaultValue based on the searchBoxValue
                defaultValue={searchBoxValue}
                // Set the onValueChange prop to update the searchBoxValue
                onValueChange={handleSearchBoxChange}
              />
              <Button
                sx={{
                  background: '#41521F',
                  padding: '10px',
                  boxSizing: 'border-box',
                  color: '#ffffffcc',
                  minWidth: '30px',
                  '&:hover': {
                    background: '#41521F',
                  },
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterImg src={FilterIcon} />
              </Button>
            </Stack>
            {/* Toggle view area */}
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                paddingTop: '40px',
              }}
            >
              <button
                // variant="contained"
                type="button"
                style={{
                  height: '50px',
                  maxWidth: '50px',
                  paddingInline: 0,
                  outline: 'none',
                  border: 'none',
                  background: viewType === 'detail' ? '#7A3900' : '#41521F',
                  boxSizing: 'border-box',
                  padding: '10px',
                  display: 'block',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  // '&:hover': {
                  //   background: '#41521F',
                  // },
                }}
                onClick={() => appStore.setState({
                  viewType: 'detail',
                })}
              >
                <img
                  src={DetailedViewIcon}
                  alt="detailed-view"
                  width={30}
                  style={{
                    filter: viewType === 'detail' ? 'drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3)' : 'none',
                  }}
                />
              </button>
              <button
                // variant="contained"
                type="button"
                style={{
                  height: '50px',
                  maxWidth: '50px',
                  paddingInline: 0,
                  outline: 'none',
                  border: 'none',
                  background: viewType === 'grid' ? '#7A3900' : '#41521F',
                  boxSizing: 'border-box',
                  padding: '10px',
                  display: 'block',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  // '&:hover': {
                  //   background: '#7A3900',
                  // },
                }}
                onClick={() => appStore.setState({
                  viewType: 'grid',
                })}
              >
                <img
                  src={GridViewIcon}
                  alt="grid-view"
                  width={30}
                  style={{
                    filter: viewType === 'grid' ? 'drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3)' : 'none',
                  }}
                />
              </button>
            </Box>
          </Box>
          {/* Content Area */}
          <ReactiveList
            componentId="mycharacterpacks-results"
            dataField="_score"
            size={1000}
            // pagination
            innerClass={{
              pagination: 'list_pagination',
              resultStats: 'list_resultStats',
              resultsInfo: `list_resultsInfo ${!showFilter && 'hide'}`,
            }}
            className="dashboard_reactive_list cp"
            compoundClause="must"
            react={{
              and: ['mycharacterpacks-searchbox'],
            }}
            onError={(err) => console.log(err)}
            sortOptions={[
              {
                dataField: 'updated_at',
                sortBy: 'desc',
                label: 'Recent',
              },
              {
                dataField: 'updated_at',
                sortBy: 'asc',
                label: 'Oldest',
              },
              {
                dataField: 'name',
                sortBy: 'asc',
                label: 'Name - A-Z',
              }, {
                dataField: 'name',
                sortBy: 'desc',
                label: 'Name - Z-A',
              }]}
            showResultStats={false}
            render={({
              data,
            }: any) => (
              <>
                {/* {console.log('placeholder: ', data)} */}
                {/* {handleOrganizeData(data)} */}
                <Stack py="70px" gap="20px">
                  {searchBoxValue === '' && charactersSets?.character_sets
                    .filter((characterSets) => characterSets?.characters?.length === 0)
                    .map((characterSet) => (
                      characterSet?.characters
                      && (
                        <CharacterCatalogCPExpansion
                          key={characterSet._id}
                          title={characterSet?.name}
                          backgroundUrl={characterSet?.background_url}
                          characters={characterSet?.characters}
                        />
                      )
                    ))}
                  {charactersSets?.character_sets
                    .filter((characterSet) => (
                      characterSet?.characters?.some((character) => (
                        data.some((chars: any) => chars._id === character?._id)
                      ))
                    ))
                    .map((characterSet) => (
                      characterSet && characterSet.characters
                      && characterSet.characters.length > 0 ? (
                        <CharacterCatalogCPExpansion
                          key={characterSet._id}
                          title={characterSet?.name}
                          backgroundUrl={characterSet?.background_url}
                          characters={
                            searchBoxValue === ''
                              ? characterSet?.characters
                              : characterSet?.characters.filter((character) => (
                                data.map((sets: any) => sets._id).includes(character?._id)
                              ))
                          }
                        />
                        ) : null
                    ))}
                </Stack>
              </>
            )}
          />
        </ReactiveBase>
      </Box>

      {/* Modals */}
      <CreateCharacterSetModal
        open={openCreateCharacterSetModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenCreateCharacterSetModal}
        refetch={refetchCharacterSets}
      />
      <AICharacterGenWelcomeModal
        open={openAICharacterGenModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenAICharacterGenModal}
      />
      <CharacterSummaryModal
        open={openCharacterSummaryModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenCharacterSummaryModal}
      />
    </>
  );
}

export default CharacterCatalog;
