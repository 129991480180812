/* eslint-disable react/no-array-index-key */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { useState, useRef, useEffect } from 'react';
import LinesWithDates from './LinesWithDates';
import CampaignBox from './CampaignBox';
import s from './Campaign.module.scss';

const campaigns = [
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-01-01',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-01-15',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-02-01',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-02-01',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-02-01',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-02-01',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-02-01',
  },
  {
    title: 'Campaign Name',
    theme: 'Campaign theme',
    description:
      'Campaign Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
    date: '2024-02-01',
  },
];

/**
 * Campaign component displaying a list of campaigns with a timeline.
 *
 * @component
 * @example
 * // Example usage of Campaign component
 * <Campaign />
 *
 * @returns {JSX.Element} React element representing the Campaign component.
 */

const Campaign = () => {
  const [selectedCampaignIndex, setSelectedCampaignIndex] = useState(0);
  const [prevSelectedCampaignIndex, setPrevSelectedCampaignIndex] = useState(0);
  const campaignContainerRef = useRef<HTMLDivElement | null>(null);

  /**
   * Handle click on a campaign box.
   *
   * @param {number} index - The index of the clicked campaign box.
   * @returns {void}
   */

  const handleCampaignClick = (index: number) => {
    setPrevSelectedCampaignIndex(selectedCampaignIndex);
    setSelectedCampaignIndex(index);
  };

  useEffect(() => {
    if (campaignContainerRef.current) {
      const container = campaignContainerRef.current;

      // Calculate the direction of the scroll
      const direction =
        selectedCampaignIndex > prevSelectedCampaignIndex
          ? 'forward'
          : 'backward';

      // Get the target element based on the direction
      const targetElement =
        direction === 'forward'
          ? container.children[selectedCampaignIndex + 1]
          : container.children[selectedCampaignIndex - 1];

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [selectedCampaignIndex, prevSelectedCampaignIndex]);

  return (
    <div className={s.campaignManager} tabIndex={0}>
      <LinesWithDates selectedCampaignIndex={selectedCampaignIndex} />
      <div className={s.campaignContainer} ref={campaignContainerRef}>
        {campaigns.map((campaign, index) => (
          <CampaignBox
            key={index}
            campaign={campaign}
            isActive={index === selectedCampaignIndex}
            onClick={() => handleCampaignClick(index)}
            isTop={index % 2 === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default Campaign;
