import React from 'react';
import { Box } from '@mui/material';
import FramePanel from '../FramePanel/FramePanel';
import BackgroundPanel from '../BackgroundPanel/BackgroundPanel';
import OverlayPanel from '../OverlayPanel/OverlayPanel';
import TextPanel from '../TextPanel/TextPanel';
import NotesPanel from '../NotesPanel/NotesPanel';
import styles from './panelitem.module.scss';
import LibraryPanel from '../LibraryPanel/LibraryPanel';
import AIGeneratePanel from '../AIGeneratePanel/AIGeneratePanel';
import MyCollectionPanel from '../MyCollectionPanel/MyCollectionPanel';
import { ActiveTab } from '../../../store/AppStore';

interface PanelProps {
  activeTab: ActiveTab;
  onStageChange: (changeName: string) => void;
}

export default function PanelItem({ activeTab, onStageChange }: PanelProps) {
  return (
    <Box
      className={styles.panelBox_wrapper}
    >
      <Box className={styles.panelBox} sx={{ pointerEvents: 'auto' }}>
        {activeTab === 'frame' && <FramePanel onStageChange={onStageChange} />}
        {activeTab === 'background' && (
          <BackgroundPanel onStageChange={onStageChange} />
        )}
        {activeTab === 'overlay' && (
          <OverlayPanel onStageChange={onStageChange} />
        )}
        {activeTab === 'text' && <TextPanel onStageChange={onStageChange} />}
        {activeTab === 'notes' && <NotesPanel />}
        {activeTab === 'library' && <LibraryPanel onStageChange={onStageChange} />}
        {activeTab === 'aigenerate' && <AIGeneratePanel onStageChange={onStageChange} />}
        {activeTab === 'mycollection' && <MyCollectionPanel />}
      </Box>
    </Box>
  );
}
