/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import styles from './panelitemnew.module.scss';
import './panelitemnewmodifier.scss';

interface Props {
  children: ReactNode;
  onClick: () => void;
  active?: boolean;
}

function PanelItemNew({ children, onClick, active }: Props) {
  return (
    <button type="button" className={styles.panelCard} onClick={onClick}>
      <div className={`panelCard___item ${active === true && 'active'} ${styles.panelCard__item}`}>{children}</div>
      {/* <span className={styles.background} /> */}
    </button>
  );
}

export default PanelItemNew;
