/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import useGraphQL from '../../hooks/useGraphQL';
import DashboardBG from '../../images/dashboard-bg.png';
import {
  GetRealmsDocument,
  GetUserWalletDocument,
  GetUserWalletQuery,
  RealmSortByInput,
} from '../../__generated__/graphql';
import SideNav from '../../components/nav/SideNav/SideNav';
import Discover from './Discover/Discover';
import Realms from './Realms/Realms';
import Favorites from './Favorites/Favorites';
import { NavString } from '../../interfaces/navitems';
import appStore from '../../store/AppStore';
import RWSettings from './RWSettings/RWSettings';
import './Dashboard.scss';
import { useAuth } from '../../hooks/use-auth';

export const getAvaliableCredits = (query: GetUserWalletQuery) => {
  let credits = 0;
  if (query?.user?.wallet) {
    credits += query.user.wallet?.ssgpCreditPackCreditsRemaining ?? 0;
    credits += query.user.wallet?.ssgpSubscriptionCreditsRemaining ?? 0;
    credits -= query.user.wallet?.ssgpPendingCredits ?? 0;
  }
  return credits;
};

function Dashboard(): JSX.Element {
  const activeItem: NavString | null = appStore((state) => state.activeNavItem);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: favoriteRealms,
    refetch: refetchRealms,
    error: realmsError,
  } = useGraphQL(GetRealmsDocument, {
    limit: 100,
    sortBy: RealmSortByInput.CreatedatDesc,
    query: {
      is_favorited: true,
    },
  });

  // console.log('favourite realms', favoriteRealms?.realms[0]);

  if (realmsError) {
    console.log('realmsError: ', realmsError);
  }

  const auth = useAuth();

  const { data: userWallet } = useGraphQL(GetUserWalletDocument, {
    query: {
      _id: auth?.user?.uid,
    },
  });

  appStore.setState({
    userName: auth?.user?.displayName as string,
    userCredits: getAvaliableCredits(userWallet ?? {}),
  });

  useEffect(() => {
    if (activeItem === null) {
      appStore.setState({
        activeNavItem: 'Discover',
      });
    }
  });

  return (
    <ReactiveBase
      app="realms_index"
      url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
      transformRequest={(request: any) => ({
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
        },
      })}
      // @ts-ignore
      mongodb={{
        collection: 'realms',
        db: 'realm_worlds',
      }}
      analytics={false}
      credentials="none"
      style={{
        background: '#E9DCC3',
        minHeight: '100vh',
        display: 'flex',
        backgroundImage:
          activeItem === 'Discover' ? `url(${DashboardBG})` : 'none',
        backgroundSize: activeItem === 'Discover' ? 'cover' : 'none',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <SideNav />

        {activeItem === 'Discover' && <Discover />}
        {activeItem === 'Favorites' && <Favorites />}
        {activeItem === 'Realms' && <Realms />}
        {activeItem === 'News' && <> News Component</>}
        {activeItem === 'Settings' && <RWSettings />}
      </Box>
    </ReactiveBase>
  );
}

export default Dashboard;
