import {
  Box, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { Carousel } from '@trendyol-js/react-carousel';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GoldShield from '../../../../images/gold-small.png';
import DownloadICon from '../../../../images/download red.png';
import CharacterCarousel from '../CharacterCarousel/CharacterCarousel';
import { ShieldImg, DownloadImg } from '../../../../components/styledComps/styledComps';
import appStore from '../../../../store/AppStore';

type PackVariant = 'catalog' | 'store';

interface Props {
  width?: string,
  variant?: PackVariant,
}

export default function CharacterPack({ width, variant }: Props): JSX.Element {
  const [selected, setSelected] = React.useState(false);
  const openPackPurchaseSummaryModal:
  boolean = appStore(
    (state) => state.openPackPurchaseSummaryModal,
  );
  const setOpenPackPurchaseSummaryModal = (condition: boolean) => appStore.setState({
    openPackPurchaseSummaryModal: condition,
  });

  const handleOpenPackPurchaseSummaryModal = () => {
    if (variant === 'store') {
      setSelected(!selected);
      setOpenPackPurchaseSummaryModal(true);
    }
  };

  React.useEffect(() => {
    if (!openPackPurchaseSummaryModal) setSelected(false);
  }, [openPackPurchaseSummaryModal]);

  return (
    <Box width={width}>
      <Box
        sx={{
          minHeight: '184px',
          minWidth: '100%',
          background: '#fff',
          boxSizing: 'border-box',
          pt: '10px',
          pl: '13px',
          boxShadow: selected ? '0px 4px 10px 0px #41521F' : 'none',
          border: selected ? '2px solid #41521F' : 'none',
        }}
        onClick={handleOpenPackPurchaseSummaryModal}
      >

        {/* Download Icon Start */}
        {variant === 'catalog' && (
          <Box
            sx={{
              paddingRight: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
          >
            <DownloadImg src={DownloadICon} />
          </Box>
        )}
        {/* Download Icon End */}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pl={variant === 'catalog' ? '0' : '14px'}
          pr={variant === 'catalog' ? '0' : '27px'}
        >
          <Stack direction="row" gap="17px" alignItems="center">
            {variant === 'catalog' && (
              <ShieldImg style={{ marginRight: '-15px' }} src={GoldShield} />
            )}
            <Typography sx={{
              fontFamily: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              whiteSpace: 'nowrap',
            }}
            >
              Orc Essentials Pack
            </Typography>
            <Typography
              sx={{
                color: 'var(--white, #FFF)',
                background: '#41531E',
                textAlign: 'center',
                borderRadius: 4,
                fontFamily: 'DM Sans',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px',
                padding: '0 10px',
                whiteSpace: 'nowrap',
              }}
            >
              In Library
              {' '}
              <span style={{ color: 'green', fontSize: '5px' }}>✓</span>
            </Typography>
          </Stack>
          {variant === 'store' && (
            <ShieldImg src={GoldShield} />
          )}
        </Stack>

        <Box
          sx={{
            minHeight: '90px',
            maxWidth: '100%',
            py: '10px',
          }}
        >
          {' '}
          <Carousel
            show={4}
            slide={4}
            swiping
            infinite={false}
            rightArrow={(
              <ArrowRightIcon
                sx={{
                  marginTop: '55px',
                }}
              />
)}
            leftArrow={(
              <ArrowLeftIcon sx={{
                marginTop: '55px',
              }}
              />
)}
          >
            {CharacterCarousel}
          </Carousel>

        </Box>

      </Box>
    </Box>
  );
}

CharacterPack.defaultProps = {
  width: '30%',
  variant: 'catalog',
};
