/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface LeftArrowProps {
  onClick?: () => void;
}

interface RightArrowProps {
  onClick?: () => void;
}

interface ClockArrowProps {
  onClick?: () => void;
  active: boolean;
}

export function LeftArrowIcon({ onClick } : LeftArrowProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <button onClick={onClick} type="button" className={`${styles.icon} ${styles.arrow_icon} ${styles[theme]}`} aria-label="undo">
      <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect x="31.6016" y="35.1816" width="31" height="35" transform="rotate(180 31.6016 35.1816)" fill="url(#pattern0)" />
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_2688_18352" transform="matrix(0.01 0 0 0.00885714 0 0.0571429)" />
          </pattern>
          <image id="image0_2688_18352" width="100" height="100" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADyElEQVR4nO3cXahmUxgH8G00PqPmwtegXNCEXOFCM2UMqUHKhQvlZiIMFwo1kVC4lCi5kkLUXHEhJUQKpdCI0HBuuCAaU16MJj8tZ5dpzHn3Oufd7znPft/nV1PTqVlrn/nvZ3+stfZqmpRSSimllFJKKaWUUpoGbMNT7Z8rptJJqoP78Lf/lL/fW/nPU59wGg74v/KzU3vtLHXDdku7pqKJ1CdcNyaQW3vtLHXD5WMCebiiidQnXDImkFd67Sx1w3ljAtlb0UTqE04w3um9dpi64ZcxgeyoaCL1CZ+MCeTVXjtL3fDSmEB+x4aKZlJf8EDHfeSe3jpL3XB9RyB7sa6iqdQHbNTtxl46S3XwXUcg32B9ZXNpUnixokpun7ijVAd3VASyD2dVNpkmgXPUeRNHTdRZqoM9laHkpWs14NHKQMrL4qXNgOBY3IkX8Hi5IjTR4WL1fsCZzQDgeHx42PHvD7+Yo9wbKh5/D/XlEObdcdcSxz8aQij3W5490UOxeJkyyFDaVSh/LTOUUlWbmqCwu+P4SyhXNgP+BY7kJ2xthvv7jMJWSjlbrMxBPBJtIFL9CRa3UvCRlXu/zNU3w6z4UchQcLXJ/FbmUSIMSFr+JTjm5QvvmdzXZb5lgPfEUbhQsFl/PitzKmsxDmZlgcS8fOF1/fq8jIXhxAEEEq9ScC7+0L99eBZbpl01EwYSMpQHTdcCnmwft9cHDCRWKOU/CR9bHfvxBh7CVTgpSCDhQtnUPsqutoPtA8EzuK2tojKZdvQaBBIulB3iOICv2oeOp9tB0Z24qf0I6TKcjzPK6sue+w4VSjlTk39D2RblfvJuJhKoUnAyPl08prk3ilIpG5c5uzjroWyNEMrZ7YrGJFallLn1JE6lbMDbmUisSjkGzy0e09wblZHyJgLc0p4l826hrAdrIsAFy1iWOsu2NFG0L5C78Kf5tbmJph1Pesf8WQhzyToSXNvOFs6DODf1ccqQOW6e8ftLjHeRFSzq3t6+uxy6k93QDS+MJYZfds3AuFiMF8K+YF05u9q59W8Ny/ArowsuKhtx4jX8LK7ZD2OJe86F7ShAqaC38ONaJxFmgioKnFK+dcQNuBtP4OV21coH+ALfL7HraoaxhsHtnkIYs3MDX21TWAaUYQRaKJdhBFpKmmEEWmydYQT6HCHDCPTBToYR6JO2DCPQR58ZRqDPojOMQBsHZBiBttbIMAJtPpNhrJZBb880iyxu7ZdhRIHj2vmRQ/2albH2m2DuxPN4bBCbYKaUUkoppZRSSimllFJzmH8Ayi8OzwbxvCgAAAAASUVORK5CYII=" />
        </defs>
      </svg>
    </button>
  );
}

export function RightArrowIcon({ onClick } : RightArrowProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <button onClick={onClick} type="button" className={`${styles.icon} ${styles.arrow_icon} ${styles[theme]}`} aria-label="redo">
      <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect width="31" height="35" transform="matrix(1 0 0 -1 0.601562 35.1328)" fill="url(#pattern0)" />
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_2688_18356" transform="matrix(0.01 0 0 0.00885714 0 0.0571429)" />
          </pattern>
          <image id="image0_2688_18356" width="100" height="100" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADyElEQVR4nO3cXahmUxgH8G00PqPmwtegXNCEXOFCM2UMqUHKhQvlZiIMFwo1kVC4lCi5kkLUXHEhJUQKpdCI0HBuuCAaU16MJj8tZ5dpzHn3Oufd7znPft/nV1PTqVlrn/nvZ3+stfZqmpRSSimllFJKKaWUUpoGbMNT7Z8rptJJqoP78Lf/lL/fW/nPU59wGg74v/KzU3vtLHXDdku7pqKJ1CdcNyaQW3vtLHXD5WMCebiiidQnXDImkFd67Sx1w3ljAtlb0UTqE04w3um9dpi64ZcxgeyoaCL1CZ+MCeTVXjtL3fDSmEB+x4aKZlJf8EDHfeSe3jpL3XB9RyB7sa6iqdQHbNTtxl46S3XwXUcg32B9ZXNpUnixokpun7ijVAd3VASyD2dVNpkmgXPUeRNHTdRZqoM9laHkpWs14NHKQMrL4qXNgOBY3IkX8Hi5IjTR4WL1fsCZzQDgeHx42PHvD7+Yo9wbKh5/D/XlEObdcdcSxz8aQij3W5490UOxeJkyyFDaVSh/LTOUUlWbmqCwu+P4SyhXNgP+BY7kJ2xthvv7jMJWSjlbrMxBPBJtIFL9CRa3UvCRlXu/zNU3w6z4UchQcLXJ/FbmUSIMSFr+JTjm5QvvmdzXZb5lgPfEUbhQsFl/PitzKmsxDmZlgcS8fOF1/fq8jIXhxAEEEq9ScC7+0L99eBZbpl01EwYSMpQHTdcCnmwft9cHDCRWKOU/CR9bHfvxBh7CVTgpSCDhQtnUPsqutoPtA8EzuK2tojKZdvQaBBIulB3iOICv2oeOp9tB0Z24qf0I6TKcjzPK6sue+w4VSjlTk39D2RblfvJuJhKoUnAyPl08prk3ilIpG5c5uzjroWyNEMrZ7YrGJFallLn1JE6lbMDbmUisSjkGzy0e09wblZHyJgLc0p4l826hrAdrIsAFy1iWOsu2NFG0L5C78Kf5tbmJph1Pesf8WQhzyToSXNvOFs6DODf1ccqQOW6e8ftLjHeRFSzq3t6+uxy6k93QDS+MJYZfds3AuFiMF8K+YF05u9q59W8Ny/ArowsuKhtx4jX8LK7ZD2OJe86F7ShAqaC38ONaJxFmgioKnFK+dcQNuBtP4OV21coH+ALfL7HraoaxhsHtnkIYs3MDX21TWAaUYQRaKJdhBFpKmmEEWmydYQT6HCHDCPTBToYR6JO2DCPQR58ZRqDPojOMQBsHZBiBttbIMAJtPpNhrJZBb880iyxu7ZdhRIHj2vmRQ/2albH2m2DuxPN4bBCbYKaUUkoppZRSSimllFJzmH8Ayi8OzwbxvCgAAAAASUVORK5CYII=" />
        </defs>
      </svg>
    </button>
  );
}

export function ClockArrowIcon({ onClick, active } : ClockArrowProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <button onClick={onClick} type="button" className={`${styles.icon} ${styles.arrow_icon} ${styles.clocked} ${styles[theme]} ${active && styles.active}`} aria-label="history">
      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.81 27">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <g>
              <path d="m1.64,17.55h4.34" />
              <path d="m1.64,17.55v4.56" />
              <path d="m24.16,17.55c-1.6,4.91-6.04,8.45-11.26,8.45S3.24,22.47,1.64,17.55" />
            </g>
            <path d="m1.07,14.85c-.05-.44-.07-.9-.07-1.35C1,6.6,6.33,1,12.9,1s11.9,5.6,11.9,12.5c0,.45-.02.91-.07,1.35" />
          </g>
          <polyline points="12.9 6.12 12.9 13.95 7.63 13.95" />
        </g>
      </svg>
    </button>
  );
}
