/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/require-default-props */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { HiXMark } from 'react-icons/hi2';
import s from './CreateRealmModal.module.scss';
import fantasy from '../../images/fantasy.webp';
import library from '../../images/library.webp';
import uploadImage from '../../images/upload-image.webp';
import dice from '../../images/dice.webp';
import background from '../../assets/images/NewDashboard/green_banner.png';
import bookmark from '../../images/bookmark.webp';
import realm from '../../images/realms-icon-active.svg';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import { ModalContext } from './Modal';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import {
  CreateRealmDocument,
  RealmInsertInput,
} from '../../__generated__/graphql';

interface RealmProps {
  updateApp?: () => void;
  updateState?: () => void;
}

/**
 * A component for creating a new realm with user-defined details.
 *
 * @component
 * @example
 * // Example usage of CreateRealmModal component
 * <CreateRealmModal />
 *
 * @returns {JSX.Element} React element representing the create realm modal.
 */

const CreateRealmModal = ({ updateApp, updateState }: RealmProps) => {
  const modalContext = useContext(ModalContext);
  const [activeInput, setActiveInput] = useState(null);

  const handleFocus = (inputId: any) => {
    setActiveInput(inputId);
  };

  const handleBlur = () => {
    setActiveInput(null);
  };

  // form states
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = useState<Blob | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [nameInput, setNameInput] = useState('');
  const [tagsInput, setTagsInput] = useState<any>('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const tagsInputRef = useRef<HTMLInputElement>(null);
  const [inputPaddingLeft, setInputPaddingLeft] = useState<number>(12);
  const tagsContainerRef = useRef<HTMLDivElement>(null);

  const [tags, setTags] = useState<string[]>([]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tagsInput.trim() !== '') {
      setTags((prevTags) => [...prevTags, tagsInput.trim()]);
      setTagsInput('');
    }
  };

  const removeTag = (index: number) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (tagsInputRef.current && tagsContainerRef.current) {
      const totalTagsWidth = tagsContainerRef.current.offsetWidth;

      setInputPaddingLeft(12 + totalTagsWidth);
    }
  }, [tags]);

  useEffect(() => {
    if (tags.length > 0) {
      setInputPaddingLeft((prevPadding) => prevPadding + 7);
    }
  }, [tags.length]);

  // useEffect(() => {
  //   setInputPaddingLeft((prevPadding) => prevPadding + 17);
  // }, [tags.length]);

  const auth = useAuth();
  const { displayNotification } = useNotification();

  const {
    mutate: createRealm,
    isLoading: isRealmLoading,
    isError: isCreateRealmError,
    isSuccess: isRealmSuccess,
    error: createRealmError,
    data: createdRealm,
    status: createRealmStatus,
  } = useGraphQLMutation(CreateRealmDocument);

  React.useEffect(() => {
    if (isRealmLoading) {
      displayNotification({
        message: 'Creating realm...',
        type: 'success',
      });
    }

    if (isRealmSuccess) {
      console.log('createRealm Success: ', createdRealm);
      displayNotification({
        message: `Realm ${createdRealm.insertOneRealm?.name} was successfully created!`,
        type: 'success',
      });

      if (modalContext) {
        modalContext.close();
      }

      updateApp && updateApp();
      updateState && updateState();
    }

    if (isCreateRealmError) {
      console.error('createRealm Error: ', createRealmError);
      displayNotification({ message: 'Error creating Realm', type: 'error' });
    }
  }, [createRealmStatus]);

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(URL.createObjectURL(file));
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      console.log(URL.createObjectURL(file));
    }
  };

  console.log([tagsInput]);

  const handleSaveRealm = () => {
    const newRealm: RealmInsertInput = {
      name: nameInput,
      description: descriptionInput,
      is_public: true,
      is_favorited: false,
      tags,
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230804/719bc992-67c8-4226-afc0-19f828bf5faa.png',
      owner: {
        link: auth?.realmUser?.id,
      },
      createdAt: new Date(),
    };
    createRealm({
      data: newRealm,
    });
  };

  return (
    <div className={s.modal}>
      <img className={s.modal__background} src={background} alt="background" />

      {/* Gradients  */}

      <div className={s.modal__topGradient} />
      <div className={s.modal__midGradient} />
      <div className={s.modal__extraGradient} />
      <div className={s.modal__newGradient} />
      <div className={s.modal__lastGradient} />
      <div className={s.modal__bottomGradient} />

      <img className={s.modal__bookmarkImg} src={bookmark} />
      <div className={s.modal__container}>
        <img className={s.modal__container__realmImage} src={realm} />
        <p className={s.modal__container__text}>Realm Creator</p>
      </div>
      <div className={s.textContainer}>
        <p className={s.textContainer__title}>👋 Welcome, Realm Creator,</p>
        <p className={s.textContainer__info}>
          You hold the power to shape a world of fantasy and adventure. By
          filling out this form, you are about to breathe life into your very
          own realm, a world where epic quests and legendary tales will unfold.
          Are you ready? 🧙 🔮
        </p>
      </div>
      <div className={s.actionsContainer}>
        <div className={s.characterActions}>
          <p className={s.characterActions__topText}>Character image</p>
          {previewUrl === null && (
            <div className={s.characterActions__container}>
              <label htmlFor="fileInput" style={{ width: '100%' }}>
                <div className={s.characterActions__box}>
                  <div className={s.characterActions__boxImage}>
                    <img
                      className={s.characterActions__boxImage__icon}
                      src={uploadImage}
                      alt="upload icon"
                    />
                  </div>
                  <div className={s.characterActions__boxFeature}>
                    <p className={s.characterActions__boxFeature__text}>
                      Upload Image
                    </p>
                  </div>
                </div>
              </label>
              <input
                type="file"
                accept=".jpg,.png,.webp"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                id="fileInput"
              />

              <div className={s.characterActions__box}>
                <div className={s.characterActions__boxImage}>
                  <img
                    className={s.characterActions__boxImage__icon}
                    src={library}
                    alt="library icon"
                  />
                </div>
                <div className={s.characterActions__boxFeature}>
                  <p className={s.characterActions__boxFeature__text}>
                    My Library
                  </p>
                </div>
              </div>

              <div className={s.characterActions__box}>
                <div
                  className={`${s.characterActions__boxImage} ${s.characterActions__boxImagePink}`}
                >
                  <img
                    className={`${s.characterActions__boxImage__icon} ${s.characterActions__boxImage__iconPink}`}
                    src={fantasy}
                    alt="fantasy icon"
                  />
                </div>
                <div className={s.characterActions__boxFeature}>
                  <p className={s.characterActions__boxFeature__text}>
                    AI Generate
                  </p>
                </div>
              </div>
            </div>
          )}
          {previewUrl !== null && (
            <div className={s.replaceImageContainer}>
              <img
                alt="realm preview"
                src={previewUrl}
                style={{
                  height: '500px',
                  marginTop: '46px',
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              <div className={s.replaceImage}>
                <label htmlFor="fileInput" style={{ width: '100%' }}>
                  <div className={s.characterActions__box}>
                    <div className={s.characterActions__boxImage}>
                      <img
                        className={s.characterActions__boxImage__icon}
                        src={uploadImage}
                        alt="upload icon"
                      />
                    </div>
                  </div>
                </label>
                <input
                  type="file"
                  accept=".jpg,.png,.webp"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  id="fileInput"
                />
              </div>
            </div>
          )}
        </div>
        <form className={s.form}>
          <div className={s.form__box}>
            <label htmlFor="nameInput" className={s.form__box__label}>
              Name
            </label>
            <label
              className={`${s.form__box__innerLabel} ${
                activeInput === 'nameInput' || nameInput
                  ? s.form__box__innerLabelFocused
                  : ''
              }`}
            >
              Name
            </label>
            <div className={s.form__box__diceDiv}>
              <img src={dice} alt="dice icon" className={s.form__box__dice} />
            </div>
            <input
              className={s.form__box__input}
              placeholder=""
              type="text"
              id="nameInput"
              onFocus={() => handleFocus('nameInput')}
              onBlur={handleBlur}
              onChange={(e) => setNameInput(e.target.value)}
              value={nameInput}
              required
              style={{ paddingLeft: '12px' }}
            />
          </div>

          <div className={s.form__box}>
            <label htmlFor="tagsInput" className={s.form__box__label}>
              Tags
            </label>
            <label
              className={`${s.form__box__innerLabelTags} ${
                activeInput === 'tagsInput' || tagsInput
                  ? s.form__box__innerLabelTagsFocused
                  : tags.length > 0
                  ? s.form__box__innerLabelTagsFocused
                  : ''
              }`}
            >
              Tags
            </label>
            <div className={s.form__box__tags} ref={tagsContainerRef}>
              {tags.map((tag, index) => (
                <div key={index} className={s.form__box__tag}>
                  <p className={s.form__box__tagText}>{tag}</p>
                  <HiXMark onClick={() => removeTag(index)} />
                </div>
              ))}
            </div>
            <input
              ref={tagsInputRef}
              className={s.form__box__input}
              placeholder=""
              type="text"
              id="tagsInput"
              onFocus={() => handleFocus('tagsInput')}
              onBlur={handleBlur}
              onChange={(e) => setTagsInput(e.target.value)}
              value={tagsInput}
              onKeyDown={handleKeyPress}
              required
              style={{ paddingLeft: `${inputPaddingLeft}px` }}
            />
          </div>

          <div className={s.form__box}>
            <label htmlFor="descriptionInput" className={s.form__box__label}>
              <label
                className={`${s.form__box__innerLabelDescription} ${
                  activeInput === 'descriptionInput' || descriptionInput
                    ? s.form__box__innerLabelDescriptionFocused
                    : ''
                }`}
              >
                Description
              </label>
              Description
            </label>
            <input
              className={`${s.form__box__input} ${s.form__box__inputDescription}`}
              placeholder=""
              type="text"
              id="descriptionInput"
              onFocus={() => handleFocus('descriptionInput')}
              onBlur={handleBlur}
              onChange={(e) => setDescriptionInput(e.target.value)}
              value={descriptionInput}
              required
            />
          </div>

          <button
            className={s.form__button}
            type="button"
            disabled={isRealmLoading}
            onClick={handleSaveRealm}
          >
            Create Realm
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateRealmModal;
