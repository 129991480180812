import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function TextIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${styles.icon} ${styles.text_icon} ${styles[theme]} ${active && styles.active}`}
      aria-label="text icon"
    >
      <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.72328V3.91309C1 2.69032 1.995 1.69531 3.21778 1.69531H18.7063C19.9291 1.69531 20.9241 2.69032 20.9241 3.91309V5.72328M10.962 20.3127V2.59441M6.88611 20.3127H13.5754M15.014 11.01H23.4176C24.2927 11.01 25 11.7173 25 12.5924V13.5515M17.8911 24.3047V11.6454M15.3257 24.3047H20.4565" />
      </svg>
    </button>
  );
}

export default TextIcon;
