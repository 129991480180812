/* eslint-disable react/require-default-props */
/* eslint-disable object-curly-newline */
import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './tabpanel.module.scss';

export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  setValue?: (value: number) => void;
  width?: string;
  maxWidth?: string;
  topBorder?: boolean;
};

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, width, maxWidth, topBorder, ...other } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className={`tab_panel ${styles.tab_panel} ${styles[theme]} ${topBorder === true && styles.top_border}`}
          sx={{ width: `${width} !important`, maxWidth: `${maxWidth} !important` }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.defaultProps = {
  topBorder: true,
};
