import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function RectangleIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${styles.icon} ${styles.rectangle_icon} ${styles[theme]} ${active && styles.active}`}
      aria-label="rectangle icon"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <path d="M4,1h16c1.7,0,3,1.3,3,3v16c0,1.7-1.3,3-3,3H4c-1.7,0-3-1.3-3-3V4C1,2.3,2.3,1,4,1z" />
      </svg>
    </button>
  );
}

export default RectangleIcon;
