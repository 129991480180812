/* eslint-disable react/require-default-props */
import React from 'react';
import { Box } from '@mui/material';
import ExpansionHeader from './ExpansionHeader';
import { ExpansionVariant } from '../../../store/AppStore';

interface Props {
  headerChildren?: React.ReactNode;
  title: string;
  children: React.ReactNode;
  isExpanded: boolean;
  setIsExpanded: () => void;
  variant?: ExpansionVariant;
}

/**
 * A container that takes in the expansion header and expansion item as children components.
 * Commonly used in the token maker panels.
 * Has a variant that allows us to use them on white backgrounds in the character catalog section
 * @date 11/22/2023 - 11:14:42 AM
 *
 * @param {Props} param0
 * @param {React.ReactNode} param0.headerChildren
 * @param {string} param0.title
 * @param {React.ReactNode} param0.children
 * @param {boolean} param0.isExpanded
 * @param {() => void} param0.setIsExpanded
 * @param {ExpansionVariant} param0.variant
 * @returns
 */
function Expansion({
  headerChildren,
  title,
  children,
  isExpanded,
  setIsExpanded,
  variant,
}: Props) {
  return (
    <Box width="100%">
      <ExpansionHeader
        isExpanded={isExpanded}
        title={title}
        onExpandClick={setIsExpanded}
        variant={variant}
      >
        {headerChildren}
      </ExpansionHeader>
      <Box>
        {children}
      </Box>
    </Box>
  );
}

Expansion.defaultProps = {
  variant: 'token-maker',
};

export default Expansion;
