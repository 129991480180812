import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useParams } from 'react-router-dom';
import CharacterNote from './CharacterNote';
import PlusIcon from '../../images/plus.webp';
import { CustomScrollWrapper, EditorImg } from '../styledComps/styledComps';
import {
  CharacterLong_Term_Goal, CharacterShort_Term_Goal, Character_Note, NoteInsertInput,
} from '../../__generated__/graphql';

interface Props {
  borderTopLeft?: number,
  borderTopRight?: number,
  title: string,
  variant?: 'card' | 'integrated',
  realmNote?: boolean,
  notes: (Character_Note | NoteInsertInput | CharacterShort_Term_Goal | CharacterLong_Term_Goal)[],
  setNotes: (notes:
  (Character_Note | NoteInsertInput | CharacterShort_Term_Goal | CharacterLong_Term_Goal)[]
  ) => void,
}

/**
 * Represents a wrapper component for character notes.
 *
 * @component
 * @example
 * // Example usage of CharacterNotesWrapper component
 * <CharacterNotesWrapper
 *   borderTopLeft={4}
 *   borderTopRight={4}
 *   title="Short Term Goals"
 *   variant="integrated"
 *   notes={[{ _id: '1', content: 'Example note 1' }, { _id: '2', content: 'Example note 2' }]}
 * />
 *
 * @param {Props} param0 - The properties of the component.
 * @param {number} param0.borderTopLeft - Border radius for the top-left corner.
 * @param {number} param0.borderTopRight - Border radius for the top-right corner.
 * @param {string} param0.title - The title of the notes wrapper.
 * @param {("card" | "integrated")} param0.variant - The variant of the wrapper.
 * @param {SampleNote[]} param0.notes - Array of sample notes.
 * @returns {JSX.Element} React element representing a wrapper for character notes.
 */

function CharacterNotesWrapper({
  borderTopLeft,
  borderTopRight,
  title,
  variant,
  realmNote,
  notes,
  setNotes,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const { id } = useParams();
  React.useEffect(() => {
    console.log(notes);
  }, [notes]);
  return (
    <>
      <Stack
        gap="35px"
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          width: '100%',
          borderRadius: `${borderTopLeft!}px ${borderTopRight!}px 0px 0px`,
          background: '#41521F',
          padding: '16px 30px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'DM Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            letterSpacing: '0.46px',
            textTransform: 'capitalize',
            textAlign: 'left',
            width: 'fit-content',
          }}
        >
          {title}
        </Typography>
        <Stack gap="20px" direction="row" alignItems="center">
          <EditorImg
            src={PlusIcon}
            onClick={() => {
              if (realmNote === true) {
                setNotes([...notes, {
                  title: 'New Note',
                  content: '',
                  realm: {
                    link: id,
                  },
                }]);
              } else {
                setNotes([...notes, { title: 'New Note', content: '' }]);
              }
            }}
          />
          <Stack alignItems="center" justifyContent="center" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? (
              <KeyboardArrowUpIcon sx={{ fontSize: '30px', color: 'white' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'white' }} />
            )}
          </Stack>
        </Stack>
      </Stack>
      {isExpanded && (
        <Box
          sx={{
            maxHeight: variant === 'integrated' ? '486px' : '865px',
            boxSizing: 'border-box',
            pr: '6px',
            py: '5px',
            background: '#E9DCC3',
            borderRadius: variant === 'card' ? `0px 0px ${borderTopRight!}px 0px` : `0px 0px ${borderTopRight!}px ${borderTopLeft!}px`,
          }}
        >
          <CustomScrollWrapper
            maxHeight={variant === 'integrated' ? '476px' : '855px'}
            minHeight="50px"
          >
            {notes.map((note, index) => (
              <CharacterNote
                // eslint-disable-next-line react/no-array-index-key
                key={`${note.title}_${index}`}
                note={note}
                editNote={(n: Character_Note | NoteInsertInput | CharacterShort_Term_Goal
                | CharacterLong_Term_Goal) => {
                  const Notes = [...notes];
                  Notes[index] = n;
                  setNotes(Notes);
                }}
                deleteNote={() => {
                  const Notes = [...notes];
                  Notes.splice(index, 1);
                  setNotes(Notes);
                }}
                realmNote={realmNote}
                realmId={id}
              />
            ))}
          </CustomScrollWrapper>
        </Box>
      )}
    </>
  );
}

CharacterNotesWrapper.defaultProps = {
  borderTopLeft: 4,
  borderTopRight: 4,
  variant: 'integrated',
  realmNote: false,
};

export default CharacterNotesWrapper;
