/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FocusEventHandler, useContext, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  FormControlLabel,
  ToggleButton,
  Checkbox,
  Button,
} from '@mui/material';
// import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
// import Typography from '@mui/material/Typography';
// import { nanoid } from '@reduxjs/toolkit';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorResult, RGBColor } from 'react-color';
import WebFont from 'webfontloader';
import useItem from '../../../hooks/useItem';
import { StageData } from '../../../redux/currentStageData';
import fontList from '../../../config/fonts.json';
import TabPanel from '../../tab/TabPanel';
import {
  StyledTab,
  StyledTabs,
  StyledToggleButtonGroup,
  setTabId,
} from '../StyledPanel/StyledPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import { PanelItemProps } from '../FramePanel/FramePanel';
import RWColorPicker from '../../colorPicker/RWColorPicker';
import styles from './textpanel.module.scss';
import { ThemeContext } from '../../../context/ThemeContext';
import '../eachpanelmodifier.scss';

export default function TextPanel({ onStageChange }: PanelItemProps) {
  const { createItem, removeItem, updateItem, stageData } = useItem();
  const targetItemText = useMemo(
    () =>
      stageData.find((data) => data.id === 'text' || data.attrs.id === 'text'),
    [stageData],
  );
  const [alignment, setAlignment] = React.useState('left');
  const [formats, setFormats] = React.useState(() => ['normal']);
  const { theme } = useContext(ThemeContext);
  const [textBold, setTextBold] = React.useState(false);
  const [textItalic, setTextItalic] = React.useState(false);
  const [textUnderline, setTextUnderline] = React.useState<boolean>(() =>
    targetItemText?.attrs.textDecoration !== '');

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    if (targetItemText) {
      onStageChange('Updated Text Format');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontStyle: 'italic',
        updatedAt: Date.now(),
      }));
    }

    setFormats(newFormats);
  };

  useEffect(() => {
    WebFont.load({
      custom: {
        families: fontList.map((font) => font.fontFamily),
      },
      // active: () => {
      //   console.log('Fonts loaded successfully!');
      // },
      // inactive: () => {
      //   console.log('Fonts failed to load.');
      // },
    });
  }, []);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    if (targetItemText) {
      onStageChange('Updated Text Alignment');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        align: newAlignment,
        updatedAt: Date.now(),
      }));
    }
    console.log(targetItemText);
    setAlignment(newAlignment);
  };

  const [color, setColor] = React.useState<RGBColor>(() =>
    targetItemText?.attrs?.color
      ? targetItemText.attrs.color
      : '#ffffff',
  );

  const handleColorChange = (color: any) => {
    setColor(color.rgb);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemText) {
      onStageChange('Updated Text Color');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fill: color.hex,
        updatedAt: Date.now(),
      }));
      setColor(color.rgb);
    }
  };

  // OUTLINES ============================================

  const [showOutline, setShowOutline] = React.useState(false);
  const [outlineColors, setOutlineColors] = React.useState<ColorResult>();
  const [outlineColor, setOutlineColor] = React.useState<RGBColor>(() =>
    targetItemText?.attrs?.color
      ? targetItemText.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );
  const [outlineWidth, setOutlineWidth] = React.useState<number>(1);

  const handleOutlineColorChange = (color: any) => {
    setOutlineColor(color.rgb);
    setOutlineColors(color);
  };

  const handleShowOutlineChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (targetItemText) {
      setShowOutline(event.target.checked);

      if (event.target.checked) {
        onStageChange('Updated Text Outline');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          stroke: outlineColors?.hex ?? '#fff',
          strokeWidth: outlineWidth,
          updatedAt: Date.now(),
        }));
      } else {
        onStageChange('Updated Text Outline');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          stroke: null,
          strokeWidth: null,
          updatedAt: Date.now(),
        }));
      }
    }
  };

  const handleOutlineColorChangeCompleted = (color: ColorResult) => {
    if (targetItemText) {
      onStageChange('Updated Text Outline Color');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        stroke: color.hex,
        updatedAt: Date.now(),
      }));
    }

    setOutlineColor(color.rgb);
  };

  const onOutlineWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text Outline Size');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        strokeWidth: parseInt(event.target.value, 10),
        updatedAt: Date.now(),
      }));
    }

    setOutlineWidth(parseInt(event.target.value, 10));
  };

  // SHADOWS ============================================
  const [showShadow, setShowShadow] = React.useState(false);
  const [shadowColors, setShadowColors] = React.useState<ColorResult>();
  const [shadowColor, setShadowColor] = React.useState<RGBColor>(() =>
    targetItemText?.attrs?.color
      ? targetItemText.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );
  const [shadowWidth, setShadowWidth] = React.useState<number>(1);

  const handleShadowColorChange = (color: any) => {
    setShadowColor(color.rgb);
    setShadowColors(color);
  };

  const handleShowShadowChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (targetItemText) {
      setShowShadow(event.target.checked);

      if (event.target.checked) {
        onStageChange('Updated Text Shadow');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          shadowColor: shadowColors?.hex ?? '#fff',
          shadowOffsetX: shadowWidth,
          shadowOffsetY: shadowWidth,
          updatedAt: Date.now(),
        }));
      } else {
        onStageChange('Updated Text Shadow');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          shadowColor: null,
          shadowOffsetX: null,
          shadowOffsetY: null,
          updatedAt: Date.now(),
        }));
      }
    }
  };

  const handleShadowColorChangeCompleted = (color: ColorResult) => {
    if (targetItemText) {
      onStageChange('Updated Text Shadow Color');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        shadowColor: color.hex,
        updatedAt: Date.now(),
      }));
    }

    setShadowColor(color.rgb);
  };

  const onShadowWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text Shadow Size');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        shadowOffsetX: parseInt(event.target.value, 10),
        shadowOffsetY: parseInt(event.target.value, 10),
        updatedAt: Date.now(),
      }));
    }

    setShadowWidth(parseInt(event.target.value, 10));
  };

  const [font, setFont] = React.useState<string>(() =>
    targetItemText && targetItemText.attrs.text.trim().length > 0
      ? targetItemText.attrs.text
      : fontList[0].fontFamily,
  );
  const [text, setText] = React.useState<string>(() =>
    targetItemText && targetItemText.attrs.text.trim().length > 0
      ? targetItemText.attrs.text
      : '',
  );
  const [fontSize, setFontSize] = React.useState<number>(() =>
    targetItemText && targetItemText.attrs.fontSize
      ? targetItemText.attrs.fontSize / 3
      : 20,
  );
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    if (targetItemText && targetItemText.attrs) {
      if (targetItemText.attrs.stroke && targetItemText.attrs.stroke !== null) {
        setShowOutline(true);
      }
      if (targetItemText.attrs.shadowColor && targetItemText.attrs.shadowColor !== null) {
        setShowShadow(true);
        setShadowWidth(targetItemText.attrs.shadowOffsetX);
      }
      if (targetItemText.attrs.fontStyle === 'bold') {
        setTextBold(true);
        setTextItalic(false);
      } else if (targetItemText.attrs.fontStyle === 'italic') {
        setTextBold(false);
        setTextItalic(true);
      } else if (targetItemText.attrs.fontStyle === 'italic bold') {
        setTextBold(true);
        setTextItalic(true);
      }
    }
  }, []);

  React.useEffect(() => {
    if (targetItemText && targetItemText.attrs) {
      if (targetItemText.attrs.fontStyle === 'bold') {
        setTextBold(true);
        setTextItalic(false);
      } else if (targetItemText.attrs.fontStyle === 'italic') {
        setTextBold(false);
        setTextItalic(true);
      } else if (targetItemText.attrs.fontStyle === 'italic bold') {
        setTextBold(true);
        setTextItalic(true);
      }
    }
  }, [targetItemText]);

  const colorToRGB = (
    rgb: string,
  ): { red: number; green: number; blue: number } => {
    const result = rgb.replace(/[^\d,.]/g, '').split(',');
    return result
      ? {
          red: parseInt(result[0], 10),
          green: parseInt(result[1], 10),
          blue: parseInt(result[2], 10),
        }
      : {
          red: 0,
          green: 0,
          blue: 0,
        };
  };

  const onFontChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: {
      type: string;
      id: string;
      width: number;
      height: number;
      fontSize: number;
      fontFamily: string;
    },
  ) => {
    if (targetItemText) {
      onStageChange('Updated Text Font');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontFamily: value.fontFamily,
        updatedAt: Date.now(),
      }));
    }

    setFont(value.fontFamily);
  };

  const insertText = (data: { [key: string]: any }) => {
    const { width } = data;
    const { height } = data;
    const newText: StageData = {
      id: 'text',
      attrs: {
        name: 'label-target',
        dataItemType: 'text',
        fontStyle: data.fontStyle,
        fill: data.fill,
        x: (canvasWidth - width) / 2,
        y: (canvasHeight - height) / 2,
        fontSize: data.fontSize,
        fontFamily: data.fontFamily,
        text: data.text,
        align: data.textAlign,
        verticalAlign: 'top',
        zIndex: 8,
        draggable: true,
        brightness: 0,
        updatedAt: Date.now(),
      },
    };
    createItem(newText);
  };

  const onTextBlur = () => {
    if (targetItemText) {
      onStageChange('Updated Text');
      if (!text || text.trim().length === 0) {
        // string is empty
        removeItem(targetItemText.id);
      } else {
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          text,
          updatedAt: Date.now(),
        }));
      }
    } else if (!(!text || text.trim().length === 0)) {
      // string is not empty
      onStageChange('Updated Text');
      insertText({
        textAlign: alignment,
        fontFamily: font,
        text,
        fill: color,
        width: 250,
        height: 35,
        fontStyle: formats.join(' '),
        fontSize: fontSize * 3,
      });
    }
  };

  const onFontSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text Font Size');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontSize: parseInt(event.target.value, 10) * 3,
        updatedAt: Date.now(),
      }));
    }

    setFontSize(parseInt(event.target.value, 10));
  };

  const onFontStyleChange = (style: string) => {
    if (targetItemText) {
      onStageChange('Updated Font Style');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontStyle: style,
        updatedAt: Date.now(),
      }));
    }
  };

  const onTextDecorationChange = (decoration: string) => {
    if (targetItemText) {
      onStageChange('Updated Text Decoration');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        textDecoration: decoration,
        updatedAt: Date.now(),
      }));
    }
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text');
      if (!event.target.value || event.target.value.trim().length === 0) {
        // string is empty
        removeItem(targetItemText.id);
      } else {
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          text: event.target.value,
          updatedAt: Date.now(),
        }));
      }
    } else if (
      !(!event.target.value || event.target.value.trim().length === 0)
    ) {
      // string is not empty
      onStageChange('Updated Text');
      insertText({
        textAlign: alignment,
        fontFamily: font,
        text: event.target.value,
        fill: color,
        width: 250,
        height: 35,
        fontStyle: formats.join(' '),
        fontSize: fontSize * 3,
      });
    }

    setText(event.target.value);
  };

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Text" {...setTabId(0)} />
        <StyledTab label="Styles" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Box className={`text_interface_wrapper ${styles.text_interface_wrapper} ${theme === 'light' ? 'light' : 'dark'}`}>
          <Grid container className={styles.text_editor_parent_wrapper}>
            <Grid item xs={12} mb={2}>
              <TextField
                id="multiline-text"
                multiline
                defaultValue={text}
                placeholder="Write here"
                className={`text_editor_field ${styles.text_editor_field}`}
                fullWidth
                onChange={onTextChange}
                onBlur={onTextBlur}
                variant="standard"
                minRows={5}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FormControlLabel
                value="Font"
                label=""
                sx={{
                  ml: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
                control={
                  <Box
                    sx={{
                      marginRight: 'auto',
                      display: 'flex',
                      justifyContent: 'flex-center',
                      width: '100%',
                    }}
                  >
                    <Autocomplete
                      id="font-select-autocomplete"
                      blurOnSelect
                      disableClearable
                      sx={{ width: '100%' }}
                      onChange={onFontChange}
                      options={fontList}
                      size="small"
                      defaultValue={
                        targetItemText?.attrs.fontFamily ?
                        fontList.filter((fontlist) => fontlist.fontFamily === targetItemText?.attrs.fontFamily)[0]
                        : fontList[0]
                      }
                      getOptionLabel={(option) => option.fontFamily}
                      renderInput={(params) => <TextField variant="standard" className={`text_editor_autocomplete ${styles.text_editor_autocomplete}`} {...params} />}
                    />
                    <StyledToggleButtonGroup
                      size="small"
                      value={formats}
                      onChange={handleFormat}
                      aria-label="text formatting"
                    >
                      {/* <ToggleButton value="bold" aria-label="bold">
                        <FontAwesomeIcon color="white" icon={solid('bold')} />
                      </ToggleButton> */}
                    </StyledToggleButtonGroup>
                  </Box>
                }
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <Grid container>
                <Grid item xs={5} mx={0}>
                  <FormControlLabel
                    value="Size"
                    label=""
                    sx={{
                      width: '100%',
                      marginLeft: '0',
                      height: 40,
                    }}
                    control={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <TextField
                          id="outlined-font-size"
                          type="number"
                          fullWidth
                          size="small"
                          variant="standard"
                          className={`text_editor_size ${styles.text_editor_size}`}
                          defaultValue={fontSize}
                          onChange={onFontSizeChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    }
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={7} className={styles.text_style_button_wrapper}>
                  <button
                    type="button"
                    className={`${styles.text_style_button} ${styles.bold} ${textBold ? styles.active : ''}`}
                    onClick={() => {
                      setTextBold(!textBold);
                      if (!textBold && textItalic) {
                        onFontStyleChange('italic bold');
                      } else if (!textBold && !textItalic) {
                        onFontStyleChange('bold');
                      } else if (textBold && textItalic) {
                        onFontStyleChange('italic');
                      } else {
                        onFontStyleChange('normal');
                      }
                      console.log(textBold);
                    }}
                  >
                    B
                  </button>
                  <button
                    type="button"
                    className={`${styles.text_style_button} ${styles.italics} ${textItalic ? styles.active : ''}`}
                    onClick={() => {
                      setTextItalic(!textItalic);
                      if (textBold && !textItalic) {
                        onFontStyleChange('italic bold');
                      } else if (!textBold && !textItalic) {
                        onFontStyleChange('italic');
                      } else if (textBold && textItalic) {
                        onFontStyleChange('bold');
                      } else {
                        onFontStyleChange('normal');
                      }
                    }}
                  >
                    I
                  </button>
                  <button
                    type="button"
                    className={`${styles.text_style_button} ${styles.underline} ${textUnderline ? styles.active : ''}`}
                    onClick={() => {
                      setTextUnderline(!textUnderline);
                      if (!textUnderline) {
                        onTextDecorationChange('underline');
                      } else {
                        onTextDecorationChange('');
                      }
                    }}
                  >
                    U
                  </button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} mb={2}>
              <Grid container>
                <Grid item xs={6}>
                  <Box>
                    <RWColorPicker
                      options={{
                        showHue: false,
                        showAlpha: false,
                        showSaturation: false,
                        fillClickable: true,
                      }}
                      color={color}
                      onChange={handleColorChange}
                      onChangeComplete={handleColorChangeCompleted}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Size"
                    label="Size"
                    sx={{
                      ml: 2,
                      width: '100%',
                      height: 30,
                    }}
                    control={
                      <Box
                        sx={{
                          width: 60,
                          marginLeft: 'auto',
                          mr: 2,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <TextField
                          id="outlined-font-size"
                          type="number"
                          fullWidth
                          size="small"
                          defaultValue={fontSize}
                          onChange={onFontSizeChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <FormControlLabel
                value="Alignment"
                label="Alignment"
                sx={{
                  ml: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                control={
                  <Box
                    className="alignment-wrapper"
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <StyledToggleButtonGroup
                      size="small"
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="left" aria-label="left aligned">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-left')}
                        />
                      </ToggleButton>
                      <ToggleButton value="center" aria-label="centered">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-center')}
                        />
                      </ToggleButton>
                      <ToggleButton value="right" aria-label="right aligned">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-right')}
                        />
                      </ToggleButton>
                      <ToggleButton value="justify" aria-label="justified">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-justify')}
                        />
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  </Box>
                }
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} mb={2} height={30}>
              <FormControlLabel
                value="Outline"
                label="Outline"
                sx={{ ml: 0, width: '100%' }}
                control={
                  <Checkbox
                    checked={showOutline}
                    onChange={handleShowOutlineChange}
                    size="small"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
            {showOutline && (
              <>
                <FormControlLabel
                  value="Size"
                  label=""
                  sx={{ ml: 0, width: '100%', height: 40, mt: 2 }}
                  control={
                    <Box
                      sx={{
                        width: '100%',
                        padding: '0 10px',
                        marginBottom: '20px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <TextField
                        id="outlined-font-size"
                        type="number"
                        fullWidth
                        size="small"
                        variant="standard"
                        className={`text_editor_size ${styles.text_editor_size}`}
                        defaultValue={outlineWidth}
                        onChange={onOutlineWidthChange}
                        inputProps={{ min: 1, max: 100 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  }
                  labelPlacement="start"
                />
                <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
                  <RWColorPicker
                    options={{
                      showHue: true,
                      showAlpha: false,
                      showSaturation: true,
                      showInputFields: false,
                      showTitle: false,
                    }}
                    color={outlineColor}
                    onChange={handleOutlineColorChange}
                    onChangeComplete={handleOutlineColorChangeCompleted}
                  />
                </Box>
              </>
            )}
            <Grid item xs={12} mb={2} height={30}>
              <FormControlLabel
                value="Shadow"
                label="Shadow"
                sx={{ ml: 0, width: '100%' }}
                control={
                  <Checkbox
                    checked={showShadow}
                    onChange={handleShowShadowChange}
                    size="small"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
            {showShadow && (
              <>
                <FormControlLabel
                  value="Size"
                  label=""
                  sx={{ ml: 0, width: '100%', height: 40, mt: 2 }}
                  control={
                    <Box
                      sx={{
                        width: '100%',
                        padding: '0 10px',
                        marginBottom: '20px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <TextField
                        id="outlined-font-size"
                        type="number"
                        fullWidth
                        size="small"
                        variant="standard"
                        className={`text_editor_size ${styles.text_editor_size}`}
                        defaultValue={shadowWidth}
                        onChange={onShadowWidthChange}
                        inputProps={{ min: 1, max: 100 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  }
                  labelPlacement="start"
                />
                <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
                  <RWColorPicker
                    options={{
                      showHue: true,
                      showAlpha: false,
                      showSaturation: true,
                      showInputFields: false,
                      showTitle: false,
                    }}
                    color={shadowColor}
                    onChange={handleShadowColorChange}
                    onChangeComplete={handleShadowColorChangeCompleted}
                  />
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <RWColorPicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChangeCompleted}
          />
        </Box>
      </TabPanel>
    </>
  );
}
