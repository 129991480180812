import {
  Button, FilledInput, TextField, styled,
} from '@mui/material';

export const GoogleButton = styled(Button)({
  border: 'none',
  margin: '0 auto',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.5,
  height: '60px',
  backgroundColor: 'white',
  color: '#757575',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    border: 'none',
    backgroundColor: '#e8e8e8',
  },
  '&:active': {
    border: 'none',
  },
});

export const RMTextField = styled(TextField)({
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '&': {
    display: 'flex',
    margin: '0 auto',
    width: '524px',
    height: '50px',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #email': {
    color: 'black',
  },
  '& .MuiFilledInput-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px hsla(0,0%,100%,1) inset',
    '-webkit-text-fill-color': 'black',
  },
});

export const RMFilledInput = styled(FilledInput)({
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '&': {
    display: 'flex',
    width: '524px',
    height: '50px',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #password': {
    color: 'black',
  },
  '& .MuiFilledInput-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px hsla(0,0%,100%,1) inset',
    '-webkit-text-fill-color': 'black',
  },
});
