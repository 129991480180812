/* eslint-disable no-nested-ternary */
import React from 'react';
import useGraphQL from '../../hooks/useGraphQL';
import { GetUserGeneratedImagesDocument } from '../../__generated__/graphql';
import ImageCard from './ImageCard';
import appStore from '../../store/AppStore';

interface GeneratedImagesProps {
  workflowId: number | undefined;
  onImageSelect : (imageUrl : string) => void;
}

const NewGeneratedImages: React.FC<GeneratedImagesProps> = ({ workflowId, onImageSelect }) => {
  const {
    data: generatedImages, error, isLoading, refetch, isRefetching, isRefetchError, failureReason,
  } = useGraphQL(
    GetUserGeneratedImagesDocument,
    {
      query: {
        workflow_id: workflowId,
      },
    },
  );
  console.log('workflowId: ', workflowId);
  console.log('generatedImages Result: ', generatedImages);
  console.log('isLoading: ', isLoading);
  console.log('isRefetching: ', isRefetching);
  console.log('isRefetchError: ', isRefetchError);
  console.log('failureReason: ', failureReason);
  console.log('error: ', error);

  // if generatedImages are null or an empty array, refetch every 2 seconds,
  // then stop refreshing when generatedImages has data or the refetch count is 30
  React.useEffect(() => {
    let refetchCount = 0;
    if (workflowId !== -1) {
      const interval = setInterval(() => {
        if (!generatedImages?.user_image_generations.length) {
          refetch().then((res) => {
            console.log('refetch result: ', res?.data?.user_image_generations);
          });
          console.log('refetching...');
          refetchCount += 1;
        } else {
          clearInterval(interval);
          appStore.setState({
            aiIsLoading: false,
            aiIsLoaded: true,
          });
        }
        if (refetchCount === 30) {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    } return () => {};
  }, [generatedImages, refetch, workflowId]);
  return (
    generatedImages?.user_image_generations
      && generatedImages.user_image_generations.length > 0 ? (
        generatedImages.user_image_generations.map((userImageGeneration) => (
          <ImageCard key={userImageGeneration?._id ?? ''} imageUrl={userImageGeneration?.image_url ?? ''} onClick={onImageSelect} />
        ))
      ) : null
  );
};

export default NewGeneratedImages;
