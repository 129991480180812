/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Container from '@mui/material/Container';
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import {
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
// import TodayIcon from '@mui/icons-material/Today';
import { useForm, Controller } from 'react-hook-form';
import Image from 'mui-image';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../hooks/use-auth';
import RealmWorldsService from '../../services/realmWorldsService';
// import GoogleConsentModal from '../../components/modals/GooglePopup';
import rwlogo from '../../images/Realm_worlds_logo.png';
import GoogleIcon from '../../images/google_icon.svg';
// import SignupBox from '../../components/auth/SignupBox/SignupBox';
import LoginBGImg from '../../images/login-bg.png';
import {
  GoogleButton, LoginButton, RMFilledInput,
  RMTextField, SignUpButton, StyledCard, StyledCardContent, StyledContainer,
} from '../../components/auth/SignupBox/SignupBox.styles';
import { fetchCreateRealmWorldsUser } from '../../__generated__/realmWorldsApiComponents';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#FF6746',
      main: '#FF6746',
      dark: '#262626',
      contrastText: '#fff',
    },
  },
});

type SignupForm = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  ofAge: boolean;
  acceptedTerms: boolean;
};
type SignupBoxProps = {
  onSignup?: () => void;
};
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return (
    <Link
      {...props}
      component={RouterLink as any}
      sx={{
        fontFamily: 'Dungeon',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        textDecorationLine: 'underline',
      }}
    />
  );
}

export default function SignUp(props: SignupBoxProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authError, setError] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authErrorMessage, setErrorMessage] = React.useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    formState: { errors }, control, handleSubmit,
  } = useForm<SignupForm>();

  // const [openGoogleModal, setOpenGoogleModal] = React.useState(false);

  const onSubmit = (form: SignupForm) => {
    console.log({
      email: form.email,
      password: form.password,
    });
    setLoading(true);
    RealmWorldsService.CreateNewUserAccount({
      email: form.email as string,
      password: form.password as string,
      displayName: form.firstName && form.lastName ? `${form.firstName as string} ${form.lastName}` as string : '',
    }).then(async (result) => {
      if (result) {
        await auth?.signin(form.email as string, form.password as string);
        navigate('/');
      }
      setLoading(false);
    });
  };

  const handleSigninWithGoogle = async () => {
    console.log('signin with google');
    // setOpenGoogleModal(true);
    setLoading(true);
    try {
      const result = await auth?.signInWithPopupFlow();
      // if you get here, you have signed in.
      // check if the user new.
      const userDetails = auth?.getAdditionalUserInfoFlow(result);
      const currentUser = result.user;
      const idToken = await auth?.user?.getIdToken();
      if (userDetails?.isNewUser) {
        // TODO update API to use the new user endpoint

        // if the user is new, then we need to notify the backend that this is a new user.
        const newUserResult = await fetchCreateRealmWorldsUser({
          body: {
            displayName: currentUser?.displayName,
            email: currentUser?.email,
            photoUrl: currentUser?.photoURL,
            password: null,
            userId: currentUser?.uid,
            subscriptionTierName: 'Free',
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        console.log('new user result: ', newUserResult);
      }
      props?.onSignup?.();
    } catch (error: any) {
      setError(true);
      setErrorMessage('Could not sign in with Google. Please try again.');
    }
  };

  const styles = {
    google: {
      display: 'flex',
      width: '524px',
      height: '50px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      flexShrink: 0,
      borderRadius: '4px',
      background: '#FFF',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth={false}
        disableGutters
        sx={{
          backgroundImage: `url(${LoginBGImg})`,
          backgroundSize: 'cover', // Ensure the image covers the entire container
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh', // Ensure the container takes up the full viewport height
          display: 'flex', // Optional: Use flex to center content vertically
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledContainer>
          <StyledCard>
            <StyledCardContent>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid container spacing={0.5} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Image
                      style={{
                        maxWidth: '150px',
                        maxHeight: '120px',
                      }}
                      src={rwlogo}
                      alt="RW Logo"
                    />
                  </Grid>
                  <Grid item xs={12}>

                    <Typography
                      variant="h3"
                      align="center"
                      color="common.black"
                      textAlign="center"
                      fontFamily="Passero One"
                      fontSize="45px"
                      fontStyle="normal"
                      fontWeight={400}
                    >
                      Realm Worlds
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GoogleButton
                      fullWidth
                      variant="outlined"
                      onClick={() => handleSigninWithGoogle()}
                      startIcon={<Avatar sx={{ width: 24, height: 24 }} src={GoogleIcon} />}
                      sx={styles.google}
                    >
                      Signup with Gmail
                    </GoogleButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      color="common.black"
                      fontFamily="Passero One"
                      fontSize="24px"
                      fontStyle="normal"
                      fontWeight={400}
                    >
                      Or

                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['DatePicker']}> */}
                  {/* <DatePicker label="Basic date picker" /> */}
                  {/* </DemoContainer> */}
                  {/* </LocalizationProvider> */}
                  {/* </Grid> */}

                  {/* <Grid item xs={12}>
                    <RMTextField
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end" style={{ color: 'black' }}>
                            <TodayIcon sx={{ width: 24, height: 24 }} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      variant="filled"
                      id="date"
                      label="Date"
                      name="date"
                      autoComplete="date"
                    />
                  </Grid> */}

                  {/* Email here */}
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <RMTextField
                          required
                          fullWidth
                          variant="filled"
                          id="email"
                          label="Username or Email"
                          autoComplete="email"
                          InputProps={{ disableUnderline: true }}
                          {...field}
                          error={Boolean(errors.email)}
                          helperText={errors.email && 'email is required'}
                        />
                      )}
                    />
                  </Grid>
                  {/* Password here */}
                  <Grid item xs={12} mt={0.5}>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl
                          variant="filled"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            maxWidth: '524px',
                            margin: '0 auto',
                          }}
                        >

                          <InputLabel
                            sx={{
                              color: 'black',
                            }}
                            htmlFor="password"
                          >
                            Password

                          </InputLabel>
                          <RMFilledInput
                            id="password"
                            disableUnderline
                            type={showPassword ? 'text' : 'password'}
                            {...field}
                            endAdornment={(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )}
                          />
                          <FormHelperText id="component-error-text" error={Boolean(errors.password)}>{errors.password && 'password is required'}</FormHelperText>

                        </FormControl>
                      )}
                    />
                    <Grid item xs={12} mt={0.5}>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl
                            variant="filled"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              maxWidth: '524px',
                              margin: '0 auto',
                            }}
                          >

                            <InputLabel
                              sx={{
                                color: 'black',
                              }}
                              htmlFor="outlined-adornment-password"
                            >
                              Confirm Password

                            </InputLabel>
                            <RMFilledInput
                              id="password"
                              disableUnderline
                              type={showPassword ? 'text' : 'password'}
                              {...field}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            )}
                            />
                            <FormHelperText id="component-error-text" error={Boolean(errors.confirmPassword)}>{errors.confirmPassword && 'Password Confirmation is required'}</FormHelperText>

                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                &nbsp;
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      color="#000"
                      textAlign="center"
                      fontFamily="Dungeon"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={400}
                      lineHeight="16px"
                    >
                      By clicking Log in you accept Realm Worlds
                      {' '}
                      <LinkRouter color="#5693CB" to="/signup" variant="body2">Terms of Service</LinkRouter>
                      {' '}
                      and
                      {' '}
                      <LinkRouter color="#5693CB" to="/signup" variant="body2">Privacy Policy</LinkRouter>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={{ mb: 0.5 }} />
                <SignUpButton
                  type="submit"
                  variant="contained"
              // onClick={handleLogin}
                  loading={loading}
                >
                  Sign up
                </SignUpButton>
                <Grid item sx={{ mb: 1 }} />
                <LoginButton
              // type="submit"
                  variant="contained"
                  onClick={() => navigate('/login')}
                  // loading={authLoading}
                >
                  Already Have an Account? &nbsp;
                  {' '}
                  <span style={{ color: '#5693CB' }}>Log in!</span>
                </LoginButton>
              </Box>
            </StyledCardContent>
          </StyledCard>
        </StyledContainer>
      </Container>
    </ThemeProvider>
  );
}
