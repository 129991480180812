import React from 'react';
import s from './DashboardHeader.module.scss';
import HeaderTabs from './HeaderTabs';
import HeaderMenu from './HeaderMenu';

/**
 * Header component for the dashboard, consisting of tabs and a menu.
 *
 * @component
 * @example
 * // Example usage of DashboardHeader component
 * <DashboardHeader />
 *
 * @returns {JSX.Element} React element representing the dashboard header.
 */

const DashboardHeader = () => (
  <div className={s.header}>
    <HeaderTabs
      tabs={[
        { name: 'Dashboard view', path: 'dashboard-view' },
        { name: 'Detail view', path: 'detail-view' },
      ]}
    />
    <HeaderMenu />
  </div>
);

export default DashboardHeader;
