import React from 'react';
import {
  Box,
  Stack, Tooltip, Zoom,
} from '@mui/material';
import {
  AttributeTextField,
  AttributeWrapper,
  HelpImg,
  SectionHeadingText,
  SmallHeadingText,
} from '../../styledComps/styledComps';
import HelpIcon from '../../../images/help.webp';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';

const ProfessionOptions = [
  'BlackSmith',
  'GoldSmith',
  'Sailor',
  'Stable Boy',
  'Jester',
  'Farmer',
  'Miner',
];

const RelationshipOptions = [
  'Single',
  'Married',
  'Player',
  'Divorcee',
  'Widow',
];

// life altering event (LAE)
const LAEOptions = [
  'Death',
  'Disaster',
  'Love',
  'Divorce',
  'Zen',
];

interface Props {
  characterProfession: string;
  setCharacterProfession: (char: string) => void;
  characterRelationship: string;
  setCharacterRelationship: (char: string) => void;
  characterLifeAlteringEvent: string;
  setCharacterLifeAlteringEvent: (char: string) => void;
  characterBackStory: string;
  setCharacterBackStory: (char: string) => void;
}

function CharacterBackground({
  characterProfession,
  setCharacterProfession,
  characterRelationship,
  setCharacterRelationship,
  characterLifeAlteringEvent,
  setCharacterLifeAlteringEvent,
  characterBackStory,
  setCharacterBackStory,
}: Props) {
  // const [birthStory, setBirthStory] = React.useState('');
  const [parents, setParents] = React.useState('');
  return (
    <Stack gap="22px">
      <Stack gap="7px" direction="row" justifyContent="center" alignItems="center">
        <SectionHeadingText>Background</SectionHeadingText>
        <Tooltip
          title="Background of the character you are about to create are as follows "
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
          componentsProps={{
            tooltip: {
              sx: {
                fontFamily: 'Passero One',
                fontWeight: '400px',
                fontSize: '14px',
                padding: '10px',
                bgcolor: '#3c3c3ce6',
                '& .MuiTooltip-arrow': {
                  color: '#3c3c3ce6',
                },
                maxWidth: '250px',
              },
            },
          }}
        >
          <HelpImg src={HelpIcon} />
        </Tooltip>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap="28px">
        <CharacterFilterDropdown
          width="421px"
          title="Professions"
          options={ProfessionOptions}
          tooltip
          toolTipMessage="Choose the profession you wish to apply to the character."
          value={characterProfession}
          onChange={setCharacterProfession}
        />
        <CharacterFilterDropdown
          width="421px"
          title="Relationship"
          options={RelationshipOptions}
          tooltip
          toolTipMessage="Choose the relationship you wish to apply to the character."
          value={characterRelationship}
          onChange={setCharacterRelationship}
        />
        <CharacterFilterDropdown
          width="421px"
          title="Life Altering Event"
          options={LAEOptions}
          tooltip
          toolTipMessage="Choose the life altering event you wish to apply to the character."
          value={characterLifeAlteringEvent}
          onChange={setCharacterLifeAlteringEvent}
        />
        <AttributeWrapper width="646px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                minWidth: '100%',
              }}
            >
              <Stack direction="row" alignItems="center" gap="2px">
                <SmallHeadingText>Birth Story</SmallHeadingText>
                <Tooltip
                  title="Enter the birth story here "
                  arrow
                  placement="bottom"
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontFamily: 'Passero One',
                        fontWeight: '400px',
                        fontSize: '14px',
                        padding: '10px',
                        bgcolor: '#3c3c3ce6',
                        '& .MuiTooltip-arrow': {
                          color: '#3c3c3ce6',
                        },
                        maxWidth: '250px',
                      },
                    },
                  }}
                >
                  <HelpImg src={HelpIcon} />
                </Tooltip>
              </Stack>
              <AttributeTextField
                rows={4}
                multiline
                placeholder="Enter short term goals below"
                value={characterBackStory}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterBackStory(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </AttributeWrapper>
        <AttributeWrapper width="645px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                minWidth: '100%',
              }}
            >
              <Stack direction="row" alignItems="center" gap="2px">
                <SmallHeadingText>Parents (Lineage)</SmallHeadingText>
                <Tooltip
                  title="Enter the lineage of the character here "
                  arrow
                  placement="bottom"
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontFamily: 'Passero One',
                        fontWeight: '400px',
                        fontSize: '14px',
                        padding: '10px',
                        bgcolor: '#3c3c3ce6',
                        '& .MuiTooltip-arrow': {
                          color: '#3c3c3ce6',
                        },
                        maxWidth: '250px',
                      },
                    },
                  }}
                >
                  <HelpImg src={HelpIcon} />
                </Tooltip>
              </Stack>
              <AttributeTextField
                rows={4}
                multiline
                placeholder="Enter long term goals below"
                value={parents}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setParents(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </AttributeWrapper>
      </Stack>
    </Stack>
  );
}

export default CharacterBackground;
