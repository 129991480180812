/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  // useContext,
  useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { PanelItemProps } from '../FramePanel/FramePanel';
import AzamelCoinImg from '../../../images/azamel_Coin.png';
import SnailImg from '../../../images/Snail.png';
import RabbitImg from '../../../images/rabbit.png';
import CartonImg from '../../../images/cartoon.png';
// import { ThemeContext } from '../../../context/ThemeContext';

import '../eachpanelmodifier.scss';
import styles from './aigeneratepanel.module.scss';
import ImageGenSelect from '../../ImageGenSelect/ImageGenSelect';
import {
  StyledTab,
  StyledTabs,
  setTabId,
} from '../StyledPanel/StyledPanel';
import TabPanel from '../../tab/TabPanel';
import {
  GetArchtypesAttributesDocument, GetBodyShapesAttributesDocument, GetCharacterHeightsDocument, GetClassesAttributesDocument, GetEyesAttributesDocument, GetGendersAttributesDocument, GetHairAttributesDocument, GetImageMasksDocument, GetPersonalitiesAttributesDocument, GetRacesAttributesDocument, GetSkinsAttributesDocument, GetWeaponsAttributesDocument, Image_Mask,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';
import GenerateImages from '../../../views/Generate/Generate';
import { GenerationNewImagesVariables, fetchGenerationNewImages } from '../../../__generated__/realmWorldsApiComponents';
import appStore from '../../../store/AppStore';
import { NotificationActions } from '../../../redux/notification';
import CharacterTypeMenu from '../../characterTypeMenu/CharacterTypeMenu';

const GenerateButton = styled(LoadingButton)({
  background: '#41521F',
  borderRadius: 4,
  color: '#fff',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',
  whiteSpace: 'nowrap', // Shift the button to the right

  '&:hover': {
    backgroundColor: '#fff',
    color: '#41521F',
  },
});

const PromptButton = styled(LoadingButton)({
  color: '#fff',
  fontFamily: 'Roboto',
  fontSize: '12px',
  whiteSpace: 'nowrap', // Shift the button to the right

  '&:hover': {
    // backgroundColor: 'none',
    // // color: '#',
  },
});

const amount = [
  {
    value: 1,
    label: '1',
  },

  {
    value: 4,
    label: '4',
  },
];

const speed = [
  {
    value: 1,
    label: <img src={SnailImg} alt="snail-img" />,
  },

  {
    value: 3.8, // Label Style needs to be adjusted to accomodate value - 4
    label: <img src={RabbitImg} alt="rabbit-img" />,
  },
];

const resolution = [
  {
    value: 1.1,
    label: '512x512',
  },

  {
    value: 2,
    label: '768x768',
  },
  {
    value: 3,
    label: '1024x1024',
  },
  {
    value: 3.8, // Label Style needs to be adjusted to accomodate value - 4
    label: '1280x1280',
  },
];

const StyledSlider = styled(Slider)({
  color: '#fff',
  '& .MuiSlider-track': {
    color: '#fffff',

  },
  '& .MuiSlider-thumb ': {
    color: '#656565',

  },
  '& .MuiSlider-rail': {
    color: '#656565',

  },
  '& .MuiSlider-mark': {
    backgroundColor: '#fffff',

  },
  '& .MuiSlider-markLabel': {
    color: '#fff',
    fontFamily: 'DM Sans',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '10px', /* 200% */
    letterSpacing: '0.1px',
    marginRight: 1,
    marginLeft: 1,
  },
});

export default function AIGeneratePanel({ onStageChange }: PanelItemProps) {
  const [tabValue, setTabValue] = React.useState(0);
  const aiWorkFlowId: number = appStore(
    (state) => state.aiWorkFlowId,
  );
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const { data: hair } = useGraphQL(
    GetHairAttributesDocument,
    {},
  );
  const { data: eyes } = useGraphQL(
    GetEyesAttributesDocument,
    {},
  );
  const { data: skin } = useGraphQL(
    GetSkinsAttributesDocument,
    {},
  );

  const { data: races } = useGraphQL(
    GetRacesAttributesDocument,
    {},
  );

  const { data: genders } = useGraphQL(
    GetGendersAttributesDocument,
    {},
  );

  const { data: bodyShapes } = useGraphQL(
    GetBodyShapesAttributesDocument,
    {},
  );

  const { data: classes } = useGraphQL(
    GetClassesAttributesDocument,
    {},
  );

  const { data: weapons } = useGraphQL(
    GetWeaponsAttributesDocument,
    {},
  );

  const { data: personalities } = useGraphQL(
    GetPersonalitiesAttributesDocument,
    {},
  );

  const { data: archetypes } = useGraphQL(
    GetArchtypesAttributesDocument,
    {},
  );

  const { data: imageMasks } = useGraphQL(
    GetImageMasksDocument,
    {},
  );

  const { data: characterHeights } = useGraphQL(
    GetCharacterHeightsDocument,
    {},
  );
  const [selectedHair, setSelectedHair] = React.useState<string | null>(hair?.hairs?.[0]?.name ?? null);
  const [selectedEyes, setSelectedEyes] = React.useState<string | null>(eyes?.eyes?.[0]?.name ?? null);
  const [selectedSkin, setSelectedSkin] = React.useState<string | null>(skin?.skins?.[0]?.name ?? null);
  const [selectedRace, setSelectedRace] = React.useState<string | null>(races?.races?.[0]?.name ?? null);
  const [selectedGender, setSelectedGender] = React.useState<string | null>(genders?.genders?.[0]?.name ?? null);
  const [selectedBodyShape, setSelectedBodyShape] = React.useState<string | null>(bodyShapes?.body_shapes?.[0]?.name ?? null);
  const [selectedClass, setSelectedClass] = React.useState<string | null>(classes?.classes?.[0]?.name ?? null);
  const [selectedWeapon, setSelectedWeapon] = React.useState<string | null>(weapons?.weapons?.[0]?.name ?? null);
  const [selectedPersonality, setSelectedPersonality] = React.useState<string | null>(personalities?.personalities?.[0]?.name ?? null);
  const [selectedArchetype, setSelectedArchetype] = React.useState<string | null>(archetypes?.archetypes?.[0]?.name ?? null);
  const [selectedImageMask, setSelectedImageMask] = React.useState<string | null>(imageMasks?.image_masks?.[0]?.name ?? null);
  const [selectedCharacterHeight, setSelectedCharacterHeight] = React.useState<string | null>(characterHeights?.character_heights?.[0]?.name ?? null);

  // const { theme } = useContext(ThemeContext);

  const [active, setActive] = useState('');
  const localActive = localStorage.getItem('activeForeground');
  const [isGenerating, setIsGenerating] = useState(false);

  const dispatch = useDispatch();

  if (imageMasks) console.log('imageMasks: ', imageMasks.image_masks);

  useEffect(() => {
    if (localActive !== null) {
      setActive(JSON.parse(localActive));
    } else {
      setActive('');
    }
  }, []);

  // set localstorage for active item
  useEffect(() => {
    localStorage.setItem('activeForeground', JSON.stringify(active));
  }, [active]);

  const selectedGenerationSku = '65389a2c86a07d599cb662ba';
  const selectedImageGenerationStyleId = '6524e69e381386a2148c5470';
  const selectedPromptTemplateId = '6524e471f3ef6b46b9d3a141';

  const generateImagesFromSelection = async () => {
    setIsGenerating(true);
    try {
      const GenerateRequest: GenerationNewImagesVariables = {
        body: {
          archtypesId: selectedArchetype,
          bodyShapeId: selectedBodyShape,
          characterHeightId: selectedCharacterHeight,
          classId: selectedClass,
          eyesId: selectedEyes,
          genderId: selectedGender,
          generationSkuId: selectedGenerationSku,
          hairId: selectedHair,
          imageGenerationStyleId: selectedImageGenerationStyleId,
          maskId: selectedImageMask,
          personalityId: selectedPersonality,
          promptTemplateId: selectedPromptTemplateId,
          raceId: selectedRace,
          skinId: selectedSkin,
          weaponId: selectedWeapon,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const workflowResult = await fetchGenerationNewImages(GenerateRequest);
      console.log('workflowResults: ', workflowResult);

      // start checking workflow (max 30 tried (1min))
      const { workflowId: currentWorkflowId } = workflowResult;
      appStore.setState({
        aiWorkFlowId: currentWorkflowId ?? -1,
      });
      setIsGenerating(false);
    } catch (error: any) {
      console.log('error getting workflod ID: ', error);
      dispatch(
        NotificationActions.addNotification({
          open: true,
          type: 'error',
          message: `${error.message}`,
          timeout: 5000,
        }),
      );
    }
    setIsGenerating(false);
  };

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Image Generation" {...setTabId(0)} />
        <StyledTab label="Settings" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel
        value={tabValue}
        index={0}
      >
        <Box sx={{ margin: 1 }}>
          <InputLabel sx={{
            lineHeight: '25px',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: '0.1px',
            color: '#fff',
          }}
          >
            Select Prompt:

          </InputLabel>
          <Box sx={{ borderBottom: '1px solid white' }}>

            <PromptButton fullWidth>Female wizard with golden hair and browns</PromptButton>

          </Box>
          <Box sx={{ borderBottom: '1px solid white' }}>

            <PromptButton fullWidth>Female wizard with golden hair and browns</PromptButton>

          </Box>
          <Box sx={{ borderBottom: '1px solid white' }}>

            <PromptButton fullWidth>Female wizard with golden hair and browns</PromptButton>

          </Box>
          <Box sx={{ borderBottom: '1px solid white' }}>

            <PromptButton fullWidth>Female wizard with golden hair and browns</PromptButton>

          </Box>
          <Box sx={{ borderBottom: '1px solid white' }}>

            <PromptButton fullWidth>Female wizard with golden hair and browns</PromptButton>

          </Box>
          <Box sx={{ marginLeft: 2, marginRight: 2 }}>
            <ImageGenSelect label="Hair (opt.)" value={selectedHair} onChange={(e) => setSelectedHair(e.target.value as string)} options={hair?.hairs as any ?? []} />
            <ImageGenSelect label="Eyes (opt.)" value={selectedEyes} onChange={(e) => setSelectedEyes(e.target.value as string)} options={eyes?.eyes as any ?? []} />
            <ImageGenSelect label="Skin (opt.)" value={selectedSkin} onChange={(e) => setSelectedSkin(e.target.value as string)} options={skin?.skins as any ?? []} />
            <ImageGenSelect label="Race (opt.)" value={selectedRace} onChange={(e) => setSelectedRace(e.target.value as string)} options={races?.races as any ?? []} />
            <ImageGenSelect label="Gender (opt.)" value={selectedGender} onChange={(e) => setSelectedGender(e.target.value as string)} options={genders?.genders as any ?? []} />
            <ImageGenSelect label="Body Shape (opt.)" value={selectedBodyShape} onChange={(e) => setSelectedBodyShape(e.target.value as string)} options={bodyShapes?.body_shapes as any ?? []} />
            <ImageGenSelect label="Class (opt.)" value={selectedClass} onChange={(e) => setSelectedClass(e.target.value as string)} options={classes?.classes as any ?? []} />
            <ImageGenSelect label="Weapon (opt.)" value={selectedWeapon} onChange={(e) => setSelectedWeapon(e.target.value as string)} options={weapons?.weapons as any ?? []} />
            <ImageGenSelect label="Personality (opt.)" value={selectedPersonality} onChange={(e) => setSelectedPersonality(e.target.value as string)} options={personalities?.personalities as any ?? []} />
            <ImageGenSelect label="Archetype (opt.)" value={selectedArchetype} onChange={(e) => setSelectedArchetype(e.target.value as string)} options={archetypes?.archetypes as any ?? []} />
            {/* <ImageGenSelect label="Image Mask" value={selectedImageMask} onChange={(e) => setSelectedImageMask(e.target.value as string)} options={imageMasks?.image_masks as any ?? []} /> */}
            <ImageGenSelect label="Character Height (opt.)" value={selectedCharacterHeight} onChange={(e) => setSelectedCharacterHeight(e.target.value as string)} options={characterHeights?.character_heights as any ?? []} />
            <Box sx={{
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'center',
            }}
            >
              <GenerateButton fullWidth sx={{ marginTop: 2.5 }} onClick={generateImagesFromSelection}>
                {' '}
                {isGenerating ? (
                  <>
                    Generating &nbsp;
                    <CircularProgress size={15} />
                  </>
                ) : (
                  <>
                    Generate &nbsp;
                    {' '}
                    <img src={AzamelCoinImg} alt="coin-img" />
                    {' '}
&nbsp; 5
                  </>
                )}
              </GenerateButton>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <LinearProgress
                variant="determinate"
                value={50}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    background: '#64802E',
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                margin: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'white',
              }}
            >
              <Grid container alignItems="baseline">
                <Grid item xs={5} />
                <Grid item xs={1}>
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    35%
                    {' '}
                    <img src={SnailImg} alt="snail-img" />
                  </Typography>
                </Grid>
                <Grid item xs={5} />
              </Grid>
            </Box>

          </Box>

        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography sx={{
          lineHeight: '25px',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          letterSpacing: '0.1px',
          color: '#fff',
          padding: '18px',
        }}
        >
          Select Preferences:

        </Typography>
        <Box sx={{
          width: 320,
          marginRight: 2,
          marginLeft: 2,
        }}
        >

          <Typography sx={{
            lineHeight: '25px',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: '0.1px',
            color: '#fff',
          }}
          >
            Amount

          </Typography>
          <StyledSlider
            defaultValue={2}
            step={1}
            min={1}
            max={4}
            valueLabelDisplay="auto"
            marks={amount}
          />
        </Box>

        <Box sx={{
          width: 320,
          marginRight: 2,
          marginLeft: 2,
        }}
        >
          <Typography sx={{
            lineHeight: '25px',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: '0.1px',
            color: '#fff',
          }}
          >
            Speed

          </Typography>
          <StyledSlider
            defaultValue={4}
            step={1}
            min={1}
            max={4}
            valueLabelDisplay="auto"
            marks={speed}
          />
        </Box>

        <Box sx={{
          width: 320,
          marginRight: 2,
          marginLeft: 2,
        }}
        >
          <Typography sx={{
            lineHeight: '25px',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: '0.1px',
            color: '#fff',
            textAlign: 'center',
          }}
          >
            Resolution

          </Typography>
          <StyledSlider
            defaultValue={3}
            step={1}
            min={1}
            max={4}
            valueLabelDisplay="auto"
            marks={resolution}
            sx={{
              //
            }}
          />
        </Box>

        <Typography sx={{
          lineHeight: '25px',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          letterSpacing: '0.1px',
          color: '#fff',
          padding: '28px',
        }}
        >
          Style

        </Typography>
        <Box sx={{
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          marginLeft: 4.5,
        }}
        >
          <Grid container>
            <Grid item xs={4}>
              <img src={CartonImg} alt="sample-img" />
              <Typography sx={{
                lineHeight: '25px',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '0.1px',
                color: '#fff',
              }}
              >
                Cartoon

              </Typography>
            </Grid>
            <Grid item xs={4}>
              <img src={CartonImg} alt="sample-img" />
              <Typography sx={{
                lineHeight: '25px',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '0.1px',
                color: '#fff',
              }}
              >
               &nbsp;&nbsp; Anime

              </Typography>
            </Grid>
            <Grid item xs={4}>
              <img src={CartonImg} alt="sample-img" />
              <Typography sx={{
                lineHeight: '25px',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '0.1px',
                color: '#fff',
              }}
              >
                Realistic

              </Typography>
            </Grid>
          </Grid>

        </Box>

        <Box sx={{
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
        >
          <GenerateButton fullWidth sx={{ marginTop: 2, marginLeft: 2.5, marginRight: 2.5 }}>
            {' '}
            Generating &nbsp;
            {' '}
            <img src={AzamelCoinImg} alt="coin-img" />
            {' '}
&nbsp; 5
          </GenerateButton>
        </Box>
        <Box sx={{ margin: 2 }}>
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              '& .MuiLinearProgress-bar': {
                background: '#64802E',
              },
            }}
          />
        </Box>

        <Box
          sx={{
            margin: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'white',
          }}
        >
          <Grid container alignItems="baseline">
            <Grid item xs={5} />
            <Grid item xs={1}>
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                Done
                {' '}
                <img src={SnailImg} alt="snail-img" />
              </Typography>
            </Grid>
            <Grid item xs={5} />
          </Grid>
        </Box>
      </TabPanel>
      {imageMasks && imageMasks.image_masks && imageMasks.image_masks !== null && (
        <div className={styles.character_type_menu_wrapper}>
          <CharacterTypeMenu
            characterList={imageMasks?.image_masks as Image_Mask[]}
            setSelectedMask={
              (id: string) => setSelectedImageMask(id)
            }
            selectedMask={selectedImageMask}
          />
        </div>
      )}
    </>
  );
}
