import React from 'react';
import { Box } from '@mui/material';
import { Token_Set } from '../../../__generated__/graphql';
import Expansion from './Expansion';
import addIcon from '../../../assets/images/add_24px.png';
import editIcon from '../../../assets/images/mode_edit_24px.png';
import ExpansionFetchTokenItem from './ExpansionFetchTokenItem';

interface Props {
  token_set: Token_Set;
  tokensSlice: number;
  tightSpacing?: boolean;
}

function ExpansionTokenSetsItem({ token_set, tokensSlice, tightSpacing }: Props) {
//   console.log(token_set);
  const [tokenSetExpanded, setTokenSetExpanded] = React.useState(false);

  return (
    token_set.tokens?.length && token_set.tokens?.length > 0 && (
      <Expansion
        title={token_set.name as string}
        isExpanded={tokenSetExpanded}
        setIsExpanded={() => setTokenSetExpanded(!tokenSetExpanded)}
        headerChildren={(
          <>
            <button
              style={{
                padding: '5px',
                display: 'block',
                background: 'transparent',
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              type="button"
            >
              <img src={editIcon} alt="edit Icon" />
            </button>
            <button
              style={{
                padding: '5px',
                display: 'block',
                background: 'transparent',
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              type="button"
            >
              <img src={addIcon} alt="add Icon" />
            </button>
          </>
        )}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: tightSpacing ? '8px' : '16px',
            rowGap: tightSpacing ? '8px' : '16px',
          }}
        >
          {token_set?.tokens?.slice(0, tokensSlice)
            .map((tokenID: any) => <ExpansionFetchTokenItem tokenID={tokenID} key={tokenID} />)}
        </Box>
      </Expansion>
    )
  );
}

ExpansionTokenSetsItem.defaultProps = {
  tightSpacing: false,
};

export default ExpansionTokenSetsItem;
