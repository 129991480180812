import React from 'react';
import './contentLoading.scss';

interface Props {
  showNoOfItemsPlaceholder?: boolean;
  noOfItems?: number
}

function ContentLoading({ showNoOfItemsPlaceholder, noOfItems }: Props) {
  return (
    <div>
      {showNoOfItemsPlaceholder && <div className="content-placeholder no-of-items mt-4" />}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '8px',
          rowGap: '8px',
        }}
      >
        {[...Array(noOfItems)].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="p-1" style={{ backgroundColor: '#f2f2f2' }} key={index}>
            <div className="content-placeholder img-placeholder mb-2" />
            {/* <div className="content-placeholder btn-placeholder" /> */}
          </div>
        ))}
      </div>
    </div>
  );
}

ContentLoading.defaultProps = {
  showNoOfItemsPlaceholder: false,
  noOfItems: 9,
};

export default ContentLoading;
