import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function AdminSettingsIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${styles.icon} ${styles.admin_settings_icon} ${styles[theme]} ${active && styles.active}`}
      aria-label="admin settings"
    >
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
      >
        <circle className={styles.admin_settings_cls_2} cx="12.5" cy="7.39" r="4.25" />
        <path className={styles.admin_settings_cls_2} d="m12.85,22.04H3.08s0-3.44,0-3.44c1.77-3.09,6.13-4.31,11.63-3.73-1.91,1.52-2.34,4.17-1.87,7.18Z" />
        <path className={styles.admin_settings_cls_2} d="m23.91,19.77c0-.29-.03-.57-.09-.84l1.16-.67-1.28-2.21-1.16.67c-.42-.37-.91-.66-1.45-.84v-1.34h-2.56v1.34c-.54.18-1.04.47-1.45.84l-1.16-.67-1.28,2.21,1.16.67c-.06.27-.09.55-.09.84s.03.57.09.84l-1.16.67,1.28,2.21,1.16-.67c.42.37.91.66,1.45.84v1.34h2.56v-1.34c.54-.18,1.04-.47,1.45-.84l1.16.67,1.28-2.21-1.16-.67c.06-.27.09-.55.09-.84Zm-4.09,1.77c-.98,0-1.77-.79-1.77-1.77s.79-1.77,1.77-1.77,1.76.79,1.76,1.77-.79,1.77-1.76,1.77Z " />
        <rect className={styles.admin_settings_cls_1} width="25" height="25" />
      </svg>
    </button>
  );
}

export default AdminSettingsIcon;
