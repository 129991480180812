import { LoadingButton } from '@mui/lab';
import {
  Button, Container, Card, CardContent,
  FilledInput,
  TextField, styled,
} from '@mui/material';
import LoginScroll from '../../../images/login-scroll.png';

export const GoogleButton = styled(Button)({
  border: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.5,
  height: '60px',
  backgroundColor: 'white',
  color: '#757575',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    border: 'none',
    backgroundColor: '#e8e8e8',
  },
  '&:active': {
    border: 'none',
  },
});

export const RMTextField = styled(TextField)({
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '&': {
    display: 'flex',
    width: '524px',
    margin: '0 auto',
    height: '50px',
    fontFamily: 'Passero One',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#FFF',
    color: 'black',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #email, #date': {
    color: 'black',
  },
  '& .MuiFilledInput-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px hsla(0,0%,100%,1) inset',
    '-webkit-text-fill-color': 'black',
  },
});

export const RMFilledInput = styled(FilledInput)({
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '&': {
    display: 'flex',
    width: '524px',
    margin: '0 auto',
    height: '50px',
    fontFamily: 'Passero One',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #password': {
    color: 'black',
  },
  '& .MuiFilledInput-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px hsla(0,0%,100%,1) inset',
    '-webkit-text-fill-color': 'black',
  },
});

export const SignUpButton = styled(LoadingButton)({
  mt: 1,
  mb: 10,
  height: 60,
  width: '300px',
  borderRadius: 4,
  background: '#41521F',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',

  '&: hover': {
    backgroundColor: '#fff',
    color: '#41521F',
  },
});

export const LoginButton = styled(LoadingButton)({
  mb: 4,
  height: 60,
  width: '300px',
  borderRadius: 4,
  color: 'black',
  background: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',
  whiteSpace: 'nowrap',

  '&: hover': {
    backgroundColor: 'black',
    color: '#fff',
  },
});

export const StyledContainer = styled(Container)({
  width: 1203,
  height: 1080,
  backgroundImage: `url(${LoginScroll})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  gap: 24,
});

export const StyledCard = styled(Card)({
  width: '60vw',
  backgroundColor: '#0000',
  boxShadow: 'none',
  display: 'flex',
});

export const StyledCardContent = styled(CardContent)({
  backgroundColor: '#0000',
});
