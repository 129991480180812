/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import s from './CampaignBox.module.scss';
import token1 from '../../assets/images/sample_tokens/token_8(15).png';
import token2 from '../../assets/images/sample_tokens/token_12(5).png';
import token3 from '../../assets/images/sample_tokens/token_6(23).png';
import token4 from '../../assets/images/sample_tokens/token_7(16).png';
import token5 from '../../assets/images/sample_tokens/token_10(6).png';

interface CampaignBoxProps {
  campaign: { title: string; description: string; date: string; theme: string };
  isActive: boolean;
  onClick: () => void;
  isTop: boolean;
}

/**
 * CampaignBox component representing a box with campaign details and tokens.
 *
 * @component
 * @example
 * // Example usage of CampaignBox component
 * <CampaignBox
 *   campaign={{
 *     title: 'Campaign Name',
 *     theme: 'Campaign theme',
 *     description: 'Campaign Description...',
 *     date: '2024-01-01',
 *   }}
 *   isActive={true}
 *   onClick={() => handleCampaignClick(index)}
 *   isTop={true}
 * />
 *
 * @param {object} props - The properties of the CampaignBox component.
 * @param {object} props.campaign - The campaign details including title, theme, description, and date.
 * @param {string} props.campaign.title - The title of the campaign.
 * @param {string} props.campaign.theme - The theme of the campaign.
 * @param {string} props.campaign.description - The description of the campaign.
 * @param {string} props.campaign.date - The date of the campaign.
 * @param {boolean} props.isActive - Whether the campaign box is active.
 * @param {Function} props.onClick - The function to be called on campaign box click.
 * @param {boolean} props.isTop - Whether the campaign box is positioned at the top.
 *
 * @returns {JSX.Element} React element representing the CampaignBox component.
 */

const CampaignBox = ({
  campaign,
  isActive,
  onClick,
  isTop,
}: CampaignBoxProps) => {
  const boxStyle = {
    opacity: isActive ? 1 : 0.5,
    marginTop: isTop ? 0 : '350px',
  };

  const dateStyle: React.CSSProperties = {
    position: 'absolute',
    top: isTop ? '335px' : '249px',
  };

  const boxClass = isTop ? 'line-above' : 'line-below';

  return (
    <div className={s.campaignLayout}>
      <div
        className={`${s.campaignBox} ${boxClass}`}
        style={boxStyle}
        onClick={onClick}
      >
        <div className={s.campaignBox__container}>
          <h2 className={s.campaignBox__container__title}>{campaign.title}</h2>
          <p className={s.campaignBox__container__theme}>{campaign.theme}</p>
          <p className={s.campaignBox__container__description}>
            {campaign.description}
          </p>
          <div className={s.campaignBox__container__imgBox}>
            <img
              className={s.campaignBox__container__img}
              src={token1}
              alt="token"
            />
            <img
              className={s.campaignBox__container__img}
              src={token2}
              alt="token"
            />
            <img
              className={s.campaignBox__container__img}
              src={token3}
              alt="token"
            />
            <img
              className={s.campaignBox__container__img}
              src={token4}
              alt="token"
            />
            <img
              className={s.campaignBox__container__img}
              src={token5}
              alt="token"
            />
          </div>
        </div>
      </div>
      <p
        style={dateStyle}
        className={`${s.campaignLayout__date} ${isActive ? s.active : ''}`}
      >
        12/19/2023
      </p>
    </div>
  );
};

export default CampaignBox;
