import React from 'react';
import {
  Box, Stack, Typography,
} from '@mui/material';
import {
  FlexibleHeightImg, SectionHeadingText,
} from '../../styledComps/styledComps';
import FancyUnderline from '../../../images/fancy-underline-one.webp';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
// import DiceIcon from '../../../images/dice.webp';
import CharacterSectionWithHelp from './CharacterSectionWithHelp';
import CharacterAbilitiesGen from './CharacterAbilitiesGen';
import {
  Body_Shape, CharacterAbility, Eye, GetAlignmentsDocument, GetBodyShapesAttributesDocument,
  GetEyesAttributesDocument, GetSkinsAttributesDocument, Skin,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';

// const BodyTypeOptions = [
//   'Slim',
//   'Athletic',
//   'Curvy',
//   'Heavyset',
//   'Muscular',
//   'Petite',
//   'Tall',
//   'Short',
//   'Average',
//   'Broad',
// ];

// const SkinColorOptions = [
//   'Fair',
//   'Light',
//   'Medium',
//   'Olive',
//   'Tan',
//   'Brown',
//   'Dark Brown',
//   'Black',
//   'Albino',
//   'Bronzed',
// ];

const HairColorOptions = [
  'Black',
  'Blonde',
  'Brown',
  'Red',
  'Blue',
  'Green',
  'Purple',
  'Pink',
  'Silver',
  'Golden',
];

const HairStyleOptions = [
  'Straight',
  'Wavy',
  'Curly',
  'Short',
  'Long',
  'Buzzed',
  'Bop',
  'Afro',
  'Braided',
  'Bald',
];

// const EyeColorOptions = [
//   'Blue',
//   'Green',
//   'Brown',
//   'Black',
//   'Amber',
//   'Gray',
//   'Hazel',
//   'Red',
//   'Violet',
//   'Silver',
// ];

const AlignmentOptions = [
  'Lawful Good',
  'Neutral Good',
  'Chaotic Good',
  'Lawful Neutral',
  'True Neutral',
  'Chaotic Neutral',
  'Lawful Evil',
  'Neutral Evil',
  'Chaotic Evil',
];

interface Props {
  characterBodyType: Body_Shape | null;
  setCharacterBodyType: (char: Body_Shape) => void;
  characterSkinColor: Skin | null;
  setCharacterSkinColor: (char: Skin) => void;
  characterHairColor: string;
  setCharacterHairColor: (char: string) => void;
  characterHairStyle: string;
  setCharacterHairStyle: (char: string) => void;
  characterEyeColor: Eye | null;
  setCharacterEyeColor: (char: Eye) => void;
  characterAbilities: CharacterAbility,
  setCharacterAbilities: (ab: CharacterAbility) => void;
  characterAlignment: string;
  setCharacterAlignment: (char: string) => void;
}

function CharacterCharacteristics({
  characterBodyType,
  setCharacterBodyType,
  characterSkinColor,
  setCharacterSkinColor,
  characterHairColor,
  setCharacterHairColor,
  characterHairStyle,
  setCharacterHairStyle,
  characterEyeColor,
  setCharacterEyeColor,
  characterAbilities,
  setCharacterAbilities,
  characterAlignment,
  setCharacterAlignment,
}: Props) {
  const { data: alignments } = useGraphQL(
    GetAlignmentsDocument,
    {},
  );
  console.log(alignments?.alignments);
  const { data: bodyTypes } = useGraphQL(
    GetBodyShapesAttributesDocument,
    {},
  );
  console.log(bodyTypes?.body_shapes);
  const { data: skinColor } = useGraphQL(
    GetSkinsAttributesDocument,
    {},
  );
  console.log(skinColor?.skins);
  const { data: eyeColor } = useGraphQL(
    GetEyesAttributesDocument,
    {},
  );
  console.log(eyeColor?.eyes);

  return (
    <Stack gap="33px">
      <Stack gap="21px">
        <Stack justifyContent="center" alignItems="center" gap="14px">
          <Typography
            sx={{
              color: '#250503',
              fontFamily: 'Passero One',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '50px',
              letterSpacing: '.1px',
            }}
          >
            Identifying Characteristics
          </Typography>
          <FlexibleHeightImg width="102px" src={FancyUnderline} />
        </Stack>
        <SectionHeadingText sx={{ fontSize: '28px!important' }}>
          Physical Profile
        </SectionHeadingText>
        <Stack flexDirection="row" gap="23px" flexWrap="wrap">
          <CharacterFilterDropdown
            width="calc(32% - 8px)"
            title="Body Type"
            options={bodyTypes?.body_shapes}
            value={characterBodyType?.name}
            onChange={setCharacterBodyType}
          />
          <CharacterFilterDropdown
            width="calc(32% - 8px)"
            title="Skin Color"
            options={skinColor?.skins}
            value={characterSkinColor?.name}
            onChange={setCharacterSkinColor}
          />
          <CharacterFilterDropdown
            width="calc(32% - 8px)"
            title="Eye Color"
            options={eyeColor?.eyes}
            value={characterEyeColor?.name}
            onChange={setCharacterEyeColor}
          />
          <CharacterFilterDropdown
            width="calc(32% - 8px)"
            title="Hair Color"
            options={HairColorOptions}
            value={characterHairColor}
            onChange={setCharacterHairColor}
          />
          <CharacterFilterDropdown
            width="calc(32% - 8px)"
            title="Hair Style"
            options={HairStyleOptions}
            value={characterHairStyle}
            onChange={setCharacterHairStyle}
          />
        </Stack>
      </Stack>
      <Box>
        <CharacterSectionWithHelp
          title="Abilities"
          message="lorem ipsum dolor"
        />
        <CharacterAbilitiesGen
          abilities={characterAbilities}
          setCharacterAbilities={(ab: CharacterAbility) => setCharacterAbilities(ab)}
          variant="characterWizard1"
        />
      </Box>
      {/* Character Alignment */}
      <Box>
        <CharacterSectionWithHelp
          title="Select an Alignment"
          message="lorem ipsum dolor"
        />
        <Stack direction="row" gap="12px" flexWrap="wrap" justifyContent="center">
          {AlignmentOptions.map((alignment) => (
            <Box
              key={alignment}
              sx={{
                width: '100px',
                height: '55px',
                borderRadius: '10px',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                background: alignment === characterAlignment ? '#41521F' : '#A89F68',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: '16px',
                cursor: 'pointer',
              }}
              onClick={() => setCharacterAlignment(alignment)}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontFamily: 'Passero One',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                {alignment}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}

export default CharacterCharacteristics;
