/* eslint-disable max-len */
import React, { useEffect } from 'react';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Stack } from '@mui/material';
import styled from '@emotion/styled';
import useGraphQL from '../../hooks/useGraphQL';
import { GetManyMediaAssetsDocument } from '../../__generated__/graphql';

interface Props {
  packImagesIds: string[] | undefined,
}

const SelectedImg = styled('img')({
  width: '396px',
  height: '393px',
  objectFit: 'cover',
});

const CarouselImg = styled('img')({
  width: '70px',
  height: '80px',
  objectFit: 'cover',
  cursor: 'pointer',
});

const NavArrow = styled(ArrowForwardIosRoundedIcon)({
  color: '#d9d9d9',
  cursor: 'pointer',
  transition: 'ease-in-out .3s',
  '&:hover': {
    color: '#585858',
  },
});

const DisplayTokenSummaryCarousel: React.FC<Props> = ({
  packImagesIds,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: packImages, status: packImagesStatus, error: packImagesError } = useGraphQL(
    GetManyMediaAssetsDocument,
    {
      query: {
        _id_in: packImagesIds ?? [],
      },
    },
  );
  const [selectedImage, setSelectedImage] = React.useState<string>(
    '',
  );

  useEffect(() => {
    // setSelectedImage(packImages?.media_assets?.[0]?.thumbnail_url ?? '');
    console.log('selected image', packImages?.media_assets);
  }, [selectedImage]);

  /**
   * Represents the translateX value for the carousel slide animation.
   */
  const [translateX, setTranslateX] = React.useState<number>(0);

  /**
   * Handles the carousel swipe based on the specified direction.
   *
   * @param {('forward' | 'backward')} direction - The direction of the carousel swipe.
   * @returns {void}
   */
  const handleCarouselSwipe = (direction: 'forward' | 'backward') => {
    const carouselPosition = (-translateX) - 70;
    const carouselWidth = packImagesIds ? (packImagesIds.length * 70) + ((packImagesIds.length - 2) * 20) : 0;
    console.log(carouselPosition, carouselWidth);
    if (direction === 'forward') {
      if ((-carouselPosition) < (carouselWidth)) {
        setTranslateX(translateX + 90);
      }
    }
    if (direction === 'backward') {
      if (carouselPosition < -70) {
        setTranslateX(translateX - 90);
      }
    }
  };
  useEffect(() => {
    if (packImagesStatus === 'success') setSelectedImage(packImages?.media_assets?.[0]?.thumbnail_url!);
  }, [packImagesStatus]);

  return (
    <Stack gap="20px" justifyContent="center" alignItems="center">
      {packImagesStatus === 'success'
     && (
     <>

       <SelectedImg src={selectedImage ?? packImages?.media_assets?.[0]?.thumbnail_url} />
       <Box width="100%">
         <Stack direction="row" gap="20px" flexWrap="nowrap">
           <Stack width="40px" justifyContent="center" alignItems="center">
             <NavArrow
               sx={{ transform: 'rotate(180deg)' }}
               onClick={() => handleCarouselSwipe('backward')}
             />
           </Stack>
           <Box width="340px" overflow="hidden">
             <Stack
               direction="row"
               gap="20px"
               flexWrap="nowrap"
               sx={{
                 transform: `translateX(${translateX * -1}px)`,
                 transition: 'ease-in-out .3s',
               }}
             >
               {packImages?.media_assets?.map((packImage) => (
                 <Box
                   key={`${packImage?.name}-${packImage?.thumbnail_url}`}
                   onClick={() => setSelectedImage(packImage?.thumbnail_url ?? '')}
                 >
                   <CarouselImg src={packImage?.thumbnail_url} />
                 </Box>
               ))}
             </Stack>
           </Box>
           <Stack width="40px" justifyContent="center" alignItems="center">
             <NavArrow onClick={() => handleCarouselSwipe('forward')} />
           </Stack>
         </Stack>
       </Box>
       <Stack direction="row" gap="10px">
         {packImages?.media_assets?.map((packImage, index) => (
           <Box
             key={packImage?.name}
             onClick={() => {
               setTranslateX(index * 90);
               setSelectedImage(packImage?.thumbnail_url ?? '');
             }}
             sx={{
               width: '12px',
               height: '12px',
               borderRadius: '50%',
               backgroundColor: translateX === index * 90 ? '#d9d9d9' : '#585858',
               cursor: 'pointer',
               // border: '2px solid red',
             }}
           />
         ))}
       </Stack>
     </>
     )}
    </Stack>
  );
};

export default DisplayTokenSummaryCarousel;
