/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
import React, { useContext } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Popover,
  Slider,
  TextField,
  Input,
  Typography,
} from '@mui/material';
import { CustomPicker, InjectedColorProps } from 'react-color';
import {
  Alpha,
  Hue,
  Saturation,
  Checkboard,
} from 'react-color/lib/components/common';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './rwcolorpicker.module.scss';
import './rwcolorpickermodifier.scss';

export type RWColorPickerOptions = {
  showFillColor?: boolean;
  showSaturation?: boolean;
  showHue?: boolean;
  hueAlignment?: 'vertical' | 'horizontal';
  showAlpha?: boolean;
  alphaAlignment?: 'vertical' | 'horizontal';
  fillClickable?: boolean;
  fillLabel?: string;
  showTitle?: boolean;
  showInputFields?: boolean;
  variant?: 'tokenmaker' | 'character',
};

export type RWColorPickerProps = {
  // eslint-disable-next-line react/require-default-props
  options?: RWColorPickerOptions;
} & InjectedColorProps;

const defaultOptions: RWColorPickerOptions = {
  showFillColor: true,
  showSaturation: true,
  showHue: true,
  hueAlignment: 'horizontal',
  showAlpha: true,
  alphaAlignment: 'vertical',
  fillClickable: false,
  fillLabel: 'Fill/Color',
  showTitle: true,
  showInputFields: true,
  variant: 'tokenmaker',
};

function RWColorPicker(props: RWColorPickerProps) {
  const { hsl, rgb, hex, onChange, options: originalOptions } = props;
  // console.log('rgb: ', rgb);
  // console.log('hex: ', hex);

  const options = { ...defaultOptions, ...originalOptions };

  const handleColorChange = (color: any) => {
    if (onchange !== undefined) {
      onChange?.(color);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (options?.fillClickable) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const customPointer = (
    <Box
      sx={{
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderBottom: '5px solid transparent',
        borderLeft: '10px solid black',
        borderRight: '10px solid black',
      }}
    />
  );

  const { theme } = useContext(ThemeContext);

  const [redValue, setRedValue] = React.useState(rgb?.r ?? 0);
  const [greenValue, setGreenValue] = React.useState(rgb?.g ?? 0);
  const [blueValue, setBlueValue] = React.useState(rgb?.b ?? 0);

  React.useEffect(() => {
    setRedValue(rgb?.r ?? 0);
    setGreenValue(rgb?.g ?? 0);
    setBlueValue(rgb?.b ?? 0);
  }, [rgb]);

  return (
    <Box className={`rwcolorpicker_wrapper ${styles.rwcolorpicker_wrapper} ${theme === 'light' ? 'light' : 'dark'} ${styles[options.variant!]}`}>
      {options?.showTitle && (
        <Typography className={`${styles.rwcolorpicker_title} ${styles[theme]} ${styles[options.variant!]}`}>
          {options.variant === 'character' ? 'Select Color' : 'Edit Color'}
        </Typography>
      )}
      {options?.showInputFields && (
        <Box className={`rwcolorpicker_form_parent_wrapper ${styles.rwcolorpicker_form_parent_wrapper} ${styles[options.variant!]}`}>
          <Box className={styles.rwcolorpicker_form_wrapper}>
            <Box className={styles.rwcolorpicker_input_wrapper}>
              <Typography className={styles.rwcolorpicker_input_title}>Red</Typography>
              <Input
                value={redValue}
                type="number"
                inputProps={{ min: 0, max: 255 }}
                onChange={(event) => {
                  const newRedValue = parseInt(event.target.value, 10);
                  if (!Number.isNaN(newRedValue)) {
                    setRedValue(newRedValue);
                    handleColorChange({ ...rgb, r: newRedValue });
                  }
                }}
                className={styles.rwcolorpicker_input}
              />
            </Box>
            <Box className={styles.rwcolorpicker_input_wrapper}>
              <Typography className={styles.rwcolorpicker_input_title}>Green</Typography>
              <Input
                value={greenValue}
                type="number"
                inputProps={{ min: 0, max: 255 }}
                onChange={(event) => {
                  const newGreenValue = parseInt(event.target.value, 10);
                  if (!Number.isNaN(newGreenValue)) {
                    setGreenValue(newGreenValue);
                    handleColorChange({ ...rgb, g: newGreenValue });
                  }
                }}
                className={styles.rwcolorpicker_input}
              />
            </Box>
            <Box className={styles.rwcolorpicker_input_wrapper}>
              <Typography className={styles.rwcolorpicker_input_title}>Blue</Typography>
              <Input
                value={blueValue}
                type="number"
                inputProps={{ min: 0, max: 255 }}
                onChange={(event) => {
                  const newBlueValue = parseInt(event.target.value, 10);
                  if (!Number.isNaN(newBlueValue)) {
                    setBlueValue(newBlueValue);
                    handleColorChange({ ...rgb, b: newBlueValue });
                  }
                }}
                className={styles.rwcolorpicker_input}
              />
            </Box>
            <Box className={styles.rwcolorpicker_input_wrapper}>
              <Typography className={styles.rwcolorpicker_input_title}>Hex</Typography>
              <Input value={hex?.replace('#', '')} className={styles.rwcolorpicker_input} />
            </Box>
          </Box>
          <Box className={styles.rwcolorpicker_colorbox_wrapper}>
            <Box sx={{ backgroundColor: hex }} className={styles.rwcolorpicker_colorbox} />
          </Box>
        </Box>
      )}
      {options?.showSaturation && (
        <Box className={`rwcolorpicker_saturation ${styles.rwcolorpicker_saturation} ${styles[options.variant!]}`}>
          <Saturation {...props} color={rgb} onChange={handleColorChange} />
        </Box>
      )}
      {options?.showHue && (
        <Box className={`rwcolorpicker_hue ${styles.rwcolorpicker_hue} ${styles[options.variant!]}`}>
          <Hue
            {...props}
            color={hsl}
            onChange={handleColorChange}
            direction={options.hueAlignment}
          />
        </Box>
      )}
      {false && (
        <Box position="relative" my={10} left={0} width={192} height={10}>
          <Alpha {...props} color={hsl} onChange={handleColorChange} />

          {/* <Slider
            defaultValue={0}
            onChange={handleColorChange}
            aria-label="Alpha"
            color="secondary"
          /> */}
        </Box>
      )}
      {false && (
        <Box>
          <FormControlLabel
            value={options.fillLabel}
            label={options.fillLabel}
            sx={{ mt: 0, ml: 0 }}
            control={
              <Box
                position="relative"
                width={48}
                height={30}
                ml={1}
                sx={{ cursor: 'pointer' }}
              >
                <Checkboard size={12} white="#fff" grey="#c8c8c8" />
                <Box
                  position="relative"
                  component="button"
                  border={0}
                  p={0}
                  width={48}
                  height={30}
                  sx={{ backgroundColor: hex, opacity: hsl?.a }}
                  onClick={handleClick}
                />
              </Box>
            }
            labelPlacement="start"
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              maxWidth={220}
              p={2}
            >
              <Grid item>
                <Box position="relative" left={0} width={144} height={126}>
                  <Saturation
                    {...props}
                    color={rgb}
                    onChange={handleColorChange}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  position="relative"
                  sx={{
                    mt: 2,
                    left: 0,
                    width: 192,
                    height: 10,
                  }}
                >
                  <Hue
                    {...props}
                    color={hsl}
                    onChange={handleColorChange}
                    direction="horizontal"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  position="relative"
                  mt={2}
                  left={0}
                  width={192}
                  height={10}
                >
                  <Alpha {...props} color={hsl} onChange={handleColorChange} />
                </Box>
              </Grid>
            </Grid>
          </Popover>
        </Box>
      )}
    </Box>
  );
}

export default CustomPicker(RWColorPicker);
