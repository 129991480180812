/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import {
  Box,
  CssBaseline,
} from '@mui/material';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import useGraphQL from '../../hooks/useGraphQL';
import DashboardBG from '../../images/dashboard-bg.png';
import {
  GetRealmsDocument, RealmSortByInput,
} from '../../__generated__/graphql';
import SideNav from '../../components/nav/SideNav/SideNav';
import { NavString } from '../../interfaces/navitems';
import appStore from '../../store/AppStore';
import LibraryContentPacksExample from './Favorites/LibraryContentPacksExample';
// import { useAuth } from '../../hooks/use-auth';

function LibraryContentPacksExampleDashboard(): JSX.Element {
  // const auth = useAuth();
  const activeItem: NavString | null = appStore((state) => state.activeNavItem);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: favoriteRealms, refetch: refetchRealms, error: realmsError } = useGraphQL(
    GetRealmsDocument,
    {
      limit: 100,
      sortBy: RealmSortByInput.CreatedatDesc,
      query: {
        is_favorited: true,
      },
    },
  );

  if (realmsError) {
    console.log('realmsError: ', realmsError);
  }

  useEffect(() => {
    if (activeItem === null) {
      appStore.setState({
        activeNavItem: 'Discover',
      });
    }
  });

  return (
    <ReactiveBase
      app="tokens_index"
      url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
      transformRequest={(request: any) => ({
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
        },
      })}
      // @ts-ignore
      mongodb={{
        collection: 'tokens',
        db: 'realm_worlds',
      }}
      analytics={false}
      credentials="none"
      style={{
        background: '#E9DCC3',
        minHeight: '100vh',
        display: 'flex',
        backgroundImage: activeItem === 'Discover' ? `url(${DashboardBG})` : 'none',
        backgroundSize: activeItem === 'Discover' ? 'cover' : 'none',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <SideNav />

        <LibraryContentPacksExample />

      </Box>
    </ReactiveBase>
  );
}

export default LibraryContentPacksExampleDashboard;
