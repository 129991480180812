/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import {
  ToggleButtonGroup,
  styled,
  Tab,
  Tabs,
} from '@mui/material';
import { ThemeContext } from '../../../context/ThemeContext';
import './styledpanel.scss';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }),
);

export function setTabId(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

// export const StyledTabs = styled((props: StyledTabsProps) => (
//   <Tabs
//     {...props}
//     variant="fullWidth"
//     TabIndicatorProps={{ children: <span className="MuiTabs_indicatorSpan" /> }}
//   />
// ))({
//   minHeight: 14,
//   '& .MuiTabs-indicator': {
//     display: 'flex',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },
//   '& .MuiTabs-indicatorSpan': {
//     maxWidth: 0,
//     width: '100%',
//     backgroundColor: 'transparent',
//   },
// });

export function StyledTabs({ children, value, onChange }:StyledTabsProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <Tabs
      variant="fullWidth"
      value={value}
      onChange={onChange}
      className={theme === 'light' ? 'styled_tabs light' : 'styled_tabs dark'}
    >
      {children}
    </Tabs>
  );
}

export interface StyledTabProps {
  label: string;
}

// export const StyledTab = styled((props: StyledTabProps) => (
//   <Tab disableRipple {...props} />
// ))(({ theme }) => ({
//   textTransform: 'none',
//   fontWeight: theme.typography.fontWeightRegular,
//   fontSize: theme.typography.pxToRem(15),
//   marginRight: 0,
//   paddingTop: '1.5rem',
//   paddingBottom: '1.5rem',
//   height: 14,
//   minHeight: 14,
//   color: '#fff',
//   borderRadius: 0,
//   backgroundColor: '#141218',
//   '&.Mui-selected': {
//     color: '#fff',
//     backgroundColor: '#141218',
//   },
//   '&.Mui-focusVisible': {
//     backgroundColor: 'rgba(100, 95, 228, 0.32)',
//   },
//   '&:not(:first-of-type)': {
//     borderRadius: 0,
//   },
//   '&:first-of-type': {
//     borderRadius: '4px 0px 0px 0px',
//   },
//   '&:last-of-type': {
//     borderRadius: '0px 4px 0px 0px',
//   },
// }));

// Component definition
export function StyledTab(props:StyledTabProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <Tab
      disableRipple
      {...props}
      className={theme === 'light' ? 'styled_tab light' : 'styled_tab dark'}
    />
  );
}
