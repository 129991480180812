/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, FormHelperText } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../hooks/use-auth';

const inputStyle: React.CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  background: '#E9DCC3',
  width: '230px',
  position: 'relative',
  border: 'none',
  letterSpacing: '10.5px',
  bottom: '5px',
  marginLeft: 22,
};

const dashStyle: React.CSSProperties = {
  position: 'absolute',
  color: 'black',
  textShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setError] = useState(false);
  const [authErrorMessage, setErrorMessage] = useState('');

  const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
    setAuthLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
    const formEmail = data.get('email');
    if (formEmail) {
      try {
        const response = await auth?.sendPasswordResetEmail(formEmail);
        if (response) {
          alert(`Reset password request sent to: ${formEmail}`);
          setAuthLoading(false);
          navigate('/');
          // console.log("reset password request sent. navigating to home");
        } else {
          setErrorMessage('Reset password request failed. Please try again.');
          setAuthLoading(false);
        }
      } catch (error: any) {
        if (error.code) {
          if (error.code === 'auth/user-not-found') {
            setErrorMessage(
              "We can't find a user with that email. Please try again.",
            );
            setError(true);
          } else {
            setErrorMessage('Something went wrong. Please try again.');
            setError(true);
          }
          setAuthLoading(false);
        }
      }
    } else {
      alert('Please enter email address.');
      setAuthLoading(false);
    }
  };

  return (
    <Box width="678px" display="flex" flexDirection="column" justifyContent="space-between">
      <Typography
        sx={{
          p: 1,
          background: '#250503',
          color: '#fff',
          fontFamily: 'Passero One',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px', /* 83.333% */
          letterSpacing: '0.1px',
          // height: 47,
        }}
      >
        Trouble signing in?

      </Typography>
      <Box>
        <Typography mt={0} align="justify">Choose how you will receive your OTP:</Typography>
        <Card sx={{ minWidth: 275, marginTop: 1, background: '#E9DCC3' }}>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" noValidate onSubmit={handleReset} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Typography sx={{
                    paddingLeft: 3,
                    color: 'black',
                    textAlign: 'center',
                    fontFamily: 'DM Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                  }}
                  >
                    Email:

                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label={<span style={{ color: 'black', marginLeft: 2 }}>&nbsp;Email</span>}
                      name="email"
                      autoComplete="email"
                      style={{
                        width: '524px',
                        borderRadius: 4,
                        background: 'rgba(255, 255, 255, 0.50)',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                      }}
                    />
                  </Grid>
                  {authError ? (
                    <Grid item xs={12}>
                      <FormHelperText
                        sx={() => ({
                          mx: 'auto',
                          color: 'red',
                        })}
                      >
                        {authErrorMessage}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Typography sx={{
                    paddingLeft: 3,
                    paddingTop: 3,
                    color: 'black',
                    textAlign: 'center',
                    fontFamily: 'DM Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                  }}
                  >
                    Phone Number:

                  </Typography>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <input name="phone" type="tel" maxLength={10} style={inputStyle} />
                    <div style={dashStyle}>__ __ __ __ __ __ __ __ __ __</div>

                  </Grid>
                </Grid>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{
                    background: '#D1C5AE',
                    mt: 3,
                    mb: 2,
                    display: 'flex',
                    alignContent: 'flex-end',
                    marginLeft: 'auto',
                  }}
                  loading={authLoading}
                >
                  Send
                </LoadingButton>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
