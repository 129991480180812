import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import ShoppingCartIcon from '../../images/shopping-cart.webp';

/**
 * Represents the props for the `RecommendedCard` component.
 *
 * @interface
 * @property {string} [width='100%'] - The width of the card.
 * @property {string} [borderColor='#FFF'] - The border color of the card.
 * @property {any[]} featuredImages - An array of objects representing featured images.
 * @property {string} title - The title of the card.
 */
interface Props {
  width?: string,
  borderColor?: string,
  featuredImages: any[],
  title: string,
}

const BigImg = styled('img')({
  height: '25px',
  width: '25px',
  objectFit: 'contain',
});

/**
 * Represents a recommended card component.
 *
 * @component
 * @param {Props} props - The properties of the `RecommendedCard` component.
 * @returns {JSX.Element} - The rendered `RecommendedCard` component.
 */
function RecommendedCard({
  width,
  borderColor,
  featuredImages,
  title,
}: Props) {
  return (
    <Box width={width} position="relative">
      <Box
        sx={{
          width: '100%',
          height: '140px',
          position: 'relative',
          borderRadius: '5px',
          overflow: 'hidden',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack direction="row">
          {featuredImages.slice(0, 3).map((featuredImage) => (
            <img
              key={featuredImage._id}
              src={featuredImage.url}
              alt="featured"
              style={{
                width: 'calc(100% / 3)',
                height: '140px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '140px',
          boxSizing: 'border-box',
          border: `2px solid ${borderColor}`,
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '5px',
          overflow: 'hidden',
          background: 'rgba(65, 82, 31, 0.50)',
          padding: '6px 8px',
        }}
      >
        <Stack gap="20px">
          <Stack direction="row" justifyContent="flex-end">
            <BigImg src={ShoppingCartIcon} />
          </Stack>
          <Stack justifyContent="center">
            <Typography
              sx={{
                color: 'white',
                fontFamily: 'Passero One',
                fontSize: '25px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                textDecorationLine: 'underline',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

/**
 * Default props for the `RecommendedCard` component.
 */
RecommendedCard.defaultProps = {
  width: '100%',
  borderColor: '#FFF',
};

export default RecommendedCard;
