/* eslint-disable  @typescript-eslint/no-unused-vars */
import React from 'react';
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Box, Stack } from '@mui/material';
import { EditorImg } from '../styledComps/styledComps';
import BoldIcon from '../../images/bold.webp';
import ItalicIcon from '../../images/italic.webp';
import UnderlineIcon from '../../images/underline.webp';
import StrikethroughIcon from '../../images/strikethrough.webp';
import UnoderedIcon from '../../images/bullet-list.webp';
import CalendarIcon from '../../images/calendar.webp';
import DeleteIcon from '../../images/trash.webp';
import './notes.scss';

interface Props {
  note: string;
  editNote: () => void;
  deleteNote: () => void;
  setNoteData: (str: string) => void;
}

function NoteEditor({
  note, editNote, deleteNote, setNoteData,
}: Props) {
  const notesContent = ContentState.createFromText(note);
  const raw = convertToRaw(notesContent); // RawDraftContentState JSON
  const [contentState, setContentState] = React.useState(raw); // ContentState JSON

  const updateNoteContent = () => {
    const contentStateFromString = convertFromRaw(contentState);
    const text = contentStateFromString.getPlainText();
    // Now you can send `text` back up to the parent component
    setNoteData(text);
  };

  React.useEffect(() => {
    editNote();
  }, [contentState]);

  React.useEffect(() => {
    updateNoteContent();
  }, [contentState]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        gap="20px"
        justifyContent="space-between"
        sx={{
          position: 'absolute',
          right: '20px',
          top: '14px',
        }}
      >
        <EditorImg src={CalendarIcon} />
        <EditorImg src={DeleteIcon} onClick={deleteNote} />
      </Stack>
      <Editor
        defaultContentState={contentState}
        onContentStateChange={setContentState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onBlur={() => updateNoteContent}
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            inDropdown: false,
            className: 'editorWrapper',
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: { icon: BoldIcon, className: 'editorImg' },
            italic: { icon: ItalicIcon, className: 'editorImg' },
            underline: { icon: UnderlineIcon, className: 'editorImg' },
            strikethrough: { icon: StrikethroughIcon, className: 'editorImg' },
          },
          list: {
            inDropdown: false,
            className: 'editorWrapper',
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered'],
            unordered: { icon: UnoderedIcon, className: 'editorImg' },
          },
        }}
      />
    </Box>
  );
}

export default NoteEditor;
