import React from 'react';
import { Box } from '@mui/material';
import styles from './card.module.scss';

interface Props {
  imageUrl: string;
  onClick: (url: string) => void;
}

function ImageCard({ imageUrl, onClick }: Props) {
  return (
    <Box className={styles.image_card} onClick={() => onClick(imageUrl)}>
      <img className={styles.image_card_image} src={imageUrl} alt="ai-generated" />
    </Box>
  );
}

export default ImageCard;
