import React from 'react';
import {
  Button, Checkbox, FormControlLabel, Stack, Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import CharacterStartBGImg from '../../../images/craft-character-start-1.webp';
import {
  CraftStartOptionHeadingImg, CraftStartOptionWrapper, FlexibleHeightImg,
} from '../../styledComps/styledComps';
import DecorativeLineImage from '../../../images/decorative-line.webp';
import ReadingWizardImage from '../../../images/wizard-reading.png';
import MageImage from '../../../images/character-placeholder.webp';
import CharacterSheetIcon from '../../../images/character-sheet-dark.webp';
import WizardIcon from '../../../images/wizard.webp';
import FantasyIcon from '../../../images/fantasy.webp';
import appStore, { CreateCharacterViewOptions } from '../../../store/AppStore';

const PasseroFont = styled(Typography)({
  fontFamily: 'Passero One',
  fontWeight: '400',
  '&.title': {
    color: '#250503',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  '&.buttonText': {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textTransform: 'none',
  },
});

const BodyCopyText = styled(Typography)({
  fontFamily: 'DM Sans',
  fontWeight: '400',
  color: '#250503',
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: 'normal',
  padding: '0 25px',
});

const OptionButton = styled(Button)({
  borderRadius: '5px',
  display: 'block',
  border: 'none',
  outline: 'none',
  padding: '10px 24px',
  cursor: 'pointer',
  color: 'white',
});

function CraftCharacterStart() {
  const [hideCraftStart, setHideCraftStart] = React.useState(false);
  const setCreateCharacterView = (view: CreateCharacterViewOptions) => appStore.setState({
    createCharacterView: view,
  });
  const setDefaultCreateCharacterView = (view: CreateCharacterViewOptions) => appStore.setState({
    defaultCreateCharacterView: view,
  });
  const handleHideCreateStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideCraftStart(event.target.checked);
  };
  const handleGoToView = (view: CreateCharacterViewOptions) => {
    setCreateCharacterView(view);
    if (hideCraftStart) setDefaultCreateCharacterView(view);
  };

  return (
    <Stack
      sx={{
        height: 'calc(100vh - 48px)',
        background: `url(${CharacterStartBGImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxSizing: 'border-box',
      }}
      gap="30px"
      pt="100px"
    >
      {/* Title */}
      <Stack gap="10px" justifyContent="center" alignItems="center">
        <Typography
          sx={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Passero One',
            fontSize: '57px',
            fontStyle: 'normal',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            fontWeight: 400,
            lineHeight: '64px',
            letterSpacing: '-.25px',
          }}
        >
          Let’s Begin To Craft Your Character
        </Typography>
        <FlexibleHeightImg width="102px" src={DecorativeLineImage} />
      </Stack>

      {/* options */}
      <Stack direction="row" gap="50px" justifyContent="center">
        <CraftStartOptionWrapper width="350px" pb="50px">
          <CraftStartOptionHeadingImg src={ReadingWizardImage} style={{ objectPosition: 'bottom' }} />
          <Stack pt="15px" gap="10px" alignItems="center" justifyContent="center">
            <FlexibleHeightImg width="40px" src={CharacterSheetIcon} />
            <PasseroFont className="title">Character Sheet</PasseroFont>
            <BodyCopyText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore.
            </BodyCopyText>
            <OptionButton
              sx={{
                background: '#41521F',
                '&:hover': {
                  background: '#41521F',
                },
              }}
              onClick={() => handleGoToView('sheet')}
            >
              <PasseroFont className="buttonText">Begin Crafting</PasseroFont>
            </OptionButton>
          </Stack>
        </CraftStartOptionWrapper>
        <CraftStartOptionWrapper width="350px" pb="50px">
          <CraftStartOptionHeadingImg src={MageImage} style={{ objectPosition: 'center' }} />
          <Stack pt="15px" gap="10px" alignItems="center" justifyContent="center">
            <FlexibleHeightImg width="40px" src={WizardIcon} />
            <PasseroFont className="title">Wizard</PasseroFont>
            <BodyCopyText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore.
            </BodyCopyText>
            <OptionButton
              sx={{
                background: '#41521F',
                '&:hover': {
                  background: '#41521F',
                },
              }}
              onClick={() => handleGoToView('wizard')}
            >
              <PasseroFont className="buttonText">Begin Crafting</PasseroFont>
            </OptionButton>
          </Stack>
        </CraftStartOptionWrapper>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <CraftStartOptionWrapper width="750px" py="21px">
          <Stack pt="15px" gap="10px" alignItems="center" justifyContent="center">
            <PasseroFont className="title">AI Generate Character</PasseroFont>
            <BodyCopyText width="322px">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore.
            </BodyCopyText>
            <OptionButton
              sx={{
                background: '#DA16A2',
                '&:hover': {
                  background: '#DA16A2',
                },
              }}
            >
              <Stack direction="row" justifyContent="center" gap="8px" alignItems="center">
                <FlexibleHeightImg width="20px" src={FantasyIcon} />
                <PasseroFont className="buttonText">AI Generate</PasseroFont>
              </Stack>
            </OptionButton>
          </Stack>
        </CraftStartOptionWrapper>
      </Stack>

      {/* Don't show this again */}
      <Stack justifyContent="center" alignItems="center">
        <FormControlLabel
          control={(
            <Checkbox
              inputProps={{ 'aria-label': 'Do not show create start again' }}
              onChange={handleHideCreateStart}
              sx={{
                color: '#E9DCC3',
                boxSizing: 'border-box',
                borderRadius: '4px',
                '&.Mui-checked': {
                  color: '#41521F',
                },
              }}
            />
          )}
          label="Do not show this again."
          sx={{
            '.MuiTypography-root': {
              color: '#FFF',
              fontFamily: 'DM Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '.5px',
            },
          }}
        />
      </Stack>
    </Stack>
  );
}

export default CraftCharacterStart;
