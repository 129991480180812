/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import './App.css';
import './fonts.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProvideAuth, useAuth } from './hooks/use-auth';
import Login from './views/Login/Login';
// import DashboardNew from './views/Dashboard/Dashboard';
import SignUp from './views/Signup/Signup';
import RealmDashboard from './views/Dashboard/Realm/Dashboard';
import NoMatch from './views/NoMatch';
import CharacterManager from './views/Realm/Characters/CharacterGenerator';
import TokenMaker from './components/realms/TokenMaker/TokenMaker';
import GenerateImages from './views/Generate/Generate';
import LibraryContentPacksExampleDashboard from './views/Dashboard/LibraryContentPacksExampleDashboard';
import DashboardLayout from './ui/DashboardLayout/DashboardLayout';
import DashboardView from './ui/DashboardView/DashboardView';
import DetailView from './ui/DetailView/DetailView';

const queryClient = new QueryClient();

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = await auth?.getCurrentUserTokens();
      if (token.fb === undefined || token.realm === undefined) {
        navigate('/login', { state: { from: location } });
      }
      setLoading(false);
    };
    checkAuth();
  }, [auth, navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return children;
}

// function RealmWorldsHome() {
//   const navigate = useNavigate();
//   React.useEffect(() => {
//     navigate('/dashboard');
//   });
//   return <div />;
// }

function App() {
  return (
    <BrowserRouter>
      <ProvideAuth>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              element={
                <RequireAuth>
                  <DashboardLayout />
                </RequireAuth>
              }
            >
              <Route index element={<Navigate replace to="dashboard-view" />} />
              <Route path="dashboard-view" element={<DashboardView />} />
              <Route path="detail-view" element={<DetailView />} />
            </Route>

            <Route
              path="/rwlibrary"
              element={<LibraryContentPacksExampleDashboard />}
            />
            <Route
              path="/imageGen"
              element={
                <RequireAuth>
                  <GenerateImages />
                </RequireAuth>
              }
            />
            <Route
              path="realm/:id"
              element={
                <RequireAuth>
                  <RealmDashboard />
                </RequireAuth>
              }
            >
              <Route
                path="campaign"
                element={
                  <RequireAuth>
                    <NoMatch />
                  </RequireAuth>
                }
              />
              <Route
                path="character"
                element={
                  <RequireAuth>
                    <CharacterManager />
                  </RequireAuth>
                }
              />
              <Route
                path="tokenmaker"
                element={
                  <RequireAuth>
                    <TokenMaker />
                  </RequireAuth>
                }
              />
              <Route
                path="imageGen"
                element={
                  <RequireAuth>
                    <GenerateImages />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<NoMatch />} />
            </Route>

            <Route path="signup" element={<SignUp />} />
            <Route path="login" element={<Login />} />
          </Routes>
        </QueryClientProvider>
      </ProvideAuth>
    </BrowserRouter>
  );
}

export default App;
