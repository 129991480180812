import React from 'react';
import { Stack } from '@mui/material';
import { SectionHeadingText } from '../../styledComps/styledComps';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import {
  Body_Shape, Eye, GetBodyShapesAttributesDocument, GetEyesAttributesDocument,
  GetSkinsAttributesDocument, Skin,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';

const HairColorOptions = [
  'Black',
  'Blonde',
  'Brown',
  'Red',
  'Blue',
  'Green',
  'Purple',
  'Pink',
  'Silver',
  'Golden',
];

const HairStyleOptions = [
  'Straight',
  'Wavy',
  'Curly',
  'Short',
  'Long',
  'Buzzed',
  'Bop',
  'Afro',
  'Braided',
  'Bald',
];

interface Props {
  characterBodyType: Body_Shape | null;
  setCharacterBodyType: (char: Body_Shape) => void;
  characterSkinColor: Skin | null;
  setCharacterSkinColor: (char: Skin) => void;
  characterHairColor: string;
  setCharacterHairColor: (char: string) => void;
  characterHairStyle: string;
  setCharacterHairStyle: (char: string) => void;
  characterEyeColor: Eye | null;
  setCharacterEyeColor: (char: Eye) => void;
}

function CharacterPhysicalProfile({
  characterBodyType,
  setCharacterBodyType,
  characterSkinColor,
  setCharacterSkinColor,
  characterHairColor,
  setCharacterHairColor,
  characterHairStyle,
  setCharacterHairStyle,
  characterEyeColor,
  setCharacterEyeColor,
}: Props) {
  const { data: bodyTypes } = useGraphQL(
    GetBodyShapesAttributesDocument,
    {},
  );
  const { data: skinColor } = useGraphQL(
    GetSkinsAttributesDocument,
    {},
  );
  const { data: eyeColor } = useGraphQL(
    GetEyesAttributesDocument,
    {},
  );
  return (
    <Stack gap="22px">
      <Stack gap="7px" direction="row" justifyContent="center" alignItems="center">
        <SectionHeadingText>Physical Profile</SectionHeadingText>
      </Stack>
      <Stack direction="row" flexWrap="nowrap" gap="28px">
        <CharacterFilterDropdown
          width="20%"
          title="Body Type"
          options={bodyTypes?.body_shapes}
          value={characterBodyType?.name}
          onChange={setCharacterBodyType}
        />
        <CharacterFilterDropdown
          width="20%"
          title="Skin Color"
          options={skinColor?.skins}
          value={characterSkinColor?.name}
          onChange={setCharacterSkinColor}
        />
        <CharacterFilterDropdown
          width="20%"
          title="Hair Color"
          options={HairColorOptions}
          value={characterHairColor}
          onChange={setCharacterHairColor}
        />
        <CharacterFilterDropdown
          width="20%"
          title="Hair Style"
          options={HairStyleOptions}
          value={characterHairStyle}
          onChange={setCharacterHairStyle}
        />
        <CharacterFilterDropdown
          width="20%"
          title="Eye Color"
          options={eyeColor?.eyes}
          value={characterEyeColor?.name}
          onChange={setCharacterEyeColor}
        />
      </Stack>
    </Stack>
  );
}

export default CharacterPhysicalProfile;
