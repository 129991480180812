/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Stack,
} from '@mui/material';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import {
  AdminSettingsIcon,
  CharacterSheetIcon,
  CharacterWizardIcon,
  CreateIcon,
  QueryIcon,
  RealmWorldIcon,
  ShoppingCartIcon,
} from '../../../components/toolbar_icons';
import './charactergenerator.scss';
import CharacterCatalog from './CharacterCatalog';
import CharacterStore from './CharacterStore';
import CharacterCreator from './CharacterCreator';
import ThankYouModal from '../../../components/modals/ShoppingCartPopups/ThankYouModal/ThankYouModal';
import EmptyCartModal from '../../../components/modals/ShoppingCartPopups/EmptyCartModal/EmptyCartModal';
import CartItemsModal from '../../../components/modals/ShoppingCartPopups/CartItemsModal/CartItemsModal';
import appStore, { CharacterViewOptions, CreateCharacterViewOptions } from '../../../store/AppStore';

type ViewOptions = 'creator' | 'catalog' | 'store';

export default function CharacterManager(): JSX.Element {
  const { theme } = useContext(ThemeContext);

  const selectedView:
  CharacterViewOptions = appStore(
    (state) => state.selectedCharacterView,
  );
  const setSelectedView = (view: CharacterViewOptions) => {
    if (view !== selectedView) {
      appStore.setState({
        selectedCharacterView: view,
      });
    }
  };

  const createCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.createCharacterView,
  );
  const setCreateCharacterView = (view: CreateCharacterViewOptions) => {
    if (view !== createCharacterView) {
      appStore.setState({
        createCharacterView: view,
      });
    }
  };
  const defaultCreateCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.defaultCreateCharacterView,
  );

  // TODO: Add logic to swtich between views of the different shopping modal
  const [openThankYouModal, setOpenThankYouModal] = React.useState(false);
  const [openEmptyCartModal, setOpenEmptyCartModal] = React.useState(false);
  const [openShoppingCartModal, setShoppingCartModal] = React.useState(false);

  return (
    <Box
      sx={{
        background: theme === 'light' ? '#E9DCC3' : '#313131',
        // minHeight: '100vh',
        width: '100%',
        padding: 0,
        boxSizing: 'border-box',
        overflowX: 'hidden',
      }}
    >
      <CartItemsModal open={openShoppingCartModal} onClose={() => { console.log('modal closed'); }} setOpen={setShoppingCartModal} />
      <ThankYouModal open={openThankYouModal} onClose={() => { console.log('modal closed'); }} setOpen={setOpenThankYouModal} />
      <EmptyCartModal open={openEmptyCartModal} onClose={() => { console.log('modal closed'); }} setOpen={setOpenEmptyCartModal} />
      {/* Toolkit view navigation */}
      <Box className={`toolkit ${theme === 'light' ? 'light' : 'dark'}`}>
        <CreateIcon
          onClick={() => {
            setSelectedView('creator');
            setCreateCharacterView(defaultCreateCharacterView);
          }}
          active={selectedView === 'creator'}
        />
        {selectedView === 'creator' && createCharacterView !== 'start' && (
          <Stack direction="row" alignItems="center" bgcolor="#7A3900">
            <CharacterSheetIcon
              onClick={() => setCreateCharacterView('sheet')}
              active={createCharacterView === 'sheet'}
            />
            <Box className="toolkit_divider_wrapper white" />
            <CharacterWizardIcon
              onClick={() => setCreateCharacterView('wizard')}
              active={createCharacterView === 'wizard'}
            />
          </Stack>
        )}
        <Box className="toolkit_divider_wrapper" />
        <AdminSettingsIcon
          onClick={() => setSelectedView('catalog')}
          active={selectedView === 'catalog'}
        />
        <Box className="toolkit_divider_wrapper" />
        <RealmWorldIcon
          onClick={() => setSelectedView('store')}
          active={selectedView === 'store'}
          showLock={false}
        />
        <Box className="toolkit_divider_wrapper" />
        <QueryIcon onClick={() => console.log('query')} active={false} />
        <Box className="toolkit_divider_wrapper" />
        <ShoppingCartIcon onClick={() => setShoppingCartModal(true)} active={false} />
      </Box>
      {selectedView === 'catalog' && <CharacterCatalog />}
      {selectedView === 'store' && <CharacterStore />}
      {selectedView === 'creator' && <CharacterCreator />}
    </Box>
  );
}
