import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FancyUnderline from '../../../images/fancy-underline-one.webp';
import {
  AttributeText, AttributeWrapper, BackgroundContentWrapper, CustomScrollWrapper,
  FlexibleHeightImg, GoalContentWrapper, SectionHeadingText, SmallHeadingText,
} from '../../styledComps/styledComps';
import {
  CharacterAbility, CharacterShort_Term_Goal, CharacterLong_Term_Goal,
} from '../../../__generated__/graphql';
import CharacterAbilitiesGen from './CharacterAbilitiesGen';
import CharacterNoteView from '../../notes/CharacterNoteView';
// import CharacterAttributes from './CharacterAttributes';

interface Props {
  previewUrl: string;
  characterName: string;
  characterAge: number;
  characterPronouns: string;
  characterGender: string;
  characterRace: string;
  characterClass: string;
  characterAlignment: string;
  characterPersonalityType: string;
  characterMotivations: string[];
  characterBodyType: string;
  characterSkinColor: string;
  characterHairColor: string;
  characterHairStyle: string;
  characterEyeColor: string;
  characterAbilities: CharacterAbility,
  characterProfession: string;
  characterRelationship: string;
  // characterLifeAlteringEvent: string;
  characterBackStory: string;
  characterLineage: string;
  characterShortTermGoals: CharacterShort_Term_Goal[],
  characterLongTermGoals: CharacterLong_Term_Goal[],
}

function CharacterWizardSummary({
  previewUrl,
  characterName,
  characterAge,
  characterPronouns,
  characterGender,
  characterRace,
  characterClass,
  characterAlignment,
  characterPersonalityType,
  characterMotivations,
  characterBodyType,
  characterSkinColor,
  characterHairColor,
  characterHairStyle,
  characterEyeColor,
  characterAbilities,
  characterProfession,
  characterRelationship,
  // characterLifeAlteringEvent,
  characterBackStory,
  characterLineage,
  characterShortTermGoals,
  characterLongTermGoals,
}: Props) {
  return (
    <Stack gap="33px">
      <Stack gap="21px">
        <Stack justifyContent="center" alignItems="center" gap="14px">
          <Typography
            sx={{
              color: '#250503',
              fontFamily: 'Passero One',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '50px',
              letterSpacing: '.1px',
            }}
          >
            Review
          </Typography>
          <FlexibleHeightImg width="102px" src={FancyUnderline} />
        </Stack>
        <Stack flexDirection="row" gap="23px" flexWrap="wrap">
          <Stack direction="row" gap="27px" alignItems="flex-start">
            {/* Character Image Box */}
            <Stack
              sx={{
                borderRadius: '4px',
                background: '#E3D5BC',
                width: '396px',
              }}
              gap="8px"
            >
              <SmallHeadingText pt="9px" pl="21px">Character Image</SmallHeadingText>
              {previewUrl !== '' && previewUrl !== null ? (
                <img
                  alt="character preview"
                  src={previewUrl}
                  style={{
                    height: '410px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              ) : (
                <Stack height="410px" alignItems="center" justifyContent="center">
                  <AttributeText>None</AttributeText>
                </Stack>
              )}
            </Stack>
            {/* Character Attributes */}
            <Stack direction="row" flexWrap="wrap" gap="28px" width="896px">
              <AttributeWrapper width="260px">
                <Stack width="100%">
                  <SmallHeadingText>Name</SmallHeadingText>
                  <AttributeText>{characterName !== '' ? characterName : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="114px">
                <Stack width="100%">
                  <SmallHeadingText>Age</SmallHeadingText>
                  <AttributeText>{characterAge}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="228px">
                <Stack width="100%">
                  <SmallHeadingText>Pronouns</SmallHeadingText>
                  <AttributeText>{characterPronouns !== '' && characterPronouns !== undefined ? characterPronouns : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="210px">
                <Stack width="100%">
                  <SmallHeadingText>Gender</SmallHeadingText>
                  <AttributeText>{characterGender !== '' && characterGender !== undefined ? characterGender : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="320px">
                <Stack width="100%">
                  <SmallHeadingText>Race</SmallHeadingText>
                  <AttributeText>{characterRace !== '' && characterRace !== undefined ? characterRace : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="260px">
                <Stack width="100%">
                  <SmallHeadingText>Class</SmallHeadingText>
                  <AttributeText>{characterClass !== '' && characterClass !== undefined ? characterClass : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="260px">
                <Stack width="100%">
                  <SmallHeadingText>Alignment</SmallHeadingText>
                  <AttributeText>{characterAlignment !== '' && characterAlignment !== undefined ? characterAlignment : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="434px">
                <Stack width="100%">
                  <SmallHeadingText>Motivations</SmallHeadingText>
                  <Stack direction="row" flexWrap="wrap" columnGap="5px">
                    {characterMotivations.length === 0 ? (
                      <AttributeText>None</AttributeText>
                    ) : characterMotivations.map((motivation) => (
                      <AttributeText
                        sx={{ display: 'inline!important' }}
                        key={motivation}
                      >
                        {`${motivation},`}
                      </AttributeText>
                    ))}
                  </Stack>
                </Stack>
              </AttributeWrapper>
              <AttributeWrapper width="434px">
                <Stack width="100%">
                  <SmallHeadingText>PersonalityType</SmallHeadingText>
                  <AttributeText>{characterPersonalityType !== '' && characterPersonalityType !== undefined ? characterPersonalityType : 'None'}</AttributeText>
                </Stack>
              </AttributeWrapper>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* Physical profile */}
      <Stack gap="22px">
        <Stack gap="7px" direction="row" justifyContent="center" alignItems="center">
          <SectionHeadingText>Physical Profile</SectionHeadingText>
        </Stack>
        <Stack direction="row" flexWrap="nowrap" gap="28px">
          <AttributeWrapper width="20%">
            <Stack width="100%">
              <SmallHeadingText>Body Type</SmallHeadingText>
              <AttributeText>{characterBodyType !== '' && characterBodyType !== undefined ? characterBodyType : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
          <AttributeWrapper width="20%">
            <Stack width="100%">
              <SmallHeadingText>Skin Color</SmallHeadingText>
              <AttributeText>{characterSkinColor !== '' && characterSkinColor !== undefined ? characterSkinColor : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
          <AttributeWrapper width="20%">
            <Stack width="100%">
              <SmallHeadingText>Hair Color</SmallHeadingText>
              <AttributeText>{characterHairColor !== '' && characterHairColor !== undefined ? characterHairColor : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
          <AttributeWrapper width="20%">
            <Stack width="100%">
              <SmallHeadingText>Hair Style</SmallHeadingText>
              <AttributeText>{characterHairStyle !== '' && characterHairStyle !== undefined ? characterHairStyle : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
          <AttributeWrapper width="20%">
            <Stack width="100%">
              <SmallHeadingText>Eye Color</SmallHeadingText>
              <AttributeText>{characterEyeColor !== '' && characterEyeColor !== undefined ? characterEyeColor : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
        </Stack>
      </Stack>
      {/* Abilities */}
      <Stack gap="22px">
        <Stack gap="7px" direction="row" justifyContent="center" alignItems="center">
          <SectionHeadingText>Abilities</SectionHeadingText>
        </Stack>
        <CharacterAbilitiesGen
          abilities={characterAbilities}
          setCharacterAbilities={() => {}}
          variant="characterWizard2"
        />
      </Stack>
      {/* Background */}
      <Stack gap="22px">
        <Stack gap="7px" direction="row" justifyContent="center" alignItems="center">
          <SectionHeadingText>Background</SectionHeadingText>
        </Stack>
        <Stack direction="row" flexWrap="wrap" gap="28px">
          <AttributeWrapper width="calc(50% - 14px)">
            <Stack width="100%">
              <SmallHeadingText>Professions</SmallHeadingText>
              <AttributeText>{characterProfession !== '' ? characterProfession : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
          <AttributeWrapper width="calc(50% - 14px)">
            <Stack width="100%">
              <SmallHeadingText>Relationship</SmallHeadingText>
              <AttributeText>{characterRelationship !== '' ? characterRelationship : 'None'}</AttributeText>
            </Stack>
          </AttributeWrapper>
          {/* Birth Story */}
          <BackgroundContentWrapper
            maxHeight="125px"
            width="calc(50% - 14px)"
            py="5px"
            sx={{
              background: '#E3D5BC!important',
              border: 'none!important',
            }}
          >
            <CustomScrollWrapper
              sx={{
                height: '100%',
              }}
            >
              <Box borderRight="solid 1px #BCAD93" p="12px 30px 12px 12px">
                <SmallHeadingText>Birth Story</SmallHeadingText>
                <AttributeText>
                  {characterBackStory !== '' ? characterBackStory : 'No back story available'}
                </AttributeText>
              </Box>
            </CustomScrollWrapper>
          </BackgroundContentWrapper>
          {/* Lineage */}
          <BackgroundContentWrapper
            maxHeight="125px"
            width="calc(50% - 14px)"
            py="5px"
            sx={{
              background: '#E3D5BC!important',
              border: 'none!important',
            }}
          >
            <CustomScrollWrapper
              sx={{
                height: '100%',
              }}
            >
              <Box borderRight="solid 1px #BCAD93" p="12px 30px 12px 12px">
                <SmallHeadingText>Parents (Lineage)</SmallHeadingText>
                <AttributeText>
                  {characterLineage !== '' ? characterLineage : 'No back story available'}
                </AttributeText>
              </Box>
            </CustomScrollWrapper>
          </BackgroundContentWrapper>
          {/* short term goals */}
          <Box width="calc(50% - 14px)">
            <Stack
              gap="35px"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{
                width: '100%',
                borderRadius: '4px 4px 0px 0px',
                background: '#41521F',
                padding: '5px 23px',
                boxSizing: 'border-box',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  letterSpacing: '0.46px',
                  textTransform: 'capitalize',
                  textAlign: 'left',
                  width: 'fit-content',
                }}
              >
                Short Term Goals
              </Typography>
              <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'white' }} />
            </Stack>
            <GoalContentWrapper
              height="127px"
              py="5px"
              sx={{
                background: '#E3D5BC',
              }}
            >
              <CustomScrollWrapper
                sx={{
                  height: '100%',
                }}
              >
                <Box borderRight="solid 1px #BCAD93" p="0">
                  {characterShortTermGoals.map((note) => (
                    <CharacterNoteView note={note} />
                  ))}
                </Box>
              </CustomScrollWrapper>
            </GoalContentWrapper>
          </Box>
          {/* long term goals */}
          <Box width="calc(50% - 14px)">
            <Stack
              gap="35px"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{
                width: '100%',
                borderRadius: '4px 4px 0px 0px',
                background: '#41521F',
                padding: '5px 23px',
                boxSizing: 'border-box',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  letterSpacing: '0.46px',
                  textTransform: 'capitalize',
                  textAlign: 'left',
                  width: 'fit-content',
                }}
              >
                Long Term Goals
              </Typography>
              <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'white' }} />
            </Stack>
            <GoalContentWrapper
              height="127px"
              py="5px"
              sx={{
                background: '#E3D5BC',
              }}
            >
              <CustomScrollWrapper
                sx={{
                  height: '100%',
                }}
              >
                <Box borderRight="solid 1px #BCAD93" p="0">
                  {characterLongTermGoals.map((note) => (
                    <CharacterNoteView note={note} />
                  ))}
                </Box>
              </CustomScrollWrapper>
            </GoalContentWrapper>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CharacterWizardSummary;
