/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Box, Button, Modal, Stack, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import CoinIcon from '../../images/azamel_Coin.png';
import ShoppingCartIcon from '../../images/shopping-cart.webp';
import PlusIcon from '../../assets/images/Add.png';
import { TokenBalanceWrapper } from '../styledComps/styledComps';
import appStore from '../../store/AppStore';
import SampleLibraryImages from './sampleLibraryImages.json';
import DisplayCarousel from '../displayCarousel/DisplayCarousel';
import RecommendedCard from '../recommendedCard/RecommendedCard';

/**
 * Represents the props for the `PackPurchaseSummaryModal` component.
 *
 * @interface
 * @property {boolean} open - Controls the visibility of the modal.
 * @property {() => void} onClose - Callback function when the modal is closed.
 * @property {() => void} [setOpen] - Callback function to set the modal visibility.
 */
type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
} & React.PropsWithChildren;

const SubHeading = styled(Typography)({
  color: '#250503',
  fontFamily: 'Passero One',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '.1px',
});

const SmallText = styled(Typography)({
  color: '#250503',
  fontFamily: 'DM Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
});

const CustomButton = styled(Button)({
  width: '165px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '45px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
});

const SmallImg = styled('img')({
  height: '20px',
  width: '20px',
  objectFit: 'contain',
});

const BigImg = styled('img')({
  height: '25px',
  width: '25px',
  objectFit: 'contain',
});

/**
 * Represents a modal for pack purchase summary.
 *
 * @component
 * @param {RWModalProps} props - The properties of the `PackPurchaseSummaryModal` component.
 * @returns {JSX.Element} - The rendered `PackPurchaseSummaryModal` component.
 */
function PackPurchaseSummaryModal({
  open,
  onClose,
  setOpen,
}: RWModalProps) {
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
  };

  const userCreditBalance:
  number = appStore(
    (state) => state.userCredits,
  );

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(4px)',
          },
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '70%',
          right: '14px',
          transform: 'translate(0%, -70%)',
          width: '968px',
          background: '#FFF',
          padding: '20px 20px 55px 55px',
          boxSizing: 'border-box',
        }}
      >
        {/* close button */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          <Button
            sx={{
              display: 'flex',
              padding: '0',
              color: 'white',
              height: '17px',
              minWidth: '1px',
              background: 'transparent',
              '&:hover': {
                background: 'transparent',
              },
            }}
            onClick={() => handleClose()}
          >
            <CloseIcon sx={{ color: '#250503' }} />
          </Button>
        </Stack>
        {/* carousel and purchase */}
        <Stack direction="row">
          {/* carousel */}
          <Box height="525px" width="460px">
            <DisplayCarousel packImages={SampleLibraryImages} />
          </Box>
          {/* summary and purchase */}
          <Box p="28px 14px">
            <Stack gap="19px">
              <Typography
                sx={{
                  color: '#250503',
                  fontFamily: 'Passero One',
                  fontSize: '36px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px', /* 55.556% */
                  letterSpacing: '.1px',
                }}
              >
                Sacred Mother
              </Typography>
              <SmallText
                sx={{
                  color: '#250503',
                  fontFamily: 'DM Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  width: '360px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut ornare lectus sit amet est.
              </SmallText>
              <Box>
                <SubHeading> Cost </SubHeading>
                <Stack direction="row">
                  <SmallImg src={CoinIcon} />
                  <SmallText> 10 coins</SmallText>
                </Stack>
                <SmallText> $2 dollars</SmallText>
              </Box>
              <Box>
                <SubHeading> Coin Balance </SubHeading>
                <Stack direction="row" alignItems="center" gap="13px">
                  <TokenBalanceWrapper>
                    <BigImg src={CoinIcon} />
                    <SmallText>{userCreditBalance}</SmallText>
                    <SmallImg src={PlusIcon} />
                  </TokenBalanceWrapper>
                  <Typography
                    sx={{
                      color: '#250503',
                      fontFamily: 'Passero One',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      background: '#D0B17A',
                      borderRadius: '10px',
                      padding: '10px',
                    }}
                  >
                    Use Balance
                  </Typography>
                </Stack>
              </Box>
              <Stack direction="row" alignItems="center" gap="25px">
                <CustomButton
                  sx={{
                    color: 'white',
                    background: '#D0B17A',
                    '&:hover': {
                      background: '#D0B17A',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: '#250503',
                      fontFamily: 'Passero One',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                    }}
                  >
                    Purchase
                  </Typography>
                </CustomButton>
                <CustomButton
                  sx={{
                    color: 'white',
                    background: '#41521F',
                    '&:hover': {
                      background: '#41521F',
                    },
                  }}
                >
                  <Stack direction="row" alignItems="center" gap="10px">
                    <Typography
                      sx={{
                        color: 'white',
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                      }}
                    >
                      Add Cart
                    </Typography>
                    <BigImg src={ShoppingCartIcon} />
                  </Stack>
                </CustomButton>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {/* recommended for you */}
        <Stack gap="20px" pt="43px">
          <SubHeading sx={{ fontSize: '25px!important' }}>
            Recommended for you
          </SubHeading>
          <Stack width="100%" height="140px" direction="row" flexWrap="nowrap" gap="50px">
            <RecommendedCard
              title="Sorcerers"
              width="33%"
              featuredImages={SampleLibraryImages.slice(0, 3)}
              borderColor="#250503"
            />
            <RecommendedCard
              title="Philosophers"
              width="33%"
              featuredImages={SampleLibraryImages.slice(2, 5)}
              borderColor="#250503"
            />
            <RecommendedCard
              title="High Priests"
              width="33%"
              featuredImages={SampleLibraryImages.slice(6, 9)}
              borderColor="#250503"
            />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

/**
 * Default props for the `PackPurchaseSummaryModal` component.
 */
PackPurchaseSummaryModal.defaultProps = {
  setOpen: () => {},
};

export default PackPurchaseSummaryModal;
