import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function ShoppingCartIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${styles.icon} ${styles.shop_icon} ${styles[theme]} ${active && styles.active}`}
      aria-label="shopping cart"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
      >
        <path className={styles.shop_cls_1} d="m7.71,12.05h8.35c.73,0,1.39-.39,1.75-1.02l4.05-7.21c.42-.75-.12-1.67-.98-1.67H4.63s-.89-2.15-.89-2.15H0v2.18h2.28l3.6,8.67-1.32,2.07c-.98,1.54.12,3.55,1.94,3.55h13.28v-2.18H6.61c-.14,0-.23-.16-.15-.28l1.25-1.96Zm-2.17-7.72h13.53s-3.11,5.54-3.11,5.54H7.84s-2.3-5.54-2.3-5.54Z" />
        <circle className={styles.shop_cls_1} cx="6.57" cy="19.78" r="2.22" />
        <circle className={styles.shop_cls_1} cx="17.57" cy="19.78" r="2.22" />
      </svg>
    </button>
  );
}

export default ShoppingCartIcon;
