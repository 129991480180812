import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
};

export default function ThankYouModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = (e: Event, r: 'backdropClick' | 'escapeKeyDown') => { onClose?.(e, r); setOpen(false); };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: '1080px',
              height: '406px',
              background: '#fff',
              borderRadius: 4,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: '1080px',
                height: '130px',
                background: '#41521F',
                mb: 10,
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
              sx={{
                height: '100%',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Passero One',
                  fontSize: '36px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                }}
              >
                Thank you for your purchase!

              </Typography>
              {' '}
&nbsp;
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Passero One',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '30px',
                  letterSpacing: '0.1px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Passero One',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '30px',
                  letterSpacing: '0.1px',
                }}
              >
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignContent="center"
              sx={{
                height: '100%',
                textAlign: 'center',
              }}
            >

              <LoadingButton
                type="submit"
                variant="contained"
                onClick={() => { console.log('on click!'); }}
          // loading={authLoading} TODO: Implement loading state when button is made functional
                sx={{
                  mt: 1,
                  mb: 1,
                  height: 40,
                  width: '157px',
                  borderRadius: '5px',
                  background: '#41521F',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  fontFamily: 'Passero One',
                  whiteSpace: 'nowrap',
                }}
              >
                Character Catalog

              </LoadingButton>
              &nbsp;&nbsp;&nbsp;
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={() => { console.log('on click!'); }}
          // loading={authLoading} TODO: Implement loading state when button is made functional
                sx={{
                  mt: 1,
                  mb: 1,
                  height: 40,
                  width: '157px',
                  borderRadius: '5px',
                  background: '#41521F',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  fontFamily: 'Passero One',
                  whiteSpace: 'nowrap',
                }}
              >
                Download Assets

              </LoadingButton>
              &nbsp;&nbsp;&nbsp;
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={() => { console.log('on click!'); }}
          // loading={authLoading} TODO: Implement loading state when button is made functional
                sx={{
                  mt: 1,
                  mb: 1,
                  height: 40,
                  width: '157px',
                  borderRadius: '5px',
                  background: '#41521F',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  fontFamily: 'Passero One',
                  whiteSpace: 'nowrap',
                }}
              >
                RW Character Store

              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
