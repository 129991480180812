/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  Input,
  TextField,
} from '@mui/material';
import { NotificationActions } from '../../../redux/notification';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import { CreateTokenSetDocument, Token_Set, Token_SetInsertInput } from '../../../__generated__/graphql';
import { useAuth } from '../../../hooks/use-auth';
import styles from './newtokensetform.module.scss';
import { ThemeContext } from '../../../context/ThemeContext';

interface Props {
  newTokenSet: Token_SetInsertInput;
  handleEditNewTokenSetName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditNewTokenSetDescription: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleEditNewTokenSetThumbnailUrl: (event: React.ChangeEvent<HTMLInputElement>) => void;
  realmID: string;
  closeNewTokenSetForm: () => void;
  showStatus: (open: boolean, message: string, status: string, timeout: number) => void;
  appendNewTokenSet: (tokenSet: Token_Set) => void;
}

export default function NewTokenSetForm({
  newTokenSet,
  handleEditNewTokenSetName,
  handleEditNewTokenSetDescription,
  handleEditNewTokenSetThumbnailUrl,
  realmID,
  closeNewTokenSetForm,
  showStatus,
  appendNewTokenSet,
}: Props) {
  const { theme } = useContext(ThemeContext);
  const auth = useAuth();
  const {
    data: createdTokenSet,
    mutate: createTokenSet,
    error: createTokenSetError,
    status: createTokenSetStatus,
  } = useGraphQLMutation(CreateTokenSetDocument);
  const dispatch = useDispatch();

  if (createTokenSetError) console.log('error creating token set: ', createTokenSetError);

  const handleSubmitNewTokenSet = (event: React.FormEvent) => {
    event.preventDefault();
    createTokenSet({
      data: {
        name: newTokenSet.name,
        description: newTokenSet.description,
        thumbnail_url: newTokenSet.thumbnail_url,
        realm: {
          link: realmID,
        },
        owner: {
          link: auth?.realmUser?.id ?? '',
        },
      },
    });
  };

  React.useEffect(() => {
    if (createTokenSetStatus === 'success') {
      dispatch(
        NotificationActions.addNotification({
          open: true,
          type: 'success',
          message: `${createdTokenSet.insertOneToken_set?.name} token set created successfully!`,
          timeout: 5000,
        }),
      );
      showStatus(true, `${createdTokenSet.insertOneToken_set?.name} token set created successfully!`, 'success', 5000);
      appendNewTokenSet(createdTokenSet.insertOneToken_set as Token_Set);
      closeNewTokenSetForm();
    } else if (createTokenSetStatus === 'error') {
      dispatch(
        NotificationActions.addNotification({
          open: true,
          type: 'error',
          message: `${newTokenSet.name} token set creation failed!`,
          timeout: 5000,
        }),
      );
      showStatus(true, `${newTokenSet.name} token set creation failed!`, 'error', 5000);
    }
  }, [createTokenSetStatus]);

  return (
    <form onSubmit={handleSubmitNewTokenSet}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <FormControl>
          <Input
            value={newTokenSet.name}
            placeholder="Enter token set name here"
            className={`save_modal_input ${styles.save_modal_input} ${styles[theme]}`}
            sx={{ mb: 2 }}
            onChange={handleEditNewTokenSetName}
          />
        </FormControl>
        <FormControl>
          <TextField
            value={newTokenSet.description}
            placeholder="Enter token set description here"
            className={`save_modal_text_area ${styles[theme]}`}
            sx={{ mb: 2 }}
            onChange={handleEditNewTokenSetDescription}
            variant="standard"
          />
        </FormControl>
        <FormControl>
          <Input
            value={newTokenSet.thumbnail_url}
            placeholder="Enter token set thumbnail Url here"
            className={`save_modal_input ${styles.save_modal_input} ${styles[theme]}`}
            sx={{ mb: 2 }}
            onChange={handleEditNewTokenSetThumbnailUrl}
          />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '400px' }}>
          <Button sx={{ p: '10px 20px' }} type="submit" className={`save_modal_button ${styles.save_modal_button} ${styles[theme]}`} disabled={newTokenSet.name === '' || newTokenSet.description === '' || newTokenSet.thumbnail_url === ''}> Create New Token Set</Button>
          <Button variant="contained" color="error" sx={{ p: '10px 20px' }} disabled={createTokenSetStatus === 'loading'} onClick={closeNewTokenSetForm}> Back </Button>
        </Box>
      </Box>
    </form>
  );
}
