/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Box, Button, Modal, Stack, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomBorder from '../../images/custom-border-image-1.webp';
import BookmarkImage from '../../images/bookmark.webp';
import backgroundImage from '../../images/pcs-background-1.webp';
import KindredIcon from '../../images/kindred.svg';
import EditIcon from '../../images/edit.webp';
import NotesClosed from '../../images/notes-deselected.webp';
import NotesOpen from '../../images/notes-selected.webp';
import CharacterDPPlaceholder from '../../images/character-placeholder-2.webp';
import {
  AttributeText,
  AttributeWrapper,
  BackgroundContentWrapper,
  BookmarkImg, BookmarkTitleImg, CharacterDPImage,
  CustomScrollWrapper,
  GoalContentWrapper,
  ImageBackgroundWrapper, SectionHeadingText, SmallHeadingText,
} from '../styledComps/styledComps';
import CharacterAbilitiesGen from '../realms/Character/CharacterAbilitiesGen';
import sampleNotes from '../notes/characternotesample.json';
import CharacterNotesWrapper from '../notes/CharacterNotesWrapper';
import CharacterNoteView from '../notes/CharacterNoteView';
import { Character } from '../../__generated__/graphql';
import appStore from '../../store/AppStore';

type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
} & React.PropsWithChildren;

/**
 * Represents a modal for displaying a summary of a character.
 *
 * @component
 * @example
 * // Example usage of CharacterSummaryModal component
 * <CharacterSummaryModal open={true} onClose={() => {}} onConfirm={() => {}} />
 *
 * @param {object} props - The properties of the component.
 * @param {boolean} props.open - Determines if the modal is open.
 * @param {() => void} props.onClose - Callback function to close the modal.
 * @param {() => void} [props.setOpen] - Optional callback to set the open state.
 * @param {() => void} props.onConfirm - Callback function when confirming an action.
 * @returns {JSX.Element} React element representing a character summary modal.
 */
function CharacterSummaryModal({
  open,
  onClose,
  setOpen,
}: RWModalProps) {
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
  };
  const [notesPanelOpen, setNotesPanelOpen] = React.useState(false);
  const selectedCharacter:
  Character | null = appStore(
    (state) => state.selectedCharacter,
  );
  console.log(selectedCharacter);

  const sampleAttributes = [
    {
      key: 'Age',
      value: 'Not Available',
    },
    {
      key: 'Gender',
      value: selectedCharacter?.gender ?? 'None selected',
    },
    {
      key: 'Pronouns',
      value: selectedCharacter?.pronoun ?? 'None selected',
    },
    {
      key: 'Race',
      value: selectedCharacter?.race ?? 'Tiefling',
    },
    {
      key: 'Class',
      value: selectedCharacter?.class ?? 'None selected',
    },
    {
      key: 'Alignment',
      value: selectedCharacter?.alignment ?? 'None selected',
    },
    {
      key: 'Motivations',
      value: selectedCharacter?.motivations
        ? selectedCharacter.motivations.map((item) => `${item}`).join(', ') : 'Be free',
    },
    {
      key: 'Personality Type',
      value: 'Not Available',
    },
  ];

  const sampleBackground = [
    {
      key: 'Professions',
      value: selectedCharacter?.profession ?? 'None selected',
    },
    {
      key: 'Relationship',
      value: selectedCharacter?.relationship_status ?? 'None selected',
    },
    {
      key: 'Life Altering Event',
      value: selectedCharacter?.life_altering_event ?? 'None selected',
    },
  ];

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(4px)',
          },
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '70%',
          right: '14px',
          transform: 'translate(0%, -70%)',
          display: 'flex',
          border: 'none',
          outline: 'none',
        }}
      >
        <Box
          sx={{
            width: '1071px',
            height: '926px',
            outline: 'none',
            // overflow: 'hidden',
            boxSizing: 'border-box',
            backgroundSize: 'cover',
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: 'center center',
            '&::before': {
              content: '""',
              width: '1071px',
              height: '926px',
              display: 'block',
              boxSizing: 'border-box',
              position: 'absolute',
              top: '0',
              left: '0',
              border: '8px solid #68583D',
              borderImageSource: `url(${CustomBorder})`,
              borderImageSlice: '17',
              borderImageRepeat: 'round',
              backgroundColor: '#D0B17ACC',
              objectFit: 'cover',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: 'calc(100% + 45px)',
              boxSizing: 'border-box',
              '&:before, &:after': {
                boxSizing: 'inherit',
              },
              pb: '20px',
            }}
          >
            {/* Bookmark Title */}
            <Box
              sx={{
                position: 'relative',
                width: '360px',
                height: '64px',
                mt: '-45px',
                zIndex: '10',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                zIndex={2}
                position="absolute"
                left="44px"
                top="50%"
                sx={{
                  transform: 'translate(0%, -50%)',
                }}
              >
                <BookmarkTitleImg src={KindredIcon} />
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Passero One',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  Character Summary
                </Typography>
              </Stack>
              <BookmarkImg src={BookmarkImage} />
            </Box>
            <Stack px="30px">
              {/* edit and notes and close button */}
              <Stack
                direction="row"
                gap="15px"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
              >
                <Button
                  sx={{
                    display: 'flex',
                    padding: '0',
                    color: 'white',
                    height: '33px',
                    width: '33px',
                    borderRadius: '50%',
                    minWidth: '1px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white',
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                >
                  <img src={EditIcon} alt="edit" style={{ height: '21px', width: 'auto' }} />
                </Button>
                <Button
                  sx={{
                    display: 'flex',
                    padding: '0',
                    color: 'white',
                    height: '33px',
                    width: '33px',
                    borderRadius: '50%',
                    minWidth: '1px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: notesPanelOpen ? '#41521F' : 'white',
                    '&:hover': {
                      background: notesPanelOpen ? '#41521F' : 'white',
                    },
                  }}
                  onClick={() => setNotesPanelOpen(!notesPanelOpen)}
                >
                  <img
                    src={notesPanelOpen ? NotesOpen : NotesClosed}
                    alt="edit"
                    style={{ height: '21px', width: 'auto' }}
                  />
                </Button>
                <Button
                  sx={{
                    display: 'flex',
                    padding: '0',
                    color: 'white',
                    height: '17px',
                    minWidth: '1px',
                    background: 'transparent',
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  onClick={() => handleClose()}
                >
                  <CloseIcon sx={{ color: '#250503' }} />
                </Button>
              </Stack>
              {/* Abilities and Info stack */}
              <Stack direction="row" justifyContent="space-between" pt="10px">
                <Box width="136px">
                  <CharacterAbilitiesGen
                    variant="characterSummary"
                    abilities={selectedCharacter?.abilities}
                    setCharacterAbilities={() => {}}
                  />
                </Box>
                <Stack width="795px" p={0} gap="38px">
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="flex-end"
                    sx={{
                      background: '#E3D5BC',
                      borderRadius: '4px',
                      position: 'relative',
                      minHeight: '221px',
                    }}
                  >
                    <ImageBackgroundWrapper>
                      <CharacterDPImage
                        src={selectedCharacter?.avatar_url
                          ?? CharacterDPPlaceholder}
                      />
                    </ImageBackgroundWrapper>
                    <Stack width="492px">
                      <Typography
                        sx={{
                          color: '#250503',
                          fontFamily: 'Passero One',
                          fontSize: '40px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '45px',
                          pt: '10px',
                        }}
                      >
                        {selectedCharacter?.name ?? 'Character Name'}
                      </Typography>
                      <Stack direction="row" columnGap=".3%" rowGap="15px" width="100%" flexWrap="wrap" pb="10px">
                        {sampleAttributes.map((attr) => (
                          <Box key={attr.key} width="33%">
                            <SmallHeadingText>{attr.key}</SmallHeadingText>
                            <AttributeText textTransform="capitalize">{attr.value}</AttributeText>
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                  {/* Background */}
                  <AttributeWrapper width="100%" sx={{ px: '0!important', pb: '0!important' }}>
                    <SectionHeadingText textAlign="center" pt="11px">Background</SectionHeadingText>
                    <Stack direction="row" mt="15px" gap=".3%" px="37px">
                      {sampleBackground.map((background) => (
                        <Box key={background.key} width="33%">
                          <SmallHeadingText>{background.key}</SmallHeadingText>
                          <AttributeText>{background.value}</AttributeText>
                        </Box>
                      ))}
                    </Stack>
                    <Stack direction="row" mt="15px" borderTop="solid 1px #BCAD93">
                      <Box
                        p="10px 26px 17px 37px"
                        borderRight="solid 1px #BCAD93"
                        boxSizing="border-box"
                        width="50%"
                      >
                        <SmallHeadingText>Birth Story</SmallHeadingText>
                        <BackgroundContentWrapper
                          height="125px"
                          py="5px"
                        >
                          <CustomScrollWrapper
                            sx={{
                              height: '100%',
                            }}
                          >
                            <Box borderRight="solid 1px #BCAD93" p="12px 30px 12px 12px">
                              <AttributeText>
                                {selectedCharacter?.backstory ?? 'No back story available'}
                              </AttributeText>
                            </Box>
                          </CustomScrollWrapper>
                        </BackgroundContentWrapper>
                      </Box>
                      <Box
                        p="10px 37px 17px 26px"
                        boxSizing="border-box"
                        width="50%"
                      >
                        <SmallHeadingText>Parents (Lineage)</SmallHeadingText>
                        <BackgroundContentWrapper
                          height="125px"
                          py="5px"
                        >
                          <CustomScrollWrapper
                            sx={{
                              height: '100%',
                            }}
                          >
                            <Box borderRight="solid 1px #BCAD93" p="12px 30px 12px 12px">
                              <AttributeText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip. ex
                              </AttributeText>
                            </Box>
                          </CustomScrollWrapper>
                        </BackgroundContentWrapper>
                      </Box>
                    </Stack>
                  </AttributeWrapper>
                  {/* Goals */}
                  <AttributeWrapper width="100%">
                    <SectionHeadingText textAlign="center" pt="11px">Goals</SectionHeadingText>
                    <Stack mt="15px" px="25px" direction="row" gap="50px">
                      {/* short term goals */}
                      <Box width="50%">
                        <Stack
                          gap="35px"
                          alignItems="center"
                          direction="row"
                          justifyContent="space-between"
                          sx={{
                            width: '100%',
                            borderRadius: '4px 4px 0px 0px',
                            background: '#41521F',
                            padding: '5px 23px',
                            boxSizing: 'border-box',
                            position: 'relative',
                          }}
                        >
                          <Typography
                            sx={{
                              color: 'white',
                              fontFamily: 'DM Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                              letterSpacing: '0.46px',
                              textTransform: 'capitalize',
                              textAlign: 'left',
                              width: 'fit-content',
                            }}
                          >
                            Short Term Goals
                          </Typography>
                          <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'white' }} />
                        </Stack>
                        <GoalContentWrapper
                          height="127px"
                          py="5px"
                        >
                          <CustomScrollWrapper
                            sx={{
                              height: '100%',
                            }}
                          >
                            <Box borderRight="solid 1px #BCAD93" p="0">
                              {selectedCharacter?.short_term_goals !== undefined
                              && selectedCharacter?.short_term_goals !== null
                              && selectedCharacter?.short_term_goals.map((note) => (
                                note !== null && <CharacterNoteView note={note} />
                              ))}
                            </Box>
                          </CustomScrollWrapper>
                        </GoalContentWrapper>
                      </Box>
                      {/* long term goals */}
                      <Box width="50%">
                        <Stack
                          gap="35px"
                          alignItems="center"
                          direction="row"
                          justifyContent="space-between"
                          sx={{
                            width: '100%',
                            borderRadius: '4px 4px 0px 0px',
                            background: '#41521F',
                            padding: '5px 23px',
                            boxSizing: 'border-box',
                            position: 'relative',
                          }}
                        >
                          <Typography
                            sx={{
                              color: 'white',
                              fontFamily: 'DM Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                              letterSpacing: '0.46px',
                              textTransform: 'capitalize',
                              textAlign: 'left',
                              width: 'fit-content',
                            }}
                          >
                            Long Term Goals
                          </Typography>
                          <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'white' }} />
                        </Stack>
                        <GoalContentWrapper
                          height="127px"
                          py="5px"
                        >
                          <CustomScrollWrapper
                            sx={{
                              height: '100%',
                            }}
                          >
                            <Box borderRight="solid 1px #BCAD93" p="0">
                              {selectedCharacter?.long_term_goals !== undefined
                              && selectedCharacter?.long_term_goals !== null
                              && selectedCharacter?.long_term_goals.map((note) => (
                                note !== null && <CharacterNoteView note={note} />
                              ))}
                            </Box>
                          </CustomScrollWrapper>
                        </GoalContentWrapper>
                      </Box>
                    </Stack>
                  </AttributeWrapper>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
        {notesPanelOpen && (
          <Box sx={{ width: '410px' }}>
            <CharacterNotesWrapper
              title="Notes"
              notes={sampleNotes}
              variant="card"
              borderTopLeft={0}
              setNotes={() => {}}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
}

CharacterSummaryModal.defaultProps = {
  setOpen: () => {},
};

export default CharacterSummaryModal;
