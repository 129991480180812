import React from 'react';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Stack } from '@mui/material';
import styled from '@emotion/styled';

/**
 * @typedef {Object} Props
 * @property {any[]} packImages - An array of images for the carousel.
 */
interface Props {
  packImages: any[],
}

const SelectedImg = styled('img')({
  width: '396px',
  height: '393px',
  objectFit: 'cover',
});

const CarouselImg = styled('img')({
  width: '70px',
  height: '80px',
  objectFit: 'cover',
  cursor: 'pointer',
});

const NavArrow = styled(ArrowForwardIosRoundedIcon)({
  color: '#d9d9d9',
  cursor: 'pointer',
  transition: 'ease-in-out .3s',
  '&:hover': {
    color: '#585858',
  },
});

/**
 * Represents the main display carousel component.
 *
 * @component
 * @param {Props} props - The properties of the `DisplayCarousel` component.
 * @returns {JSX.Element} - The rendered `DisplayCarousel` component.
 */
function DisplayCarousel({
  packImages,
}: Props) {
  const [selectedImage, setSelectedImage] = React.useState<string>(packImages[0].url);
  /**
   * Represents the translateX value for the carousel slide animation.
   */
  const [translateX, setTranslateX] = React.useState<number>(0);

  /**
   * Handles the carousel swipe based on the specified direction.
   *
   * @param {('forward' | 'backward')} direction - The direction of the carousel swipe.
   * @returns {void}
   */
  const handleCarouselSwipe = (direction: 'forward' | 'backward') => {
    const carouselPosition = (-translateX) - 70;
    const carouselWidth = (packImages.length * 70) + ((packImages.length - 2) * 20);
    console.log(carouselPosition, carouselWidth);
    if (direction === 'forward') {
      if ((-carouselPosition) < (carouselWidth)) {
        setTranslateX(translateX + 90);
      }
    }
    if (direction === 'backward') {
      if (carouselPosition < -70) {
        setTranslateX(translateX - 90);
      }
    }
  };
  return (
    <Stack gap="20px" justifyContent="center" alignItems="center">
      <SelectedImg src={selectedImage} />
      <Box width="100%">
        <Stack direction="row" gap="20px" flexWrap="nowrap">
          <Stack width="40px" justifyContent="center" alignItems="center">
            <NavArrow
              sx={{ transform: 'rotate(180deg)' }}
              onClick={() => handleCarouselSwipe('backward')}
            />
          </Stack>
          <Box width="340px" overflow="hidden">
            <Stack
              direction="row"
              gap="20px"
              flexWrap="nowrap"
              sx={{
                transform: `translateX(${translateX * -1}px)`,
                transition: 'ease-in-out .3s',
              }}
            >
              {packImages.map((packImage) => (
                <Box
                  key={packImage._id}
                  onClick={() => setSelectedImage(packImage.url)}
                >
                  <CarouselImg src={packImage.url} />
                </Box>
              ))}
            </Stack>
          </Box>
          <Stack width="40px" justifyContent="center" alignItems="center">
            <NavArrow onClick={() => handleCarouselSwipe('forward')} />
          </Stack>
        </Stack>
      </Box>
      <Stack direction="row" gap="10px">
        {packImages.map((packImage, index) => (
          <Box
            key={packImage._id}
            onClick={() => {
              setTranslateX(index * 90);
              setSelectedImage(packImage.url);
            }}
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: translateX === index * 90 ? '#d9d9d9' : '#585858',
              cursor: 'pointer',
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default DisplayCarousel;
