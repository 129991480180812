import {
  Box, ListItemIcon, ListItemText, Typography, styled,
} from '@mui/material';

export const StyledImage = styled('img')({
  width: '45px',
  height: '45px',
  flexShrink: 0,
});

export const StyledAvatar = styled('img')({
  width: '40px',
  height: '40px',
  p: 0.5,
  flexShrink: 0,
});
export const StyledMembershipCard = styled('img')({
  flexShrink: 0,
});
export const StyledUserName = styled(Typography)({
  color: '#1D1B20',
  textAlign: 'center',
  fontFamily: 'Passero One',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});
export const StyledListItemIcon = styled(ListItemIcon)({
  width: '45px',
  height: '45px',
  flexShrink: 0,
});
export const StyledListItemText = styled(ListItemText)({
  color: '#250503',
  textAlign: 'center',
  fontFamily: 'Passero One, sans-serif',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  '& .MuiTypography-root': {
    fontFamily: 'Passero One',
  },
});
export const StyledText = styled(Typography)({
  align: 'center',
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Passero One',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const VerticalSpacingBox = styled(Box)(({ theme }) => ({
  padding: '5px',
  display: 'grid',
  gap: theme.spacing(1),
  height: '100vh', // Adjust the value as needed
}));
