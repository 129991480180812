/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Button, Card, CardContent, CardMedia, Grid, Typography, styled,
} from '@mui/material';
import React from 'react';
import './CarouselCard.styles.css';

const StyledTitle = styled(Typography)({
  width: '45px',
  color: '#250503',
  fontFamily: 'Passero One',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textDecorationLine: 'underline',
});

const mockData = [1, 2, 3, 4, 5, 6, 7, 8];

const CarouselCard = mockData?.map((result: any) => (
  <Grid
    item
    xs={3}
    sx={{
      p: 0.5,
    }}
    key={result}
  >

    <Card
      sx={{
        display: 'flex',
        borderRadius: '5px',
        background: '#FFF',
        border: '1px solid white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box sx={{
        flex: 1, width: 360, maxHeight: 155, position: 'relative',
      }}
      >

        <CardMedia
          component="img"
          image="https://picsum.photos/200/200"
          alt="Live from space album cover"
        />
        <Typography sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textDecoration: 'underline',
          color: '#FFF',
          fontFamily: 'Passero One',
          fontSize: '40px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}
        >
          #TAG

        </Typography>
      </Box>
    </Card>
  </Grid>
));

export default CarouselCard;
