import React from 'react';
import useGraphQL from '../../../hooks/useGraphQL';
import { GetTokenDocument, Token } from '../../../__generated__/graphql';
import ExpansionTokenItem from './ExpansionTokenItem';

interface Props {
  tokenID: string;
}

function ExpansionFetchTokenItem({ tokenID }: Props) {
  const { data: token } = useGraphQL(
    GetTokenDocument,
    {
      query: {
        _id: tokenID,
      },
    },
  );
  // console.log(tokenID, token);

  if (token?.token === undefined) return null;

  return (
    <ExpansionTokenItem token={token?.token as Token} key={`fetch ${tokenID}`} />
  );
}

export default ExpansionFetchTokenItem;
