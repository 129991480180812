import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { ReactiveBase, ReactiveList, SearchBox } from '@appbaseio/reactivesearch';
import Expansion from '../../panel/Expansion/Expansion';
import BackArrow from '../../../images/back.webp';
import FilterIcon from '../../../images/filter-slider.webp';
import CharacterLibraryExpansionItem from './CharacterLibraryExpansionItem';

interface Props {
  handleCloseLibraryView: () => void,
  SampleLibraryImages: any[],
  previewBackground: string,
  handleLibraryImageClick: (link: string) => void,
}

const FilterImg = styled('img')({
  width: '30px',
  height: '30px',
  flexShrink: 0,
});

/**
 * Represents a React component for the library view of the Create Character Set Modal - thumbnails.
 *
 * @component
 * @example
 * // Example usage of CharacterLibraryView component
 * <CharacterLibraryView
 *   handleCloseLibraryView={handleCloseLibraryViewFunction}
 *   SampleLibraryImages={sampleLibraryImagesArray}
 *   previewBackground="backgroundImageUrl"
 *   handleLibraryImageClick={handleLibraryImageClickFunction}
 * />
 *
 * @param {Props} param0 - The component properties.
 * @param {() => void} param0.handleCloseLibraryView - Callback for closing the library view.
 * @param {{}} param0.SampleLibraryImages - Array of sample library images.
 * @param {string} param0.previewBackground - URL of the preview background.
 * @param {(link: string) => void} param0.handleLibraryImageClick
 * - Callback for handling library image click.
 * @returns {*}
 */
function CharacterLibraryView({
  handleCloseLibraryView,
  SampleLibraryImages,
  previewBackground,
  handleLibraryImageClick,
}: Props) {
  const [libraryPackExpanded, setLibraryPackExpanded] = React.useState(false);
  return (
    <>
      <Stack spacing="15px" direction="row" alignItems="center">
        <Button
          variant="contained"
          sx={{
            p: '0',
            background: 'transparent',
            boxShadow: 'none',
            outline: 'none',
            '&:hover': {
              background: 'transparent',
              boxShadow: 'none',
            },
          }}
          onClick={handleCloseLibraryView}
        >
          <img
            src={BackArrow}
            alt="back arrow"
            style={{ width: '50px', height: 'auto' }}
          />
        </Button>
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        >
          Select a Thumbnail
        </Typography>
      </Stack>
      <ReactiveBase
        app="realms_index"
        url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
        transformRequest={(request: any) => ({
          ...request,
          headers: {
            ...request.headers,
            Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
          },
        })}
        // @ts-ignore
        mongodb={{
          collection: 'realms',
          db: 'realm_worlds',
        }}
        analytics={false}
        credentials="none"
      >
        <Stack direction="row" gap="30px" alignItems="center" flexWrap="nowrap" width="100%">
          <SearchBox
            componentId="mycharacterpackslibrary-searchbox"
            iconPosition="right"
            dataField={['name']}
            includeFields={['*']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            className="my_custom_searchbox cpp"
          />
          <Button
            sx={{
              background: '#41521F',
              padding: '10px',
              boxSizing: 'border-box',
              color: '#ffffffcc',
              minWidth: '30px',
              '&:hover': {
                background: '#41521F',
              },
            }}
            // onClick={() => setShowFilter(!showFilter)}
          >
            <FilterImg src={FilterIcon} />
          </Button>
        </Stack>
        <ReactiveList
          componentId="mycharacterpackslibrary-results"
          dataField="_score"
          size={10}
          pagination
          innerClass={{
            pagination: 'list_pagination',
            resultStats: 'list_resultStats',
            resultsInfo: 'list_resultsInfo',
          }}
          className="dashboard_reactive_list cpp"
          compoundClause="must"
          react={{
            and: ['discover-searchbox'],
          }}
          onError={(err) => console.log(err)}
          sortOptions={[
            {
              dataField: 'updated_at',
              sortBy: 'desc',
              label: 'Recent',
            },
            {
              dataField: 'updated_at',
              sortBy: 'asc',
              label: 'Oldest',
            },
            {
              dataField: 'name',
              sortBy: 'asc',
              label: 'Name - A-Z',
            }, {
              dataField: 'name',
              sortBy: 'desc',
              label: 'Name - Z-A',
            }]}
          showResultStats={false}
          render={({
            data,
          }: any) => (
            <>
              {console.log('placeholder: ', data)}
              <Box
                sx={{
                  height: '400px',
                  maxHeight: '400px',
                  overflowY: 'scroll',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '40px',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                    backgroundColor: '#CFCAAD',
                    borderRadius: '5px',
                  },
                  '&.scrollbar': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#CFCAAD',
                    backgroundColor: '#CFCAAD',
                    borderRadius: '5px',
                  },
                  '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                    {
                      backgroundColor: '#41521F',
                      borderRadius: '5px',
                    },
                }}
              >
                <Expansion
                  title="Orc Essentials Pack"
                  isExpanded={libraryPackExpanded}
                  setIsExpanded={() => setLibraryPackExpanded(!libraryPackExpanded)}
                  variant="character"
                >
                  <Stack gap="15px" direction="row" alignItems="center" flexWrap="wrap">
                    {SampleLibraryImages.slice(0, 4).map((libraryImage) => (
                      <CharacterLibraryExpansionItem
                        key={libraryImage._id}
                        previewUrl={previewBackground}
                        imageUrl={libraryImage.url}
                        handleLibraryImageClick={(link: string) => (
                          handleLibraryImageClick(link)
                        )}
                      />
                    ))}
                    {libraryPackExpanded && (
                      SampleLibraryImages.slice(4).map((libraryImage) => (
                        <CharacterLibraryExpansionItem
                          key={libraryImage._id}
                          previewUrl={previewBackground}
                          imageUrl={libraryImage.url}
                          handleLibraryImageClick={(link: string) => (
                            handleLibraryImageClick(link)
                          )}
                        />
                      )))}
                  </Stack>
                </Expansion>
                <Expansion
                  title="Orc Essentials Pack"
                  isExpanded={libraryPackExpanded}
                  setIsExpanded={() => setLibraryPackExpanded(!libraryPackExpanded)}
                  variant="character"
                >
                  <Stack gap="15px" direction="row" alignItems="center" flexWrap="wrap">
                    {SampleLibraryImages.slice(0, 4).map((libraryImage) => (
                      <CharacterLibraryExpansionItem
                        key={libraryImage._id}
                        previewUrl={previewBackground}
                        imageUrl={libraryImage.url}
                        handleLibraryImageClick={(link: string) => (
                          handleLibraryImageClick(link)
                        )}
                      />
                    ))}
                    {libraryPackExpanded && (
                      SampleLibraryImages.slice(4).map((libraryImage) => (
                        <CharacterLibraryExpansionItem
                          key={libraryImage._id}
                          previewUrl={previewBackground}
                          imageUrl={libraryImage.url}
                          handleLibraryImageClick={(link: string) => (
                            handleLibraryImageClick(link)
                          )}
                        />
                      ))
                    )}
                  </Stack>
                </Expansion>
                <Expansion
                  title="Orc Essentials Pack"
                  isExpanded={libraryPackExpanded}
                  setIsExpanded={() => setLibraryPackExpanded(!libraryPackExpanded)}
                  variant="character"
                >
                  <Stack gap="15px" direction="row" alignItems="center" flexWrap="wrap">
                    {SampleLibraryImages.slice(0, 4).map((libraryImage) => (
                      <CharacterLibraryExpansionItem
                        key={libraryImage._id}
                        previewUrl={previewBackground}
                        imageUrl={libraryImage.url}
                        handleLibraryImageClick={(link: string) => (
                          handleLibraryImageClick(link)
                        )}
                      />
                    ))}
                    {libraryPackExpanded && (
                      SampleLibraryImages.slice(4).map((libraryImage) => (
                        <CharacterLibraryExpansionItem
                          key={libraryImage._id}
                          previewUrl={previewBackground}
                          imageUrl={libraryImage.url}
                          handleLibraryImageClick={(link: string) => (
                            handleLibraryImageClick(link)
                          )}
                        />
                      ))
                    )}
                  </Stack>
                </Expansion>
              </Box>
            </>
          )}
        />
      </ReactiveBase>
    </>
  );
}

export default CharacterLibraryView;
