import { Box } from '@mui/material';
import {
  PayPalButtons,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js';
import React from 'react';

export default function SimplePaypal(): JSX.Element {
  return (
    <PayPalScriptProvider
      options={{
        clientId:
          'ARGmIPVlNc8Qj-UITWrnCFSX1roeQ30-tuUE-BA4tqHd7oE6mXZBRRjPBHYqYvH36ge4OI4PQdNo4-2U', // sandbox
        components: ['buttons', 'messages', 'funding-eligibility'],
        enableFunding: ['paylater'],
      }}
    >
      <Box
        sx={{
          width: '400px',
          height: '220px',
          m: '10px',
          overflow: 'scroll',
        }}
      >
        <PayPalButtons
          style={{ layout: 'vertical' }}
        />
      </Box>
    </PayPalScriptProvider>
  );
}
