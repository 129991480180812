import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import EmptyCard from '../../aiCards/EmptyCard';
import styles from './aigenerated.module.scss';
import loadingGif from '../../../assets/images/loading.gif';
import appStore from '../../../store/AppStore';
import NewGeneratedImages from '../../aiCards/NewGeneratedImages';

interface Props {
  onImageSelect : (imageUrl : string) => void;
}

function AIGenerated({ onImageSelect }: Props) {
  const workFlowId:
  number = appStore(
    (state) => state.aiWorkFlowId,
  );

  const isLoading:
  boolean = appStore(
    (state) => state.aiIsLoading,
  );

  const isLoaded:
  boolean = appStore(
    (state) => state.aiIsLoaded,
  );

  useEffect(() => {
    if (workFlowId !== -1 && !isLoaded) {
      appStore.setState({
        aiIsLoading: true,
      });
    }
  }, [workFlowId, isLoaded]);

  return (
    <>
      {(!isLoading && !isLoaded) && (
        <div className={styles.cards_wrapper}>
          <EmptyCard />
          <EmptyCard />
          <EmptyCard />
          <EmptyCard />
        </div>
      )}
      {isLoading && (
        <Box className={styles.cards_wrapper}>
          <img src={loadingGif} className={styles.loading_gif} alt="loading gif" />
        </Box>
      )}
      {workFlowId && workFlowId && (workFlowId !== -1) && (
        <div className={styles.cards_wrapper}>
          <NewGeneratedImages
            workflowId={workFlowId}
            onImageSelect={onImageSelect}
          />
        </div>
      )}
    </>
  );
}

export default AIGenerated;
