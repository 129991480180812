/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
// import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {
  Box,
  CircularProgress,
  ThemeProvider, createTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginBox from '../../components/auth/LoginBox/LoginBox';
import { useAuth } from '../../hooks/use-auth';
import LoginBGImg from '../../images/login-bg.png';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#FF6746',
      main: '#FF6746',
      dark: '#262626',
      contrastText: '#fff',
    },
  },
});

export default function LoginNew() {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();

  React.useEffect(() => {
    if (auth?.user) {
      auth?.getCurrentUserTokens();
      navigate(location?.state?.from ?? '/');
    }
  }, [auth?.user, navigate, location]);

  if (auth?.user) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth={false}
        disableGutters
        sx={{
          backgroundImage: `url(${LoginBGImg})`,
          backgroundSize: 'cover', // Ensure the image covers the entire container
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh', // Ensure the container takes up the full viewport height
          display: 'flex', // Optional: Use flex to center content vertically
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoginBox
          onLogin={() => {
            navigate('/');
          }}
        />
      </Container>
    </ThemeProvider>
  );
}
