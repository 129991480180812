/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import { Button } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import LoginBGImg from '../../../images/login-scroll.png';
import UploadIMG from '../../../images/upload-img-icon.png';
import {
  FinishButton,
  RMBio, RMInputLabel, RMName, RMTag, TagButton,
} from './CreateRealmPopup.styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
};

type RealmData = {
  realmName: string,
  realmBio: string,
  tags: string[],
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
  createRealmHandler: (realmData: RealmData) => void,
};

export default function CreateRealmPopup({
  open, onClose, setOpen, createRealmHandler,
}: RWModalProps) {
  const [realmName, setRealmName] = React.useState('');
  const [realmBio, setRealmBio] = React.useState('');
  const [tags, setTags] = React.useState<string[]>([]);
  const [currentTag, setCurrentTag] = React.useState('');
  const maxVisibleTags = 5;
  const [showAllTags, setShowAllTags] = React.useState(false);

  // Handle the Enter key press event in the tag input
  const handleTagKeyPress = (e: any) => {
    if (e.key === 'Enter' && currentTag.trim() !== '') {
      // Add the current tag to the tags array
      setTags([...tags, currentTag.trim()]);
      // Clear the input
      setCurrentTag('');
    }
  };

  // Handle tag button click to delete a tag from the array
  const handleTagDelete = (tagToDelete:any) => {
    const updatedTags = tags.filter((tag) => tag !== tagToDelete);
    setTags(updatedTags);
  };

  // Toggle showing all tags
  const toggleShowAllTags = () => {
    setShowAllTags(!showAllTags);
  };
  const handleClose = (e: Event, r: 'backdropClick' | 'escapeKeyDown') => { onClose?.(e, r); setOpen(false); };

  const handleCreateRealm = () => {
    // Call the createRealmHandler with the input data
    createRealmHandler({ realmName, realmBio, tags });
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            sx={{
              width: '60vw',
              height: '90vh',
              margin: '0 auto',
              backgroundImage: `url(${LoginBGImg})`,
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >

            <Box sx={{
              width: '30vw',
              height: '52vh',
              margin: '0 auto',
              // border: '1px solid green',
            }}
            >
              <Box m={1}>
                <RMInputLabel>Realm Name</RMInputLabel>
                <RMName id="name" variant="outlined" placeholder="Caldore" onChange={(e) => setRealmName(e.target.value)} />
              </Box>

              <Box m={1}>
                <RMInputLabel>Realm Bio</RMInputLabel>
                <RMBio
                  id="bio"
                  placeholder="This is where you start your journey into Caldore, where is it you hail from?"
                  multiline
                  minRows={4}
                  onChange={(e) => setRealmBio(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  height: '120px', background: 'white', m: 1, borderRadius: 1, overflow: 'hidden',
                }}
              >
        &nbsp;
                <RMTag
                  id="tag"
                  variant="outlined"
                  placeholder="Add A Tag"
                  onChange={(e) => setCurrentTag(e.target.value)}
                  onKeyPress={handleTagKeyPress} // Handle Enter key press
                  value={currentTag}
                />
        &nbsp;&nbsp;&nbsp;&nbsp;
                {tags.slice(0, maxVisibleTags).map((tag) => (
                  <TagButton style={{ marginLeft: 10 }} key={`${tag}-key`} onClick={() => handleTagDelete(tag)}>
                    <span>X &nbsp;</span>
                    {' '}
                    {tag}
                  </TagButton>
                ))}
                {tags.length > maxVisibleTags && !showAllTags && (
                <TagButton onClick={toggleShowAllTags}>
                  +
                  {tags.length - maxVisibleTags}
                  {' '}
                  {/* Display the number of hidden tags */}
                </TagButton>
                )}
                {showAllTags && (
                <TagButton onClick={toggleShowAllTags}>
                  Show less
                </TagButton>
                )}
&nbsp;
              </Box>

              <Box
                sx={{
                  height: '120px', background: 'white', m: 1, borderRadius: 1,
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={7}
                    sx={{
                      height: '100px', background: '#FEF7FF', margin: 1, p: 1, borderRadius: 1,
                    }}
                  >
                    <Typography sx={{ fontFamily: 'Passero One', fontSize: '30px' }}>Realm Cover</Typography>
                    <Typography sx={{ fontFamily: 'Passero One', fontSize: '16px' }}>Upload cover for your realm!</Typography>
                    <Typography sx={{ fontFamily: 'Passero One', fontSize: '16px' }}>Recommended: 762 x 762</Typography>

                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      height: '100px',
                      background: '#A89F68',
                      margin: 1,
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center', /* Vertical alignment */
                      justifyContent: 'center', /* Horizontal alignment */
                    }}
                  >
                    <Typography sx={{ fontFamily: 'Passero One' }}>
                      {' '}
                      <img
                        src={UploadIMG}
                        alt="upload img"
                        style={{
                          width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle',
                        }}
                      />
                      Add a Tag

                    </Typography>

                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: 'flex', alignContent: 'flex-end', m: 1 }}>
                <FinishButton onClick={handleCreateRealm}>Finish</FinishButton>
              </Box>

            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
