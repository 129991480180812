/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  Box, Grid, Modal, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hooks/use-auth';
import useModal from '../../hooks/useModal';
import useNotification from '../../hooks/useNotification';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import { UpdateUserConfigDocument } from '../../__generated__/graphql';

type ModalSettingsProps = {
  open: boolean,
  onClose?: (value: boolean | null) => void,
};

export default function ShowInToolModal({
  open,
  onClose,
}: ModalSettingsProps) {
  const auth = useAuth();
  const { displayNotification } = useNotification();
  const {
    modalStyle,
  } = useModal();

  const {
    mutate: updateUserConfig,
    data: updatedUserConfig,
    error: updateUserConfigError,
    isError: isUpdateUserConfigError,
    isSuccess: isUpdateUserConfigSuccess,
    isLoading: isUpdateUserConfigLoading,
  } = useGraphQLMutation(
    UpdateUserConfigDocument,
  );

  if (updateUserConfigError) {
    // TODO Check if we get an unauthorized error that the token has been expired and refresh it
    // auth?.refreshToken();
    onClose?.(null);
    console.error('upsertUserConfig Error: ', isUpdateUserConfigError);
    displayNotification({ message: 'Error Updating Settings', type: 'error' });
  }
  if (isUpdateUserConfigSuccess) {
    onClose?.(updatedUserConfig.updateOneUser?.config?.showInToolByDefault ?? false);
    displayNotification({ message: 'Settings Updated!', type: 'success' });
  }
  if (isUpdateUserConfigLoading) {
    displayNotification({ message: 'Updating Settings...', type: 'info' });
  }

  return (
    <Modal
      open={open}
      onClose={() => { onClose?.(null); }}
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
    >
      <Box sx={modalStyle}>
        <Grid container>
          <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
            <Typography variant="h4" align="center">Always Show in Extension?</Typography>
          </Grid>
          <Grid item mb={4} xs={12} sx={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', border: '1px solid #FFFFFF', borderRadius: '10px' }}>
            <Grid container px={10} py={4}>
              <Grid item xs={12} mb={2}>
                <Typography variant="h6" align="center" color="rgba(255, 255, 255, 0.5)">You can change this in Account&gt;User Settings</Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Grid container justifyContent="center" alignItems="center">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      updateUserConfig({
                        data: {
                          _id: auth?.realmUser?.id,
                          config: {
                            showInToolByDefault: true,
                          },
                        },
                      });
                    }}
                    loading={isUpdateUserConfigLoading}
                    sx={{
                      backgroundColor: '#FF6746', px: 10, mx: 'auto', borderRadius: '3vmin',
                    }}
                  >
                    Yes
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      updateUserConfig({
                        data: {
                          _id: auth?.realmUser?.id,
                          config: {
                            showInToolByDefault: false,
                          },
                        },
                      });
                    }}
                    loading={isUpdateUserConfigLoading}
                    sx={{
                      backgroundColor: 'rgba(217, 217, 217, 0.2)', px: 10, mx: 'auto', borderRadius: '3vmin',
                    }}
                  >
                    No
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

ShowInToolModal.defaultProps = {
  onClose: () => { },
};
