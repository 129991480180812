import request from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useAuth } from './use-auth';

function useGraphQLMutation<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
): UseMutationResult<TResult, unknown, TVariables> {
  const auth = useAuth();

  const mutationFn = async (variables: TVariables) => {
    const tokens = await auth?.getCurrentUserTokens();
    const data = await request(
      process.env.REACT_APP_GRAPHQL_URL ?? 'NO URL SPECIFIED',
      document,
      variables || undefined,
      {
        Authorization: `Bearer ${tokens.realm}`,
      },
    );

    return data;
  };

  return useMutation(mutationFn);
}

export default useGraphQLMutation;
