import React from 'react';
import {
  Box,
  Button,
  Stack,
} from '@mui/material';
import {
  AttributeWrapper,
  SmallHeadingText,
  GenerateDiceImg,
  AttributeTextField,
} from '../../styledComps/styledComps';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import DiceIcon from '../../../images/dice.webp';
import {
  Class, Gender, GetClassesAttributesDocument, GetGendersAttributesDocument,
  GetPersonalitiesAttributesDocument,
  GetPronounsDocument, GetRacesAttributesDocument, Personality, Race,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';

const PronounOptions = [
  'She/Her',
  'He/Him',
  'Other',
  'They/Them',
];

const AlignmentOptions = [
  'Lawful Good',
  'Neutral Good',
  'Chaotic Good',
  'Lawful Neutral',
  'True Neutral',
  'Chaotic Neutral',
  'Lawful Evil',
  'Neutral Evil',
  'Chaotic Evil',
];

const MotivationOptions = [
  'Explorer',
  'Adventurer',
  'Seeker of Riches',
  'Heroism Survivor',
  'Amass Power',
  'Be Free',
  'Love',
  'Fun Seeker',
  'Andrenaline Junkie',
];

interface Props {
  characterName: string;
  setCharacterName: (char: string) => void;
  characterAge: number;
  setCharacterAge: (char: number) => void;
  characterPronouns: string;
  setCharacterPronouns: (char: string) => void;
  characterGender: Gender | null;
  setCharacterGender: (char: Gender) => void;
  characterRace: Race | null;
  setCharacterRace: (char: Race) => void;
  characterClass: Class | null;
  setCharacterClass: (char: Class) => void;
  characterAlignment: string;
  setCharacterAlignment: (char: string) => void;
  characterPersonalityType: Personality | null;
  setCharacterPersonalityType: (char: Personality) => void;
  characterMotivations: string[];
  setCharacterMotivations: (char: string[]) => void;
}

/**
 * Represents a component for character attributes.
 *
 * @component
 * @example
 * // Example usage of CharacterAttributes component
 * <CharacterAttributes />
 *
 * @returns {JSX.Element} React element representing character attributes.
 */
function CharacterAttributes({
  characterName,
  setCharacterName,
  characterAge,
  setCharacterAge,
  characterPronouns,
  setCharacterPronouns,
  characterGender,
  setCharacterGender,
  characterRace,
  setCharacterRace,
  characterClass,
  setCharacterClass,
  characterAlignment,
  setCharacterAlignment,
  characterPersonalityType,
  setCharacterPersonalityType,
  characterMotivations,
  setCharacterMotivations,
}: Props) {
  const { data: genders } = useGraphQL(
    GetGendersAttributesDocument,
    {},
  );
  const { data: races } = useGraphQL(
    GetRacesAttributesDocument,
    {},
  );
  const { data: classes } = useGraphQL(
    GetClassesAttributesDocument,
    {},
  );
  const { data: pronouns } = useGraphQL(
    GetPronounsDocument,
    {},
  );
  console.log(pronouns?.pronouns);
  const { data: personalities } = useGraphQL(
    GetPersonalitiesAttributesDocument,
    {},
  );
  return (
    <Stack direction="row" flexWrap="wrap" gap="28px" width="896px" paddingTop="9px">
      {/* note:
        * 280px is 33.333% adjusted for flex gap
        * 434px is 50% adjusted for flex gap
        */}
      <AttributeWrapper width="588px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack width="540px">
            <SmallHeadingText>Name</SmallHeadingText>
            {/* <AttributeText>Enter Character Name</AttributeText> */}
            <AttributeTextField
              placeholder="Enter Character Name"
              value={characterName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCharacterName(event.target.value);
              }}
              fullWidth
            />
          </Stack>
          <Button
            sx={{
              width: '33px',
              minWidth: '0px',
              height: '33px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              '&:hover': {
                background: '#FFF',
              },
            }}
          >
            <GenerateDiceImg src={DiceIcon} />
          </Button>
        </Stack>
      </AttributeWrapper>
      <AttributeWrapper width="280px">
        <Box>
          <SmallHeadingText>Age</SmallHeadingText>
          {/* <AttributeText>Insert Age</AttributeText> */}
          <AttributeTextField
            placeholder="Insert Age"
            type="number"
            value={characterAge}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCharacterAge(parseInt(event.target.value, 10));
            }}
            fullWidth
            inputProps={{
              min: 0,
            }}
          />
        </Box>
      </AttributeWrapper>
      <CharacterFilterDropdown
        width="434px"
        title="Pronouns"
        options={PronounOptions}
        value={characterPronouns}
        onChange={setCharacterPronouns}
      />
      <CharacterFilterDropdown
        width="434px"
        title="Gender"
        options={genders?.genders}
        value={characterGender?.name}
        onChange={setCharacterGender}
      />
      <CharacterFilterDropdown
        width="280px"
        title="Race"
        options={races?.races}
        tooltip
        toolTipMessage="Choose the race you wish to apply to the character."
        value={characterRace?.name}
        onChange={setCharacterRace}
      />
      <CharacterFilterDropdown
        width="280px"
        title="Class"
        options={classes?.classes}
        tooltip
        toolTipMessage="Choose the class you wish to apply to the character."
        value={characterClass?.name}
        onChange={setCharacterClass}
      />
      <CharacterFilterDropdown
        width="280px"
        title="Alignment"
        options={AlignmentOptions}
        tooltip
        toolTipMessage="Choose the alignment you wish to apply to the character."
        value={characterAlignment}
        onChange={setCharacterAlignment}
      />
      <CharacterFilterDropdown
        width="434px"
        title="Motivations"
        options={MotivationOptions}
        tooltip
        toolTipMessage="Choose the motivations you wish to apply to the character."
        multipleSelect
        multipleSelectLowerLimit={1}
        multipleSelectUpperLimit={3}
        value={characterMotivations}
        onChange={setCharacterMotivations}
      />
      <CharacterFilterDropdown
        width="434px"
        title="Personality Type"
        options={personalities?.personalities}
        tooltip
        toolTipMessage="Choose the personality type you wish to apply to the character."
        value={characterPersonalityType?.name}
        onChange={setCharacterPersonalityType}
      />
      {/* <AttributeWrapper width="434px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              minWidth: '100%',
            }}
          >
            <Stack direction="row" alignItems="center" gap="2px">
              <SmallHeadingText>Short Term Goals</SmallHeadingText>
              <Tooltip
                title="Enter the short term goals "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontFamily: 'Passero One',
                      fontWeight: '400px',
                      fontSize: '14px',
                      padding: '10px',
                      bgcolor: '#3c3c3ce6',
                      '& .MuiTooltip-arrow': {
                        color: '#3c3c3ce6',
                      },
                      maxWidth: '250px',
                    },
                  },
                }}
              >
                <HelpImg src={HelpIcon} />
              </Tooltip>
            </Stack>
            <AttributeTextField
              rows={4}
              multiline
              placeholder="Enter short term goals below"
              value={shortTermGoals}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShortTermGoals(event.target.value);
              }}
              fullWidth
            />
          </Box>
        </Stack>
      </AttributeWrapper>
      <AttributeWrapper width="434px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              minWidth: '100%',
            }}
          >
            <Stack direction="row" alignItems="center" gap="2px">
              <SmallHeadingText>Long Term Goals</SmallHeadingText>
              <Tooltip
                title="Enter the long term goals "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontFamily: 'Passero One',
                      fontWeight: '400px',
                      fontSize: '14px',
                      padding: '10px',
                      bgcolor: '#3c3c3ce6',
                      '& .MuiTooltip-arrow': {
                        color: '#3c3c3ce6',
                      },
                      maxWidth: '250px',
                    },
                  },
                }}
              >
                <HelpImg src={HelpIcon} />
              </Tooltip>
            </Stack>
            <AttributeTextField
              rows={4}
              multiline
              placeholder="Enter long term goals below"
              value={longTermGoals}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLongTermGoals(event.target.value);
              }}
              fullWidth
            />
          </Box>
        </Stack>
      </AttributeWrapper> */}
    </Stack>
  );
}

export default CharacterAttributes;
