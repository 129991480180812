/* eslint-disable react/require-default-props */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Zoom,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AttributeWrapper,
  SmallHeadingText,
  HelpImg,
  AttributeTextField,
} from '../../styledComps/styledComps';
import HelpIcon from '../../../images/help.webp';

interface Props {
  width?: string,
  title?: string,
  tooltip?: boolean,
  options: any[] | undefined,
  toolTipMessage?: string,
  multipleSelect?: boolean,
  multipleSelectLowerLimit?: number,
  multipleSelectUpperLimit?: number,
  value: any,
  onChange: (val: any) => void,
}

/**
 * Represents a character filter dropdown component.
 *
 * @component
 * @example
 * // Example usage of CharacterFilterDropdown component
 * <CharacterFilterDropdown
 *   width="100%"
 *   title="Filter Options"
 *   tooltip
 *   options={['Option 1', 'Option 2', 'Option 3']}
 *   toolTipMessage="This is a tooltip message."
 *   multipleSelect
 *   multipleSelectLowerLimit={1}
 *   multipleSelectUpperLimit={2}
 * />
 *
 * @param {object} props - The component properties.
 * @param {string} [props.width='100%'] - The width of the component.
 * @param {string} props.title - The title of the dropdown.
 * @param {boolean} [props.tooltip=false] - Flag indicating whether to display a tooltip.
 * @param {string[]} props.options - The available options in the dropdown.
 * @param {string} [props.toolTipMessage=''] - The tooltip message.
 * @param {boolean} [props.multipleSelect=false]
 * - Flag indicating whether multiple options can be selected.
 * @param {number} [props.multipleSelectLowerLimit=1]
 * - The lower limit of options that can be selected.
 * @param {number} [props.multipleSelectUpperLimit=2]
 * - The upper limit of options that can be selected.
 * @param {any} props.value - The current value of the dropdown.
 * @param {(val: any) => void} props.onChange - Function to call when the dropdown value changes.
 *
 * @returns {JSX.Element} React element representing the character filter dropdown.
 */
function CharacterFilterDropdown({
  width,
  title,
  tooltip,
  options,
  toolTipMessage,
  multipleSelect,
  multipleSelectLowerLimit,
  multipleSelectUpperLimit,
  value,
  onChange,
}: Props) {
  const [showMenu, setShowMenu] = React.useState(false);
  const triggerRef = React.useRef<HTMLDivElement>();

  return (
    <AttributeWrapper
      width={width}
      onClick={() => setShowMenu(!showMenu)}
      ref={triggerRef}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="100%">
          <Stack direction="row" alignItems="center" gap="2px">
            {title && title !== '' && (<SmallHeadingText>{title}</SmallHeadingText>)}
            {tooltip && (
              <Tooltip
                title={toolTipMessage}
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontFamily: 'Passero One',
                      fontWeight: '400px',
                      fontSize: '14px',
                      padding: '10px',
                      bgcolor: '#3c3c3ce6',
                      '& .MuiTooltip-arrow': {
                        color: '#3c3c3ce6',
                      },
                      maxWidth: '250px',
                    },
                  },
                }}
              >
                <HelpImg src={HelpIcon} />
              </Tooltip>
            )}
          </Stack>
          <Select
            value={value !== undefined && value !== null ? value : ''}
            onChange={(event: SelectChangeEvent<string>) => {
              onChange(event.target.value);
            }}
            input={(
              <AttributeTextField
                fullWidth
                sx={{
                  width: 'calc(100% + 12px)',
                }}
              />
            )}
            renderValue={() => {
              if (Array.isArray(value)) {
                return multipleSelect ? value.join(', ') : value[0];
              }
              return value; // Handle the case when selected is a single string
            }}
            multiple={multipleSelect}
            IconComponent={KeyboardArrowDownIcon}
          >
            {/* <MenuItem value="">{placeholder}</MenuItem> */}
            {options !== undefined && options.map((option) => (
              <MenuItem key={option._id ?? option} value={option}>
                {option.name ?? option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>
    </AttributeWrapper>
  );
}

CharacterFilterDropdown.defaultProps = {
  width: '100%',
  tooltip: false,
  toolTipMessage: '',
  title: '',
  multipleSelect: false,
  multipleSelectLowerLimit: 1,
  multipleSelectUpperLimit: 2,
};

export default CharacterFilterDropdown;
