/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ResetPassword from '../../views/ResetPassword';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '50vw',
  bgcolor: '#E8DDC3',
  boxShadow: 24,
  outline: 'none',
};

// const PhoneInput = styled(Input)({
//   '& .phone-input': {
//     fontFamily: 'monospace', /* Use a monospaced font to ensure even spacing */
//     position: 'relative', /* Make sure the dashes are positioned relative to the input */
//   },

//   '& .phone-input::after': {
//     // content: '-', /* Add a dash after each character */
//     position: 'absolute',
//     bottom: '4px', /* Adjust the vertical position of the dashes */
//     left: 0, /* Position the dashes directly under each character */
//     pointerEvents: 'none', /* Ensure the dashes don't interfere with input */
//   },

//   /* Adjust the input width if needed */
//   '& .phone-input input': {
//     textAlign: 'center', /* Center-align the text within the input */
//   },

// });

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
};

export default function PasswordReset({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = (e: Event, r: 'backdropClick' | 'escapeKeyDown') => { onClose?.(e, r); setOpen(false); };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
            <ResetPassword />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
