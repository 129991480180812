/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Carousel } from '@trendyol-js/react-carousel';
import {
  Box, Button, Card, CardContent, CardMedia, Grid, Typography, styled,
} from '@mui/material';
import {
  MultiDataList,
  MultiList, ReactiveList, SearchBox, SingleDataList, SingleList, SingleRange,
} from '@appbaseio/reactivesearch';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import DiscoverNews from '../../../images/discover-news.png';
import FilterRealms from '../../../images/filter-icon.png';
import PlusIcon from '../../../images/plus-icon.png';
import FavoritesIcon from '../../../images/favorites-icon.svg';
import FantasyIcon from '../../../images/fantasy-icon.png';
import DashboardBG from '../../../images/dashboard-bg.png';
import RealmsCard from '../RealmsCard';
import CreateRealmPopup from '../../../components/modals/CreateRealmPopup/CreateRealmPopup';
import {
  CreateRealmDocument, GetManyMediaAssetsDocument, Realm, RealmInsertInput,
} from '../../../__generated__/graphql';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import useGraphQL from '../../../hooks/useGraphQL';
import PreviewRealmPopup from '../../../components/modals/PreviewRealmPopup/PreviewRealmPopup';

const StyledImage = styled('img')({
  width: '50px',
  height: '50px',
  flexShrink: 0,
  cursor: 'pointer',
});
const TopImage = styled('img')({
  width: '25px',
  height: '25px',
  flexShrink: 0,
  cursor: 'pointer',
});

interface RealmData extends RealmInsertInput {
  realmName?: string,
  realmBio?: string,
  tags?: string[],
  isFavorited: boolean,
  isPublic: any,
  owner:any
}
export default function Favorites(): JSX.Element {
  const navigate = useNavigate();
  const auth = useAuth();
  const [openCreateRealmPopupModal, setopenCreateRealmPopupModal] = React.useState(false);
  const [openPreviewRealmPopup, setOpenPreviewRealmPopup] = React.useState(false);
  const [previewRealm, setPreviewRealm] = React.useState({});
  const { mutate: createRealm, error: createRealmError } = useGraphQLMutation(
    CreateRealmDocument,
  );
  const { data: mediaAssets, error: mediaAssetsError } = useGraphQL(
    GetManyMediaAssetsDocument,
    {
      query: {
        _id_in: [
          '65270628336522b5fca93c80',
          '65270629336522b5fca93d08',
        ],
      },
    },
  );
  console.log('mediaAssets', mediaAssets);
  console.log('mediaAssetsError', mediaAssetsError);
  useEffect(() => { }, [previewRealm]);
  const handleCreateRealm = async (props: any) => {
    const { realmName, realmBio, tags } = props;
    const userInput: RealmInsertInput = {
      name: realmName,
      description: realmBio,
      tags,
      is_favorited: true,
      is_public: false,
      owner: {
        link: auth?.realmUser?.id,
      },
    };
    try {
      // Use the createRealm mutation function from your GraphQL client
      const result = await createRealm({
        data: userInput,
      });

      console.log('Realm created:', result);
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error creating realm:', error.message);
      // Handle unexpected errors
    }
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        minWidth: '86vw',
      }}
    >
      <CreateRealmPopup
        open={openCreateRealmPopupModal}
        onClose={() => { console.log('modal closed'); }}
        setOpen={setopenCreateRealmPopupModal}
        createRealmHandler={handleCreateRealm}
      />
      <PreviewRealmPopup open={openPreviewRealmPopup} onClose={() => { console.log('modal closed'); }} setOpen={setOpenPreviewRealmPopup} realm={previewRealm} />
      <Box
        display="flex"
        justifyContent="end"
      >
        <Box sx={{
          width: '366px',
          height: '40px',
          flexShrink: 0,
          borderRadius: '10px',
          background: '#FFF',
        }}
        >
          {/* Hide this box */}
          {/* <Box sx={{
            display: 'none',
          }}
          > */}
          {/* <SingleList
            componentId="fav-multilist"
            compoundClause="must"
            dataField="isFavorited"
            showSearch={false}
            defaultValue="true"
            showCount
            showRadio
          /> */}
          {/* </Box> */}
          <SearchBox
            componentId="fav-searchbox"
            dataField={['name']}
            includeFields={['name', 'owner', 'is_favorited', 'thumbnail_url']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            // onKeyDown={(e, triggerQuery) => {
            //   if (e.key === 'Enter') {
            //     triggerQuery();
            //   }
            // }}
            // @ts-ignore
            renderItem={(res: any) => (
              <Button onClick={() => navigate(`/realm/${res._id}/campaign`)}>
                {' '}
                {res._source.name}
                {' '}
              </Button>
            )}
            // custom query to only search for realms where isFavorited = true
            customQuery={(value, props) => ({
              $search: {
                equals: {
                  path: 'is_favorited',
                  value: true,
                },
              },
            })}
          />
        </Box>
        {' '}
&nbsp;&nbsp;
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '91.627px',
          height: '40px',
          borderRadius: '10px',
          background: '#41521F',
        }}
        >
          {' '}
          <TopImage src={FantasyIcon} />
          <TopImage src={PlusIcon} onClick={() => setopenCreateRealmPopupModal(true)} />

        </Box>
      </Box>

      <Box sx={{ maxWidth: '80vw' }}>
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 8,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledImage src={FavoritesIcon} />
            {' '}
&nbsp;
            <Typography sx={{
              color: '#250503',
              textAlign: 'center',
              fontFamily: 'Passero One',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.1px',
            }}
            >
              Favorites
            </Typography>
          </Box>
          <StyledImage src={FilterRealms} />
        </Box>

        {/* TODO - figure out how to add default filter to only get "my favorite" realms */}
        <ReactiveList
          componentId="fav-results"
          dataField="_score"
          size={10}
          pagination
          innerClass={{
            pagination: 'list_pagination',
            resultStats: 'list_resultStats',
            resultsInfo: 'list_resultsInfo',
          }}
          className="dashboard_reactive_list"
          compoundClause="must"
          includeFields={['name', 'owner', 'is_favorited', 'thumbnail_url', 'description', 'is_public', 'tags']}
          react={{
            and: ['fav-searchbox'],
          }}
          onError={(err) => console.log(err)}
          sortOptions={[
            {
              dataField: 'updated_at',
              sortBy: 'desc',
              label: 'Recent',
            },
            {
              dataField: 'updated_at',
              sortBy: 'asc',
              label: 'Oldest',
            },
            {
              dataField: 'name',
              sortBy: 'asc',
              label: 'Name - A-Z',
            }, {
              dataField: 'name',
              sortBy: 'desc',
              label: 'Name - Z-A',
            }]}
          showResultStats={false}
          render={({
            key, data, loading, resultStats,
          }: any) => (
            <Box>
              <Typography>
                {'DEBUG: '}
                {JSON.stringify(resultStats)}
              </Typography>
              <Grid container spacing={2} m={1} key={data.id}>
                {data && data?.length > 0 ? (
                  data
                    .map((item: Realm) => (
                      <RealmsCard
                        handleClick={() => {
                          setOpenPreviewRealmPopup(true); setPreviewRealm(item as any);
                          console.log('Log it', previewRealm);
                        }}
                        handleKeyDown={(event: any) => {
                          if (event.key === 'Enter') {
                            setOpenPreviewRealmPopup(true);
                          }
                        }}
                        realm={item}
                        key={item._id}
                        realmId={item._id}
                        realmName={item.name}
                        realmDescription={item.description ?? ''}
                        thumbnail={item.thumbnail_url ?? ''}
                        isFavorited={item.is_favorited}
                        tags={item.tags ?? [] as string[]}
                      />
                    ))
                ) : (
                  <p>No favorited items found.</p>
                )}
              </Grid>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}
