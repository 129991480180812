import React from 'react';
import {
  Box, InputBase, Stack, styled,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PencilIcon from '../../images/edit-pencil.webp';
import NoteEditor from './NoteEditor';
import {
  CharacterLong_Term_Goal, CharacterShort_Term_Goal, Character_Note, NoteInsertInput,
} from '../../__generated__/graphql';

interface Props {
  note: Character_Note | NoteInsertInput | CharacterShort_Term_Goal | CharacterLong_Term_Goal,
  editNote: (note: Character_Note | NoteInsertInput | CharacterShort_Term_Goal
  | CharacterLong_Term_Goal) => void,
  deleteNote: () => void;
  realmNote: boolean | undefined;
  realmId: string | undefined;
}

/**
 * Represents a component for displaying and editing character notes.
 *
 * @component
 * @example
 * // Example usage of CharacterNote component
 * <CharacterNote note={{ title: 'Example Note', date: '2023-11-29' }} />
 *
 * @param {Props} param0 - The properties of the component.
 * @param {SampleNote} param0.note - The note data for the character.
 * @returns {JSX.Element} React element representing a character note.
 */
const PencilImg = styled('img')({
  height: '15px',
  width: 'auto',
});

function CharacterNote({
  note,
  editNote,
  deleteNote,
  realmNote,
  realmId,
}: Props) {
  const [noteData, setNoteData] = React
    .useState<Character_Note | NoteInsertInput | CharacterShort_Term_Goal
  | CharacterLong_Term_Goal>(note);
  const [isExpanded, setIsExpanded] = React.useState(false);

  // const setTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   editNote({ title: event.target.value, content: note.content });
  // };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py="11px"
        px="21px"
        sx={{
          borderBottom: '1px solid #BCAD93',
          borderRight: '1px solid #BCAD93',
        }}
      >
        <InputBase
          value={noteData.title}
          onChange={(event: React
            .ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (realmNote === true) {
              setNoteData({
                title: event.target.value,
                content: noteData.content,
                realm: {
                  link: realmId,
                },
              });
            } else {
              setNoteData({ title: event.target.value, content: noteData.content });
            }
          }}
          onBlur={() => {
            editNote(noteData);
          }}
          sx={{
            boxSizing: 'border-box',
            width: '185px',
            height: '31px',
            borderRadius: '5px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: isExpanded ? '#A89F68' : 'transparent',
            input: {
              color: '#250503',
              padding: '5px 6px',
              fontFamily: 'DM Sans',
              fontWeight: '400px',
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '.5px',
            },
          }}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="5px" width="140px">
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap="5px">
            {/* <InputBase
              value={noteData?.date}
              sx={{
                boxSizing: 'border-box',
                width: '100px',
                height: 'fit-content',
                input: {
                  color: '#250503',
                  fontFamily: 'DM Sans',
                  fontWeight: '400px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '.5px',
                },
              }}
            /> */}
            <PencilImg src={PencilIcon} />
          </Stack>
          <Box p="5px" width="34px" maxHeight="34px" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? (
              <KeyboardArrowUpIcon sx={{ color: '#250503' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: '#250503' }} />
            )}
          </Box>
        </Stack>
      </Stack>
      {isExpanded && (
        <NoteEditor
          note={note.content!}
          setNoteData={(str: string) => {
            if (realmNote === true) {
              setNoteData({
                title: noteData.title,
                content: str,
                realm: {
                  link: realmId,
                },
              });
            } else {
              setNoteData({ title: noteData.title, content: str });
            }
          }}
          editNote={() => editNote(noteData)}
          deleteNote={deleteNote}
        />
      )}
    </>
  );
}

export default CharacterNote;
