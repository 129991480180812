import React, { useContext } from 'react';
import { ThemeContext } from '../../../../context/ThemeContext';
import styles from './tabbutton.module.scss';

interface TabButtonProps {
  active: boolean;
  name: string;
  onClick: () => void;
}

function TabButton({ active, name, onClick }: TabButtonProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <button
      type="button"
      onClick={onClick}
      className={active ? `${styles.tab_button} ${styles[theme]} ${styles.active}` : `${styles.tab_button} ${styles[theme]}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="48"
        viewBox="0 0 160 48"
        fill="none"
      >
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0H111.116C117.101 0 122.771 2.68025 126.57 7.30485L160 48H0V10Z"
          fill="#E9E9E9"
        />
      </svg>
      <div className={styles.button_name}>{name}</div>
    </button>
  );
}

export default TabButton;
