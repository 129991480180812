/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Typography,
} from '@mui/material';
import { ColorResult, RGBColor } from 'react-color';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import { CreateCharacterSetDocument } from '../../__generated__/graphql';
import CharacterSetModalPreview from '../realms/Character/CharacterSetModalPreview';
import appStore, { ThumbnailSource } from '../../store/AppStore';
import SampleLibraryImages from './sampleLibraryImages.json';
import RWColorPicker from '../colorPicker/RWColorPicker';
import CharacterLibraryView from '../realms/Character/CharacterLibraryView';
import CharacterGeneralView from '../realms/Character/CharacterGeneralView';

/**
 * Represents the props for the `CreateCharacterSetModal` component.
 *
 * @interface
 * @property {boolean} open - Controls the visibility of the modal.
 * @property {() => void} onClose - Callback function when the modal is closed.
 * @property {() => void} [setOpen] - Callback function to set the modal visibility.
 * @property {() => void} onConfirm - Callback function when the user confirms the action.
 * @property {() => void} refetch - Callback function to refetch data after an action.
 */
type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
  refetch: () => void;
} & React.PropsWithChildren;

type ViewOption = 'general' | 'library';

export default function CreateCharacterSetModal({
  open,
  onClose,
  setOpen,
  refetch,
}: RWModalProps) {
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
  };
  const [showPreview, setShowPreview] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = React.useState<Blob | null>(null);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [previewBackground, setPreviewBackground] = React.useState('');
  const [view, setView] = React.useState<ViewOption>('general');
  const [characterSetTitle, setCharacterSetTitle] = React.useState('');

  const selectedThumbnailSource: ThumbnailSource = appStore(
    (state) => state.previewThumbnailSource,
  );
  const setSelectedThumbnailSource = (source: ThumbnailSource) => {
    appStore.setState({
      previewThumbnailSource: source,
    });
  };

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      setSelectedThumbnailSource('upload');
      setShowPreview(true);
    }
  };

  const handleLibraryView = () => {
    setSelectedThumbnailSource('library');
    setShowPreview(true);
    setView('library');
  };

  const handleCloseLibraryView = () => {
    // setShowPreview(true);
    setView('general');
  };

  const handleColorView = () => {
    setSelectedThumbnailSource('color');
    setShowPreview(true);
  };

  const [color, setColor] = React.useState<RGBColor>(() => ({
    r: 255,
    g: 255,
    b: 255,
  }));

  const handleColorChange = (ccolor: any) => {
    setColor(ccolor.rgb);
  };

  const handleColorChangeCompleted = (chcolor: ColorResult) => {
    setColor(chcolor.rgb);
    console.log(chcolor.hex);
    setPreviewBackground(chcolor.hex);
    setPreviewUrl('');
  };

  const handleLibraryImageClick = (link: string) => {
    setPreviewUrl(link);
  };

  React.useEffect(() => {
    if (previewUrl && previewUrl !== null) setPreviewBackground(`url(${previewUrl})`);
  }, [previewUrl]);

  const { displayNotification } = useNotification();
  const auth = useAuth();
  const {
    data: createdCharacterSet,
    mutate: createCharacterSet,
    status: createCharacterStatus,
  } = useGraphQLMutation(
    CreateCharacterSetDocument,
  );

  const handleCreateCharacterSet = () => {
    createCharacterSet({
      data: {
        name: characterSetTitle,
        owner: {
          link: auth?.realmUser?.id,
        },
        background_url: previewUrl ?? null,
      },
    });
  };

  const handleUploadCharacterSetImage = (id: string) => {
    const formData = new FormData();
    formData.append('BackgroundImage', selectedFileBlob!);
    formData.append('OriginalImage', selectedFileBlob!);
    displayNotification({ message: 'Uploading character set background image ...', type: 'info' });
    fetch(`${process.env.REACT_APP_API_URL}/CharacterSet/${id}/images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          displayNotification({ message: 'Failed to upload character set background image', type: 'error' });
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        handleClose();
        displayNotification({ message: 'Character set background image uploaded successfully', type: 'success' });
        refetch();
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        displayNotification({ message: 'Failed to upload character set background image', type: 'error' });
      });
  };

  React.useEffect(() => {
    if (createCharacterStatus === 'loading') {
      displayNotification({ message: 'Creating character set ...', type: 'info' });
    }
    if (createCharacterStatus === 'error') {
      displayNotification({ message: 'Failed to create character set', type: 'error' });
    }
    if (createCharacterStatus === 'success') {
      if (selectedThumbnailSource === 'upload') {
        displayNotification({ message: 'Character set created successfully', type: 'success' });
        handleUploadCharacterSetImage(createdCharacterSet.insertOneCharacter_set?._id);
      } else {
        handleClose();
        displayNotification({ message: 'Character set created successfully', type: 'success' });
        refetch();
      }
    }
  }, [createCharacterStatus]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              backdropFilter: 'blur(4px)',
            },
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 999 }}
      >
        <>
          <Box
            sx={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -30%)',
              width: '600px',
              outline: 'none',
              overflow: 'hidden',
            }}
          >
            {/* heading and close button */}
            <Box
              sx={{
                width: '100%',
                borderRadius: '20px 20px 0px 0px',
                background: '#41521F',
                padding: '12px 20px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                gap: '35px',
              }}
            >
              <button
                type="button"
                onClick={handleClose}
                style={{
                  display: 'block',
                  background: 'transparent',
                  border: 'none',
                  outline: 'none',
                  filter: 'none',
                  cursor: 'pointer',
                }}
                aria-label="close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10 10"
                  width={10}
                >
                  <polygon
                    style={{ fill: '#fff', strokeWidth: 0 }}
                    points="6.03 5 10 8.97 9.49 9.49 8.97 10 5 6.03 1.03 10 .51 9.49 0 8.97 3.97 5 0 1.03 .51 .51 1.03 0 5 3.97 8.97 0 9.49 .51 10 1.03 6.03 5"
                  />
                </svg>
              </button>
              <Typography
                sx={{
                  color: 'white',
                  fontFamily: 'Passero One',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '26px' /* 130% */,
                  letterSpacing: '0.46px',
                  textTransform: 'uppercase',
                }}
              >
                Create a character set
              </Typography>
            </Box>
            {/* character set name and thumnbail options */}
            <Box
              sx={{
                width: '100%',
                borderRadius: showPreview ? '0px' : '0px 0px 20px 20px',
                background: '#FFF',
                padding: '45px 65px',
                boxSizing: 'border-box',
                maxHeight: '600px',
              }}
            >
              {view === 'general' && (
                <CharacterGeneralView
                  handleFileChange={
                    (event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)
                  }
                  handleLibraryView={handleLibraryView}
                  handleColorView={handleColorView}
                  handleSaveSet={handleCreateCharacterSet}
                  disableSave={previewBackground === '' || characterSetTitle === ''}
                  characterSetTitle={characterSetTitle}
                  setCharacterTitle={(value: string) => setCharacterSetTitle(value)}
                />
              )}
              {view === 'library' && (
                <CharacterLibraryView
                  handleCloseLibraryView={handleCloseLibraryView}
                  SampleLibraryImages={SampleLibraryImages}
                  previewBackground={previewUrl!}
                  handleLibraryImageClick={(link: string) => handleLibraryImageClick(link)}
                />
              )}
            </Box>
            {/* Preview */}
            {showPreview && (
              <CharacterSetModalPreview background={previewBackground} />
            )}
          </Box>
          {selectedThumbnailSource === 'color' && (
            <Box
              sx={{
                position: 'absolute',
                top: '45%',
                left: '80%',
                transform: 'translate(-80%, -45%)',
                width: '330px',
                outline: 'none',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  borderRadius: '20px',
                  background: '#FFF',
                  padding: '23px 12px',
                  boxSizing: 'border-box',
                  maxHeight: '480px',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                }}
              >
                <RWColorPicker
                  options={{
                    variant: 'character',
                  }}
                  color={color}
                  onChange={handleColorChange}
                  onChangeComplete={handleColorChangeCompleted}
                />
              </Box>
            </Box>
          )}
        </>
      </Modal>
    </div>
  );
}

/**
 * Default props for the `CreateCharacterSetModal` component.
 */
CreateCharacterSetModal.defaultProps = {
  setOpen: () => {},
};
