/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Carousel } from '@trendyol-js/react-carousel';
import {
  Box, Grid, Typography, styled,
} from '@mui/material';
import { ReactiveList, SearchBox } from '@appbaseio/reactivesearch';
import DiscoverNews from '../../../images/discover-news.png';
import FilterRealms from '../../../images/filter-icon.png';
import PlusIcon from '../../../images/plus-icon.png';
import FantasyIcon from '../../../images/fantasy-icon.png';
import CarouselCard from '../CarouselCard';
import RealmsCard from '../RealmsCard';
import { useAuth } from '../../../hooks/use-auth';
import CreateRealmPopup from '../../../components/modals/CreateRealmPopup/CreateRealmPopup';
import SubscriptionPopup from '../../../components/modals/SubscriptionPopup/SubscriptionPopup';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import { CreateRealmDocument, Realm, RealmInsertInput } from '../../../__generated__/graphql';
import PreviewRealmPopup from '../../../components/modals/PreviewRealmPopup/PreviewRealmPopup';

const StyledImage = styled('img')({
  width: '50px',
  height: '50px',
  flexShrink: 0,
  cursor: 'pointer',
});
const TopImage = styled('img')({
  width: '25px',
  height: '25px',
  flexShrink: 0,
  cursor: 'pointer',
});

export default function Discover(): JSX.Element {
  const auth = useAuth();
  const [openCreateRealmPopupModal, setopenCreateRealmPopupModal] = React.useState(false);
  const [openSubscriptionPopupModal, setopenSubscriptionPopupModal] = React.useState(false);
  const [openPreviewRealmPopup, setOpenPreviewRealmPopup] = React.useState(false);
  const [previewRealm, setPreviewRealm] = React.useState({});
  const { mutate: createRealm, error: createRealmError } = useGraphQLMutation(
    CreateRealmDocument,
  );
  useEffect(() => { }, [previewRealm]);
  const handleCreateRealm = async (props: any) => {
    const { realmName, realmBio, tags } = props;
    const userInput: RealmInsertInput = {
      name: realmName,
      description: realmBio,
      tags,
      is_favorited: true,
      is_public: false,
      owner: {
        link: auth?.realmUser?.id,
      },
    };
    try {
      // Use the createRealm mutation function from your GraphQL client
      const result = await createRealm({
        data: userInput,
      });

      console.log('Realm created:', result);
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error creating realm:', error.message);
      // Handle unexpected errors
    }
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 3,
        pl: 6.5,
        pr: 7.5,
        pb: 6.5,
      }}
    >
      <CreateRealmPopup open={openCreateRealmPopupModal} onClose={() => { console.log('modal closed'); }} setOpen={setopenCreateRealmPopupModal} createRealmHandler={handleCreateRealm} />
      <SubscriptionPopup open={openSubscriptionPopupModal} onClose={() => { console.log('modal closed'); }} setOpen={setopenSubscriptionPopupModal} />
      <PreviewRealmPopup open={openPreviewRealmPopup} onClose={() => { console.log('modal closed'); }} setOpen={setOpenPreviewRealmPopup} realm={previewRealm} />
      <Box
        display="flex"
        justifyContent="end"
      >
        <Box sx={{
          width: '366px',
          height: '40px',
          flexShrink: 0,
          borderRadius: '10px',
          background: '#FFF',
        }}
        >
          <SearchBox
            componentId="discover-searchbox"
            dataField={['name']}
            includeFields={['name', 'owner']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            customQuery={(value, props) => ({
              $search: {
                equals: {
                  path: 'is_public',
                  value: true,
                },
              },
            })}
            // @ts-ignore

          />
        </Box>
        {' '}
&nbsp;&nbsp;
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '91.627px',
          height: '40px',
          borderRadius: '10px',
          background: '#41521F',
        }}
        >
          {' '}
          <TopImage src={FantasyIcon} onClick={() => setopenSubscriptionPopupModal(true)} />
          <TopImage src={PlusIcon} onClick={() => setopenCreateRealmPopupModal(true)} />

        </Box>
      </Box>

      <Box sx={{ maxWidth: '80vw' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 10,
          }}
        >
          <Typography sx={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Passero One',
            fontSize: '100px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            paddingBottom: 5,
          }}
          >
            DISCOVER

          </Typography>
          <Carousel show={4.5} slide={3} swiping>
            {CarouselCard}
          </Carousel>
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledImage src={DiscoverNews} />
            {' '}
&nbsp;
            <Typography sx={{
              color: '#250503',
              textAlign: 'center',
              fontFamily: 'Passero One',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.1px',
            }}
            >
              Discover New Realms
            </Typography>
          </Box>
          <StyledImage src={FilterRealms} />
        </Box>

        <ReactiveList
          componentId="discover-results"
          dataField="_score"
          size={10}
          pagination
          innerClass={{
            pagination: 'list_pagination',
            resultStats: 'list_resultStats',
            resultsInfo: 'list_resultsInfo',
          }}
          className="dashboard_reactive_list"
          compoundClause="must"
          react={{
            and: ['discover-searchbox'],
          }}
          onError={(err) => console.log(err)}
          sortOptions={[
            {
              dataField: 'updated_at',
              sortBy: 'desc',
              label: 'Recent',
            },
            {
              dataField: 'updated_at',
              sortBy: 'asc',
              label: 'Oldest',
            },
            {
              dataField: 'name',
              sortBy: 'asc',
              label: 'Name - A-Z',
            }, {
              dataField: 'name',
              sortBy: 'desc',
              label: 'Name - Z-A',
            }]}
          showResultStats={false}
          render={({
            key, data, loading, resultStats,
          }: any) => (
            <Box>
              {/* <Typography>
                hi
                {' '}
                {JSON.stringify(resultStats)}
              </Typography> */}
              <Grid container spacing={2} m={1} key={data.id}>

                {data.map((item: Realm) => (
                  <RealmsCard
                    handleClick={() => {
                      setOpenPreviewRealmPopup(true); setPreviewRealm(item as any);
                      console.log('Log it', previewRealm);
                    }}
                    handleKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        setOpenPreviewRealmPopup(true);
                      }
                    }}
                    key={item._id}
                    realm={item}
                    realmId={item._id}
                    realmName={item.name}
                    realmDescription={item.description ?? ''}
                    thumbnail={item.thumbnail_url ?? ''}
                    isFavorited={item.is_favorited}
                    tags={item.tags ?? [] as string[]}
                  />
                ))}
              </Grid>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}
