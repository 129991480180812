import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface BodyPartSelectProps {
  label: string;
  value: any ;
  onChange: (event: any) => void;
  options: { __typename?: string | undefined; name?: string | null | undefined; _id?: any }[];
}

export default function ImageGenSelect({
  label, value, onChange, options,
}: BodyPartSelectProps) {
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        '& .MuiInputLabel-root.Mui-focused': {
          marginTop: 1,
          color: '#fff',
        },
        '& .MuiInputLabel-root': {
          color: '#fff',
          marginTop: 1,
        },

        '& .MuiSelect-select': {
          lineHeight: '20px',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          color: '#fff',
        },
      }}
    >
      <InputLabel
        sx={{
          '&': {
            lineHeight: '20px',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            color: 'red',
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={label}
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
          '& .MuiSelect-select': {
            borderBottom: '1px solid #fff',
          },
        }}
        MenuProps={{
          sx: {
            '.MuiList-root': {
              color: '#fff',
              background: 'black',
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
