import React, {
  ReactNode, createContext, useState, useMemo,
} from 'react';

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<any>({
  theme: 'light',
  setTheme: () => {},
});

function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setTheme] = useState('light');
  // Wrap the value prop in useMemo to optimize performance
  const contextValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme]);
  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}
export default ThemeProvider;
