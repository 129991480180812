/* eslint-disable  react/require-default-props */
import React from 'react';
import {
  Box, List, ListItem, ListItemText,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FloatingMenu, FloatingMenuItem } from '../../floatingMenu';
import styles from './filterdropdown.module.scss';
import { RWLibraryString } from '../../../interfaces/rwlibrary';
import { MyLibraryString } from '../../../interfaces/mylibrary';
import { MyCollectionString } from '../../../interfaces/mycollection';

interface Props {
  options: RWLibraryString[] | MyLibraryString[] | MyCollectionString[];
  setSelectedFilterOption: (option: RWLibraryString | MyLibraryString | MyCollectionString) => void;
  disableFirstOption: boolean;
}

function FilterDropDown({
  options, setSelectedFilterOption, disableFirstOption,
}: Props) {
  const [showMenu, setShowMenu] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleClickListItem = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Box sx={{ p: 0, position: 'relative' }}>
      <List
        component="nav"
        aria-label="Device settings"
        className={styles.filter_dropdown_list}
      >
        <ListItem
          id="lock-button"
          onClick={handleClickListItem}
          className={styles.filter_dropdown_list_item}
        >
          <ListItemText
            className={styles.filter_dropdown_list_item_text}
            primary={options[selectedIndex]}
          />
          { showMenu
            ? <KeyboardArrowUpIcon sx={{ color: '#fff' }} />
            : <KeyboardArrowDownIcon sx={{ color: '#fff' }} />}
        </ListItem>
      </List>
      {showMenu && (
        <Box
          sx={{
            p: 0,
            position: 'absolute',
            left: 0,
            width: '100%',
            zIndex: 5,
          }}
        >
          <FloatingMenu fullWidth>
            {options.map((option: RWLibraryString
            | MyLibraryString | MyCollectionString, index) => (
              <FloatingMenuItem
                key={option}
                disabled={disableFirstOption && index === 0}
                active={index === selectedIndex}
                onClick={() => {
                  setSelectedIndex(index);
                  setShowMenu(false);
                  setSelectedFilterOption(option);
                }}
              >
                {option}
              </FloatingMenuItem>
            ))}
          </FloatingMenu>
        </Box>
      )}
    </Box>
  );
}

export default FilterDropDown;
