import React, { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
// import { ArrowDropDown } from '@mui/icons-material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { FlexibleHeightImg, SectionHeadingText } from '../../styledComps/styledComps';
import HelpIcon from '../../../images/question-mark-1.webp';

interface Props {
  title: string,
  message: string,
  misc?: ReactNode | null,
}

function CharacterSectionWithHelp({
  title,
  message,
  misc,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <Box>
      <Stack justifyContent="space-between" direction="row">
        <SectionHeadingText sx={{ fontSize: '28px!important' }}>{title}</SectionHeadingText>
        <Stack direction="row" gap="10px">
          {misc && <Box>{misc}</Box>}
          <FlexibleHeightImg
            width="27px"
            src={HelpIcon}
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              cursor: 'pointer',
            }}
          />
          <KeyboardArrowDown
            className={`expansionArrow ${isExpanded ? 'rotated' : ''} cp`}
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              cursor: 'pointer',
            }}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          width: '100%',
          background: '#A3794A',
          height: '.5px',
          mt: '10px',
          mb: '20px',
        }}
      />
      {isExpanded && (
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '.1px',
            mb: '20px',
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
}

CharacterSectionWithHelp.defaultProps = {
  misc: null,
};

export default CharacterSectionWithHelp;
