import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './card.module.scss';

function EmptyCard() {
  return (
    <Box className={styles.empty_card_box}>
      <Typography className={styles.empty_card_text}>
        ?
      </Typography>
    </Box>
  );
}

export default EmptyCard;
