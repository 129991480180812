import React from 'react';
import { Box } from '@mui/material';
import './expansion.scss';
import useItem from '../../../hooks/useItem';
import useToken from '../../../hooks/useToken';
import { handleURLImageDrop } from '../../realms/TokenMaker/utils';
// import star from '../../../assets/images/star.png';

interface Props {
  aiImage: any;
}

function ExpansionAIImageItem({ aiImage }: Props) {
  const {
    createItem, removeItem,
  } = useItem();
  const {
    setToken,
  } = useToken();
  return (
    <Box sx={{ position: 'relative', width: 100, height: 100 }}>
      {aiImage?.image_url && aiImage?.image_url !== undefined ? (
        <Box
          onClick={() => {
            if (aiImage && aiImage.image_url) {
              handleURLImageDrop(aiImage.image_url, createItem, removeItem, setToken);
            }
          }}
        >
          <img src={aiImage.thumbnail_url ? aiImage.thumbnail_url : aiImage.image_url} alt={aiImage.name} className="expansionTokenItemImage" />
        </Box>
      ) : (
        <span>No thumbnail available</span>
      )}
      {/* {aiImage?.is_favorited === true && (
        <img src={star} alt="favorited" className="expansionTokenItemStar" />
      )} */}
    </Box>
  );
}

export default ExpansionAIImageItem;
