/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { RiUserSettingsFill } from 'react-icons/ri';
import characterPlaceholder from '../../images/character-placeholder.webp';
import s from './MyCharacters.module.scss';
import useGraphQL from '../../hooks/useGraphQL';
import { GetCharactersDocument } from '../../__generated__/graphql';

interface CharacterProps {
  id: string;
}

/**
 * Component that displays a list of user's characters.
 *
 * @component
 * @example
 * // Example usage of MyCharacters component
 * <MyCharacters id="user123" />
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier of the user.
 *
 * @returns {JSX.Element} React element representing a list of user's characters.
 */

const MyCharacters = ({ id }: CharacterProps) => {
  const {
    data: allCharacters,
    error: characterError,
    isLoading,
  } = useGraphQL(GetCharactersDocument, {
    limit: 6,
  });

  console.log('all characters', allCharacters);

  return (
    <div className={s.container}>
      <div className={s.box}>
        <p className={s.box__heading}>My Characters</p>
        <button type="button" className={s.box__button}>
          <RiUserSettingsFill />
          <p className={s.box__button__text}>Character Catalog</p>
        </button>
      </div>
      <div className={s.characterBox}>
        {allCharacters?.characters.map((character, index) => (
          <div key={index} className={s.characterContainer}>
            <div className={s.characterContainer__imageWrapper}>
              <img
                src={character?.character_image_thumbnail_url!}
                alt="character image"
                className={s.characterContainer__image}
              />
            </div>
            <div className={s.characterContainer__box}>
              <p className={s.characterContainer__box__title}>
                {character?.name}
              </p>
              <p className={s.characterContainer__box__type}>
                {character?.race}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={s.border} />
    </div>
  );
};

export default MyCharacters;
