/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Carousel } from '@trendyol-js/react-carousel';
import {
  Box, Button, Card, CardContent, CardMedia, Grid, Typography, styled,
} from '@mui/material';
import { ReactiveList, SearchBox } from '@appbaseio/reactivesearch';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import FilterRealms from '../../../images/filter-icon.png';
import PlusIcon from '../../../images/plus-icon.png';
import FantasyIcon from '../../../images/fantasy-icon.png';
import DashboardBG from '../../../images/dashboard-bg.png';
import FavoritesIcon from '../../../images/favorites-icon.svg';
import RealmsIcon from '../../../images/realms-icon.svg';
import CarouselCard from '../CarouselCard';
import RealmsCard from '../RealmsCard';
import CreateRealmPopup from '../../../components/modals/CreateRealmPopup/CreateRealmPopup';
import PreviewRealmPopup from '../../../components/modals/PreviewRealmPopup/PreviewRealmPopup';
import SubscriptionPopup from '../../../components/modals/SubscriptionPopupLight/SubscriptionPopupLight';
import { CreateRealmDocument, Realm, RealmInsertInput } from '../../../__generated__/graphql';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import { useAuth } from '../../../hooks/use-auth';
import appStore from '../../../store/AppStore';
import { NavString } from '../../../interfaces/navitems';

const StyledImage = styled('img')({
  width: '50px',
  height: '50px',
  flexShrink: 0,
  cursor: 'pointer',
});
const TopImage = styled('img')({
  width: '25px',
  height: '25px',
  flexShrink: 0,
  cursor: 'pointer',
});

interface RealmData extends RealmInsertInput {
  realmName?: string,
  realmBio?: string,
  tags?: string[],
  is_favorited: boolean,
  is_public: any,
  owner:any
}

export default function Realms(): JSX.Element {
  const navigate = useNavigate();
  const auth = useAuth();
  const [openCreateRealmPopupModal, setOpenCreateRealmPopupModal] = React.useState(false);
  const [openSubscriptionPopupModal, setOpenSubscriptionPopupModal] = React.useState(false);
  const [openPreviewRealmPopup, setOpenPreviewRealmPopup] = React.useState(false);
  const [previewRealm, setPreviewRealm] = React.useState({});
  const activeItem: NavString | null = appStore((state) => state.activeNavItem);
  const { mutate: createRealm, error: createRealmError } = useGraphQLMutation(
    CreateRealmDocument,
  );

  useEffect(() => { }, [previewRealm]);
  const handleCreateRealm = async (props: any) => {
    const { realmName, realmBio, tags } = props;
    const userInput: RealmInsertInput = {
      name: realmName,
      description: realmBio,
      tags,
      is_favorited: true,
      is_public: false,
      owner: {
        link: auth?.realmUser?.id,
      },
    };
    try {
      // Use the createRealm mutation function from your GraphQL client
      const result = await createRealm({
        data: userInput,
      });

      console.log('Realm created:', result);
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error creating realm:', error.message);
      // Handle unexpected errors
    }
  };
  const styles = {
    exploreRealmButton: {
      mt: 3,
      mb: 4,
      height: 60,
      width: '190px',
      borderRadius: 4,
      background: '#41521F',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    createRealmButton: {
      mt: 3,
      mb: 4,
      height: 60,
      width: '298px',
      borderRadius: 4,
      background: '#772900',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        minWidth: '86vw',
      }}
    >
      <CreateRealmPopup
        open={openCreateRealmPopupModal}
        onClose={() => { console.log('modal closed'); }}
        setOpen={setOpenCreateRealmPopupModal}
        createRealmHandler={handleCreateRealm}
      />
      <SubscriptionPopup open={openSubscriptionPopupModal} onClose={() => { console.log('modal closed'); }} setOpen={setOpenSubscriptionPopupModal} />
      <PreviewRealmPopup open={openPreviewRealmPopup} onClose={() => { console.log('modal closed'); }} setOpen={setOpenPreviewRealmPopup} realm={previewRealm} />
      <Box
        display="flex"
        justifyContent="end"
      >
        <Box sx={{
          width: '366px',
          height: '40px',
          flexShrink: 0,
          borderRadius: '10px',
          background: '#FFF',
        }}
        >
          <SearchBox
            componentId="realms-searchbox"
            dataField={['name']}
            includeFields={['name', 'owner']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            // onKeyDown={(e, triggerQuery) => {
            //   if (e.key === 'Enter') {
            //     triggerQuery();
            //   }
            // }}
            // @ts-ignore
            renderItem={(res: any) => (
              <Button onClick={() => navigate(`/realm/${res._id}/campaign`)}>
                {' '}
                {res._source.name}
                {' '}
              </Button>
            )}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // customQuery={(value, props) => ({
            //   query: {
            //     bool: {
            //       must: [
            //         {
            //           match: {
            //             name: value,
            //           },
            //         },
            //         {
            //           match: {
            //             owner: 'your_owner_id',
            //           },
            //         },
            //       ],
            //     },
            //   },
            // })}
          />
        </Box>
        {' '}
&nbsp;&nbsp;
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '91.627px',
          height: '40px',
          borderRadius: '10px',
          background: '#41521F',
        }}
        >
          {' '}
          <TopImage src={FantasyIcon} onClick={() => setOpenSubscriptionPopupModal(true)} />
          <TopImage src={PlusIcon} onClick={() => setOpenCreateRealmPopupModal(true)} />

        </Box>
      </Box>

      <Box sx={{ maxWidth: '80vw' }}>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledImage src={FavoritesIcon} />
          {' '}
&nbsp;
          <Typography sx={{
            color: '#250503',
            textAlign: 'center',
            fontFamily: 'Passero One',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
          }}
          >
            Favorites
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{
            color: '#250503',
            textAlign: 'center',
            fontFamily: 'DM Sans',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
          }}
          >
            Your collection is empty...
          </Typography>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={() => appStore.setState({
              activeNavItem: 'Discover',
            })}
            sx={styles.exploreRealmButton}
          >
            Explore Realms
          </LoadingButton>
        </Box>

        <Box sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledImage src={RealmsIcon} />
            {' '}
&nbsp;
            <Typography sx={{
              color: '#250503',
              textAlign: 'center',
              fontFamily: 'Passero One',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.1px',
            }}
            >
              Realms Library
            </Typography>
          </Box>
          <StyledImage src={FilterRealms} />
        </Box>

        <ReactiveList
          componentId="realms-results"
          dataField="_score"
          size={10}
          pagination
          innerClass={{
            pagination: 'list_pagination',
            resultStats: 'list_resultStats',
            resultsInfo: 'list_resultsInfo',
          }}
          className="dashboard_reactive_list"
          compoundClause="must"
          react={{
            and: ['realms-searchbox'],
          }}
          onError={(err) => console.log(err)}
          sortOptions={[
            {
              dataField: 'updatedAt',
              sortBy: 'desc',
              label: 'Recent',
            },
            {
              dataField: 'updatedAt',
              sortBy: 'asc',
              label: 'Oldest',
            },
            {
              dataField: 'name',
              sortBy: 'asc',
              label: 'Name - A-Z',
            }, {
              dataField: 'name',
              sortBy: 'desc',
              label: 'Name - Z-A',
            }]}
          showResultStats={false}
          render={({
            key, data, loading, resultStats,
          }: any) => (
            <Box>
              <Typography>

                {/* {'DEBUG: '}
                {JSON.stringify(resultStats)}
                {' Loading: '}
                {JSON.stringify(loading)} */}
              </Typography>
              <Grid container spacing={2} m={1} key={data.id}>

                {data ? data.map((item: Realm) => (
                  <RealmsCard
                    handleClick={() => {
                      setOpenPreviewRealmPopup(true); setPreviewRealm(item as any);
                      console.log('Log it', previewRealm);
                    }}
                    handleKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        setOpenPreviewRealmPopup(true);
                      }
                    }}
                    realm={item}
                    realmId={item._id}
                    realmName={item.name}
                    realmDescription={item.description ?? ''}
                    thumbnail={item.thumbnail_url ?? ''}
                    isFavorited={item.is_favorited}
                    tags={item.tags ?? [] as string[]}
                  />
                ))
                  : (
                    <Box sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '0 auto',
                    }}
                    >

                      <Typography sx={{
                        color: '#250503',
                        textAlign: 'center',
                        fontFamily: 'DM Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                      }}
                      >
                        Nothing to see here...You don’t have anything in your library yet!
                      </Typography>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={() => setOpenCreateRealmPopupModal(true)}
                        sx={styles.createRealmButton}
                      >
                        Create New Realm
                      </LoadingButton>
                    </Box>
                  )}
                {!data && (
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledImage src={RealmsIcon} />
                    {' '}
&nbsp;
                    <Typography sx={{
                      color: '#250503',
                      textAlign: 'center',
                      fontFamily: 'Passero One',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                    }}
                    >
                      Explore More!
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      pt: 3,
                    }}
                  >

                    <Carousel show={4} slide={4} swiping>
                      {CarouselCard}
                    </Carousel>
                  </Box>
                </Box>
                )}
              </Grid>
            </Box>
          )}
        />

      </Box>
    </Box>
  );
}
