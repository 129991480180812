import React from 'react';
import {
  InputBase,
  Stack, Tooltip, Typography, Zoom,
} from '@mui/material';
import WingedImg from '../../../images/winged-backdrop.webp';
import HelpIcon from '../../../images/help.webp';
import { HelpImg } from '../../styledComps/styledComps';

interface Props {
  variant?: 'characterGen' | 'characterSummary' | 'characterWizard1' | 'characterWizard2',
  title: string,
  value: number,
  tip: string,
  onIncrease?: () => void,
  onChange: (val: any) => void,
}

/**
 * Represents a component for displaying character abilities.
 *
 * @component
 * @example
 * // Example usage of CharacterAbility component
 * <CharacterAbility title="Strength" value={18} variant="characterGen" />
 *
 * @param {object} props - The properties of the component.
 * @param {("characterGen" | "characterSummary")} [props.variant="characterGen"]
 * - The variant of the component.
 * @param {string} props.title - The title of the ability.
 * @param {number} props.value - The value of the ability.
 * @returns {JSX.Element} React element representing character ability.
 */
function CharacterAbility({
  variant,
  title,
  value,
  tip,
  onIncrease,
  onChange,
}: Props) {
  return (
    <Stack
      justifyContent="space-between"
      sx={{
        width: variant === 'characterGen' ? '154px' : '136px',
        height: variant === 'characterGen' ? '122px' : '109px',
        backgroundImage: `url(${WingedImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        pt: variant === 'characterGen' ? '15px' : '13px',
        pb: variant === 'characterGen' ? '7px' : '6px',
        boxSizing: 'border-box',
        position: 'relative',
      }}
    >
      <Stack direction="row" gap="5px" justifyContent="center">
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
        {variant === 'characterGen' && (
          <Tooltip
            title={tip}
            arrow
            placement="bottom"
            TransitionComponent={Zoom}
            componentsProps={{
              tooltip: {
                sx: {
                  fontFamily: 'Passero One',
                  fontWeight: '400px',
                  fontSize: '14px',
                  padding: '10px',
                  bgcolor: '#3c3c3ce6',
                  '& .MuiTooltip-arrow': {
                    color: '#3c3c3ce6',
                  },
                  maxWidth: '250px',
                },
              },
            }}
          >
            <HelpImg src={HelpIcon} />
          </Tooltip>
        )}
      </Stack>
      {(variant === 'characterSummary' || variant === 'characterWizard2') && (
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'Passero One',
            fontSize: '50px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '1',
            mt: '-24px!important',
            textAlign: 'center',
            display: 'inline-block',
          }}
        >
          {value}
        </Typography>
      )}
      {(variant === 'characterGen' || variant === 'characterWizard1') && (
        <InputBase
          sx={{
            border: 'none',
            outline: 'none',
            input: {
              color: '#250503',
              fontFamily: 'Passero One',
              fontSize: '60px',
              fontStyle: 'normal',
              fontWeight: 400,
              width: '90px',
              textAlign: 'center',
              padding: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              // lineHeight: '1',
              mt: '-5px!important',
              // mb: '-24px!important',
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none!important',
                margin: 0,
              },
              '&[type=number]': {
                MozAppearance: 'textfield!important',
              },
            },
            'MuiInputBase-root': {
              outline: 'none!important',
              border: 'none!important',
              padding: 0,
            },
          }}
          type="number"
          value={value}
          onChange={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            onChange(parseInt(event.target.value, 10));
          }}
          inputProps={{ min: 0 }}
        />
      )}
      <Typography
        sx={{
          color: 'white',
          fontFamily: 'DM Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          textAlign: 'center',
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={onIncrease}
      >
        {variant === 'characterGen' || variant === 'characterWizard1' ? '+2' : ''}
      </Typography>
    </Stack>
  );
}

CharacterAbility.defaultProps = {
  variant: 'characterGen',
  onIncrease: () => {},
};

export default CharacterAbility;
