import React from 'react';
import {
  Box, Stack, Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SampleNote } from '../../store/AppStore';
import { AttributeText } from '../styledComps/styledComps';
import { CharacterLong_Term_Goal, CharacterShort_Term_Goal } from '../../__generated__/graphql';

interface Props {
  note: SampleNote | CharacterShort_Term_Goal | CharacterLong_Term_Goal,
}

/**
 * Represents a view for displaying a character note.
 *
 * @component
 * @example
 * // Example usage of CharacterNoteView component
 * <CharacterNoteView note={{ title: 'Note Title', note: 'This is a sample note.' }} />
 *
 * @param {object} props - The properties of the component.
 * @param {SampleNote} props.note - The note data to be displayed.
 * @returns {JSX.Element} React element representing a character note view.
 */
function CharacterNoteView({
  note,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py="11px"
        pl="23px"
        sx={{
          borderBottom: '1px solid #BCAD93',
        }}
      >
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontWeight: '400px',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '.5px',
          }}
        >
          {note?.title}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="5px" width="140px">
          <Box p="5px" width="34px" maxHeight="34px" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? (
              <KeyboardArrowUpIcon sx={{ color: '#250503' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: '#250503' }} />
            )}
          </Box>
        </Stack>
      </Stack>
      {isExpanded && (
        <Box
          sx={{
            background: '#F7F2E9',
            p: '8px 16px 8px 23px',
          }}
        >
          <AttributeText>
            {note?.content}
          </AttributeText>
        </Box>
      )}
    </>
  );
}

export default CharacterNoteView;
