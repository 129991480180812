import React from 'react';
import CraftCharacterStart from '../../../components/realms/Character/CraftCharacterStart';
import appStore, { CreateCharacterViewOptions } from '../../../store/AppStore';
import CharacterSheet from '../../../components/realms/Character/CharacterSheet';
import CharacterWizard from '../../../components/realms/Character/CharacterWizard';

function CharacterCreator() {
  const createCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.createCharacterView,
  );

  const defaultCreateCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.defaultCreateCharacterView,
  );

  // eslint-disable-next-line max-len
  const [selectedView, setSelectedView] = React.useState<CreateCharacterViewOptions>(defaultCreateCharacterView);

  React.useEffect(() => {
    setSelectedView(defaultCreateCharacterView);
  }, []);

  React.useEffect(() => {
    setSelectedView(createCharacterView);
  }, [createCharacterView]);

  return (
    <>
      {selectedView === 'start' && (
        <CraftCharacterStart />
      )}
      {selectedView === 'sheet' && (
        <CharacterSheet />
      )}
      {selectedView === 'wizard' && (
        <CharacterWizard />
      )}
    </>
  );
}

export default CharacterCreator;
