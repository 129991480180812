import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface Props {
  previewUrl: string | null,
  imageUrl: string,
  handleLibraryImageClick: (link: string) => void;
}

const CharacterImg = styled('img')({
  width: '100px',
  height: '90px',
  objectFit: 'cover',
  objectPosition: 'center',
  boxSizing: 'border-box',
  cursor: 'pointer',
  borderRadius: '5px',
  '&.selected': {
    border: 'solid 5px #41521F',
    boxShadow: '0px 4px 10px 0px #41521F',
  },
});

function CharacterLibraryExpansionItem({
  previewUrl,
  imageUrl,
  handleLibraryImageClick,
}: Props) {
  return (
    <Box
      onClick={() => handleLibraryImageClick(imageUrl)}
    >
      <CharacterImg
        src={imageUrl}
        className={previewUrl === imageUrl ? 'selected' : ''}
      />
    </Box>
  );
}

export default CharacterLibraryExpansionItem;
