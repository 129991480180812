/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import s from './DetailView.module.scss';
import EmptyFavoriteStar from '../../assets/images/empty-star.png';
import FullFavoriteStar from '../../assets/images/full-star.png';
import useGraphQL from '../../hooks/useGraphQL';
import { GetTokenSetsDocument } from '../../__generated__/graphql';

interface WindowProps {
  id: string;
  isFavorited: boolean | undefined;
  name: string | undefined;
  description: string | undefined | null;
  tags: any;
}

/**
 * Represents a component for displaying detailed information about a realm in a side window.
 *
 * @component
 * @example
 * // Example usage of SideWindow component
 * <SideWindow
 *   id="realm_id"
 *   isFavorited={true}
 *   name="Realm Name"
 *   description="Realm Description"
 *   tags={['Tag1', 'Tag2', 'Tag3']}
 * />
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier of the realm.
 * @param {boolean} props.isFavorited - Indicates whether the realm is favorited.
 * @param {string} props.name - The name of the realm.
 * @param {string|null|undefined} props.description - The description of the realm.
 * @param {Array} props.tags - An array of tags associated with the realm.
 * @returns {JSX.Element} React element representing the side window for displaying realm details.
 */

const SideWindow = ({
  id,
  isFavorited,
  name,
  description,
  tags,
}: WindowProps) => {
  const {
    data: tokens,
    error: tokenError,
    isLoading,
  } = useGraphQL(GetTokenSetsDocument, {
    query: {
      realm: {
        _id: id,
      },
    },
  });

  // console.log('tokens', tokens);

  const hasTokens = tokens?.token_sets[0]?.tokens;

  return (
    <div key={id} className={s.window__realmRow}>
      <img
        src={isFavorited ? FullFavoriteStar : EmptyFavoriteStar}
        alt="favourite icon"
        className={s.window__realmIcon}
      />

      <div className={s.window__realmWrapper}>
        <div className={s.window__realmInfo}>
          <p className={s.window__realmInfo__title}>{name}</p>
          <div className={s.window__realmInfo__bottom}>
            {tags.slice(0, 3)?.map((tag: any, index: number) => (
              <div
                key={index}
                className={
                  tag?.split(' ').length === 1
                    ? s.window__realmInfo__tag
                    : s.window__realmInfo__longTag
                }
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
        <p className={s.window__realmParagraph}>{description}</p>
        <div className={s.window__realmTokens}>
          {hasTokens ? (
            tokens?.token_sets[0]?.tokens
              ?.slice(-3)
              .map((token: any, index) => (
                <img
                  key={index}
                  src={token.thumbnail_url}
                  alt="token"
                  className={s.window__realmTokens__image}
                />
              ))
          ) : isLoading ? (
            <p className={s.window__realmTokens__text}>
              Loading tokens...please wait
            </p>
          ) : (
            <p className={s.window__realmTokens__text}>
              You have no tokens, Enter realm to create one.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideWindow;
