/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { TableFooter, Typography } from '@mui/material';

import FreeShield from '../../images/free-sub.png';
import SilverShield from '../../images/silver-sub.png';
import GoldShield from '../../images/gold-sub.png';
import PlatinumShield from '../../images/platinum-sub.png';

const ShieldImg = styled('img')({
  width: '100px',
  height: '100px',
  flexShrink: 0,
});

const LevelUpButton = styled(LoadingButton)({
  background: '#41521F',
  borderRadius: 4,
  color: '#fff',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',
  whiteSpace: 'nowrap', // Shift the button to the right

  '&:hover': {
    backgroundColor: '#fff',
    color: '#41521F',
  },
});

const ExitButton = styled(LoadingButton)({
  background: '#870000',
  borderRadius: 4,
  color: '#fff',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',
  whiteSpace: 'nowrap',
  marginLeft: 'auto', // Shift the button to the right

  '&:hover': {
    backgroundColor: '#fff',
    color: '#870000',
  },
});
function createData(
  name: string,
  free: number | string,
  silver: number | string,
  gold: number | string,
  platinum: number | string,
) {
  return {
    name, free, silver, gold, platinum,
  };
}

const rows = [
  createData('Realms Managed', 1, 5, 10, 25),
  createData('Campaigns', 1, 5, 10, 25),
  createData('Story-Acts', 1, 5, 10, 25),
  createData('RW Library Ready to use Campaigns', '✘', '✓', '✓', '✓'),
  createData('RW Library - Stories', '✘', '✓', '✓', '✓'),
  createData('RW Library - Character Mod', '✘', '✓', '✓', '✓'),
  createData('RW Library - Token Maker', '✘', '✓', '✓', '✓'),
  createData('AI Generation', '✘', '✓', '✓', '✓'),
  createData(
    'Picture Resolution',
    '512 x 512',
    '512 x 512',
    'up to 1024 x 1024',
    'up to 1536 x 1536',
  ),
  createData('Content Packs', '✘', '✘', '1 free a month', '3 free a month'),
  createData('Credits', 0, 50, 400, '1,000'),
  createData('Slow Tier', '✘', '✓', '✓', '✓'),
  createData('Fast Tier', '✘', '✘', '✘', '✓'),
];

export default function DenseTable() {
  return (
    <TableContainer component={Paper} sx={{ padding: '32px', margin: '16px', background: '#E8D4B2' }}>
      <Table
        sx={{ minWidth: 1024, background: '#E8D4B2' }}
        size="medium"
        aria-label="Subscription page"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              {' '}
              <ExitButton>X Exit</ExitButton>
            </TableCell>
            <TableCell align="center">
              <ShieldImg src={FreeShield} />
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >

                FREE

              </Typography>
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >
                <br />
                -
              </Typography>

            </TableCell>
            <TableCell align="center">
              <ShieldImg src={SilverShield} />
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >

                SILVER

              </Typography>
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >
                <br />
                $7.99
                <span style={{ fontSize: '16px' }}>/Month</span>
              </Typography>

            </TableCell>
            <TableCell align="center">
              <ShieldImg src={GoldShield} />
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >

                GOLD

              </Typography>
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >
                <br />
                $19.99
                <span style={{ fontSize: '16px' }}>/Month</span>
              </Typography>

            </TableCell>
            <TableCell align="center">
              <ShieldImg src={PlatinumShield} />
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >

                PLATINUM

              </Typography>
              <Typography sx={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Passero One',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
              }}
              >
                <br />
                $34.99
                <span style={{ fontSize: '16px' }}>/Month</span>
              </Typography>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                whiteSpace: 'nowrap',
              }}
            >
              <TableCell component="th" scope="row" sx={{ color: '#000' }}>
                {row.name}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color:
                    row.free === '✓'
                      ? '#70FF00'
                      : row.free === '✘'
                        ? '#EA1E1E'
                        : '#000',
                  whiteSpace: 'nowrap',
                }}
              >
                {row.free}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: '#727272',
                  color:
                    row.silver === '✓'
                      ? '#70FF00'
                      : row.silver === '✘'
                        ? '#EA1E1E'
                        : '#000',
                  whiteSpace: 'nowrap',
                }}
              >
                {row.silver}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: '#A89B59',
                  color:
                    row.gold === '✓'
                      ? '#70FF00'
                      : row.gold === '✘'
                        ? '#EA1E1E'
                        : '#000',
                  whiteSpace: 'nowrap',
                }}
              >
                {row.gold}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: '#748E82',
                  color:
                    row.platinum === '✓'
                      ? '#70FF00'
                      : row.platinum === '✘'
                        ? '#EA1E1E'
                        : '#000',
                  whiteSpace: 'nowrap',
                }}
              >
                {row.platinum}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ color: '#000' }}> *If user has purchased any credits</TableCell>
            <TableCell align="center" />
            <TableCell align="center">
              <LevelUpButton>Level Up</LevelUpButton>
            </TableCell>
            <TableCell align="center">
              <LevelUpButton>Level Up</LevelUpButton>
            </TableCell>
            <TableCell align="center">
              <LevelUpButton>Level Up</LevelUpButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
