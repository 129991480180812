/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Button, Card, CardContent, CardMedia, Grid, Typography, styled,
} from '@mui/material';
import React, { useState } from 'react';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useNavigate } from 'react-router-dom';
import { Star } from '@mui/icons-material';
import { GetTokenSetsDocument, Maybe } from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';

const StyledTitle = styled(Typography)({
  width: '135px',
  color: '#250503',
  fontFamily: 'Passero One',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textDecorationLine: 'underline',
  overflow: 'hidden', // Prevents text from overflowing
  whiteSpace: 'nowrap', // Prevents text from wrapping
  textOverflow: 'ellipsis',
});

interface RealmsCardProps {
  realm: object;
  realmId: string;
  realmName: string;
  realmDescription: string;
  thumbnail: string;
  // realmIsPublic: boolean;
  isFavorited: boolean;
  // realmOwner: string;
  tags: Maybe<Maybe<string>[]>;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  handleClick?: React.MouseEventHandler;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  handleKeyDown?: React.KeyboardEventHandler;
}

const RealmsCard: React.FC<RealmsCardProps> = ({
  // eslint-disable-next-line max-len
  realm, realmId, realmName, realmDescription, thumbnail, isFavorited, tags, handleClick, handleKeyDown,
}) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState('https://api.example.com/image-url.jpg');
  const [isImageBroken, setIsImageBroken] = useState(false);
  const starRightPosition = isImageBroken ? '2%' : '2%';
  const starRightPositionLarge = isImageBroken ? '8%' : '3%';
  const starRightPositionSmall = isImageBroken ? '22%' : '24%';

  const handleImageError = () => {
    setIsImageBroken(true);
  };

  const { data: tokenSets } = useGraphQL(
    GetTokenSetsDocument,
    {
      query: {
        realm: {
          _id: realmId,
        },
      },
    },
  );

  // console.log(`Realm: ${realmId}, /n TokenSets: ${JSON.stringify(tokenSets?.token_sets)}`);

  return (
    <Grid
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      item
      xs={3}
      sx={{
        p: 0.5,
        cursor: 'pointer',
      }}
      key={realmId}
    >

      <Card
        sx={{
          display: 'flex',
          borderRadius: '5px',
          background: '#FFF',
          border: '1px solid white',
          maxHeight: 180,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <CardContent>
            <StyledTitle>
              {realmName}
            </StyledTitle>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                maxHeight: '4em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 3, // Limit to three lines
                '-webkit-box-orient': 'vertical',
              }}
            >
              {realmDescription}
            </Typography>
            <Box display="flex" justifyContent="flex-start" pt={1}>
              <Button sx={{
                borderRadius: '5px',
                background: '#A89F68',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
              >
                <Typography sx={{
                  display: 'flex',
                  height: '18px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                  color: '#41521F',
                  textAlign: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                >
                  {tags && tags.length >= 1 ? tags[0] : 'Tag'}
                </Typography>
              </Button>
              {' '}
            &nbsp;
              <Button sx={{
                borderRadius: '5px',
                background: '#A89F68',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
              >
                <Typography sx={{
                  display: 'flex',
                  height: '18px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                  color: '#41521F',
                  textAlign: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                >
                  {tags && tags.length >= 2 ? tags[1] : ' Long Tag'}
                </Typography>
              </Button>
            </Box>
            <Box display="flex" pt={1}>
              <Button
                sx={{
                  borderRadius: '5px',
                  background: '#41521F',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                }}
                onClick={() => {
                  navigate(`/realm/${realmId}/character`);
                }}
              >
                <Typography sx={{
                  display: 'flex',
                  height: '18px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                  color: '#9fa986',
                  textAlign: 'center',
                  fontFamily: 'Passero One',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                >
                  Enter Realm
                </Typography>
              </Button>
            </Box>

          </CardContent>
        </Box>
        <Box sx={{
          flex: 1, width: 360, maxHeight: 180, position: 'relative',
        }}
        >
          {(isImageBroken || !thumbnail) ? (
            <CardMedia
              component="img"
              sx={{ minWidth: 194, minHeight: 180 }}
              image="https://picsum.photos/194"
              alt="This is a placeholder"
              onError={handleImageError}
            />
          ) : (
            <CardMedia
              component="img"
              sx={{ minWidth: 194, minHeight: 180 }}
              image={thumbnail}
              alt="Live from space album cover"
              onError={handleImageError}
            />
          )}

          {isFavorited ? (
            <Star
              sx={{
                position: 'absolute',
                top: '15%',
                right: starRightPosition,
                transform: 'translate(-50%, -50%)',
                color: '#F8D979',
                fontFamily: 'Passero One',
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                '@media (max-width: 1800px)': {
                  right: starRightPositionLarge,
                },
                '@media (max-width: 1700px)': {
                  right: '20%',
                },
              }}
            />
          ) : (
            <StarBorderIcon sx={{
              position: 'absolute',
              top: '15%',
              right: starRightPosition,
              transform: 'translate(-50%, -50%)',
              textDecoration: 'underline',
              color: '#F8D979',
              fontFamily: 'Passero One',
              fontSize: '40px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              '@media (max-width: 1700px)': {
                right: starRightPositionSmall,
              },
              '@media (max-width: 1800px)': {
                right: starRightPositionLarge,
              },
            }}
            />
          )}
        </Box>
      </Card>
    </Grid>
  );
};
export default RealmsCard;
