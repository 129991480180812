/* eslint-disable react/require-default-props */
import React from 'react';
import s from './Spinner.module.scss';

interface SpinnerProps {
  hero?: boolean;
  realm?: boolean;
}

/**
 * A simple spinner component that indicates loading or processing.
 *
 * @component
 * @example
 * // Example usage of Spinner component
 * <Spinner hero />
 *
 * @param {Object} props - The properties of the component.
 * @param {boolean} [props.hero] - Indicates whether the spinner is styled as a hero spinner.
 *
 * @returns {JSX.Element} React element representing a spinner.
 */

const Spinner = ({ hero, realm }: SpinnerProps) => (
  <div
    className={`${s.spinner} ${hero ? s.spinner__hero : ''} ${
      realm ? s.spinner__realm : ''
    }`}
  />
);

export default Spinner;
