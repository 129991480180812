/* eslint-disable react/no-array-index-key */
import React from 'react';
import s from './LinesWithDates.module.scss';

interface LineWithDatesProps {
  selectedCampaignIndex: number;
}

/**
 * LinesWithDates component representing a line with circles indicating dates for campaigns.
 *
 * @component
 * @example
 * // Example usage of LinesWithDates component
 * <LinesWithDates selectedCampaignIndex={3} />
 *
 * @param {Object} props - The properties of the LinesWithDates component.
 * @param {number} props.selectedCampaignIndex - The index of the selected campaign.
 *
 * @returns {JSX.Element} React element representing the LinesWithDates component.
 */

const LinesWithDates = ({ selectedCampaignIndex }: LineWithDatesProps) => {
  /**
   * Inline style for the line container.
   * @type {React.CSSProperties}
   */

  const lineStyle: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <div className={`${s.lineWithDates}`} style={lineStyle}>
      {Array.from({ length: 8 }).map((_, index) => (
        <div
          key={index}
          className={`${s.circle} ${
            index === selectedCampaignIndex ? s.selected : ''
          }`}
        />
      ))}
    </div>
  );
};

export default LinesWithDates;
