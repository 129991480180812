import React from 'react';
import notes from '../../assets/images/NewDashboard/note_icon.png';
import s from './CampaignManager.module.scss';
import Campaign from './Campaign';

/**
 * CampaignManager component representing a container for managing campaigns.
 *
 * @component
 * @example
 * // Example usage of CampaignManager component
 * <CampaignManager />
 *
 * @returns {JSX.Element} React element representing the CampaignManager component.
 */

const CampaignManager = () => (
  <div className={s.container}>
    <div className={s.box}>
      <p className={s.box__heading}>My Campaigns</p>
      <button type="button" className={s.box__button}>
        <img src={notes} alt="notes icon" className={s.box__button__image} />
        <p className={s.box__button__text}>Campaign Manager</p>
      </button>
    </div>
    <Campaign />
  </div>
);

export default CampaignManager;
