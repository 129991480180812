/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import TabPanel from '../../tab/TabPanel';
import {
  StyledTab,
  StyledTabs,
  setTabId,
} from '../StyledPanel/StyledPanel';
import { PanelItemProps } from '../FramePanel/FramePanel';
import GoldImg from '../../../images/gold-small.png';
import SampleImg from '../../../images/image124.png';
// import { ThemeContext } from '../../../context/ThemeContext';

import '../eachpanelmodifier.scss';
import FixedFilter from '../../fixedFilter/FixedFilter';
import Expansion from '../Expansion/Expansion';
import LibraryContentPacksExample from '../../../views/Dashboard/Favorites/LibraryContentPacksExample';
import appStore from '../../../store/AppStore';
import { RWLibraryString } from '../../../interfaces/rwlibrary';
import { MyLibraryString } from '../../../interfaces/mylibrary';
import { MyCollectionString } from '../../../interfaces/mycollection';
import RWLibrary from './RWLibrary/RWLibrary';
import MyLibrary from './MyLibrary/MyLibrary';

// Need a separate list for RW and My Libs
const optionsRWLib: RWLibraryString[] = [
  'Filter',
  // 'Favorites',
  'Token Packs',
  'Tokens',
  'Realms',
  'A-Z',
  'Z-A',
];

const optionsMyLib: MyLibraryString[] = [
  'Sort',
  'Favorites',
  'Tokens',
  'Realms',
  'A.I. Generated',
  'A-Z',
  'Z-A',
];
const images = [
  SampleImg, SampleImg, SampleImg, SampleImg, SampleImg, SampleImg,
];
export default function LibraryPanel({ onStageChange }: PanelItemProps) {
  const [tabValue, setTabValue] = React.useState(0);
  const activeRWLibraryFilter:
  RWLibraryString | MyLibraryString | null = appStore(
    (state) => state.activeRWLibraryFilter,
  );

  const activeMyLibraryFilter:
  MyLibraryString | null = appStore(
    (state) => state.activeMyLibraryFilter,
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [rwLibraryFilter, setRWLibraryFilter] = useState<RWLibraryString | null | string>(null);
  const [myLibraryFilter, setMyLibraryFilter] = useState<MyLibraryString | null | string>(null);
  const [myLibraryApp, setMyLibraryApp] = useState('');
  const [myLibraryCollection, setMyLibraryCollection] = useState('');
  const [myLibraryFetchSize, setMyLibraryFetchSize] = React.useState(100);
  const [myLibraryPagination, setMyLibraryPagination] = React.useState(false);

  const [sacredTestExpanded, setSacredTestExpanded] = useState(false);
  const [sacredTest2Expanded, setSacredTest2Expanded] = useState(false);

  useEffect(() => {
    console.log('Test here', activeRWLibraryFilter, rwLibraryFilter);
  }, [activeRWLibraryFilter, rwLibraryFilter]);

  useEffect(() => {
    if (myLibraryFilter === null) {
      setMyLibraryFetchSize(100);
      setMyLibraryPagination(false);
      setMyLibraryApp('token_sets_index');
      setMyLibraryCollection('token_sets');
    }
    if (activeMyLibraryFilter === 'A.I. Generated') {
      setMyLibraryFetchSize(21);
      setMyLibraryPagination(true);
      setMyLibraryApp('user_image_generation_index');
      setMyLibraryCollection('user_image_generations');
    } else if (activeMyLibraryFilter === 'Tokens') {
      setMyLibraryFetchSize(100);
      setMyLibraryPagination(false);
      setMyLibraryApp('token_sets_index');
      setMyLibraryCollection('token_sets');
    } else if (activeMyLibraryFilter === 'Favorites') {
      setMyLibraryFetchSize(100);
      setMyLibraryPagination(false);
      setMyLibraryApp('user_library_content_pack_favorites_index');
      setMyLibraryCollection('user_library_content_pack_favorites');
    }
    // else {
    //   setMyLibraryFetchSize(100);
    //   setMyLibraryPagination(false);
    //   setMyLibraryApp('');
    //   setMyLibraryCollection('');
    // }
  }, [activeMyLibraryFilter, myLibraryFilter]);

  useEffect(() => {
    if (tabValue === 1) {
      appStore.setState({
        activeMyLibraryFilter: null,
      });
    }
  }, [tabValue]);

  // const { theme } = useContext(ThemeContext);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="RW Library" {...setTabId(0)} />
        <StyledTab label="My Library" {...setTabId(0)} />
      </StyledTabs>
      {tabValue === 0 && (
        <FixedFilter
          options={optionsRWLib}
          setSelectedFilterOption={(option) => {
            setRWLibraryFilter(option);
            appStore.setState({
              activeRWLibraryFilter: option as RWLibraryString,
            });
          }}
        />
      )}
      {tabValue === 1 && (
        <FixedFilter
          options={optionsMyLib}
          setSelectedFilterOption={(option) => {
            setMyLibraryFilter(option);
            appStore.setState({
              activeMyLibraryFilter: option === 'Sort' ? null : option as MyLibraryString,
            });
          }}
        />
      )}
      <TabPanel value={tabValue} index={0} topBorder={false}>
        <Box>
          <ReactiveBase
            key={activeRWLibraryFilter}
            app="library_content_packs_index"
            url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
            transformRequest={(request: any) => ({
              ...request,
              headers: {
                ...request.headers,
                Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
              },
            })}
             // @ts-ignore
            mongodb={{
              collection: 'library_content_packs',
              db: 'realm_worlds',
            }}
            analytics={false}
            credentials="none"
          >

            <RWLibrary />
          </ReactiveBase>
        </Box>
      </TabPanel>
      <TabPanel topBorder={false} value={tabValue} index={1}>
        <Box>
          <ReactiveBase
            app={myLibraryApp}
            url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
            transformRequest={(request: any) => ({
              ...request,
              headers: {
                ...request.headers,
                Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
              },
            })}
            // @ts-ignore
            mongodb={{
              collection: myLibraryCollection,
              db: 'realm_worlds',
            }}
            analytics={false}
            credentials="none"
          >
            <MyLibrary
              fetchSize={myLibraryFetchSize}
              pagination={myLibraryPagination}
            />
          </ReactiveBase>
        </Box>
      </TabPanel>
    </>
  );
}
