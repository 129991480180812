import {
  Box, Card, CardMedia, Grid, Typography,
} from '@mui/material';
import React from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const mockData = [1, 2, 3, 4];

const RecommendedCard = mockData?.map((result: any) => (
  <Grid
    item
    xs={3}
    sx={{
      ml: 1.5,
      mr: 1.5,
      mb: 1.5,
    }}
    key={result}
  >

    <Card
      sx={{
        display: 'flex',
        borderRadius: '5px',
        background: '#FFF',
        border: '1px solid white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box sx={{
        flex: 1, width: 240, maxHeight: 145, position: 'relative',
      }}
      >

        <CardMedia
          component="img"
          image="https://picsum.photos/200/200"
          alt="Live from space album cover"
        />
        <Typography sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textDecoration: 'underline',
          color: '#FFF',
          fontFamily: 'Passero One',
          fontSize: '40px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}
        >
          Philosophers
        </Typography>
        <ShoppingCartOutlinedIcon sx={{
          position: 'absolute',
          top: '15%',
          right: '0%',
          transform: 'translate(-50%, -50%)',
          cursor: 'pointer',
        }}
        />
      </Box>
    </Card>
  </Grid>
));

export default RecommendedCard;
