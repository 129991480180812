import React from 'react';
import { Box } from '@mui/material';
import { GetTokenSetsDocument, Realm, Token } from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';
import Expansion from './Expansion';
import ExpansionTokenItem from './ExpansionTokenItem';

interface Props {
  realm: Realm;
  tokensSlice: number;
}

function ExpansionRealmItem({ realm, tokensSlice }: Props) {
  const [tokenSetExpanded, setTokenSetExpanded] = React.useState(false);

  const { data: tokensets } = useGraphQL(
    GetTokenSetsDocument,
    {
      query: {
        realm: {
          _id: realm._id,
        },
      },
    },
  );
  const collatedTokens = tokensets?.token_sets.map((tokenSet) => tokenSet?.tokens) ?? [];
  const flattenedCollatedTokens = collatedTokens.reduce((accumulator, subarray) => {
    if (subarray) {
      return (accumulator ?? []).concat(subarray) as Token[];
    }
    return accumulator ?? [];
  }, [] as Token[]);

  const data = flattenedCollatedTokens?.filter((item) => item);

  // console.log(data);

  return (
    <Box
      sx={{ display: data?.length && data?.length > 0 ? 'block' : 'none' }}
    >
      <Expansion
        title={realm.name as string}
        isExpanded={tokenSetExpanded}
        setIsExpanded={() => setTokenSetExpanded(!tokenSetExpanded)}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '16px',
            rowGap: '16px',
          }}
        >
          {data?.slice(0, tokensSlice)
            .map((token: any) => <ExpansionTokenItem token={token} key={token._id} />)}
          {tokenSetExpanded && data?.slice(tokensSlice)
            .map((token: any) => <ExpansionTokenItem token={token} key={token._id} />)}
        </Box>
      </Expansion>
    </Box>
  );
}

export default ExpansionRealmItem;
