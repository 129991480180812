/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick?: () => void;
  active: boolean;
}

function HamburgerIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${styles.icon} ${styles.hamburger_icon} ${styles[theme]} ${active && styles.active}`}
      aria-label="hamburger icon"
    >
      <svg viewBox="0 0 19 13" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0195312 2.45898V0.458984H18.0195V2.45898H0.0195312ZM0.0195312 7.45898H18.0195V5.45898H0.0195312V7.45898ZM0.0195312 12.459H18.0195V10.459H0.0195312V12.459Z" />
      </svg>
    </button>
  );
}

export default HamburgerIcon;
