import request from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { useAuth } from './use-auth';

function useGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): UseQueryResult<TResult> {
  const auth = useAuth();
  const query = useQuery([
    (document.definitions[0] as any).name.value, variables,
  ], async ({ queryKey }) => {
    const token = await auth?.getCurrentUserTokens();
    // console.log('useGraphQL token: ', token);
    const req = request(
      process.env.REACT_APP_GRAPHQL_URL ?? 'NO URL SPECIFIED',
      document,
      queryKey[1] ? queryKey[1] : undefined,
      {
        Authorization: `Bearer ${token.realm}`,
      },
    );
    return req;
  });

  return query;
}

export default useGraphQL;
