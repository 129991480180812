/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import s from './AllRealms.module.scss';
// import favoriteStar from '../../assets/images/empty-star.png';
// import settingsIcon from '../../images/settings-icon-active.svg';
import EmptyFavoriteStar from '../../assets/images/empty-star.png';
import FullFavoriteStar from '../../assets/images/full-star.png';
import errorPlaceholder from '../../assets/images/NewDashboard/image_realm.jpg';

interface RealmProps {
  id: string;
  thumbnail_url: any;
  tags: any;
  realmName: string;
  isFavorited: boolean;
}

/**
 * A component representing an individual realm in a list of all realms.
 *
 * @component
 * @example
 * // Example usage of AllRealms component
 * <AllRealms
 *   id="exampleRealmId"
 *   thumbnail_url="exampleImageUrl"
 *   tags={['tag1', 'tag2', 'tag3']}
 *   realmName="Example Realm"
 *   isFavorited={false}
 * />
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier of the realm.
 * @param {string} props.thumbnail_url - The URL of the realm's thumbnail image.
 * @param {string[]} props.tags - An array of tags associated with the realm.
 * @param {string} props.realmName - The name of the realm.
 * @param {boolean} props.isFavorited - Indicates whether the realm is favorited.
 *
 * @returns {JSX.Element} React element representing an individual realm.
 */

const AllRealms = ({
  id,
  thumbnail_url,
  tags,
  realmName,
  isFavorited,
}: RealmProps) => {
  const navigate = useNavigate();
  const [brokenImages, setBrokenImages] = useState<string[]>([]);

  const handleImageError = (realmId: string) => {
    setBrokenImages((prevBrokenImages) => [...prevBrokenImages, realmId]);
  };

  return (
    <div key={id} className={s.realm}>
      {brokenImages.includes(id) || !thumbnail_url ? (
        <img
          className={s.realm__background}
          src={errorPlaceholder}
          alt="This is a placeholder"
          onError={() => handleImageError(id)}
        />
      ) : (
        <img
          className={s.realm__background}
          src={thumbnail_url}
          alt="realm image"
          onError={() => handleImageError(id)}
        />
      )}
      <div className={s.realm__top}>
        <img
          className={s.realm__top__star}
          src={isFavorited ? FullFavoriteStar : EmptyFavoriteStar}
          alt="favorite star"
        />
        <IoMdSettings />
      </div>
      <div className={s.realm__footer}>
        <div className={s.realm__info}>
          <p className={s.realm__info__heading}>{realmName}</p>
          <div className={s.realm__info__bottom}>
            <div className={s.realm__info__tags}>
              {tags?.slice(0, 3)?.map((tag: string, index: number) => (
                <div
                  key={index}
                  className={
                    tag?.split(' ').length === 1
                      ? s.realm__info__tag
                      : s.realm__info__longTag
                  }
                >
                  {tag}
                </div>
              ))}
            </div>
            <button
              type="button"
              className={s.realm__info__button}
              onClick={() => navigate(`/realm/${id}/character`)}
            >
              Enter Realm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRealms;
