import React from 'react';
import badge from '../../assets/images/azamel_Simple_UI_Shield_for_Website_flat_Minimalistic_Modern_UI_80f35a7e-9d54-451e-a766-5dfa8fb53c5e-removebg-preview 2.png';
import userImage from '../../assets/images/3d_avatar_28.png';
import token from '../../assets/images/azamel_Coin.png';
import plus from '../../assets/images/Add.png';
import realmWorldLogo from '../../assets/images/Realm_worlds APP LOGO_v1 2.png';
import s from './HeaderMenu.module.scss';
import { useAuth } from '../../hooks/use-auth';
import useGraphQL from '../../hooks/useGraphQL';
import appStore from '../../store/AppStore';
import { GetUserWalletDocument } from '../../__generated__/graphql';
import { getAvaliableCredits } from '../../views/Dashboard/Dashboard';

/**
 * Header menu component containing user information and tokens.
 *
 * @component
 * @example
 * // Example usage of HeaderMenu component
 * <HeaderMenu />
 *
 * @returns {JSX.Element} React element representing the header menu.
 */

const HeaderMenu = () => {
  const auth = useAuth();

  const { data: userWallet } = useGraphQL(GetUserWalletDocument, {
    query: {
      _id: auth?.user?.uid,
    },
  });

  appStore.setState({
    userName: auth?.user?.displayName as string,
    userCredits: getAvaliableCredits(userWallet ?? {}),
  });

  const userCredits: number = appStore((state) => state.userCredits);

  const userName: string = appStore((state) => state.userName as string);

  console.log(userCredits);

  return (
    <div className={s.realm_wrapper}>
      <img className={s.logo} src={realmWorldLogo} alt="realm world {} logo" />
      <div className={s.token_wrapper}>
        <img className={s.token} src={token} alt="token" />
        <p>{userCredits !== null ? userCredits : 'loading...'}</p>
        <button type="button" className={s.addButton}>
          <img src={plus} alt="token" />
        </button>
      </div>
      <img className={s.userImage} src={userImage} alt="user profile" />
      <p className={s.userName}>{userName || 'loading...'}</p>
      <img className={s.shield} src={badge} alt="shield" />
    </div>
  );
};

export default HeaderMenu;
