import React from 'react';
import './fixedfilter.scss';
import {
  Box,
} from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import { ThemeContext } from '../../context/ThemeContext';
import FilterDropDown from './filterDropDown/FilterDropDown';
import { RWLibraryString } from '../../interfaces/rwlibrary';
import { MyLibraryString } from '../../interfaces/mylibrary';
import { MyCollectionString } from '../../interfaces/mycollection';

interface Props {
  options: RWLibraryString[] | MyLibraryString[] | MyCollectionString[];
  setSelectedFilterOption: (option: RWLibraryString | MyLibraryString | MyCollectionString) => void;
  disableFirstOption?: boolean;
}

function FixedFilter({ options, setSelectedFilterOption, disableFirstOption }: Props) {
  const { theme } = React.useContext(ThemeContext);
  const disableFirstFilterOption = disableFirstOption !== undefined ? disableFirstOption : true;
  return (
    <Box pt={2} className={`fixedFilterWrapper ${theme}`}>
      <FilterDropDown
        options={options}
        setSelectedFilterOption={setSelectedFilterOption}
        disableFirstOption={disableFirstFilterOption}
      />
    </Box>
  );
}

FixedFilter.defaultProps = {
  disableFirstOption: true,
};

export default FixedFilter;
