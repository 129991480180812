import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ThemeContext } from '../../../context/ThemeContext';
import PlusIcon from '../../../images/plus-math.svg';
import KindredIcon from '../../../images/kindred.svg';
import PeopleIcon from '../../../images/people.svg';
import FantasyIcon from '../../../images/fantasy-icon.png';
import appStore, { CharacterViewOptions, CreateCharacterViewOptions } from '../../../store/AppStore';
import './character.scss';

interface Props {
  openCreateCharacterSetModal: () => void;
  openAICharacterGenModal: () => void;
}

function CharacterSetHeader(
  { openCreateCharacterSetModal, openAICharacterGenModal }: Props,
) {
  const { theme } = useContext(ThemeContext);
  const setSelectedView = (view: CharacterViewOptions) => {
    appStore.setState({
      selectedCharacterView: view,
    });
  };
  const createCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.createCharacterView,
  );
  const setCreateCharacterView = (view: CreateCharacterViewOptions) => {
    if (view !== createCharacterView) {
      appStore.setState({
        createCharacterView: view,
      });
    }
  };
  const defaultCreateCharacterView:
  CreateCharacterViewOptions = appStore(
    (state) => state.defaultCreateCharacterView,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '14px',
        alignItems: 'center',
        px: '66px',
      }}
    >
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width={50}
        height={50}
      >
        <circle
          style={{
            fill: theme === 'light' ? '#250503' : 'white',
            strokeWidth: 0,
          }}
          cx="24.93"
          cy="16.61"
          r="7.83"
        />
        <circle
          style={{
            fill: theme === 'light' ? '#250503' : 'white',
            strokeWidth: 0,
          }}
          cx="11.39"
          cy="21.93"
          r="5.83"
        />
        <circle
          style={{
            fill: theme === 'light' ? '#250503' : 'white',
            strokeWidth: 0,
          }}
          cx="38.5"
          cy="21.93"
          r="5.83"
        />
        <path
          style={{
            fill: theme === 'light' ? '#250503' : 'white',
            strokeWidth: 0,
          }}
          d="m24.97,25.49h0c6.63,0,12.01,5.38,12.01,12.01v2.06c0,.87-.71,1.58-1.58,1.58H14.55c-.87,0-1.58-.71-1.58-1.58v-2.06c0-6.63,5.38-12.01,12.01-12.01Z"
        />
        <path
          style={{
            fill: theme === 'light' ? '#250503' : 'white',
            strokeWidth: 0,
          }}
          d="m9.84,37.46c0,1.36.42,2.62,1.15,3.67h-5.98c-1.34,0-2.42-1.08-2.42-2.42v-.25c0-4.85,3.96-8.81,8.81-8.81.25,0,.5.01.75.03-1.45,2.07-2.31,4.6-2.31,7.32v.46Z"
        />
        <path
          style={{
            fill: theme === 'light' ? '#250503' : 'white',
            strokeWidth: 0,
          }}
          d="m47.31,38.46v.25c0,1.34-1.08,2.42-2.42,2.42h-5.93c.72-1.04,1.15-2.31,1.15-3.67v-.46c0-2.73-.85-5.25-2.31-7.33.23-.02.46-.03.7-.03,4.85,0,8.81,3.97,8.81,8.81Z"
        />
        <rect style={{ fill: 'none' }} width="50" height="50" />
      </svg>
      <Typography className={`heading ${theme === 'light' ? 'light' : 'dark'}`}>{`Characters in ${appStore((state) => state.selectedRealm?.name) ?? ''}`}</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '26px',
        }}
      >
        <button
          type="button"
          className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
          // onClick={openCharacterCreatorModal}
          onClick={() => {
            setSelectedView('creator');
            setCreateCharacterView(defaultCreateCharacterView);
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
            <img src={KindredIcon} style={{ width: '25px', height: 'auto' }} alt="kindred" />
            <Typography className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}>Create a Character</Typography>
          </div>
        </button>
        <button
          type="button"
          className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
          onClick={openCreateCharacterSetModal}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
            <img src={PeopleIcon} style={{ width: '25px', height: 'auto' }} alt="kindred" />
            <Typography className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}>Create a Character Set</Typography>
          </div>
        </button>
        <button
          type="button"
          className={`headingButton ai-gen ${theme === 'light' ? 'light' : 'dark'}`}
          onClick={openAICharacterGenModal}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
            <img src={FantasyIcon} style={{ width: '25px', height: 'auto' }} alt="kindred" />
            <Typography className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}>AI Generate Character</Typography>
          </div>
        </button>
      </Box>
    </Box>
  );
}

export default CharacterSetHeader;
