/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/require-default-props */
import React, { useContext, useState, useEffect } from 'react';
import { Box, Collapse } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './panels.module.scss';
import {
  CircleIcon,
  RectangleIcon,
  ForegroundIcon,
  TextIcon,
  NotesIcon,
  LeftArrowIcon,
  RightArrowIcon,
  ClockArrowIcon,
  FantasyIcon,
  RealmWorldIcon,
  HamburgerIcon,
  ShoppingCartIcon,
} from '../toolbar_icons';
import PanelItem from './PanelItem/PanelItem';
import Menu from './Menu';
import appStore, { ActiveTab } from '../../store/AppStore';

export interface PanelProps {
  onStageChange: (changeName: string) => void;
  goToPast?: () => void;
  gotToFuture?: () => void;
  handleHistory?: () => void;
  historyOpened?: boolean;
  // handleOpenAssetViewer?: () => void;
  // handleSave?: () => void;
  // saveDisabled?: boolean;
  // handleSaveAs?: () => void;
  handleStageClear: () => void;
}

function Panels({
  onStageChange,
  goToPast,
  gotToFuture,
  handleHistory,
  historyOpened,
  // handleSave,
  // saveDisabled,
  // handleSaveAs,
  handleStageClear,
}: PanelProps) {
  const { theme } = useContext(ThemeContext);
  const [opened, setOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const setOpenTokenPackPurchaseSummaryModal = (condition: boolean) =>
    appStore.setState({
      openTokenPackPurchaseSummaryModal: condition,
    });

  const activeTab: ActiveTab = appStore((state) => state.activeTab);

  const setActiveTab = (tab: ActiveTab) =>
    appStore.setState({
      activeTab: tab,
    });
  const setNewPanel = (value: ActiveTab) => {
    setOpened((prev) => {
      let opened1 = true;

      if (activeTab === value) {
        opened1 = !prev;
      } else {
        setActiveTab(value);
      }

      if (opened1) {
        setMenuOpened(false);
        if (historyOpened) {
          if (handleHistory !== undefined) {
            handleHistory();
          }
        }
      } else {
        setActiveTab('none');
      }
      return opened1;
    });
  };
  const toggleViewMenu = () => {
    setMenuOpened(!menuOpened);
    if (historyOpened) {
      if (handleHistory !== undefined) {
        handleHistory();
      }
    }
    setOpened(false);
    setActiveTab('none');
  };

  useEffect(() => {
    if (historyOpened) {
      setOpened(false);
      setActiveTab('none');
      setMenuOpened(false);
    }
  }, [historyOpened]);

  useEffect(() => {
    if (activeTab === 'aigenerate') {
      setOpened(true);
    }
  }, [activeTab]);

  return (
    <>
      <div className={`${styles.toolkit} ${styles[theme]}`}>
        <HamburgerIcon
          onClick={toggleViewMenu}
          active={menuOpened && activeTab === 'none'}
        />
        <div className={styles.toolkit_divider_wrapper}>
          <span />
        </div>
        <CircleIcon
          onClick={() => setNewPanel('frame')}
          active={activeTab === 'frame'}
        />
        <RectangleIcon
          onClick={() => setNewPanel('background')}
          active={activeTab === 'background'}
        />
        <ForegroundIcon
          onClick={() => setNewPanel('overlay')}
          active={activeTab === 'overlay'}
        />
        <TextIcon
          onClick={() => setNewPanel('text')}
          active={activeTab === 'text'}
        />
        <NotesIcon
          onClick={() => setNewPanel('notes')}
          active={activeTab === 'notes'}
        />
        <div className={styles.toolkit_divider_wrapper}>
          <span />
        </div>
        <LeftArrowIcon onClick={goToPast} />
        <RightArrowIcon onClick={gotToFuture} />
        <ClockArrowIcon
          onClick={handleHistory}
          active={historyOpened === true}
        />
        <div className={styles.toolkit_divider_wrapper}>
          <span />
        </div>
        <FantasyIcon
          onClick={() => setNewPanel('aigenerate')}
          active={activeTab === 'aigenerate'}
        />
        <RealmWorldIcon
          onClick={() => setNewPanel('library')}
          active={activeTab === 'library'}
        />
        <ShoppingCartIcon
          onClick={() => setOpenTokenPackPurchaseSummaryModal(true)}
          active={activeTab === 'shoppingcart'}
        />
      </div>
      {menuOpened && activeTab === 'none' && (
        <Menu
          closeMenu={() => setMenuOpened(false)}
          // handleSave={() => handleSave}
          // handleSaveAs={() => handleSaveAs}
          handleStageClear={handleStageClear}
          // saveDisabled={saveDisabled}
          openMyCollection={() => setNewPanel('mycollection')}
          activeTab={activeTab}
        />
      )}
      <Box
        sx={{ display: 'flex', flexDirection: 'row', pointerEvents: 'none' }}
      >
        <Collapse
          className={styles.panel_collapse}
          in={opened}
          orientation="horizontal"
        >
          <PanelItem activeTab={activeTab} onStageChange={onStageChange} />
        </Collapse>
        {/* <Slide
          direction="right"
          in={opened}
          container={drawerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <PanelItem activeTab={activeTab} />
        </Slide> */}
      </Box>
    </>
  );
}

export default Panels;
