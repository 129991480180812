import React from 'react';
import styles from './panels.module.scss';
import { FloatingMenu, FloatingMenuItem } from '../floatingMenu';

interface Props {
  closeMenu: () => void;
  // handleSave: () => void;
  // handleSaveAs: () => void;
  handleStageClear: () => void;
  // eslint-disable-next-line react/require-default-props
  // saveDisabled?: boolean;
  openMyCollection: () => void;
  activeTab: string;
}

function Menu({
  closeMenu,
  // handleSave,
  // handleSaveAs,
  handleStageClear,
  // saveDisabled,
  openMyCollection,
  activeTab,
}: Props) {
  const handleFilesClick = () => {
    openMyCollection();
    closeMenu();
  };

  // const handleSaveClick = () => {
  //   handleSave();
  //   closeMenu();
  // };

  // const handleSaveAsClick = () => {
  //   handleSaveAs();
  //   closeMenu();
  // };

  const handleClearCanvasClick = () => {
    handleStageClear();
    closeMenu();
  };

  return (
    <div className={styles.menu}>
      <FloatingMenu>
        <FloatingMenuItem
          onClick={handleFilesClick}
          active={activeTab === 'mycollection'}
        >
          Files
        </FloatingMenuItem>
        {/* <FloatingMenuItem onClick={handleSaveClick} disabled={saveDisabled}>
          Save
        </FloatingMenuItem>
        <FloatingMenuItem onClick={handleSaveAsClick}>
          Save as
        </FloatingMenuItem> */}
        <FloatingMenuItem onClick={handleClearCanvasClick}>
          Clear Canvas
        </FloatingMenuItem>
        <FloatingMenuItem>View Collection</FloatingMenuItem>
        <FloatingMenuItem>Exit Session</FloatingMenuItem>
      </FloatingMenu>
    </div>
  );
}

export default Menu;
