/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import {
  IoMdSearch,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowBack,
  IoMdSettings,
  IoMdArrowForward,
  IoMdArrowBack,
} from 'react-icons/io';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import { useNavigate } from 'react-router-dom';
import s from './DetailView.module.scss';
import RealmsIcon from '../../images/Realm_worlds_logo.png';
import cloud from '../../assets/images/NewDashboard/cloud_png.png';
import CampaignManager from '../CampaignManager/CampaignManager';
import MyCharacters from '../MyCharacters/MyCharacters';
import MyTokens from '../MyTokens/MyTokens';

import '../SwiperComponent/DetailSwiper.scss';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import errorPlaceholder from '../../assets/images/NewDashboard/image_realm.jpg';
import useGraphQL from '../../hooks/useGraphQL';
import {
  GetRealmsDocument,
  RealmSortByInput,
} from '../../__generated__/graphql';
import Spinner from '../Spinners/Spinner';
import SideWindow from './SideWindow';
import CreateRealmModal from '../Modal/CreateRealmModal';
import Modal from '../Modal/Modal';

/**
 * Represents a component for detailed viewing and management of realms.
 *
 * @component
 * @example
 * // Example usage of DetailView component
 * <DetailView />
 *
 * @returns {JSX.Element} React element representing the detailed view for managing realms.
 */

const DetailView = () => {
  const {
    data: realmsData,
    error: realmsError,
    isLoading,
  } = useGraphQL(GetRealmsDocument, {
    limit: 100,
    sortBy: RealmSortByInput.CreatedatDesc,
  });

  console.log(realmsData);

  // console.log(realmsData?.realms);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [brokenImages, setBrokenImages] = useState<string[]>([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [updateRender, setUpdateRender] = useState(false);

  const slides = realmsData && realmsData?.realms?.length < 2 ? 1 : 3;

  const updateState = () => {
    setTimeout(() => {
      setUpdateRender((prevValue) => !prevValue);
    }, 200);
  };

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navigate = useNavigate();

  const handleImageError = (realmId: string) => {
    setBrokenImages((prevBrokenImages) => [...prevBrokenImages, realmId]);
  };

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(
      swiper.realIndex + 1 === realmsData?.realms?.length!
        ? 0
        : swiper.realIndex + 1,
    );
    console.log(swiper.realIndex);
    console.log(`'current slide index', ${currentSlideIndex}`);
    console.log(realmsData?.realms);
  };

  return (
    <div className={`${s.layout} ${!isCollapsed ? s.collapsed : ''}`}>
      <div className={`${s.window} ${!isCollapsed ? s.collapsed : ''}`}>
        <div className={s.window__header}>
          <div className={s.window__header__text}>My Realms</div>
          <div className={s.window__header__container}>
            <IoMdSearch />
            <FaPlus />
          </div>
        </div>
        <div className={s.window__sort}>
          <p className={s.window__sort__text}>Sort</p>
          <IoIosArrowDown />
        </div>
        <div className={s.window__title}>
          <p className={s.window__title__name}>Name/Tag</p>
          <p className={s.window__title__description}>Description</p>
          <p className={s.window__title__tokens}>Tokens</p>
        </div>
        <div className={s.window__realmContainer}>
          {realmsData && realmsData.realms && realmsData.realms.length > 0 ? (
            realmsData.realms.map((realm) => (
              <SideWindow
                key={realm?._id}
                id={realm?._id}
                description={realm?.description}
                isFavorited={realm?.is_favorited}
                name={realm?.name}
                tags={realm?.tags}
              />
            ))
          ) : (
            <p className={s.window__realmNull}>No realms available</p>
          )}
        </div>
      </div>

      <button
        type="button"
        className={s.layout__windowButton}
        onClick={handleCollapseToggle}
      >
        {!isCollapsed ? <IoIosArrowBack /> : <IoIosArrowForward />}
      </button>

      <div className={s.box}>
        <img className={s.box__logo} src={RealmsIcon} alt="realm world logo" />
        <div className={s.box__gradient} />
        <img src={cloud} className={s.box__gradientImg} alt="cloud" />
        {isLoading ? (
          <p className={s.box__loadingInfo}>Loading realm information...</p>
        ) : (
          <div className={s.box__main}>
            {realmsData?.realms && realmsData.realms.length > 0 ? (
              <>
                <p className={s.box__main__heading}>
                  {realmsData?.realms[currentSlideIndex]?.name}
                </p>
                <div className={s.box__main__container}>
                  <p className={s.box__main__body}>
                    {realmsData?.realms[currentSlideIndex]?.description}
                  </p>
                </div>
                <div className={s.box__main__tagContainer}>
                  {realmsData?.realms[currentSlideIndex]?.tags
                    ?.slice(0, 3)
                    ?.map((tag: any, index: number) => (
                      <div
                        key={index}
                        className={
                          tag?.split(' ').length === 1
                            ? s.box__main__tag
                            : s.box__main__longTag
                        }
                      >
                        {tag}
                      </div>
                    ))}
                </div>
                <div className={s.box__main__actions}>
                  <button
                    type="button"
                    className={s.box__main__enterButton}
                    onClick={() =>
                      navigate(
                        `/realm/${realmsData?.realms[currentSlideIndex]?._id}/character`,
                      )
                    }
                  >
                    Enter Realm
                  </button>

                  <div className={s.box__main__settingsContainer}>
                    <IoMdSettings />
                  </div>
                </div>
              </>
            ) : (
              <div>
                <p
                  className={`${s.box__loadingInfo} ${s.box__loadingInfo__null}`}
                >
                  You have no realms available. Click the button below to create
                  a realm
                </p>
                <div className={s.box__main__buttonContainer}>
                  <Modal>
                    <Modal.Open opensWindowName="createRealm">
                      <button
                        type="button"
                        className={s.box__main__createButton}
                      >
                        Create a Realm
                      </button>
                    </Modal.Open>

                    <Modal.Window name="createRealm">
                      <CreateRealmModal updateState={updateState} />
                    </Modal.Window>
                  </Modal>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={s.main}>
        {isLoading ? (
          <Spinner hero />
        ) : (
          <div className="container">
            <Swiper
              effect="coverflow"
              grabCursor
              loop
              slidesPerView={slides}
              initialSlide={0}
              onSlideChange={(swiper) => handleSlideChange(swiper)}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 200,
                modifier: 2.5,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
              style={{
                paddingRight: `${
                  realmsData && realmsData?.realms?.length < 3 ? '0' : '260px'
                }`,
                paddingLeft: `${
                  realmsData && realmsData?.realms?.length < 2 ? '30%' : '0'
                }`,
              }}
            >
              {realmsData?.realms.map((realm) => (
                <SwiperSlide key={realm?._id}>
                  {brokenImages.includes(realm?._id) ||
                  !realm?.thumbnail_url ? (
                    <img
                      className="swiperImage"
                      src={errorPlaceholder}
                      alt="This is a placeholder"
                      onError={() => handleImageError(realm?._id)}
                    />
                  ) : (
                    <img
                      className="swiperImage"
                      src={realm.thumbnail_url}
                      alt="realm image"
                      onError={() => handleImageError(realm._id)}
                    />
                  )}
                </SwiperSlide>
              ))}

              <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <IoMdArrowBack />
                </div>
                <div className="swiper-button-next slider-arrow">
                  <IoMdArrowForward />
                </div>
              </div>
            </Swiper>
          </div>
        )}

        <CampaignManager />
        <MyCharacters id={realmsData?.realms[currentSlideIndex]?._id} />
        <MyTokens id={realmsData?.realms[currentSlideIndex]?._id} />
      </div>
    </div>
  );
};

export default DetailView;
