import React from 'react';
import {
  Box,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import appStore, { ThumbnailSource } from '../../../store/AppStore';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import PlaceHolderImage from '../../../images/preview-image-placeholder.webp';
import IncreaseIcon from '../../../images/increase.webp';
import DecreaseIcon from '../../../images/decrease.webp';
import PanIcon from '../../../images/pan.webp';

interface Props {
  background: string,
}

/**
 * Represents a React component to preview the selected thumbnail for Create Character Set modal.
 *
 * @component
 * @example
 * // Example usage of CharacterSetModalPreview component
 * <CharacterSetModalPreview background="backgroundImageUrl" />
 *
 * @date 11/22/2023 - 10:59:53 AM
 *
 * @param {Props} param0
 * @param {string} param0.background
 * @returns {*}
 */
function CharacterSetModalPreview({ background }: Props) {
  /**
   * The selected thumbnail source from the application store.
   *
   * @type {ThumbnailSource}
   */
  const selectedThumbnailSource:
  ThumbnailSource = appStore(
    (state) => state.previewThumbnailSource,
  );

  /**
   * State to manage the zoom value of the character set preview.
   *
   * @type {number}
   */
  const [zoomValue, setZoomValue] = React.useState<number>(0);

  /**
   * Handles the change of the zoom value.
   *
   * @param {Event} event - The change event.
   * @param {number | number[]} newValue - The new value of the zoom.
   */
  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    setZoomValue(newValue as number);
    // console.log(zoomValue);
  };
  return (
    <>
      {/* Preview Heading */}
      <Box
        sx={{
          width: '100%',
          borderRadius: '0px',
          background: '#41521F',
          padding: '12px 20px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          gap: '35px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Passero One',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '26px', /* 130% */
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
          }}
        >
          Preview
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <button
            type="button"
            style={{
              display: 'block',
              outline: 'none',
              border: 'none',
              padding: 0,
              background: 'transparent',
              height: '18px',
              cursor: 'pointer',
            }}
          >
            <img
              src={PanIcon}
              alt="decrease"
              style={{
                height: '18px',
                width: 'auto',
              }}
            />
          </button>
          <Stack spacing="10px" direction="row" alignItems="center">
            <img
              src={DecreaseIcon}
              alt="decrease"
              style={{
                height: '18px',
                width: 'auto',
              }}
            />
            <Slider
              aria-label="zoom"
              value={zoomValue}
              onChange={handleZoomChange}
              sx={{
                width: '200px',
                color: '#D0B17A',
                '.MuiSlider-rail': {
                  color: 'white',
                },
                '.MuiSlider-thumb': {
                  width: '15px',
                  height: '15px',
                },
                '.MuiSlider-thumb:hover': {
                  boxShadow: '0px 0px 0px 8px #D0B17A3C',
                },
              }}
              min={0}
              max={100}
            />
            <img
              src={IncreaseIcon}
              alt="increase"
              style={{
                height: '18px',
                width: 'auto',
              }}
            />
          </Stack>
        </Box>
      </Box>
      {/* Preview container */}
      <Box
        sx={{
          width: '100%',
          borderRadius: '0px 0px 20px 20px',
          background,
          backgroundSize: `${100 + zoomValue}%`,
          //   backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          transition: 'ease-in-out',
          minHeight: '200px',
          boxSizing: 'border-box',
          pb: selectedThumbnailSource === 'color' ? '15px' : '45px',
          pt: '45px',
        }}
      >
        <Box
          sx={{
            border: '2px solid #FFF',
            background: 'rgba(208, 177, 122, 0.50)',
            borderImageSource: `url(${CustomBorder})`,
            borderImageSlice: '17',
            borderImageRepeat: 'round',
            width: '100%',
            minHeight: '150px',
            boxSizing: 'border-box',
            px: '24px',
            pt: '48px',
            pb: '15px',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          }}
        >
          <img
            src={PlaceHolderImage}
            alt="placeholder"
            style={{
              height: 'auto',
              width: 'calc(100% / 7)',
            }}
          />
          <img
            src={PlaceHolderImage}
            alt="placeholder"
            style={{
              height: 'auto',
              width: 'calc(100% / 7)',
            }}
          />
          <img
            src={PlaceHolderImage}
            alt="placeholder"
            style={{
              height: 'auto',
              width: 'calc(100% / 7)',
            }}
          />
          <img
            src={PlaceHolderImage}
            alt="placeholder"
            style={{
              height: 'auto',
              width: 'calc(100% / 7)',
            }}
          />
          <img
            src={PlaceHolderImage}
            alt="placeholder"
            style={{
              height: 'auto',
              width: 'calc(100% / 7)',
            }}
          />
          <img
            src={PlaceHolderImage}
            alt="placeholder"
            style={{
              height: 'auto',
              width: 'calc(100% / 7)',
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default CharacterSetModalPreview;
