/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import './expansion.scss';
import { ExpansionVariant } from '../../../store/AppStore';

interface Props {
  title: string;
  isExpanded: boolean;
  children?: React.ReactNode;
  onExpandClick: () => void;
  variant?: ExpansionVariant;
}

/**
 * Accepts a title and any other children, serves as the heading of the Expansion component.
 * It also houses the arrow to expand or collapse the Expansion component.
 * @date 11/22/2023 - 11:16:05 AM
 *
 * @param {Props} param0
 * @param {string} param0.title
 * @param {boolean} param0.isExpanded
 * @param {React.ReactNode} param0.children
 * @param {() => void} param0.onExpandClick
 * @param {ExpansionVariant} param0.variant
 * @returns
 */
function ExpansionHeader({
  title, isExpanded, children, onExpandClick, variant,
}: Props) {
  return (
    <Box px={0} py={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box p={0}>
        <Typography pr="8px" className={`expansionTitle ${variant}`} sx={{ whiteSpace: 'nowrap' }}>{title}</Typography>
      </Box>
      <Box sx={{
        display: 'flex', justifyContent: 'flex-end', columnGap: '0px', alignItems: 'center',
      }}
      >
        {children}
        <button
          style={{
            padding: '5px',
            display: 'block',
            background: 'transparent',
            outline: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
          type="button"
          onClick={onExpandClick}
          aria-label="expand"
        >
          <ArrowDropDown className={`expansionArrow ${isExpanded ? 'rotated' : ''} ${variant}`} />
        </button>
      </Box>
    </Box>
  );
}

ExpansionHeader.defaultProps = {
  variant: 'token-maker',
};

export default ExpansionHeader;
