import React from 'react';
import {
  Box, Stack, Typography,
} from '@mui/material';
import {
  FlexibleHeightImg,
} from '../../styledComps/styledComps';
import FancyUnderline from '../../../images/fancy-underline-one.webp';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import CharacterSectionWithHelp from './CharacterSectionWithHelp';
// import sampleNotes from '../../notes/characternotesample.json';
import CharacterNotesWrapper from '../../notes/CharacterNotesWrapper';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  CharacterLong_Term_Goal, CharacterShort_Term_Goal, GetPersonalitiesAttributesDocument,
  Personality,
} from '../../../__generated__/graphql';

interface Props {
  characterPersonalityType: Personality | null;
  setCharacterPersonalityType: (char: Personality) => void;
  characterMotivations: string[];
  setCharacterMotivations: (char: string[]) => void;
  characterShortTermGoals: CharacterShort_Term_Goal[];
  setCharacterShortTermGoals: (goals: CharacterShort_Term_Goal[]) => void;
  characterLongTermGoals: CharacterLong_Term_Goal[];
  setCharacterLongTermGoals: (goals: CharacterLong_Term_Goal[]) => void;
}

// const PersonalityOptions = [
//   'Extrovert',
//   'Introvert',
//   'Sensing',
//   'Thinker',
//   'Feeler',
//   'Judging',
//   'Perceiving',
// ];

const MotivationOptions = [
  'Explorer',
  'Adventurer',
  'Seeker of Riches',
  'Heroism Survivor',
  'Amass Power',
  'Be Free',
  'Love',
  'Fun Seeker',
  'Andrenaline Junkie',
];

function CharacterMotiveandGoals({
  characterPersonalityType,
  setCharacterPersonalityType,
  characterMotivations,
  setCharacterMotivations,
  characterShortTermGoals,
  setCharacterShortTermGoals,
  characterLongTermGoals,
  setCharacterLongTermGoals,
}: Props) {
  const { data: personalities } = useGraphQL(
    GetPersonalitiesAttributesDocument,
    {},
  );
  console.log(personalities?.personalities);
  return (
    <Stack gap="33px">
      <Stack gap="21px">
        <Stack justifyContent="center" alignItems="center" gap="14px">
          <Typography
            sx={{
              color: '#250503',
              fontFamily: 'Passero One',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '50px',
              letterSpacing: '.1px',
            }}
          >
            Motive & Goals
          </Typography>
          <FlexibleHeightImg width="102px" src={FancyUnderline} />
        </Stack>
        {/* Character personality */}
        <Box>
          <CharacterSectionWithHelp
            title="Personality Type"
            message="lorem ipsum dolor"
          />
          <CharacterFilterDropdown
            width="100%"
            title="Personality Type"
            options={personalities?.personalities}
            value={characterPersonalityType?.name}
            onChange={setCharacterPersonalityType}
          />
        </Box>
      </Stack>
      {/* Character motivations */}
      <Box>
        <CharacterSectionWithHelp
          title="Motivations"
          message="lorem ipsum dolor"
        />
        <CharacterFilterDropdown
          width="100%"
          title="Motivations"
          options={MotivationOptions}
          value={characterMotivations}
          onChange={setCharacterMotivations}
          multipleSelect
          multipleSelectLowerLimit={1}
          multipleSelectUpperLimit={3}
        />
      </Box>
      {/* Short Term Goals */}
      <Box>
        <CharacterNotesWrapper
          title="short term goals"
          notes={characterShortTermGoals}
          setNotes={(goals: any[]) => setCharacterShortTermGoals(goals)}
        />
      </Box>
      {/* Long Term Goals */}
      <Box>
        <CharacterNotesWrapper
          title="long term goals"
          notes={characterLongTermGoals}
          setNotes={(goals: any[]) => setCharacterLongTermGoals(goals)}
        />
      </Box>
    </Stack>
  );
}

export default CharacterMotiveandGoals;
