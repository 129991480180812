/* eslint-disable react/jsx-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/indent */
import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Link,
  ClickAwayListener,
} from '@mui/material';
// import { HiOutlinePencil } from 'react-icons/hi2';
import { BiPencil } from 'react-icons/bi';
import { Stage, Layer, Transformer } from 'react-konva';
import { ReactReduxContext, Provider } from 'react-redux';
import Dropzone from 'react-dropzone';
import { stringify } from 'qs';
import useItem from '../../../hooks/useItem';
import Panels from '../../panel/Panels';
import useWorkHistory, { StageSnapshot } from '../../../hooks/useWorkHistory';
import useHotkeyFunc from '../../../hooks/useHotKeyFunc';
import { useUpdateRealmTokenFormData } from '../../../__generated__/realmWorldsApiComponents';
import useToken from '../../../hooks/useToken';
import useTransformer from '../../../hooks/useTransformer';
import useSelection from '../../../hooks/useSelection';
// import { serialize } from 'v8';
import {
  GetTokenSetsDocument,
  Note,
  UpdateOneTokenDocument,
} from '../../../__generated__/graphql';
import useNotification from '../../../hooks/useNotification';
import { StageData } from '../../../redux/currentStageData';
import { TokenState } from '../../../redux/token';
import { canvasWidth, canvasHeight } from '../../CanvasConstants';
import ImageItem, { ImageItemProps } from '../../konvaItem/ImageItem';
import TextItem, { TextItemProps } from '../../konvaItem/TextItem';
import { Preview, PreviewRef } from '../../preview/Preview';
import Notification from '../../notifications/Notification';
import styles from './tokenmaker.module.scss';
import Drop from '../../../util/Drop';
import { ThemeContext } from '../../../context/ThemeContext';
import mageManImage from '../../../assets/images/Man_Mage.png';
import { FloatingMenu, FloatingMenuItem } from '../../floatingMenu';
import './tokenmakermodifier.scss';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import AIGenerated from './AIGenerated';
import appStore, { ActiveTab } from '../../../store/AppStore';
import useGraphQL from '../../../hooks/useGraphQL';
import { handleURLImageDrop } from './utils';
import UpdateTokenModal from '../../modals/UpdateTokenModal';
import DeleteTokenModal from '../../modals/DeleteTokenModal';
import TokenPackPurchaseSummaryModal from '../../modals/TokenPackPurchaseSummaryModal';
import CartItemsModal from '../../modals/ShoppingCartPopups/CartItemsModal/CartItemsModal';
import ThankYouModal from '../../modals/ShoppingCartPopups/ThankYouModal/ThankYouModal';
import EmptyCartModal from '../../modals/ShoppingCartPopups/EmptyCartModal/EmptyCartModal';

function TokenMaker() {
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ID, setID] = useState('');
  const realmDataString = appStore((state) => state.selectedRealm);

  const openTokenPackPurchaseSummaryModal: boolean = appStore(
    (state) => state.openTokenPackPurchaseSummaryModal,
  );
  const setOpenTokenPackPurchaseSummaryModal = (condition: boolean) =>
    appStore.setState({
      openTokenPackPurchaseSummaryModal: condition,
    });

  useEffect(() => {
    if (id !== undefined) {
      setID(id);
    }
    localStorage.removeItem('activeFrame');
    localStorage.removeItem('activeForeground');
    localStorage.removeItem('activeBackground');
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tokenSets = useGraphQL(GetTokenSetsDocument, {
    query: {
      _id: realmDataString?._id,
    },
  }).data?.token_sets;

  const { theme } = useContext(ThemeContext);
  const recentChangeName: string = appStore((state) => state.recentChangeName);
  const [past, setPast] = useState<StageSnapshot[]>([]);
  const [future, setFuture] = useState<StageSnapshot[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const { goToFuture, goToPast, recordPast, clearHistory, current } =
    useWorkHistory(past, future, setPast, setFuture);

  const { deleteItems } = useHotkeyFunc();

  const { token, setToken, clearToken, updateToken } = useToken();

  const [container, setContainer] = useState<HTMLDivElement>();

  const transformer = useTransformer();
  const { selectedItems, onSelectItem, setSelectedItems } =
    useSelection(transformer);
  const { stageData, createItem, removeItem, clearItems } = useItem();

  const stageRef = useRef() as MutableRefObject<Konva.Stage>;

  // History
  const [historyMenuOpen, setHistoryMenuOpen] = React.useState(false);
  const historyMenuRef = React.useRef<HTMLButtonElement>(null);

  const handleHistoryToggle = () => {
    setHistoryMenuOpen((prevOpen) => !prevOpen);
  };

  const handleHistoryMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      historyMenuRef.current &&
      historyMenuRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setHistoryMenuOpen(false);
  };

  const [aiGeneratorOpened, setAIGeneratorOpened] = useState(false);
  const activeTab: ActiveTab = appStore((state) => state.activeTab);

  React.useEffect(() => {
    if (activeTab === 'aigenerate') {
      setAIGeneratorOpened(true);
    } else {
      setAIGeneratorOpened(false);
    }
  }, [activeTab]);

  const setStateSizeToFitIn = useCallback(() => {
    if (!stageRef.current || !stageRef.current.container().parentElement) {
      return;
    }
    const containerWidth =
      stageRef.current.container().parentElement!.offsetWidth;

    const scale = containerWidth / canvasWidth;

    stageRef.current.width(canvasWidth * scale);
    stageRef.current.height(canvasHeight * scale);
    stageRef.current.scale({ x: scale, y: scale });

    stageRef.current.batchDraw();
  }, [stageRef]);

  useEffect(() => {
    window.addEventListener('load', setStateSizeToFitIn);
    window.addEventListener('resize', setStateSizeToFitIn);
  }, [setStateSizeToFitIn]);

  useEffect(() => {
    if (!stageRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setStateSizeToFitIn();
      });
    });
    resizeObserver.observe(stageRef.current.container());
    return () => resizeObserver.disconnect(); // clean up
  }, [setStateSizeToFitIn]);

  const onSelectEmptyBackground = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      e.target.getType() === 'Stage' && onSelectItem(e);
    },
    [onSelectItem],
  );

  const onMouseDownOnStage = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      onSelectEmptyBackground(e);
    },
    [onSelectEmptyBackground],
  );

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutate: updateTokenRequest,
    data: updatedToken,
    error: updateTokenError,
    isError: isUpdateTokenError,
    isSuccess: isUpdateTokenSuccess,
    isLoading: isUpdateTokenLoading,
  } = useGraphQLMutation(UpdateOneTokenDocument);

  if (isUpdateTokenError) {
    console.error('updateToken Error: ', updateTokenError);
  }
  if (isUpdateTokenSuccess) {
    console.log('updateToken Success: ', updatedToken);
  }
  if (isUpdateTokenLoading) {
    console.log('updateToken Loading');
  }

  const renderObject = (item: StageData) => {
    switch (item.attrs.dataItemType) {
      case 'image':
        return (
          <ImageItem
            key={`image-${item.id}`}
            data={item as ImageItemProps['data']}
            onDragEnd={() =>
              appStore.setState({
                recentChangeName: 'Image Dragged',
              })
            }
            onSelect={onSelectItem}
          />
        );
      case 'text':
        return (
          <TextItem
            key={`text-${item.id}`}
            data={item as TextItemProps['data']}
            transformer={transformer}
            onDragEnd={() =>
              appStore.setState({
                recentChangeName: 'Text Dragged',
              })
            }
            onSelect={onSelectItem}
          />
        );
      default:
        return null;
    }
  };

  const handleNewChange = (changeName: string) => {
    appStore.setState({
      recentChangeName: changeName,
    });
  };

  const imageDropped: boolean = appStore((state) => state.imageDropped);
  const setImageDropped = (condition: boolean) => {
    appStore.setState({
      imageDropped: condition,
    });
  };
  // const [imageDropped, setImageDropped] = React.useState(false);
  const handleImageDrop = (images: any[]) => {
    const imageSrc = new Image();
    const image = images[0];
    const formData = new FormData();
    formData.append('image', images[0]);
    console.log('image: ', image);
    Object.assign(image, {
      preview: URL.createObjectURL(image),
    });

    removeItem('image');
    imageSrc.onload = () => {
      const width = imageSrc.naturalWidth;
      const height = imageSrc.naturalHeight;
      const newImage: StageData = {
        id: 'image',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - width) / 2,
          y: (canvasHeight - height) / 2,
          width,
          height,
          src: image.preview,
          zIndex: 5,
          draggable: true,
          brightness: 0,
          filters: null,
          updatedAt: Date.now(),
        },
      };

      createItem(newImage);
      handleNewChange('Updated Image');
    };

    imageSrc.src = image.preview;
    setImageDropped(true);

    const defaultTokenName = `new token - ${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear().toString().slice(-2)}`;
    const newToken: TokenState = {
      data: {
        id: null,
        name: defaultTokenName,
        droppedImageBlob: images[0],
        droppedImageSrc: image.preview,
        edited: true,
      },
    };
    setToken(newToken);
  };

  const handleTokenTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newToken: TokenState = {
      data: {
        ...token.data,
        name: event.target.value,
      },
    };
    setToken(newToken);
  };

  const { displayNotification } = useNotification();

  const updateTokenModalOpen: boolean = appStore(
    (state) => state.updateTokenModalOpen,
  );

  const setupdateTokenModalOpen = (bool: boolean) => {
    appStore.setState({
      updateTokenModalOpen: bool,
    });
  };

  const deleteTokenModalOpen: boolean = appStore(
    (state) => state.deleteTokenModalOpen,
  );

  const setdeleteTokenModalOpen = (bool: boolean) => {
    appStore.setState({
      deleteTokenModalOpen: bool,
    });
  };

  useEffect(() => {
    const stageSnapshot: StageSnapshot = {
      name: recentChangeName,
      stageData,
    };
    const image = stageData.find(
      (data) => data.id === 'image' || data.attrs.id === 'image',
    );
    if (image) {
      setImageDropped(true);
    } else {
      setImageDropped(false);
    }

    let edited = false;
    if (stageData.length !== 0 && token?.data !== null) {
      if (JSON.stringify(stageData) !== JSON.stringify(token.data.edits)) {
        edited = true;
      }
    }

    updateToken(() => ({
      edited,
    }));

    recordPast(stageSnapshot);
  }, [stageData]);

  const handleCanvasImageDrop = (files: FileList) => {
    handleImageDrop([files[0]]);
  };

  const sortedStageData = useMemo(
    () =>
      stageData.sort((a, b) => {
        if (a.attrs.zIndex === b.attrs.zIndex) {
          if (a.attrs.zIndex < 0) {
            return b.attrs.updatedAt - a.attrs.updatedAt;
          }
          return a.attrs.updatedAt - b.attrs.updatedAt;
        }
        return a.attrs.zIndex - b.attrs.zIndex;
      }),
    [stageData],
  );

  const handleExitSession = ({ wait }: { wait: boolean }) => {
    if (wait) {
      setLoading(true);
      setTimeout(() => {
        clearItems();
        clearHistory();
        clearToken();
        setImageDropped(false);
        setLoading(false);
      }, 3000);
    } else {
      clearItems();
      clearHistory();
      clearToken();
      setImageDropped(false);
    }
  };

  const previewRef = useRef<PreviewRef>(null);

  const upsertToken = useUpdateRealmTokenFormData({
    onSuccess: (data) => {
      updateToken(() => ({
        id: data.updateOneToken?._id,
        name: data.updateOneToken?.name,
        isVisibleInTool: data.updateOneToken?.is_visible_in_tool,
        isPublic: data.updateOneToken?.is_public,
        edited: false,
      }));
      displayNotification({
        message: `${data.updateOneToken?.name} saved!`,
        type: 'success',
      });
    },
    onError: () => {
      displayNotification({ message: 'Error saving Token', type: 'error' });
    },
  });

  const handleSaveAs = () => {
    if (previewRef.current) {
      previewRef.current.savePreview();
    }
  };

  const handleSave = useCallback(() => {
    if (previewRef.current && token.data?.edited) {
      if (token?.data?.id) {
        const image = document.createElement('img');
        const { stage } = previewRef.current;
        stage.toBlob({
          x: 0,
          y: 0,
          width: 260,
          height: 260,
          pixelRatio: 1,
          callback: (blob) => {
            image.src = stage.toDataURL({
              x: 0,
              y: 0,
              width: 260,
              height: 260,
              pixelRatio: 1,
            });

            const tokenToUpdate = {
              finalImageBlob: blob,
              finalImageSrc: image.src,
              edits: stageData,
            };
            updateToken((prevData) => ({
              ...tokenToUpdate,
              edited: prevData.data?.edited,
            }));

            const data = {
              TokenName: token?.data?.name ?? '',
              TokenDescription: token?.data?.description ?? '',
              Token: tokenToUpdate.finalImageBlob ?? new Blob(),
              OriginalImage:
                (token?.data?.droppedImageBlob as Blob) ?? new Blob(),
              TokenEdits: tokenToUpdate.edits ?? null,
              IsPublic: token?.data?.isPublic,
              IsVisibleInTool: token?.data?.isVisibleInTool,
              Notes: [token?.data?.notes as Note],
            };

            const formdata = stringify(data, { indices: true });
            upsertToken.mutate({
              // @ts-ignore
              body: formdata,
              pathParams: {
                realmId: token?.data.realmId ?? 'error',
                tokenId: token?.data?.id ?? 'error',
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
              },
            });
          },
        });
      } else {
        handleSaveAs();
      }
    }
  }, [previewRef, token]);

  const handleStageClear = () => {
    clearItems();
    setImageDropped(false);
    setAIGeneratorOpened(false);
  };

  useHotkeys(
    'ctrl+s',
    (e) => {
      e.preventDefault();
      handleSave();
    },
    {},
    [previewRef, token],
  );

  useHotkeys(
    'ctrl+z',
    (e) => {
      e.preventDefault();
      goToPast();
    },
    {},
    [goToPast],
  );

  useHotkeys(
    'ctrl+y',
    (e) => {
      e.preventDefault();
      goToFuture();
    },
    {},
    [goToFuture],
  );

  useHotkeys(
    'backspace',
    (e) => {
      e.preventDefault();
      setImageDropped(false);
      deleteItems(selectedItems, setSelectedItems, transformer.transformerRef);
    },
    { enabled: Boolean(selectedItems.length) },
    [selectedItems, transformer.transformerRef.current],
  );

  useHotkeys(
    'delete',
    (e) => {
      e.preventDefault();
      setImageDropped(false);
      deleteItems(selectedItems, setSelectedItems, transformer.transformerRef);
    },
    { enabled: Boolean(selectedItems.length) },
    [selectedItems, transformer.transformerRef.current],
  );

  useEffect(() => {
    if (stageRef.current) {
      setContainer(stageRef.current!.container());
    }
  }, []);

  const [faqMenuOpened, setFAQMenuOpened] = useState(false);
  const handleToggleFAQMenu = () => {
    setFAQMenuOpened(!faqMenuOpened);
  };

  const handleAIImageSelected = (imageUrl: string) => {
    console.log(imageUrl);
    handleURLImageDrop(imageUrl, createItem, removeItem, setToken);
    handleNewChange('Updated Image');
    setAIGeneratorOpened(false);
  };

  // TODO: Add logic to swtich between views of the different shopping modal
  const [openThankYouModal, setOpenThankYouModal] = React.useState(false);
  const [openEmptyCartModal, setOpenEmptyCartModal] = React.useState(false);
  const [openShoppingCartModal, setShoppingCartModal] = React.useState(false);

  return (
    <>
      <Panels
        onStageChange={handleNewChange}
        goToPast={goToPast}
        gotToFuture={goToFuture}
        handleHistory={handleHistoryToggle}
        historyOpened={historyMenuOpen}
        // saveDisabled={!token.data?.edited}
        handleStageClear={handleStageClear}
      />
      <CartItemsModal
        open={openShoppingCartModal}
        onClose={() => {
          console.log('modal closed');
        }}
        setOpen={setShoppingCartModal}
      />
      <ThankYouModal
        open={openThankYouModal}
        onClose={() => {
          console.log('modal closed');
        }}
        setOpen={setOpenThankYouModal}
      />
      <EmptyCartModal
        open={openEmptyCartModal}
        onClose={() => {
          console.log('modal closed');
        }}
        setOpen={setOpenEmptyCartModal}
      />
      <TokenPackPurchaseSummaryModal
        open={openTokenPackPurchaseSummaryModal}
        onClose={() => {
          setOpenTokenPackPurchaseSummaryModal(false);
        }}
        onConfirm={() => {}}
        setOpen={setOpenTokenPackPurchaseSummaryModal}
      />
      {historyMenuOpen && (
        <Box
          className={`history_wrapper ${theme === 'light' ? 'light' : 'dark'} ${
            styles.history_wrapper
          } ${styles[theme]}`}
        >
          <ClickAwayListener onClickAway={handleHistoryMenuClose}>
            <FloatingMenu>
              {past.length && current ? (
                [...past, current].map((item) => (
                  <FloatingMenuItem
                    onClick={handleHistoryMenuClose}
                    key={item?.name}
                  >
                    {item?.name}
                  </FloatingMenuItem>
                ))
              ) : (
                <FloatingMenuItem>No Changes</FloatingMenuItem>
              )}
            </FloatingMenu>
          </ClickAwayListener>
        </Box>
      )}
      {imageDropped && (
        <div className={styles.titleWrapper}>
          <BiPencil className={`${styles.icon} ${styles[theme]}`} />
          <input
            id="tokenTitle"
            value={token?.data?.name ?? ''}
            className={`${styles.title} ${styles[theme]}`}
            onChange={handleTokenTitleChange}
          />
        </div>
      )}
      <Box
        component="main"
        className={`${styles.canvas_main_wrapper} ${styles[theme]}`}
      >
        {!imageDropped && !aiGeneratorOpened && (
          <Dropzone onDrop={handleImageDrop} noClick>
            {({ getRootProps, getInputProps, open: openfs }) => (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                {...getRootProps()}
                className={`${styles.grid_wrapper} ${
                  !imageDropped ? styles.not_image_dropped : ''
                }`}
              >
                <Grid item xs={12} className={styles.upload_wrapper}>
                  <input {...getInputProps()} />
                  {!imageDropped && (
                    <>
                      <Typography
                        align="center"
                        className={`${styles.drag_and_drop_text} ${styles[theme]}`}
                      >
                        DRAG and DROP IMAGE
                      </Typography>
                      <Typography
                        align="center"
                        className={`${styles.drag_and_drop_text__or} ${styles[theme]}`}
                      >
                        OR
                      </Typography>
                      <Typography
                        align="center"
                        className={`${styles.upload_button} ${styles[theme]}`}
                        onClick={() => {
                          openfs();
                        }}
                      >
                        UPLOAD
                      </Typography>
                      <button
                        type="button"
                        className={`${styles.realm_ai_button} ${
                          false && styles.disabled
                        } ${styles[theme]}`}
                        onClick={() => {
                          appStore.setState({
                            activeTab: 'aigenerate',
                          });
                        }}
                      >
                        <Typography
                          align="center"
                          className={`${styles.realm_ai_text} ${styles[theme]}`}
                        >
                          A.I. Generate
                        </Typography>
                        <svg
                          version="1.1"
                          id="Layer_2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24.62 24.54"
                        >
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <path d="m24.62,8.55l-4.44-1.71,2.16-4.24-4.35,1.93-1.48-4.53-1.71,4.44-4.25-2.16,1.93,4.36-4.52,1.47,3.94,1.52L.38,21.16c-.5.5-.5,1.31,0,1.81l1.2,1.2c.5.5,1.31.5,1.81,0l11.41-11.42,1.28,3.92,1.71-4.45,4.24,2.16-1.92-4.35,4.52-1.48Zm-12.12,5.02c-.21.21-.49.32-.76.32-.22,0-.44-.07-.63-.21-.05-.03-.1-.07-.14-.11-.13-.13-.21-.28-.25-.44-.13-.37-.04-.8.25-1.09l3.53-3.53c.42-.42,1.1-.42,1.52,0h.01c.42.42.42,1.11,0,1.53l-3.53,3.53Z" />
                              <path d="m13.5,16.87l.42.77c.11.2.28.37.48.48l.77.42-.77.42c-.2.11-.37.28-.48.48l-.42.77-.42-.77c-.11-.2-.28-.37-.48-.48l-.77-.42.77-.42c.2-.11.37-.28.48-.48l.42-.77Z" />
                              <path d="m20.21,17.11l.83,1.53c.22.4.55.73.95.95l1.53.83-1.53.83c-.4.22-.73.55-.95.95l-.83,1.53-.83-1.53c-.22-.4-.55-.73-.95-.95l-1.53-.83,1.53-.83c.4-.22.73-.55.95-.95l.83-1.53Z" />
                            </g>
                          </g>
                        </svg>
                      </button>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Dropzone>
        )}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <ReactReduxContext.Consumer>
              {({ store }) => (
                <Provider store={store}>
                  <Box position="absolute" right={24} zIndex={100}>
                    <Box className={styles.preview_wrapper} maxWidth={260}>
                      {imageDropped && (
                        <Preview
                          ref={previewRef}
                          onSave={() => {
                            handleExitSession({ wait: true });
                          }}
                          realm={realmDataString}
                          // setOpenSaveTokenModal={setOpenSaveTokenModal}
                          // saveNewTokenSet={saveNewTokenSet}
                        />
                      )}
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="start"
                        spacing={1}
                        className={`${
                          imageDropped && styles.manMage_wrapper_margin_top
                        }`}
                      >
                        <Box
                          className={`${styles.manMage_quote_wrapper} ${styles[theme]}`}
                        >
                          <Typography
                            className={`${styles.manMage_quote} ${styles[theme]}`}
                          >
                            IT&apos;S DANGEROUS TO GO ALONE!
                          </Typography>
                          <Link
                            color="#FF6746"
                            target="_blank"
                            href="https://chrome.google.com/webstore/category/extensions?hl=en"
                          >
                            <Typography className={styles.manMage_link}>
                              Get the Extension
                            </Typography>
                          </Link>
                        </Box>
                        <img
                          className={styles.manMage_image}
                          src={mageManImage}
                          alt="manMage"
                        />
                        {/* <Typography fontSize={50}>🧙</Typography> */}
                      </Stack>
                    </Box>
                  </Box>
                  <Stage
                    ref={stageRef}
                    width={canvasWidth}
                    height={canvasHeight}
                    onMouseDown={onMouseDownOnStage}
                  >
                    <Layer listening={false} draggable={false} />
                    <Layer>
                      {stageData.length
                        ? sortedStageData.map((item) => renderObject(item))
                        : null}
                      <Transformer
                        ref={transformer.transformerRef}
                        keepRatio
                        shouldOverdrawWholeArea
                        boundBoxFunc={(_, newBox) => newBox}
                        onTransformEnd={transformer.onTransformEnd}
                      />
                    </Layer>
                  </Stage>
                  {container ? (
                    <Drop
                      callback={handleCanvasImageDrop}
                      targetDOMElement={container}
                    />
                  ) : null}
                  <Notification />
                </Provider>
              )}
            </ReactReduxContext.Consumer>
          </Grid>
        </Grid>
      </Box>
      <div className={`${styles.faq_parent_wrapper} ${styles[theme]}`}>
        {faqMenuOpened && (
          <div className={`${styles.faq_menu_wrapper} ${styles[theme]}`}>
            <FloatingMenu>
              <FloatingMenuItem>FAQ</FloatingMenuItem>
              <FloatingMenuItem>Tool Tip Menu</FloatingMenuItem>
            </FloatingMenu>
          </div>
        )}
        <div className={`${styles.faq_wrapper} ${styles[theme]}`}>
          <button
            type="button"
            className={`${styles.faq_button} ${faqMenuOpened && styles.active}`}
            onClick={handleToggleFAQMenu}
            aria-label="toggle faq menu"
          >
            <svg
              id="Layer_2"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35.04 33.15"
            >
              <g id="Layer_1-2" data-name="Layer 1">
                <path d="m17.52,0C7.84,0,0,6.43,0,14.35c0,4.47,2.49,8.46,6.4,11.09,2.35,1.52-.1,6.66-3.37,7.23,3.17,2.29,8.58-4.39,11.22-4.22h.04c1.05.17,2.13.25,3.23.25,9.67,0,17.52-6.43,17.52-14.35S27.19,0,17.52,0Zm.15,24.41c-1.09,0-1.97-.88-1.97-1.96s.88-1.97,1.97-1.97,1.96.88,1.96,1.97-.88,1.96-1.96,1.96Zm2.7-9.54c-1.11.53-1.85.88-1.85,2.8h-2c0-3.17,1.73-4,2.99-4.61,1.08-.51,1.67-.8,1.67-2.35,0-1.19-.25-2.05-.76-2.55-.7-.69-1.86-.69-2.71-.68h-.23c-1.38,0-3.04.56-3.04,3.23h-2c0-3.85,2.61-5.23,5.04-5.23h.22c1.06-.01,2.84-.02,4.13,1.26.9.89,1.35,2.22,1.35,3.97,0,2.81-1.62,3.59-2.81,4.16Z" />
              </g>
            </svg>
          </button>
          <Typography className={styles.token_tool_version}>
            Token Tool v.1
          </Typography>
        </div>
      </div>
      {aiGeneratorOpened && !imageDropped && (
        <AIGenerated onImageSelect={handleAIImageSelected} />
      )}
      {/* modal to update token name */}
      <UpdateTokenModal
        open={updateTokenModalOpen}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={(bool: boolean) => setupdateTokenModalOpen(bool)}
      />
      {/* modal to delete a token */}
      <DeleteTokenModal
        open={deleteTokenModalOpen}
        onClose={() => {}}
        setOpen={(bool: boolean) => setdeleteTokenModalOpen(bool)}
      />
    </>
  );
}

export default TokenMaker;
