import React from 'react';
import {
  Box, Stack, Tooltip, Typography, Zoom,
} from '@mui/material';
import {
  AIButton,
  AIButtonImg,
  AIButtonText,
  AttributeTextField,
  AttributeWrapper,
  CoinImg,
  FlexibleHeightImg,
  HelpImg,
  SmallHeadingText,
} from '../../styledComps/styledComps';
import FancyUnderline from '../../../images/fancy-underline-one.webp';
import HelpIcon from '../../../images/help.webp';
import CoinIcon from '../../../images/azamel_Coin.png';
import FantasyIcon from '../../../images/fantasy.webp';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import CharacterSectionWithHelp from './CharacterSectionWithHelp';
import useGraphQL from '../../../hooks/useGraphQL';
import { GetProfessionsDocument, GetRelationshipsDocument } from '../../../__generated__/graphql';

interface Props {
  characterProfession: string;
  setCharacterProfession: (char: string) => void;
  characterRelationship: string;
  setCharacterRelationship: (char: string) => void;
  characterLifeAlteringEvent: string;
  setCharacterLifeAlteringEvent: (char: string) => void;
  characterBackStory: string;
  setCharacterBackStory: (char: string) => void;
  characterLineage: string;
  setCharacterLineage: (char: string) => void;
}

const ProfessionOptions = [
  'BlackSmith',
  'GoldSmith',
  'Sailor',
  'Stable Boy',
  'Jester',
  'Farmer',
  'Miner',
];

const RelationshipOptions = [
  'Single',
  'Married',
  'Player',
  'Divorcee',
  'Widow',
];

function CharacterBackgroundInformation({
  characterProfession,
  setCharacterProfession,
  characterRelationship,
  setCharacterRelationship,
  characterLifeAlteringEvent,
  setCharacterLifeAlteringEvent,
  characterBackStory,
  setCharacterBackStory,
  characterLineage,
  setCharacterLineage,
}: Props) {
  const { data: relationships } = useGraphQL(
    GetRelationshipsDocument,
    {},
  );
  console.log(relationships?.relationship_status);
  const { data: professions } = useGraphQL(
    GetProfessionsDocument,
    {},
  );
  console.log(professions?.profession);
  return (
    <Stack gap="33px">
      <Stack gap="21px">
        <Stack justifyContent="center" alignItems="center" gap="14px">
          <Typography
            sx={{
              color: '#250503',
              fontFamily: 'Passero One',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '50px',
              letterSpacing: '.1px',
            }}
          >
            Background Information
          </Typography>
          <FlexibleHeightImg width="102px" src={FancyUnderline} />
          <Stack direction="row" gap="30px">
            <Typography
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '30px', /* 150% */
                letterSpacing: '.1px',
              }}
            >
              Need help writing?
            </Typography>
            <Stack direction="row" gap="3px" alignItems="center">
              <AIButton>
                <AIButtonImg src={FantasyIcon} />
                <AIButtonText>AI Scribe</AIButtonText>
              </AIButton>
              <Tooltip
                title="A help note about AI scribe"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontFamily: 'Passero One',
                      fontWeight: '400px',
                      fontSize: '14px',
                      padding: '10px',
                      bgcolor: '#3c3c3ce6',
                      '& .MuiTooltip-arrow': {
                        color: '#3c3c3ce6',
                      },
                      maxWidth: '250px',
                    },
                  },
                }}
              >
                <HelpImg src={HelpIcon} style={{ marginTop: '-40px' }} />
              </Tooltip>
              <Stack direction="row" alignItems="center">
                <CoinImg src={CoinIcon} />
                <Typography
                  sx={{
                    color: '#250503',
                    fontFamily: 'Passero One',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '.1px',
                  }}
                >
                  2
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* Character birth story */}
      <Box>
        <CharacterSectionWithHelp
          title="Birth Story"
          message="lorem ipsum dolor"
        />
        <AttributeWrapper width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                minWidth: '100%',
              }}
            >
              <SmallHeadingText>Birth Story</SmallHeadingText>
              <AttributeTextField
                rows={4}
                multiline
                placeholder="Enter character birth story"
                value={characterBackStory}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterBackStory(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </AttributeWrapper>
      </Box>
      {/* Character Profession */}
      <Box>
        <CharacterSectionWithHelp
          title="Profession"
          message="lorem ipsum dolor"
        />
        <CharacterFilterDropdown
          width="100%"
          title="Profession"
          options={ProfessionOptions}
          value={characterProfession}
          onChange={setCharacterProfession}
        />
      </Box>
      {/* Character Lineage */}
      <Box>
        <CharacterSectionWithHelp
          title="Lineage"
          message="lorem ipsum dolor"
        />
        <AttributeWrapper width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                minWidth: '100%',
              }}
            >
              <SmallHeadingText>Parents (Lineage)</SmallHeadingText>
              <AttributeTextField
                rows={4}
                multiline
                placeholder="Enter character lineage"
                value={characterLineage}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterLineage(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </AttributeWrapper>
      </Box>
      {/* Character Relatioships */}
      <Box>
        <CharacterSectionWithHelp
          title="Relationship"
          message="lorem ipsum dolor"
        />
        <CharacterFilterDropdown
          width="100%"
          title="Relationship"
          options={RelationshipOptions}
          value={characterRelationship}
          onChange={setCharacterRelationship}
        />
      </Box>
      {/* Character Life Altering Event */}
      <Box>
        <CharacterSectionWithHelp
          title="Life Altering Event(s)"
          message="lorem ipsum dolor"
        />
        <AttributeWrapper width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                minWidth: '100%',
              }}
            >
              <SmallHeadingText>Life Altering Event(s)</SmallHeadingText>
              <AttributeTextField
                rows={4}
                multiline
                placeholder="Enter character life altering event(s)"
                value={characterLifeAlteringEvent}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterLifeAlteringEvent(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </AttributeWrapper>
      </Box>
    </Stack>
  );
}

export default CharacterBackgroundInformation;
