/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import {
  Button, Stack, Tooltip, Zoom,
} from '@mui/material';
import { GenerateDiceImg, HelpImg, SectionHeadingText } from '../../styledComps/styledComps';
import HelpIcon from '../../../images/help.webp';
import DiceIcon from '../../../images/dice.webp';
import CharacterAbility from './CharacterAbility';
import { CharacterAbility as CharAbility } from '../../../__generated__/graphql';

interface Props {
  variant?: 'characterGen' | 'characterSummary' | 'characterWizard1' | 'characterWizard2',
  abilities?: CharAbility | undefined | null,
  setCharacterAbilities: (ab: CharAbility) => void,
}

function CharacterAbilitiesGen({
  variant,
  abilities,
  setCharacterAbilities,
}: Props) {
  const limit = 100;
  // const [aboveLimit, setAboveLimit] = React.useState(false);
  // const checkLimit = (str: any, dex: any, con: any, int: any, wis: any, cha: any) => {
  //   const sum = str + dex + con + int + wis + cha;
  //   if (sum > limit) {
  //     setAboveLimit(true);
  //   } else {
  //     setAboveLimit(false);
  //   }
  // };
  return (
    <Stack gap="22px">
      {variant !== 'characterWizard1' && variant !== 'characterWizard2' && (
        <Stack gap="7px" direction="row" justifyContent="center" alignItems="center">
          <SectionHeadingText>Abilities</SectionHeadingText>
          {variant === 'characterGen' && (
            <>
              <Tooltip
                title="Abilities of the character you are about to create are as follows "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontFamily: 'Passero One',
                      fontWeight: '400px',
                      fontSize: '14px',
                      padding: '10px',
                      bgcolor: '#3c3c3ce6',
                      '& .MuiTooltip-arrow': {
                        color: '#3c3c3ce6',
                      },
                      maxWidth: '250px',
                    },
                  },
                }}
              >
                <HelpImg src={HelpIcon} />
              </Tooltip>
              <Button
                sx={{
                  width: '33px',
                  minWidth: '0px',
                  height: '33px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#FFF',
                  '&:hover': {
                    background: '#FFF',
                  },
                }}
              >
                <GenerateDiceImg src={DiceIcon} />
              </Button>
            </>
          )}
        </Stack>
      )}
      <Stack
        gap={variant === 'characterGen' ? '68px' : '28px'}
        direction={variant === 'characterGen' || variant === 'characterWizard1' || variant === 'characterWizard2' ? 'row' : 'column'}
        flexWrap={variant === 'characterWizard1' || variant === 'characterWizard2' ? 'wrap' : 'nowrap'}
        justifyContent="center"
        alignItems="center"
      >
        <CharacterAbility
          title="str"
          variant={variant}
          value={abilities?.strength !== undefined
            ? parseInt(abilities?.strength ?? '0', 10) : 0}
          tip="Strength of the character"
          onIncrease={() => {
            if (abilities?.strength !== undefined) {
              const newValue = parseInt(abilities?.strength ?? '0', 10) + 2;
              const total = abilities?.charisma! + abilities?.consitution!
              + abilities?.dexterity! + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  strength: newValue.toString(),
                });
              }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.strength !== undefined) {
              const newValue = val;
              const total = abilities?.charisma! + abilities?.consitution!
              + abilities?.dexterity! + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  strength: newValue.toString(),
                });
              }
            }
          }}
        />
        <CharacterAbility
          title="dex"
          variant={variant}
          value={abilities?.dexterity !== undefined
            ? abilities?.dexterity ?? 0 : 0}
          tip="Dexterity of the character"
          onIncrease={() => {
            if (abilities?.dexterity !== null && abilities?.dexterity !== undefined) {
              const newValue = abilities?.dexterity + 2;
              const total = abilities?.charisma! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  dexterity: newValue,
                });
              }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.dexterity !== null && abilities?.dexterity !== undefined) {
              const newValue = val;
              const total = abilities?.charisma! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  dexterity: newValue,
                });
              }
            }
          }}
        />
        <CharacterAbility
          title="con"
          variant={variant}
          value={abilities?.consitution !== undefined
            ? abilities?.consitution ?? 0 : 0}
          tip="Constitution of the character"
          onIncrease={() => {
            if (abilities?.consitution !== null && abilities?.consitution !== undefined) {
              const newValue = abilities?.consitution + 2;
              const total = abilities?.charisma! + abilities?.dexterity!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  consitution: newValue,
                });
              }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.consitution !== null && abilities?.consitution !== undefined) {
              const newValue = val;
              const total = abilities?.charisma! + abilities?.dexterity!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  consitution: newValue,
                });
              }
            }
          }}
        />
        <CharacterAbility
          title="int"
          variant={variant}
          value={abilities?.intelligence !== undefined
            ? abilities?.intelligence ?? 0 : 0}
          tip="Intelligence of the character"
          onIncrease={() => {
            if (abilities?.intelligence !== null && abilities?.intelligence !== undefined) {
              const newValue = abilities?.intelligence + 2;
              const total = abilities?.charisma! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.dexterity!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  intelligence: newValue,
                });
              }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.intelligence !== null && abilities?.intelligence !== undefined) {
              const newValue = val;
              const total = abilities?.charisma! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.dexterity!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  intelligence: newValue,
                });
              }
            }
          }}
        />
        <CharacterAbility
          title="wis"
          variant={variant}
          value={abilities?.wisdom !== undefined
            ? abilities?.wisdom ?? 0 : 0}
          tip="Wisdom of the character"
          onIncrease={() => {
            if (abilities?.wisdom !== null && abilities?.wisdom !== undefined) {
              const newValue = abilities?.wisdom + 2;
              const total = abilities?.charisma! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.dexterity! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  wisdom: newValue,
                });
              }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.wisdom !== null && abilities?.wisdom !== undefined) {
              const newValue = val;
              const total = abilities?.charisma! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.dexterity! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  wisdom: newValue,
                });
              }
            }
          }}
        />
        <CharacterAbility
          title="cha"
          variant={variant}
          value={abilities?.charisma !== undefined
            ? abilities?.charisma ?? 0 : 0}
          tip="Charisma of the character"
          onIncrease={() => {
            if (abilities?.charisma !== null && abilities?.charisma !== undefined) {
              const newValue = abilities?.charisma + 2;
              const total = abilities?.dexterity! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  charisma: newValue,
                });
              }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.charisma !== null && abilities?.charisma !== undefined) {
              const newValue = val;
              const total = abilities?.dexterity! + abilities?.consitution!
              + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              + abilities?.wisdom! + newValue;
              if (total <= limit) {
                setCharacterAbilities({
                  ...abilities,
                  charisma: newValue,
                });
              }
            }
          }}
        />
      </Stack>
    </Stack>
  );
}

CharacterAbilitiesGen.defaultProps = {
  variant: 'characterGen',
  abilities: null,
};

export default CharacterAbilitiesGen;
