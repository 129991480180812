import React from 'react';
import { Box } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import './charactertypemenu.scss';
import { Image_Mask } from '../../__generated__/graphql';

interface Props {
  characterList: Image_Mask[];
  setSelectedMask: (id: string) => void;
  selectedMask: string | null;
}

function CharacterTypeMenu({ characterList, setSelectedMask, selectedMask }: Props) {
  const { theme } = React.useContext(ThemeContext);

  return (
    <div className={`characterFormWrapper ${theme}`}>
      <div
        id="characterMenu"
        onWheel={(event: React.WheelEvent<HTMLDivElement>) => {
          // event.preventDefault();
          document.getElementById('characterMenu')!.scrollLeft += event.deltaY;
        }}
        className="characterItemsWrapper"
      >
        {characterList.map((item) => (
          <Box onClick={() => setSelectedMask(item._id)} key={item._id} className={`characterItemWrapper ${selectedMask === item._id ? 'active' : ''}`}>
            <label htmlFor={item.name!}>
              <div className="characterImageContainer">
                <span className="characterTitle">{item.name}</span>
                <img src={item.mask_url!} alt={item.name!} className="characterImage" />
              </div>
            </label>
            <input
              className="radioButton"
              name="character_type"
              type="radio"
              id={item._id}
              value={item.name!}
            />
          </Box>
        ))}
      </div>
    </div>

  );
}

export default CharacterTypeMenu;
