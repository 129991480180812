import React, { FC } from 'react';
import {
  Box, Button, Container, MenuItem, Select, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  GetArchtypesAttributesDocument,
  GetBodyShapesAttributesDocument,
  GetCharacterHeightsDocument,
  GetClassesAttributesDocument,
  GetEyesAttributesDocument,
  GetGendersAttributesDocument,
  GetHairAttributesDocument,
  GetImageMasksDocument,
  GetPersonalitiesAttributesDocument,
  GetRacesAttributesDocument,
  GetSkinsAttributesDocument,
  GetWeaponsAttributesDocument,
} from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';
import { GenerationNewImagesVariables, fetchGenerationNewImages } from '../../__generated__/realmWorldsApiComponents';
import { useAuth } from '../../hooks/use-auth';
import GeneratedImages from './GeneratedImages';

/*
mutation InsertAttributes(
    $hairInput: [HairInsertInput!]!,
    $eyeInput: [EyeInsertInput!]!,
    $skinInput: [SkinInsertInput!]!,
    $raceInput: [RaceInsertInput!]!,
    $genderInput: [GenderInsertInput!]!,
    $bodyShapeInput: [Body_shapeInsertInput!]!,
    $classInput: [ClassInsertInput!]!,
    $weaponInput: [WeaponInsertInput!]!,
    $personalityInput: [PersonalityInsertInput!]!,
    $archetypeInput: [ArchetypeInsertInput!]!,
    $imageMaskInput: [Image_maskInsertInput!]!,
    $characterHeightInput: [Character_heightInsertInput!]!
) {
    insertManyHairs(data: $hairInput) {
    insertedIds
    }
    insertManyEyes(data: $eyeInput) {
        insertedIds
    }
    insertManySkins(data: $skinInput) {
        insertedIds
    }
    insertManyRaces(data: $raceInput) {
        insertedIds
    }
    insertManyGenders(data: $genderInput) {
        insertedIds
    }
    insertManyBody_shapes(data: $bodyShapeInput) {
        insertedIds
    }
    insertManyClasses(data: $classInput) {
        insertedIds
    }
    insertManyWeapons(data: $weaponInput) {
        insertedIds
    }
    insertManyPersonalities(data: $personalityInput) {
        insertedIds
    }
    insertManyArchetypes(data: $archetypeInput) {
        insertedIds
    }
    insertManyImage_masks(data: $imageMaskInput) {
        insertedIds
    }
    insertManyCharacter_heights(data: $characterHeightInput) {
        insertedIds
    }
}
variables:
{
  "hairInput": [
    {"name": "Straight Black"},
    {"name": "Wavy Blonde"},
    {"name": "Curly Brown"},
    {"name": "Short Red"},
    {"name": "Long Blue"},
    {"name": "Buzzed Green"},
    {"name": "Bob Purple"},
    {"name": "Afro Pink"},
    {"name": "Braided Silver"},
    {"name": "Bald Golden"}
  ],
  "eyeInput": [
    {"name": "Blue"},
    {"name": "Green"},
    {"name": "Brown"},
    {"name": "Black"},
    {"name": "Amber"},
    {"name": "Gray"},
    {"name": "Hazel"},
    {"name": "Red"},
    {"name": "Violet"},
    {"name": "Silver"}
  ],
  "skinInput": [
    {"name": "Fair"},
    {"name": "Light"},
    {"name": "Medium"},
    {"name": "Olive"},
    {"name": "Tan"},
    {"name": "Brown"},
    {"name": "Dark Brown"},
    {"name": "Black"},
    {"name": "Albino"},
    {"name": "Bronzed"}
  ],
  "raceInput": [
    {"name": "Human"},
    {"name": "Elf"},
    {"name": "Dwarf"},
    {"name": "Orc"},
    {"name": "Halfling"},
    {"name": "Dragonborn"},
    {"name": "Gnome"},
    {"name": "Tiefling"},
    {"name": "Aarakocra"},
    {"name": "Genasi"}
  ],
  "genderInput": [
    {"name": "Male"},
    {"name": "Female"},
    {"name": "Non-Binary"},
    {"name": "Genderqueer"},
    {"name": "Genderfluid"},
    {"name": "Transgender"},
    {"name": "Two-Spirit"},
    {"name": "Agender"},
    {"name": "Bigender"},
    {"name": "Other"}
  ],
  "bodyShapeInput": [
    {"name": "Slim", "mask_url": "url1"},
    {"name": "Athletic", "mask_url": "url2"},
    {"name": "Curvy", "mask_url": "url3"},
    {"name": "Heavyset", "mask_url": "url4"},
    {"name": "Muscular", "mask_url": "url5"},
    {"name": "Petite", "mask_url": "url6"},
    {"name": "Tall", "mask_url": "url7"},
    {"name": "Short", "mask_url": "url8"},
    {"name": "Average", "mask_url": "url9"},
    {"name": "Broad", "mask_url": "url10"}
  ],
  "classInput": [
    {"name": "Warrior"},
    {"name": "Mage"},
    {"name": "Rogue"},
    {"name": "Cleric"},
    {"name": "Paladin"},
    {"name": "Warlock"},
    {"name": "Ranger"},
    {"name": "Monk"},
    {"name": "Bard"},
    {"name": "Druid"}
  ],
  "weaponInput": [
    {"name": "Sword", "mask_url": "w_url1"},
    {"name": "Bow", "mask_url": "w_url2"},
    {"name": "Axe", "mask_url": "w_url3"},
    {"name": "Dagger", "mask_url": "w_url4"},
    {"name": "Staff", "mask_url": "w_url5"},
    {"name": "Crossbow", "mask_url": "w_url6"},
    {"name": "Mace", "mask_url": "w_url7"},
    {"name": "Spear", "mask_url": "w_url8"},
    {"name": "Flail", "mask_url": "w_url9"},
    {"name": "Shield", "mask_url": "w_url10"}
  ],
  "personalityInput": [
    {"name": "Brave"},
    {"name": "Shy"},
    {"name": "Outgoing"},
    {"name": "Reserved"},
    {"name": "Loyal"},
    {"name": "Treacherous"},
    {"name": "Kind"},
    {"name": "Rude"},
    {"name": "Optimistic"},
    {"name": "Pessimistic"}
  ],
  "archetypeInput": [
    {"name": "Hero"},
    {"name": "Villain"},
    {"name": "Mentor"},
    {"name": "Damsel"},
    {"name": "Outcast"},
    {"name": "Fool"},
    {"name": "Magician"},
    {"name": "Orphan"},
    {"name": "Lover"},
    {"name": "Explorer"}
  ],
  "imageMaskInput": [
    {"name": "Mask1", "mask_url": "mask_url1"},
    {"name": "Mask2", "mask_url": "mask_url2"},
    {"name": "Mask3", "mask_url": "mask_url3"},
    {"name": "Mask4", "mask_url": "mask_url4"},
    {"name": "Mask5", "mask_url": "mask_url5"},
    {"name": "Mask6", "mask_url": "mask_url6"},
    {"name": "Mask7", "mask_url": "mask_url7"},
    {"name": "Mask8", "mask_url": "mask_url8"},
    {"name": "Mask9", "mask_url": "mask_url9"},
    {"name": "Mask10", "mask_url": "mask_url10"}
  ],
  "characterHeightInput": [
    {"name": "Tiny"},
    {"name": "Very Short"},
    {"name": "Short"},
    {"name": "Below Average"},
    {"name": "Average"},
    {"name": "Above Average"},
    {"name": "Tall"},
    {"name": "Very Tall"},
    {"name": "Giant"},
    {"name": "Colossal"}
  ]
}
*/

const GenerateImages: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [workflowId, setWorkflowId] = React.useState<number>(-1);
  const { data: hair, isError: isHairError, error: hairError } = useGraphQL(
    GetHairAttributesDocument,
    {},
  );

  const { data: eyes } = useGraphQL(
    GetEyesAttributesDocument,
    {},
  );

  const { data: skin } = useGraphQL(
    GetSkinsAttributesDocument,
    {},
  );

  const { data: races } = useGraphQL(
    GetRacesAttributesDocument,
    {},
  );

  const { data: genders } = useGraphQL(
    GetGendersAttributesDocument,
    {},
  );

  const { data: bodyShapes } = useGraphQL(
    GetBodyShapesAttributesDocument,
    {},
  );

  const { data: classes } = useGraphQL(
    GetClassesAttributesDocument,
    {},
  );

  const { data: weapons } = useGraphQL(
    GetWeaponsAttributesDocument,
    {},
  );

  const { data: personalities } = useGraphQL(
    GetPersonalitiesAttributesDocument,
    {},
  );

  const { data: archetypes } = useGraphQL(
    GetArchtypesAttributesDocument,
    {},
  );

  const { data: imageMasks } = useGraphQL(
    GetImageMasksDocument,
    {},
  );

  const { data: characterHeights } = useGraphQL(
    GetCharacterHeightsDocument,
    {},
  );

  if (isHairError) {
    // TODO Check if we get an unauthorized error that the token has been expired and refresh it
    // auth?.refreshToken();
    console.error('gql Error: ', hairError);
    if ((hairError as any)?.error.includes('expired')) {
      auth?.refreshToken().then(() => {
        navigate('/imageGen');
      });
    }
  }

  const [selectedHair, setSelectedHair] = React.useState<string>(hair?.hairs?.[0]?.name ?? 'No Hair found');
  const [selectedEyes, setSelectedEyes] = React.useState<string>(eyes?.eyes?.[0]?.name ?? 'No Eyes found');
  const [selectedSkin, setSelectedSkin] = React.useState<string>(skin?.skins?.[0]?.name ?? 'No Skin found');
  const [selectedRace, setSelectedRace] = React.useState<string>(races?.races?.[0]?.name ?? 'No Race found');
  const [selectedGender, setSelectedGender] = React.useState<string>(genders?.genders?.[0]?.name ?? 'No Gender found');
  const [selectedBodyShape, setSelectedBodyShape] = React.useState<string>(bodyShapes?.body_shapes?.[0]?.name ?? 'No Body Shape found');
  const [selectedClass, setSelectedClass] = React.useState<string>(classes?.classes?.[0]?.name ?? 'No Class found');
  const [selectedWeapon, setSelectedWeapon] = React.useState<string>(weapons?.weapons?.[0]?.name ?? 'No Weapon found');
  const [selectedPersonality, setSelectedPersonality] = React.useState<string>(personalities?.personalities?.[0]?.name ?? 'No Personality found');
  const [selectedArchetype, setSelectedArchetype] = React.useState<string>(archetypes?.archetypes?.[0]?.name ?? 'No Archetype found');
  const [selectedImageMask, setSelectedImageMask] = React.useState<string>(imageMasks?.image_masks?.[0]?.name ?? 'No Image Mask found');
  const [selectedCharacterHeight, setSelectedCharacterHeight] = React.useState<string>(characterHeights?.character_heights?.[0]?.name ?? 'No Character Height found');

  const selectedGenerationSku = '65389a2c86a07d599cb662ba';
  const selectedImageGenerationStyleId = '6524e69e381386a2148c5470';
  const selectedPromptTemplateId = '6524e471f3ef6b46b9d3a141';

  const generateImagesFromSelection = async () => {
    const GenerateRequest: GenerationNewImagesVariables = {
      body: {
        archtypesId: selectedArchetype,
        bodyShapeId: selectedBodyShape,
        characterHeightId: selectedCharacterHeight,
        classId: selectedClass,
        eyesId: selectedEyes,
        genderId: selectedGender,
        generationSkuId: selectedGenerationSku,
        hairId: selectedHair,
        imageGenerationStyleId: selectedImageGenerationStyleId,
        maskId: selectedImageMask,
        personalityId: selectedPersonality,
        promptTemplateId: selectedPromptTemplateId,
        raceId: selectedRace,
        skinId: selectedSkin,
        weaponId: selectedWeapon,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const workflowResult = await fetchGenerationNewImages(GenerateRequest);
    console.log('workflowResult: ', workflowResult);
    // start checking workflow (max 30 tried (1min))
    const { workflowId: currentWorkflowId } = workflowResult;
    setWorkflowId(currentWorkflowId ?? -1);
  };
  return (
    <Container>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Hair</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedHair}
            onChange={(e) => setSelectedHair(e.target.value as string)}
          >
            {/* @ts-ignore */}
            {hair?.hairs?.map((h) => <MenuItem key={h._id} value={h._id}>{h.name}</MenuItem>)}
          </Select>

          <Typography variant="h6">Eyes</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedEyes}
            onChange={(e) => setSelectedEyes(e.target.value as string)}
          >
            {/* @ts-ignore */}
            {eyes?.eyes?.map((e) => <MenuItem key={e._id} value={e._id}>{e.name}</MenuItem>)}
          </Select>

          <Typography variant="h6">Skin</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedSkin}
            onChange={(e) => setSelectedSkin(e.target.value as string)}
          >
            {/* @ts-ignore */}
            {skin?.skins?.map((s) => <MenuItem key={s._id} value={s._id}>{s.name}</MenuItem>)}
          </Select>

          <Typography variant="h6">Race</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedRace}
            onChange={(e) => setSelectedRace(e.target.value as string)}
          >
            {/* @ts-ignore */}
            {races?.races?.map((r) => <MenuItem key={r._id} value={r._id}>{r.name}</MenuItem>)}
          </Select>

          <Typography variant="h6">Gender</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value as string)}
          >
            {/* @ts-ignore */}
            {genders?.genders?.map((g) => <MenuItem key={g._id} value={g._id}>{g.name}</MenuItem>)}
          </Select>

          <Typography variant="h6">Body Shape</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedBodyShape}
            onChange={(e) => setSelectedBodyShape(e.target.value as string)}
          >

            {bodyShapes?.body_shapes?.map((bs) => (
            /* @ts-ignore */
              <MenuItem key={bs._id} value={bs._id}>{bs.name}</MenuItem>
            ))}
          </Select>

          <Typography variant="h6">Class</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value as string)}
          >
            {classes?.classes?.map((c) => (
            /* @ts-ignore */
              <MenuItem key={c._id} value={c._id}>{c.name}</MenuItem>
            ))}
          </Select>

          <Typography variant="h6">Weapon</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedWeapon}
            onChange={(e) => setSelectedWeapon(e.target.value as string)}
          >
            {/* @ts-ignore */}
            {weapons?.weapons?.map((w) => <MenuItem key={w._id} value={w._id}>{w.name}</MenuItem>)}
          </Select>

          <Typography variant="h6">Personality</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedPersonality}
            onChange={(e) => setSelectedPersonality(e.target.value as string)}
          >
            {personalities?.personalities?.map((p) => (
            /* @ts-ignore */
              <MenuItem key={p._id} value={p._id}>{p.name}</MenuItem>
            ))}
          </Select>

          <Typography variant="h6">Archetype</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedArchetype}
            onChange={(e) => setSelectedArchetype(e.target.value as string)}
          >
            {archetypes?.archetypes?.map((a) => (
            /* @ts-ignore */
              <MenuItem key={a._id} value={a._id}>{a.name}</MenuItem>
            ))}
          </Select>

          <Typography variant="h6">Image Mask</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedImageMask}
            onChange={(e) => setSelectedImageMask(e.target.value as string)}
          >
            {imageMasks?.image_masks?.map((im) => (
            /* @ts-ignore */
              <MenuItem key={im._id} value={im._id}>{im.name}</MenuItem>
            ))}
          </Select>

          <Typography variant="h6">Character Height</Typography>
          <Select
            style={{ minWidth: 300 }}
            value={selectedCharacterHeight}
            onChange={(e) => setSelectedCharacterHeight(e.target.value as string)}
          >
            {characterHeights?.character_heights?.map((ch) => (
            /* @ts-ignore */
              <MenuItem key={ch._id} value={ch._id}>{ch.name}</MenuItem>
            ))}
          </Select>
          <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={generateImagesFromSelection}>
            Generate
          </Button>
        </Box>
        <Box>
          <GeneratedImages workflowId={workflowId} />
        </Box>
      </Box>
    </Container>
  );
};

export default GenerateImages;
