import React from 'react';
import { Outlet } from 'react-router-dom';
import s from './DashboardLayout.module.scss';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import Notification from '../../components/notifications/Notification';

/**
 * Layout component for the dashboard, containing the header and main content area.
 *
 * @component
 * @example
 * // Example usage of DashboardLayout component
 * <DashboardLayout />
 *
 * @returns {JSX.Element} React element representing the dashboard layout.
 */

const DashboardLayout = () => (
  <div className={s.layout}>
    <DashboardHeader />
    <main className={`${s.layout__main}`}>
      <Outlet />
    </main>
    <Notification />
  </div>
);

export default DashboardLayout;
