/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import NavBar from '../../../components/nav/DashboardTopNav/NavBar';
import Notification from '../../../components/notifications/Notification';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  GetRealmDocument,
  GetUserWalletDocument,
} from '../../../__generated__/graphql';
import appStore from '../../../store/AppStore';
import { getAvaliableCredits } from '../Dashboard';
import { useAuth } from '../../../hooks/use-auth';

function RealmDashboard() {
  useEffect(() => {
    localStorage.removeItem('activeFrame');
    localStorage.removeItem('activeForeground');
    localStorage.removeItem('activeBackground');
  }, []);

  const { id } = useParams();

  const auth = useAuth();

  const { data: userWallet } = useGraphQL(GetUserWalletDocument, {
    query: {
      _id: auth?.user?.uid,
    },
  });

  appStore.setState({
    userName: auth?.user?.displayName as string,
    userCredits: getAvaliableCredits(userWallet ?? {}),
  });

  const userCredits: number = appStore((state) => state.userCredits);

  const userName: string = appStore((state) => state.userName as string);

  const { data: realm } = useGraphQL(GetRealmDocument, {
    query: {
      _id: id,
    },
  });
  if (realm && realm !== undefined) {
    appStore.setState({
      selectedRealm: realm.realm,
    });
  }

  return (
    <>
      <NavBar
        tabs={[
          { name: 'Campaign', path: 'campaign' },
          { name: 'Characters', path: 'character' },
          { name: 'Token Maker', path: 'tokenmaker' },
        ]}
        userName={userName}
        userCredits={userCredits}
      />
      <Box sx={{ paddingTop: '48px' }}>
        <Outlet />
      </Box>
      <Notification />
    </>
  );
}

export default RealmDashboard;
