import React from 'react';
import {
  Box, Card, Grid, Typography, styled,
} from '@mui/material';
import {
  ReactiveList, SearchBox,
} from '@appbaseio/reactivesearch';
import { useAuth } from '../../../hooks/use-auth';
import FilterRealms from '../../../images/filter-icon.png';
import PlusIcon from '../../../images/plus-icon.png';
import FavoritesIcon from '../../../images/favorites-icon.svg';
import FantasyIcon from '../../../images/fantasy-icon.png';
import CreateRealmPopup from '../../../components/modals/CreateRealmPopup/CreateRealmPopup';
import {
  CreateRealmDocument, RealmInsertInput,
} from '../../../__generated__/graphql';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import RenderMediaAssets from '../../../components/RenderMediaAssets/RenderMediaAssets';

const StyledImage = styled('img')({
  width: '50px',
  height: '50px',
  flexShrink: 0,
  cursor: 'pointer',
});
const TopImage = styled('img')({
  width: '25px',
  height: '25px',
  flexShrink: 0,
  cursor: 'pointer',
});

export default function LibraryContentPacksExample(): JSX.Element {
  const auth = useAuth();
  const [openCreateRealmPopupModal, setopenCreateRealmPopupModal] = React.useState(false);
  const { mutate: createRealm, error: createRealmError } = useGraphQLMutation(
    CreateRealmDocument,
  );
  const handleCreateRealm = async (props: any) => {
    const { realmName, realmBio, tags } = props;
    const userInput: RealmInsertInput = {
      name: realmName,
      description: realmBio,
      tags,
      is_favorited: true,
      is_public: false,
      owner: {
        link: auth?.realmUser?.id,
      },
    };
    try {
      // Use the createRealm mutation function from your GraphQL client
      const result = await createRealm({
        data: userInput,
      });

      console.log('Realm created:', result);
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error creating realm:', error.message);
      // Handle unexpected errors
    }
  };
  console.log('createRealmError', createRealmError);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
      }}
    >
      <CreateRealmPopup
        open={openCreateRealmPopupModal}
        onClose={() => { console.log('modal closed'); }}
        setOpen={setopenCreateRealmPopupModal}
        createRealmHandler={handleCreateRealm}
      />
      <Box
        display="flex"
        justifyContent="end"
      >
        <Box sx={{
          width: '366px',
          height: '40px',
          flexShrink: 0,
          borderRadius: '10px',
          background: '#FFF',
        }}
        >
          {/* Hide this box */}
          {/* <Box sx={{
            display: 'none',
          }}
          > */}
          {/* <SingleList
            componentId="fav-multilist"
            compoundClause="must"
            dataField="isFavorited"
            showSearch={false}
            defaultValue="true"
            showCount
            showRadio
          /> */}
          {/* </Box> */}
          <SearchBox
            componentId="fav-searchbox"
            dataField={['name']}
            includeFields={['name', 'thumbnail_url', 'token_sets', 'token_image_path']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            // onKeyDown={(e, triggerQuery) => {
            //   if (e.key === 'Enter') {
            //     triggerQuery();
            //   }
            // }}
            // @ts-ignore
            // custom query to only search for realms where isFavorited = true
            customQuery={() => ({
              $search: {
                equals: {
                  path: 'is_public',
                  value: true,
                  // query: 'RWLibrary-Token-Pack',
                  // query: 'RWLibrary-Image-Pack',
                },
              },
            })}
          />
        </Box>
        {' '}
&nbsp;&nbsp;
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '91.627px',
          height: '40px',
          borderRadius: '10px',
          background: '#41521F',
        }}
        >
          {' '}
          <TopImage src={FantasyIcon} />
          <TopImage src={PlusIcon} onClick={() => setopenCreateRealmPopupModal(true)} />

        </Box>
      </Box>

      <Box sx={{ maxWidth: '80vw' }}>
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 8,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledImage src={FavoritesIcon} />
            {' '}
&nbsp;
            <Typography sx={{
              color: '#250503',
              textAlign: 'center',
              fontFamily: 'Passero One',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.1px',
            }}
            >
              Favorites
            </Typography>
          </Box>
          <StyledImage src={FilterRealms} />
        </Box>

        {/* TODO - figure out how to add default filter to only get "my favorite" realms */}
        <ReactiveList
          componentId="fav-results"
          dataField="_score"
          size={10}
          pagination
          compoundClause="must"
          includeFields={['name', 'media_assets', 'thumbnail_url', 'asset_category_type']}
          react={{
            and: ['fav-searchbox'],
          }}
          onError={(err) => console.log(err)}
          sortOptions={[
            {
              dataField: 'created_date',
              sortBy: 'desc',
              label: 'Recent',
            },
            {
              dataField: 'created_date',
              sortBy: 'asc',
              label: 'Oldest',
            },
            {
              dataField: 'name',
              sortBy: 'asc',
              label: 'Name - A-Z',
            }, {
              dataField: 'name',
              sortBy: 'desc',
              label: 'Name - Z-A',
            }]}
          showResultStats
          render={({
            data, loading, resultStats,
          }: any) => (
            <Box>
              <Typography>
                {'DEBUG: '}
                {'LOADING: '}
                { JSON.stringify(loading) }
                {' '}
                {'RESULT STATS: '}
                {JSON.stringify(resultStats)}
                {' '}
                {JSON.stringify(data)}
              </Typography>
              <Grid container spacing={2} m={1} key={data.id}>
                {data && console.log(data)}
                {data && data?.length > 0 ? (
                  data
                    .map((item: any) => (
                      <Card>
                        <Typography key={item._id} variant="h4">
                          {item.name}
                        </Typography>
                        <RenderMediaAssets mediaAssetIds={item.media_assets as string[]} />
                      </Card>
                    ))
                ) : (
                  <p>No items found.</p>
                )}
              </Grid>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}
