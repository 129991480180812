/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ThemeContext } from '../../../context/ThemeContext';
import appStore, { ViewTypeOptions } from '../../../store/AppStore';
import CharacterPlaceholder from '../../../images/character-placeholder.webp';
import { Character, GetCharactersDocument } from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';

interface Props {
  character: Character | null,
}

function CharacterCatalogCPExpansionItem({ character }: Props) {
  const { theme } = useContext(ThemeContext);
  const viewType:
  ViewTypeOptions = appStore(
    (state) => state.viewType,
  );
  const { data: fetchedCharacter } = useGraphQL(
    GetCharactersDocument,
    {
      query: {
        _id: character?._id,
      },
    },
  );
  const characteristics = [
    { label: 'str', value: character?.abilities?.strength ?? 0 },
    { label: 'dex', value: character?.abilities?.dexterity ?? 0 },
    { label: 'con', value: character?.abilities?.consitution ?? 0 },
    { label: 'int', value: character?.abilities?.intelligence ?? 0 },
    { label: 'wis', value: character?.abilities?.wisdom ?? 0 },
    { label: 'cha', value: character?.abilities?.charisma ?? 0 },
  ];
  const [selected, setSelected] = React.useState(false);
  const openCharacterSummaryModal:
  boolean = appStore(
    (state) => state.openCharacterSummaryModal,
  );
  const setOpenCharacterSummaryModal = (condition: boolean) => appStore.setState({
    openCharacterSummaryModal: condition,
  });
  const setSelectedCharacter = (char: Character | null) => appStore.setState({
    selectedCharacter: char,
  });

  const handleItemClick = () => {
    setSelected(!selected);
    setSelectedCharacter(fetchedCharacter?.characters[0] as Character);
    setOpenCharacterSummaryModal(true);
  };

  React.useEffect(() => {
    if (!openCharacterSummaryModal) setSelected(false);
  }, [openCharacterSummaryModal]);

  return (
    <Box
      sx={{
        minHeight: viewType === 'grid' ? '265px' : '220px',
        width: viewType === 'grid' ? '209px' : '32%',
        borderRadius: '5px',
        padding: '2px',
        boxSizing: 'border-box',
        border: selected ? 'solid 5px #41521F' : 'none',
        backgroundColor: theme === 'light' ? 'white' : '#141218',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        position: 'relative',
        display: 'flex',
        flexDirection: viewType === 'grid' ? 'column' : 'row',
        gap: viewType === 'grid' ? '0' : '12px',
      }}
    >
      <Box onClick={handleItemClick}>
        <img
          src={character?.character_image_thumbnail_url ?? CharacterPlaceholder}
          style={{
            height: viewType === 'grid' ? '190px' : '100%',
            width: viewType === 'grid' ? '100%' : '174px',
            objectFit: 'cover',
            borderRadius: '3px',
            cursor: 'pointer',
          }}
          alt="character"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21.28 25"
          style={{
            height: '21px',
            width: 'auto',
            cursor: 'pointer',
          }}
        >
          <path style={{ fill: (viewType === 'detail' && theme === 'light') ? '#7A3900' : 'white', strokeWidth: '0px' }} d="m21.28,6.3v17.23c0,.81-.66,1.47-1.46,1.47H1.46c-.8,0-1.46-.66-1.46-1.47V6.3c0-.81.66-1.47,1.46-1.47h7.94v11.51l-2.31-2.31c-.49-.49-1.27-.49-1.76,0-.49.49-.49,1.27,0,1.76l4.41,4.41c.24.24.57.36.88.36s.63-.12.88-.36l4.41-4.41c.49-.49.49-1.27,0-1.76-.49-.49-1.27-.49-1.76,0l-2.26,2.27V4.83h7.94c.8,0,1.46.66,1.46,1.47Z" />
          <path style={{ fill: (viewType === 'detail' && theme === 'light') ? '#7A3900' : 'white', strokeWidth: '0px' }} d="m11.88,1.24v3.59h-2.49V1.24c0-.69.56-1.24,1.24-1.24s1.24.55,1.24,1.24Z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24.22 25"
          style={{
            height: '21px',
            width: 'auto',
            cursor: 'pointer',
          }}
        >
          <path style={{ fill: (viewType === 'detail' && theme === 'light') ? '#7A3900' : 'white', strokeWidth: '0px' }} d="m23.69,15.39l-1.94-1.45c.07-.47.11-.95.11-1.44,0-.42-.03-.83-.08-1.23l1.97-1.44c.48-.35.62-1,.33-1.51l-2.19-3.9c-.29-.52-.93-.73-1.47-.5l-2.29.98c-.68-.52-1.43-.96-2.23-1.29l-.27-2.52c-.06-.59-.56-1.03-1.15-1.04L10,0c-.59,0-1.1.44-1.17,1.03l-.32,2.63c-.74.31-1.43.72-2.06,1.19l-2.47-1.09c-.54-.24-1.17-.04-1.47.47L.23,8.08c-.3.51-.17,1.17.3,1.53l2.18,1.64.03-.05c-.06.43-.09.86-.09,1.31,0,.37.02.74.06,1.1l-.03-.05-2.22,1.62c-.48.35-.62,1-.33,1.51l2.19,3.9c.29.52.93.73,1.47.5l2.48-1.06c.62.49,1.3.91,2.03,1.24l.29,2.65c.06.59.56,1.03,1.15,1.04l4.47.05c.59,0,1.1-.44,1.17-1.03l.3-2.51c.81-.31,1.57-.73,2.26-1.24l2.29,1.01c.54.24,1.17.04,1.47-.47l2.28-3.85c.3-.51.17-1.17-.3-1.53Zm-11.43,1.24c-2.29,0-4.14-1.85-4.14-4.14s1.85-4.14,4.14-4.14,4.14,1.85,4.14,4.14-1.85,4.14-4.14,4.14Z" />
        </svg>
      </Box>
      <Box
        sx={{
          py: viewType === 'grid' ? '8px' : '18px',
          px: '6px',
        }}
      >
        {/* character name and pronoun */}
        <Typography
          sx={{
            fontFamily: 'Passero One',
            fontSize: '18px',
            fontWeight: '400',
            color: theme === 'light' ? '#250503' : 'white',
            textAlign: viewType === 'grid' ? 'center' : 'left',
          }}
        >
          {character?.name}
          {viewType === 'detail' && (
            <span
              style={{
                color: theme === 'light' ? '#250503' : 'white',
                fontFamily: 'DM Sans',
                fontSize: '12px',
                fontStyle: 'italic',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '0.1px',
                paddingLeft: '3px',
              }}
            >
              {character?.pronoun ?? 'Pronouns'}
            </span>
          )}
        </Typography>
        {/* Race | Class | Gender */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: viewType === 'grid' ? 'center' : 'left',
            gap: '10px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Passero One',
              fontSize: '14px',
              fontWeight: '400',
              color: theme === 'light' ? '#250503' : 'white',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            {character?.race ?? 'Race'}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Passero One',
              fontSize: '14px',
              fontWeight: '400',
              color: theme === 'light' ? '#250503' : 'white',
              textAlign: 'center',
            }}
          >
            |
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Passero One',
              fontSize: '14px',
              fontWeight: '400',
              color: theme === 'light' ? '#250503' : 'white',
              textAlign: 'center',
            }}
          >
            {character?.class ?? 'Class'}
          </Typography>
          {viewType === 'detail' && (
            <>
              <Typography
                sx={{
                  fontFamily: 'Passero One',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: theme === 'light' ? '#250503' : 'white',
                  textAlign: 'center',
                }}
              >
                |
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Passero One',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: theme === 'light' ? '#250503' : 'white',
                  textAlign: 'center',
                }}
              >
                {character?.gender ?? 'Gender'}
              </Typography>
            </>
          )}
        </Box>
        {/* characteristics */}
        {viewType === 'detail' && (
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
              marginTop: '12px',
            }}
          >
            {characteristics.map((chr) => (
              <Box key={chr.label}>
                <Typography
                  sx={{
                    fontFamily: 'Passero One',
                    fontSize: '17px',
                    fontWeight: '400',
                    color: theme === 'light' ? '#250503' : 'white',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  {chr.label}
                </Typography>
                <Box
                  sx={{
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    borderColor: '#41521F',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'DM Sans',
                      fontSize: '16px',
                      fontWeight: '400',
                      color: theme === 'light' ? '#41521F' : 'white',
                      textAlign: 'center',
                    }}
                  >
                    {chr.value}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {/* description */}
        {viewType === 'detail' && (
          <Typography
            sx={{
              fontFamily: 'DM Sans',
              fontSize: '12px',
              fontWeight: '400',
              color: theme === 'light' ? '#250503' : 'white',
              textAlign: 'left',
              paddingTop: '12px',
            }}
          >
            <strong>Long Term Goal: </strong>
            <span>{fetchedCharacter?.characters[0]?.long_term_goals ? fetchedCharacter?.characters[0]?.long_term_goals[0]?.content : 'None'}</span>
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CharacterCatalogCPExpansionItem;
