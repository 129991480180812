import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import useNotification from '../../hooks/useNotification';
import {
  DeleteOneTokenDocument, GetTokenSetsDocument, Token, Token_Set, UpdateOneTokenSetDocument,
} from '../../__generated__/graphql';
import appStore from '../../store/AppStore';
import useGraphQL from '../../hooks/useGraphQL';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  color: 'white',
  p: 4,
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: () => void,
  setOpen?: (v: boolean) => void,
} & React.PropsWithChildren;

export default function DeleteTokenModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = () => { onClose?.(); setOpen?.(false); };

  const selectedToken :
  Token | null = appStore(
    (state) => state.selectedToken,
  );

  const setdeleteTokenModalOpen = (bool: boolean) => {
    appStore.setState({
      deleteTokenModalOpen: bool,
    });
  };

  const { data: tokenSets } = useGraphQL(
    GetTokenSetsDocument,
    {
      query: {
        tokens_in: [
          {
            _id: selectedToken?._id,
          },
        ],
      },
    },
  );

  console.log('tokenSet associated: ', tokenSets);

  const {
    mutate: updateTokenSet,
    status: updateTokenSetStatus,
  } = useGraphQLMutation(UpdateOneTokenSetDocument);

  let associatedLength = 0;
  const associatedTokenSets: Token_Set[] = tokenSets?.token_sets as Token_Set[];

  const {
    mutate: deleteToken,
    status: deleteTokenStatus,
  } = useGraphQLMutation(DeleteOneTokenDocument);

  const { displayNotification } = useNotification();

  const handleDeleteToken = () => {
    deleteToken({
      query: {
        _id: selectedToken?._id,
      },
    });
    setdeleteTokenModalOpen(false);
  };

  React.useEffect(() => {
    if (deleteTokenStatus === 'loading') {
      displayNotification({ message: 'Deleting token...', type: 'info' });
    }
    if (deleteTokenStatus === 'error') {
      displayNotification({ message: 'Failed to delete token', type: 'error' });
    }
    if (deleteTokenStatus === 'success') {
      displayNotification({ message: 'Token deleted successfully', type: 'success' });
      if (associatedTokenSets && associatedTokenSets.length > 0) {
        associatedTokenSets.forEach((tokenSet) => {
          if (tokenSet && tokenSet !== undefined) {
            const id = tokenSet._id;
            const redTokenSetTokens = tokenSet?.tokens?.map((token) => token?._id);
            updateTokenSet({
              set: {
                tokens: {
                  link: redTokenSetTokens?.filter((tid) => tid !== selectedToken?._id),
                },
              },
              query: {
                _id: id,
              },
            });
          }
        });
      }
    }
  }, [deleteTokenStatus]);

  React.useEffect(() => {
    if (updateTokenSetStatus === 'loading') {
      displayNotification({ message: 'Deleting token from associated token set...', type: 'info' });
    }
    if (updateTokenSetStatus === 'error') {
      displayNotification({ message: 'Failed to delete token from associated token set', type: 'error' });
    }
    if (updateTokenSetStatus === 'success') {
      associatedLength += 1;
      if (associatedLength === associatedTokenSets.length) {
        displayNotification({ message: 'Successfully deleted token from all associated token sets', type: 'success' });
        appStore.setState({
          selectedToken: null,
        });
      } else {
        displayNotification({ message: 'Successfully deleted token from associated token set', type: 'success' });
      }
      setdeleteTokenModalOpen(false);
    }
  }, [updateTokenSetStatus]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 9999 }}
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              mb={2}
              p={2}
              py={3}
              sx={{
                backgroundColor: 'rgba(217, 217, 217, 0.2)',
                border: '1px solid #FFFFFF',
                borderRadius: '10px',
                height: 'fit-content',
                maxHeight: 'fit-content',
                boxSizing: 'border-box',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '10px',
                  boxSizing: 'border-box',
                  width: '100%',
                }}
              >
                <img
                  src={selectedToken?.thumbnail_url ?? ''}
                  alt={selectedToken?.name ?? ''}
                  style={{
                    height: '200px',
                    width: '200px',
                    objectFit: 'contain',
                    display: 'block',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontFamily: 'Passero One',
                    fontSize: '20px',
                    fontWeight: 400,
                    textAlign: 'center',
                  }}
                >
                  {`Are you sure you want to delete ${selectedToken?.name} token?`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <Button
                    type="button"
                    sx={{
                      display: 'block',
                      fontFamily: 'Passero One',
                      fontSize: '16px',
                      fontWeight: 400,
                      padding: '10px 15px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: '#FF6746',
                      color: 'white',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#FF6746',
                        color: 'white',
                      },
                    }}
                    onClick={handleDeleteToken}
                  >
                    Delete
                  </Button>
                  <Button
                    type="button"
                    sx={{
                      display: 'block',
                      fontFamily: 'Passero One',
                      fontSize: '16px',
                      fontWeight: 400,
                      padding: '10px 15px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: 'red',
                      color: 'white',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'red',
                        color: 'white',
                      },
                    }}
                    onClick={() => setdeleteTokenModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

DeleteTokenModal.defaultProps = {
  setOpen: () => { },
};
