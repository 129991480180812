import { StageData } from '../../../redux/currentStageData';
import { TokenState } from '../../../redux/token';
import appStore from '../../../store/AppStore';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';

const setImageDropped = (condition: boolean) => {
  appStore.setState({
    imageDropped: condition,
  });
};

export const fetchImageAndConvertToBlob = async (
  imageUrl: any,
  setToken: (tokenState: TokenState) => void,
) => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      // TODO: notification that image should be reloaded onto  the Canvas
      throw new Error('Failed to fetch image');
    }
    const arrayBuffer = await response.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: response.headers.get('content-type')! });
    const defaultTokenName = `new token - ${(new Date().getMonth()) + 1}/${new Date().getDate()}/${new Date().getFullYear().toString().slice(-2)}`;
    const newToken: TokenState = {
      data: {
        id: null,
        name: defaultTokenName,
        droppedImageBlob: blob,
        droppedImageSrc: imageUrl,
        edited: true,
      },
    };
    return setToken(newToken);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const handleURLImageDrop = (
  imageUrl: string,
  createItem: (newItem: StageData | StageData[]) => void,
  removeItem: (targetItemId: string | string[]) => void,
  setToken: (tokenState: TokenState) => void,
) => {
  const imageSrc = new Image();

  removeItem('image');
  imageSrc.onload = () => {
    const width = imageSrc.naturalWidth;
    const height = imageSrc.naturalHeight;
    const newImage: StageData = {
      id: 'image',
      attrs: {
        name: 'label-target',
        dataItemType: 'image',
        x: (canvasWidth - width) / 2,
        y: (canvasHeight - height) / 2,
        width,
        height,
        src: imageUrl,
        zIndex: 5,
        draggable: true,
        brightness: 0,
        filters: null,
        updatedAt: Date.now(),
      },
    };

    createItem(newImage);
    appStore.setState({
      recentChangeName: 'Updated Image',
    });
  };

  imageSrc.src = imageUrl;
  setImageDropped(true);

  fetchImageAndConvertToBlob(imageUrl, setToken);
};
