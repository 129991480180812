import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { InputLabel, TextField } from '@mui/material';

export const RMInputLabel = styled(InputLabel)({
  color: '#000',
  fontFamily: 'Passero One',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});
export const RMName = styled(TextField)({
  '&': {
    display: 'flex',
    // margin: '0 auto',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #name': {
    fontFamily: 'Passero One',
  },
});

export const RMBio = styled(TextField)({
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '&': {
    display: 'flex',
    // margin: '0 auto',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #bio': {
    color: 'black',
    fontFamily: 'Dungeon',
  },
});

export const RMTag = styled(TextField)({
  width: '90%',

  '&': {
    display: 'flex',
    margin: '0px auto 5px auto',
    borderRadius: '4px',
    background: '#E6E6E6',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '& #tag': {
    fontFamily: 'Passero One',
  },
});

export const FinishButton = styled(LoadingButton)({
  background: '#41521F',
  borderRadius: 4,
  color: '#fff',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',
  whiteSpace: 'nowrap',
  marginLeft: 'auto', // Shift the button to the right

  '&:hover': {
    backgroundColor: '#fff',
    color: '#41521F',
  },
});

export const TagButton = styled(LoadingButton)({
  background: '#A99E68',
  borderRadius: 83,
  color: '#000',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Passero One',
  whiteSpace: 'nowrap',
  padding: 2,
  margin: '0px auto 5px auto',

  '&:hover': {
    backgroundColor: '#000',
    color: '#A99E68',
  },
});
