/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-duplicates */
import React from 'react';
import s from './MyTokens.module.scss';
import warriorIcon from '../../assets/images/NewDashboard/warrior_token.png';
import useGraphQL from '../../hooks/useGraphQL';
import { GetTokenSetsDocument } from '../../__generated__/graphql';

interface TokensProps {
  id: string;
}

/**
 * Component that displays a list of user's tokens.
 *
 * @component
 * @example
 * // Example usage of MyTokens component
 * <MyTokens id="realm123" />
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier of the realm.
 *
 * @returns {JSX.Element} React element representing a list of user's tokens.
 */

const MyTokens = ({ id }: TokensProps) => {
  const {
    data: tokens,
    error: tokenError,
    isLoading,
  } = useGraphQL(GetTokenSetsDocument, {
    query: {
      realm: {
        _id: id,
      },
    },
  });

  const hasTokens = tokens?.token_sets[0]?.tokens;

  return (
    <div className={s.container}>
      <div className={s.box}>
        <p className={s.box__heading}>My Tokens</p>
        <button type="button" className={s.box__button}>
          <img
            className={s.box__button__image}
            src={warriorIcon}
            alt="warrior icon"
          />
          <p className={s.box__button__text}>Token Maker</p>
        </button>
      </div>
      <div className={s.tokenBox}>
        {hasTokens ? (
          tokens?.token_sets[0]?.tokens
            ?.slice(-6)
            .map((token: any, index) => (
              <img
                key={index}
                src={token.thumbnail_url}
                alt="token"
                className={s.tokenBox__image}
              />
            ))
        ) : isLoading ? (
          <p className={s.tokenBox__text}>Loading tokens...please wait</p>
        ) : (
          <p className={s.tokenBox__text}>
            You have no tokens, Enter realm to create one.
          </p>
        )}
      </div>
    </div>
  );
};

export default MyTokens;
