/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */

import React, { useState } from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { IoMdSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import s from './SwiperComponent.module.scss';
import favoriteStar from '../../assets/images/full-star.png';
import errorPlaceholder from '../../assets/images/NewDashboard/image_realm.jpg';

import {
  GetRealmsDocument,
  RealmSortByInput,
} from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';
import Spinner from '../Spinners/Spinner';

/**
 * Swiper component displaying favorite realms in a carousel.
 *
 * @component
 * @example
 * // Example usage of SwiperComponent
 * <SwiperComponent />
 *
 * @returns {JSX.Element} React element representing the Swiper component.
 */

const SwiperComponent = () => {
  const navigate = useNavigate();
  const [brokenImages, setBrokenImages] = useState<string[]>([]);

  const handleImageError = (realmId: string) => {
    setBrokenImages((prevBrokenImages) => [...prevBrokenImages, realmId]);
  };

  /**
   * Handle image error by adding the realmId to the list of broken images.
   *
   * @param {string} realmId - The ID of the realm.
   * @returns {void}
   */

  const {
    data: favoriteRealms,
    error: realmsError,
    isLoading,
  } = useGraphQL(GetRealmsDocument, {
    limit: 100,
    sortBy: RealmSortByInput.CreatedatDesc,
    query: {
      is_favorited: true,
    },
  });

  if (isLoading) return <Spinner realm />;

  if (realmsError) return <div className={s.error}>An error occurred</div>;

  if (!favoriteRealms || favoriteRealms.realms.length === 0) {
    return <div className={s.error}>You have no favorite realms</div>;
  }

  console.log(favoriteRealms);

  return (
    <div className={s.swiper}>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={34}
        slidesPerView="auto"
        className={s.container}
      >
        {favoriteRealms?.realms.map((realm) => (
          <SwiperSlide key={realm?._id} className={s.slide}>
            {brokenImages.includes(realm?._id) || !realm?.thumbnail_url ? (
              <img
                className={s.slide__background}
                src={errorPlaceholder}
                alt="This is a placeholder"
                onError={() => handleImageError(realm?._id)}
              />
            ) : (
              <img
                className={s.slide__background}
                src={realm?.thumbnail_url}
                alt="realm image"
                onError={() => handleImageError(realm?._id)}
              />
            )}

            <div className={s.slide__top}>
              <img
                className={s.slide__top__star}
                src={favoriteStar}
                alt="favorite star"
              />
              <IoMdSettings />
            </div>
            <div className={s.slide__footer}>
              <div className={s.slide__info}>
                <p className={s.slide__info__heading}>{realm?.name}</p>
                <div className={s.slide__info__bottom}>
                  <div className={s.slide__info__tags}>
                    {realm?.tags?.slice(0, 3)?.map((tag, index) => (
                      <div
                        key={index}
                        className={
                          tag?.split(' ').length === 1
                            ? s.slide__info__tag
                            : s.slide__info__longTag
                        }
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                  <button
                    type="button"
                    className={s.slide__info__button}
                    onClick={() => navigate(`/realm/${realm?._id}/character`)}
                  >
                    Enter Realm
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
