/* eslint-disable no-nested-ternary */
import React from 'react';
import Image from 'mui-image';
import { Box, CircularProgress, Typography } from '@mui/material';
import useGraphQL from '../../hooks/useGraphQL';
import { GetUserGeneratedImagesDocument } from '../../__generated__/graphql';

interface GeneratedImagesProps {
  workflowId: number;
}

const GeneratedImages: React.FC<GeneratedImagesProps> = ({ workflowId }) => {
  const {
    data: generatedImages, error, isLoading, refetch, isRefetching, isRefetchError, failureReason,
  } = useGraphQL(
    GetUserGeneratedImagesDocument,
    {
      query: {
        workflow_id: workflowId,
      },
    },
  );
  console.log('workflowId: ', workflowId);
  console.log('generatedImages Result: ', generatedImages);
  console.log('isLoading: ', isLoading);
  console.log('isRefetching: ', isRefetching);
  console.log('isRefetchError: ', isRefetchError);
  console.log('failureReason: ', failureReason);
  console.log('error: ', error);

  // if generatedImages are null or an empty array, refetch every 2 seconds,
  // then stop refreshing when generatedImages has data or the refetch count is 30
  React.useEffect(() => {
    let refetchCount = 0;
    if (workflowId !== -1) {
      const interval = setInterval(() => {
        if (!generatedImages?.user_image_generations.length) {
          refetch().then((res) => {
            console.log('refetch result: ', res?.data?.user_image_generations);
          });
          console.log('refetching...');
          refetchCount += 1;
        } else {
          clearInterval(interval);
        }
        if (refetchCount === 30) {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    } return () => {};
  }, [generatedImages, refetch, workflowId]);
  return (
    <Box sx={{ height: '100%' }} display="flex" justifyContent="center" alignContent="center" flexDirection="column">
      { workflowId === -1 ? (
        <Box sx={{ height: '100%' }} display="flex" justifyContent="center" alignContent="center">
          <Typography variant="h4" component="div" gutterBottom textAlign="center">
            Fill out the form to generate your image
          </Typography>
        </Box>
      ) : (generatedImages?.user_image_generations
            && generatedImages.user_image_generations.length === 0) ? (
              <Box width="100%">
                <CircularProgress />
              </Box>
        ) : (
          generatedImages?.user_image_generations.map((userImageGeneration) => (
            <Image
              src={userImageGeneration?.image_url ?? ''}
              alt={userImageGeneration?.name ?? ''}
              key={userImageGeneration?._id ?? ''}
              width={1024}
              fit="contain"
            />
          ))
        )}
    </Box>
  );
};

export default GeneratedImages;
