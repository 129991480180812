/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import {
  ImageList,
  ImageListItem,
  CardActionArea,
  Box,
  Grid,
  Button,
  ToggleButton,
  Divider,
  TextField,
} from '@mui/material';
// import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ContentState, DraftHandleValue, Editor, EditorState } from 'draft-js';
import { StageData } from '../../../redux/currentStageData';
import useItem from '../../../hooks/useItem';
import {
  StyledTab,
  StyledTabs,
  StyledToggleButtonGroup,
  setTabId,
} from '../StyledPanel/StyledPanel';
import TabPanel from '../../tab/TabPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import ConfirmationModal from '../../modals/ConfirmationModal';
import useToken from '../../../hooks/useToken';
import styles from './notespanel.module.scss';
import { ThemeContext } from '../../../context/ThemeContext';

export default function NotesPanel() {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(0);
  };

  const [alignment, setAlignment] = React.useState('left');
  const [formats, setFormats] = React.useState(() => ['normal']);
  const [fontSize, setFontSize] = React.useState<number>(24);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    setFormats(newFormats);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const onFontSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFontSize(parseInt(event.target.value, 10));
  };

  const { token, updateToken } = useToken();

  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(),
  );

  const saveTimeoutRef = useRef<number | null>(null);
  useEffect(() => {
    // Remove old timeout if it exists
    if (saveTimeoutRef.current) {
      window.clearTimeout(saveTimeoutRef.current);
    }

    // If the user hasn't made any new changes in the last 500ms (1/2 second), save the draft
    saveTimeoutRef.current = window.setTimeout(() => {
      const notesContent = editorState
        .getCurrentContent()
        .getPlainText('\u0001');
      updateToken((prevData) => ({
        notes: {
          title: 'My Note',
          content: notesContent,
        },
      }));
    }, 500); // Adjust this time as needed

    // Cleanup on unmount
    return () => {
      if (saveTimeoutRef.current) {
        window.clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [editorState]);

  const onEditorChange = (newEditorState: EditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    const maxLength = 500;
    let notesContent = newEditorState
      .getCurrentContent()
      .getPlainText('\u0001');
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= maxLength
    ) {
      setEditorState(newEditorState);
    } else {
      const undoState = EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      );
      notesContent = undoState.getCurrentContent().getPlainText('\u0001');
      setEditorState(undoState);
    }
  };

  const handleBeforeInput = (
    chars: string,
    newEditorState: EditorState,
    eventTimeStamp: number,
  ): DraftHandleValue => {
    const MAX_LENGTH = 500;
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    if (currentContentLength > MAX_LENGTH - 1) {
      return 'handled';
    }

    return 'not-handled';
  };

  const handleReturn = (
    e: React.KeyboardEvent<{}>,
    editorState: EditorState,
  ): DraftHandleValue => {
    const MAX_RETURNS = 10;
    const returns = editorState.getCurrentContent().getBlockMap().size;

    if (returns + 1 > MAX_RETURNS) {
      return 'handled';
    }

    return 'not-handled';
  };

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Notes" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container sx={{ px: '11px', pt: '28px' }}>
          <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
            {token?.data?.notes?.content?.length > 0 ? (
              <Button
                onClick={() => setOpenConfirmationModal(true)}
                variant="contained"
                fullWidth
                className={styles.notes_panel_default_button}
                endIcon={<FontAwesomeIcon icon={solid('pen')} size="lg" />}
              >
                Edit Note
              </Button>
            ) : (
              <Button
                onClick={() => setOpenConfirmationModal(true)}
                variant="contained"
                fullWidth
                className={styles.notes_panel_default_button}
                startIcon={<FontAwesomeIcon icon={solid('plus')} size="lg" />}
              >
                New Note
              </Button>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenConfirmationModal}
      >
        {/* <Grid container>
          <Grid item mb={1} xs={12}>
            <Typography variant="h4" align="center">
              NOTES
            </Typography>
          </Grid>
          <Grid item mb={2} xs={12} md={6} sx={{ display: 'none' }}>
            <TextField
              id="outlined-font-size"
              label="Font Size"
              type="number"
              size="small"
              defaultValue={fontSize}
              onChange={onFontSizeChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid
            item
            mb={2}
            xs={12}
            md={6}
            display="flex"
            flexWrap="wrap"
            sx={{ display: 'none' }}
          >
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

            <StyledToggleButtonGroup
              size="small"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned">
                <FontAwesomeIcon color="white" icon={solid('align-left')} />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                <FontAwesomeIcon color="white" icon={solid('align-center')} />
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                <FontAwesomeIcon color="white" icon={solid('align-right')} />
              </ToggleButton>
              <ToggleButton value="justify" aria-label="justified">
                <FontAwesomeIcon color="white" icon={solid('align-justify')} />
              </ToggleButton>
            </StyledToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <StyledToggleButtonGroup
              size="small"
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
            >
              <ToggleButton value="bold" aria-label="bold">
                <FontAwesomeIcon color="white" icon={solid('bold')} />
              </ToggleButton>
              <ToggleButton value="italic" aria-label="italic">
                <FontAwesomeIcon color="white" icon={solid('italic')} />
              </ToggleButton>
              <ToggleButton value="underlined" aria-label="underlined">
                <FontAwesomeIcon color="white" icon={solid('underline')} />
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid
          container
          p={2}
          sx={{
            backgroundColor: 'rgba(217, 217, 217, 0.2)',
            border: '1px solid #FFFFFF',
            borderRadius: '10px',
            maxHeight: 360,
          }}
        >
          <Grid item mb={4} xs={12}>
            <Editor
              editorState={editorState}
              onChange={onEditorChange}
              handleBeforeInput={handleBeforeInput}
              handleReturn={handleReturn}
            />
          </Grid>
        </Grid> */}
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <StyledTab label="Notes" {...setTabId(0)} />
        </StyledTabs>
        <TabPanel value={tabValue} index={0} width="100%">
          <Grid container sx={{ px: '11px', pt: '28px' }}>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap" sx={{ display: 'none' }}>
              <TextField
                id="outlined-font-size"
                label="Font Size"
                type="number"
                size="small"
                defaultValue={fontSize}
                onChange={onFontSizeChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mb={2}
              display="flex"
              flexWrap="wrap"
              p={2}
              sx={{
                backgroundColor: 'rgba(217, 217, 217, 0.2)',
                border: '1px solid #FFFFFF',
                borderRadius: '10px',
                height: 320,
                maxHeight: 320,
              }}
            >
              <TextField
                id="multiline-text"
                multiline
                // defaultValue={text}
                placeholder="Write here"
                className={`text_editor_field ${styles.text_editor_field}`}
                fullWidth
                // onChange={onEditorChange}
                // onBlur={onEditorChange}
                variant="standard"
                minRows={5}
              />
              {/* <Editor
                editorState={editorState}
                onChange={onEditorChange}
                handleBeforeInput={handleBeforeInput}
                handleReturn={handleReturn}
              /> */}
            </Grid>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap" sx={{ justifyContent: 'flex-end', columnGap: '16px' }}>
              <Button className={`notes_editor_button ${styles.notes_editor_button} ${styles[theme]}`}>Cancel</Button>
              <Button className={`notes_editor_button ${styles.notes_editor_button} ${styles[theme]}`}>Save</Button>
            </Grid>
          </Grid>
        </TabPanel>
      </ConfirmationModal>
    </>
  );
}
