/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Box,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import {
  BookmarkImg, BookmarkTitleImg, OptionsStack, OptionsStackIcon,
  OptionsStackIconContainer, OptionsStackText, OptionsStackTextContainer, SmallHeadingText,
} from '../styledComps/styledComps';
import CustomBorder from '../../images/custom-border-image-1.webp';
import BookmarkImage from '../../images/bookmark.webp';
import FantasyIcon from '../../images/fantasy.webp';
import UploadIcon from '../../images/upload-image.webp';
import LibraryIcon from '../../images/library.webp';
// import CharacterAttributes from '../realms/Character/CharacterAttributes';
// import CharacterAbilitiesGen from '../realms/Character/CharacterAbilitiesGen';

type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
} & React.PropsWithChildren;

export default function AICharacterGenWelcomeModal({
  open,
  onClose,
  setOpen,
}: RWModalProps) {
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(URL.createObjectURL(file));
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(4px)',
          },
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '1507px',
          minHeight: '540px',
          maxHeight: '75%',
          outline: 'none',
          // overflow: 'hidden',
          boxSizing: 'border-box',
          border: '8px solid #68583D',
          borderImageSource: `url(${CustomBorder})`,
          borderImageSlice: '17',
          borderImageRepeat: 'round',
          background: 'transparent',
          backgroundColor: '#D0B17A',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            boxSizing: 'border-box',
            '&:before, &:after': {
              boxSizing: 'inherit',
            },
            pb: '20px',
          }}
        >
          {/* Bookmark Title */}
          <Box
            sx={{
              position: 'relative',
              width: '360px',
              height: '64px',
              mt: '-45px',
              ml: '-9px',
              zIndex: '10',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap="15px"
              zIndex={2}
              position="absolute"
              left="44px"
              top="50%"
              sx={{
                transform: 'translate(0%, -50%)',
              }}
            >
              <BookmarkTitleImg src={FantasyIcon} />
              <Typography
                sx={{
                  color: '#FFF',
                  fontFamily: 'Passero One',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                AI Character Generator
              </Typography>
            </Stack>
            <BookmarkImg src={BookmarkImage} />
          </Box>
          {/* Greetings */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minHeight: 'calc(540px - 43px)',
              maxHeight: 'calc(75vh - 43px)',
              boxSizing: 'border-box',
              ml: '-20px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#CFCAAD',
                borderRadius: '8px',
              },
              '&.scrollbar': {
                scrollbarWidth: 'thin',
                scrollbarColor: '#CFCAAD',
                backgroundColor: '#CFCAAD',
                borderRadius: '8px',
              },
              '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                {
                  backgroundColor: '#41521F',
                  borderRadius: '8px',
                },
            }}
          >
            <Stack pl="92px" pr="72px" py="92px" gap="40px">
              <Stack>
                <Typography
                  sx={{
                    color: '#250503',
                    fontFamily: 'Passero One',
                    fontSize: '36px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '50px',
                    letterSpacing: '.1px',
                  }}
                >
                  👋  Hello, fellow adventurer,
                </Typography>
                <Typography
                  sx={{
                    color: '#250503',
                    fontFamily: 'DM Sans',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '30px',
                    letterSpacing: '.1px',
                    mt: '30px',
                  }}
                >
                  {/* eslint-disable-next-line max-len */}
                  In our &quot;AI Generate&quot; feature, just choose basics like race, class, and gender, and our AI will magically craft the rest of your character&apos;s tale. Want full control? Fill in all details and save. Or mix it up - add some traits and let the AI do the rest. Let&apos;s create something epic together! 🐉 ✨
                </Typography>

                <Box
                  pt="25px"
                >
                  <Stack direction="row" gap="27px" alignItems="flex-end">
                    {/* Character Image Box */}
                    <Stack
                      sx={{
                        borderRadius: '4px',
                        background: '#E3D5BC',
                        width: '396px',
                      }}
                      gap="8px"
                    >
                      <SmallHeadingText pt="9px" pl="21px">Character Image</SmallHeadingText>
                      <Stack
                        height="410px"
                        alignItems="center"
                        justifyContent="center"
                        gap="38px"
                        px="38px"
                        boxSizing="border-box"
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="fileInput" style={{ width: '100%' }}>
                          <OptionsStack>
                            <OptionsStackIconContainer>
                              <OptionsStackIcon src={UploadIcon} />
                            </OptionsStackIconContainer>
                            <OptionsStackTextContainer>
                              <OptionsStackText>Upload Image</OptionsStackText>
                            </OptionsStackTextContainer>
                          </OptionsStack>
                        </label>
                        <input
                          type="file"
                          accept=".jpg,.png,.webp"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          id="fileInput"
                        />
                        <OptionsStack>
                          <OptionsStackIconContainer>
                            <OptionsStackIcon src={LibraryIcon} />
                          </OptionsStackIconContainer>
                          <OptionsStackTextContainer>
                            <OptionsStackText>My Library</OptionsStackText>
                          </OptionsStackTextContainer>
                        </OptionsStack>
                      </Stack>
                    </Stack>
                    {/* Character Attributes */}
                    {/* <CharacterAttributes /> */}
                  </Stack>
                </Box>
              </Stack>
              {/* <CharacterAbilitiesGen /> */}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

AICharacterGenWelcomeModal.defaultProps = {
  setOpen: () => {},
};
