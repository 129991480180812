import React from 'react';
import { Box } from '@mui/material';
import { FlexibleHeightImg } from '../../styledComps/styledComps';
import appStore, { CharacterWizardViewOptions } from '../../../store/AppStore';

interface Props {
  image_url: string;
  view: CharacterWizardViewOptions;
}

function CharacterWizardViewToggle({
  image_url,
  view,
}: Props) {
  const selectedView:
  CharacterWizardViewOptions = appStore(
    (state) => state.selectedCharacterWizardView,
  );
  const setSelectedView = (v: CharacterWizardViewOptions) => {
    appStore.setState({
      selectedCharacterWizardView: v,
    });
  };
  return (
    <Box
      sx={{
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={() => setSelectedView(view)}
    >
      <Box>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 80 69.26"
          width="80px"
          height="69.26px"
          style={{
            filter: 'drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.25))',
          }}
        >
          <polygon
            style={{
              fill: selectedView === view ? '#41521F' : '#7a3900',
            }}
            points="60 0 20 0 0 34.63 20 69.26 60 69.26 80 34.63 60 0"
          />
        </svg>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <FlexibleHeightImg src={image_url} width="50px" />
      </Box>
    </Box>
  );
}

export default CharacterWizardViewToggle;
