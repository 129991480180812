/* eslint-disable react/require-default-props */
import React, { ReactNode, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './floatingmenu.module.scss';

interface Props {
  children: ReactNode;
  onClick?: (event: React.SyntheticEvent) => void;
  disabled?: boolean;
  active?: boolean;
}

function FloatingMenuItem({
  children, onClick, disabled, active,
}: Props) {
  const { theme } = useContext(ThemeContext);

  return (
    <button type="button" onClick={onClick} className={`${styles.floating_menu_item} ${styles[theme]} ${active ? styles.active : ''}`} disabled={disabled}>
      {children}
    </button>
  );
}

export default FloatingMenuItem;
